import {
  ExpandMore as ExpandMoreIcon,
  FilterAlt as FilterIcon,
  FindInPage as FindIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IShelfWithRelevance } from '../../../../types/shelf-search.type';
import ShelfItem from './ShelfItem';
import { ShelfSearchResultsProps } from './types';

/**
 * 検索結果表示コンポーネント
 */
const ShelfSearchResults: React.FC<ShelfSearchResultsProps> = ({
  searchResults,
  selectedShelves,
  isSearching,
  isLoadingExistingData,
  searchTheme,
  onToggleShelf,
  getRelevanceColor,
  hasActiveFilters,
}) => {
  const muiTheme = useTheme();
  const [displayResults, setDisplayResults] = useState<IShelfWithRelevance[]>(
    [],
  );
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);

  // 検索結果が変わったら表示結果をリセット
  useEffect(() => {
    setDisplayResults(searchResults.slice(0, 30));
    setPage(0);
    setHasMore(searchResults.length > 30);
  }, [searchResults]);

  // 無限スクロール用のIntersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && hasMore && !isLoadingMore) {
          loadMoreResults();
        }
      },
      { threshold: 0.1 },
    );

    const currentLoaderRef = loaderRef.current;
    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [hasMore, isLoadingMore]);

  // 追加データの読み込み
  const loadMoreResults = useCallback(() => {
    if (!hasMore || isLoadingMore) return;

    setIsLoadingMore(true);
    const nextPage = page + 1;
    const start = nextPage * 30;
    const end = start + 30;

    // 表示用の配列に追加
    setTimeout(() => {
      const newResults = searchResults.slice(start, end);
      setDisplayResults((prev) => [...prev, ...newResults]);
      setPage(nextPage);
      setHasMore(end < searchResults.length);
      setIsLoadingMore(false);
    }, 500); // UIのスムーズさのため少し遅延
  }, [hasMore, isLoadingMore, page, searchResults]);

  // シェルフがすでに選択されているか確認
  const isShelfSelected = (shelfId?: string) => {
    return shelfId
      ? selectedShelves.some((s) => s.shelf_id === shelfId)
      : false;
  };

  return (
    <Box
      sx={{
        width: '65%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${muiTheme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          p: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: muiTheme.palette.background.default,
          borderBottom: `1px solid ${muiTheme.palette.divider}`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FindIcon
            fontSize="small"
            sx={{ mr: 1, color: muiTheme.palette.primary.main }}
          />
          <Typography variant="subtitle1" fontWeight="bold">
            検索結果 ({searchResults.length}件)
          </Typography>
        </Box>
        {hasActiveFilters && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <FilterIcon fontSize="small" color="primary" />
            <Typography variant="caption" color="primary.main">
              フィルター適用中
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          p: 2,
          bgcolor: muiTheme.palette.background.paper,
        }}
      >
        {isSearching ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 200,
            }}
          >
            <CircularProgress />
          </Box>
        ) : isLoadingExistingData ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 200,
            }}
          >
            <CircularProgress sx={{ mb: 2 }} />
            <Typography>既存シェルフデータを読み込み中...</Typography>
          </Box>
        ) : displayResults.length > 0 ? (
          <>
            {displayResults.map((shelf) => (
              <ShelfItem
                key={shelf.shelf_id || shelf.tagline2}
                shelf={shelf}
                isSelected={isShelfSelected(shelf.shelf_id)}
                onToggle={onToggleShelf}
                getRelevanceColor={getRelevanceColor}
              />
            ))}
            {/* 無限スクロール用ローダー */}
            {hasMore && (
              <Box
                ref={loaderRef}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  py: 2,
                  alignItems: 'center',
                }}
              >
                {isLoadingMore ? (
                  <CircularProgress size={24} />
                ) : (
                  <Button
                    onClick={loadMoreResults}
                    startIcon={<ExpandMoreIcon />}
                    variant="text"
                    size="small"
                  >
                    さらに読み込む
                  </Button>
                )}
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 300,
              color: muiTheme.palette.text.secondary,
            }}
          >
            {hasActiveFilters ? (
              <>
                <FilterIcon sx={{ fontSize: 40, mb: 2, opacity: 0.6 }} />
                <Typography variant="body1" align="center">
                  フィルター条件に一致する結果が見つかりませんでした。
                  <br />
                  条件を変更して再度お試しください。
                </Typography>
              </>
            ) : (
              <>
                <SearchIcon sx={{ fontSize: 40, mb: 2, opacity: 0.6 }} />
                <Typography variant="body1">
                  {searchTheme
                    ? '検索結果がありません。別のテーマで試してください。'
                    : 'テーマを入力して検索してください。'}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShelfSearchResults;
