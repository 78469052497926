import axios, { AxiosError } from 'axios';
import { getDefaultStore } from 'jotai';
import { authAtom } from '../store/auth.store';
import { clientAtom } from '../store/client.store';

const store = getDefaultStore();

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
  timeout: 60000, // 60秒のタイムアウト
});

// リクエストインターセプター
api.interceptors.request.use(
  async (config) => {
    // Googleログインのエンドポイントはトークンチェックをスキップ
    if (config.url?.endsWith('/auth/google')) {
      return config;
    }

    config.headers['Content-Type'] = 'application/json';

    // 認証トークンの設定
    const { user } = store.get(authAtom);
    if (user?.token) {
      config.headers['Authorization'] = `Bearer ${user.token}`;
    }

    // クライアントIDの設定
    const clientState = store.get(clientAtom);
    const selectedClientId = clientState.selectedClient?.clientId;
    if (selectedClientId) {
      config.headers['X-IX-Client-ID'] = selectedClientId;
    }

    return config;
  },
  (error) => {
    console.error('Request setup error:', error);
    return Promise.reject(error);
  },
);

// レスポンスインターセプター
api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    // ネットワークエラーの処理
    if (!error.response) {
      console.error('Network error:', error.message);
      return Promise.reject(new Error('ネットワークエラーが発生しました。'));
    }

    // エラーレスポンスの処理
    switch (error.response.status) {
      case 401: // 未認証
      case 403: // 権限なし
        store.set(authAtom, { type: 'logout' });
        window.location.href = '/login';
        return Promise.reject(new Error('認証エラーが発生しました。'));

      case 400: // バリデーションエラー
        return Promise.reject(error.response.data);

      case 404: // Not Found
        return Promise.reject(new Error('リソースが見つかりませんでした。'));

      case 422: // Unprocessable Entity
        return Promise.reject(error.response.data);

      case 429: // レート制限
        return Promise.reject(
          new Error(
            'リクエスト制限を超過しました。しばらく待ってから再試行してください。',
          ),
        );

      case 500: // サーバーエラー
      default:
        console.error('Server error:', error.response.data);

        // サーバーからのエラーメッセージがある場合はそれを使用
        if (
          error.response.data &&
          typeof error.response.data === 'object' &&
          'detail' in error.response.data
        ) {
          return Promise.reject(error.response.data);
        }

        return Promise.reject(new Error('サーバーエラーが発生しました。'));
    }
  },
);

// API操作用のユーティリティ関数
export const setClientId = (clientId: string) => {
  api.defaults.headers.common['X-IX-Client-ID'] = clientId;
};

export const clearAuthHeaders = () => {
  delete api.defaults.headers.common['Authorization'];
  delete api.defaults.headers.common['X-IX-Client-ID'];
};

export default api;
