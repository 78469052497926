import { Code as CodeIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdvancedStyleEditorProps } from '../../types';

/**
 * 上級者向けスタイル編集コンポーネント
 * CSSプロパティを直接編集するためのインターフェースを提供します
 */
const AdvancedStyleEditor: React.FC<AdvancedStyleEditorProps> = ({
  style,
  onStyleChange,
  title,
}) => {
  // スタイルをテキストに変換
  const styleToText = (styleObj: Record<string, string>): string => {
    return Object.entries(styleObj)
      .map(([key, value]) => `${key}: ${value};`)
      .join('\n');
  };

  // テキストをスタイルに変換
  const textToStyle = (text: string): Record<string, string> => {
    const result: Record<string, string> = {};
    const lines = text.split('\n');

    lines.forEach((line) => {
      const trimmedLine = line.trim();
      if (!trimmedLine || !trimmedLine.includes(':')) return;

      let [property, value] = trimmedLine.split(':');
      property = property.trim();

      // 末尾のセミコロンを削除
      value = value.trim();
      if (value.endsWith(';')) {
        value = value.slice(0, -1).trim();
      }

      if (property && value) {
        result[property] = value;
      }
    });

    return result;
  };

  const [cssText, setCssText] = useState(styleToText(style));
  const [error, setError] = useState<string | null>(null);

  // スタイルが変わったらテキストも更新
  useEffect(() => {
    setCssText(styleToText(style));
  }, [style]);

  // CSSテキスト変更ハンドラー
  const handleCssTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCssText(e.target.value);
  };

  // 変更を適用
  const handleApplyChanges = () => {
    try {
      const newStyle = textToStyle(cssText);
      onStyleChange(newStyle);
      setError(null);
    } catch (err) {
      console.error(err);
      setError('CSSフォーマットが正しくありません。例: "color: #ff0000;"');
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <CodeIcon sx={{ mr: 1, opacity: 0.7 }} />
        <Typography variant="subtitle2" fontWeight="bold">
          {title}
        </Typography>
      </Box>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        CSSプロパティを直接編集します。各行に「プロパティ:
        値;」の形式で入力してください。
      </Typography>

      <Paper variant="outlined" sx={{ p: 1, mb: 2 }}>
        <TextField
          fullWidth
          multiline
          rows={10}
          value={cssText}
          onChange={handleCssTextChange}
          placeholder="例:\ncolor: #333333;\nfont-size: 16px;\nmargin-bottom: 10px;"
          InputProps={{
            style: { fontFamily: 'monospace', fontSize: '14px' },
          }}
          variant="outlined"
          size="small"
        />
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleApplyChanges}
        >
          変更を適用
        </Button>
      </Box>
    </Box>
  );
};

export default AdvancedStyleEditor;
