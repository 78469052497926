// types/stg-search-shelf-query.type.ts

export enum ShelfStatus {
  DRAFT = 'DRAFT',
  DELETED = 'DELETED',
  APPROVED = 'APPROVED',
  DEPLOYED = 'DEPLOYED',
  SUSPENDED = 'SUSPENDED',
}

interface ISimilarityCheckExample {
  tagline: string;
  reason: string;
}

export interface IStgSearchShelfQuery {
  shelf_id: string;
  tagline_1: string;
  tagline_2: string;
  where_condition: string;
  category: string;
  sub_category: string;
  status: ShelfStatus;
  item_count?: number;
  updated_at?: Date;

  similarity_check_result?: string;
  similarity_check_examples?: ISimilarityCheckExample[];
  quality_score?: number;
  quality_reason?: string;
  improved_taglines?: string[];
  relevance_score?: number;
  relevance_reason?: string;
  improved_where_condition?: string;
}
