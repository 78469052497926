import {
  FilterAlt as FilterIcon,
  CategoryRounded as ItemCategoryIcon,
  Palette as PaletteIcon,
  TextFields as TextFieldsIcon,
  TuneOutlined as TuneIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { plpService } from '../../../../services/plp-service';
import { useClient } from '../../../../store/client.store';
import { Item } from '../../../../types/item-search.type';
import DisplaySettings from './DisplaySettings';
import FilterSettings from './FilterSettings';
import ItemSelectionPanel from './ItemSelectionPanel';
import ShelfConfigPreviewLayout from './ShelfConfigPreviewLayout';
import ShelfOrderPanel from './ShelfOrderPanel';
import StyleSettings from './StyleSettings';
import TaglineOverridePanel from './TaglineOverridePanel';
import { ShelfConfigBuilderProps } from './types';

/**
 * シェルフ設定ビルダーコンポーネント
 * シェルフの設定とプレビューを並べて表示する
 */
const ShelfConfigBuilder: React.FC<ShelfConfigBuilderProps> = ({
  config,
  onConfigChange,
  clientId,
  tabConfigs,
  tabOrder,
  defaultActiveTab,
}) => {
  const theme = useTheme();
  const { client: clientState } = useClient();
  const [activeTab, setActiveTab] = useState(defaultActiveTab || 0);
  const [deviceMode, setDeviceMode] = useState<'sp' | 'pc'>('sp');
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [previewHtml, setPreviewHtml] = useState<string | null>(null);
  const [previewError, setPreviewError] = useState<string | null>(null);
  const [previewItems, setPreviewItems] = useState<Item[]>([]);

  // デバイスモード変更ハンドラー
  const handleDeviceModeChange = (newMode: 'sp' | 'pc') => {
    setDeviceMode(newMode);
    updatePreview();
  };

  // 表示設定変更ハンドラー
  const handleDisplaySettingsChange = useCallback(
    (
      uiType: 'grid' | 'slider' | 'oneitem' | 'gridbanner',
      gridColumns?: number,
      columnGap?: number,
      sliderRows?: number,
      maxItems?: number,
      minItems?: number,
    ) => {
      // 表示形式が変更された場合、表示形式に応じたデフォルトの最大商品数を設定
      if (uiType !== config.uiType && maxItems === config.maxItems) {
        maxItems = uiType === 'slider' ? 15 : 6;
      }

      // シェルフオーダーがある場合、すべてのシェルフオーダーの最大商品数も更新
      let updatedShelfOrders = config.shelfOrders || [];
      if (maxItems !== config.maxItems && updatedShelfOrders.length > 0) {
        updatedShelfOrders = updatedShelfOrders.map((order) => ({
          ...order,
          maxItems: maxItems,
        }));
      }

      onConfigChange({
        ...config,
        uiType,
        gridColumns,
        columnGap,
        sliderRows,
        maxItems,
        minItems: minItems !== undefined ? minItems : 1, // デフォルト値は1
        shelfOrders: updatedShelfOrders,
      });
    },
    [config, onConfigChange],
  );

  // フィルター設定変更ハンドラー
  const handleFilterSettingsChange = useCallback(
    (brandNames: string[], categories: string[], subCategories: string[]) => {
      onConfigChange({
        ...config,
        brandNames: brandNames.length > 0 ? brandNames : undefined,
        categories: categories.length > 0 ? categories : undefined,
        subCategories: subCategories.length > 0 ? subCategories : undefined,
      });
    },
    [config, onConfigChange],
  );

  // スタイル設定変更ハンドラー
  const handleStyleSettingsChange = useCallback(
    (shelfOption?: any, taglineOption?: any, itemOption?: any) => {
      onConfigChange({
        ...config,
        shelfOption,
        taglineOption,
        itemOption,
      });
    },
    [config, onConfigChange],
  );

  // タグラインオーバーライド変更ハンドラー
  const handleTaglineOverrideChange = useCallback(
    (overrides: any[]) => {
      onConfigChange({
        ...config,
        shelfOverrides: overrides.length > 0 ? overrides : undefined,
      });
    },
    [config, onConfigChange],
  );

  // 選択AID変更ハンドラー
  const handleSelectedAidsChange = useCallback(
    (aids: string[]) => {
      onConfigChange({
        ...config,
        filteredAids: aids.length > 0 ? aids : undefined,
      });
    },
    [config, onConfigChange],
  );

  // プレビュー更新
  const updatePreview = useCallback(async () => {
    const effectiveClientId = clientId || clientState.selectedClient?.clientId;

    if (!effectiveClientId) {
      setPreviewError('プレビューにはクライアントIDが必要です');
      return;
    }

    setIsPreviewLoading(true);
    setPreviewError(null);

    try {
      // テスト用の一時的なPLPデータを作成
      const tempPlp = {
        plpId: `preview_${Date.now()}`,
        plpBlocks: [
          {
            blockType: 'shelf' as const,
            shelfConfig: config,
          },
        ],
      };

      // プレビュー生成APIを呼び出し
      const response = await plpService.buildPlp(tempPlp, effectiveClientId);

      // HTML設定
      setPreviewHtml(response.html);

      // 商品情報の取得
      if (
        response.plp &&
        response.plp.plpBlocks &&
        response.plp.plpBlocks.length > 0 &&
        response.plp.plpBlocks[0].shelfConfig?.shelves
      ) {
        const shelves = response.plp.plpBlocks[0].shelfConfig.shelves;

        // すべてのシェルフから商品を取得
        let allItems: any[] = [];
        shelves.forEach((shelf: any) => {
          if (shelf.items && Array.isArray(shelf.items)) {
            allItems = [...allItems, ...shelf.items];
          }
        });

        // 商品データを標準形式に変換
        const standardizedItems = allItems.map((item) => ({
          aid: item.aid,
          item_title: item.itemTitle || item.title || '',
          brand_name: item.brandName || item.brand || '',
          image_url: item.imageUrl || item.image_url || '',
        }));

        // 重複を排除して一意の商品リストを作成
        const uniqueItems = Array.from(
          new Map(standardizedItems.map((item) => [item.aid, item])).values(),
        );

        setPreviewItems(uniqueItems as Item[]);
      }
    } catch (error) {
      console.error('プレビュー生成エラー:', error);
      setPreviewError('プレビューの生成中にエラーが発生しました');
    } finally {
      setIsPreviewLoading(false);
    }
  }, [config, clientId, clientState.selectedClient]);

  // 初回レンダリング時とconfig変更時にプレビューを更新
  useEffect(() => {
    if (clientId || clientState.selectedClient?.clientId) {
      updatePreview();
    }
  }, [config, clientId, clientState.selectedClient]);

  // セクションタイトル用のスタイル
  const getSectionTitleStyle = (icon: React.ReactNode, color: string) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 1.5,
          pb: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 32,
            height: 32,
            borderRadius: '50%',
            bgcolor: `${color}20`,
            mr: 1.5,
          }}
        >
          {React.cloneElement(icon as React.ReactElement, {
            sx: { color: color, fontSize: '1.2rem' },
          })}
        </Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.1rem',
            color: theme.palette.text.primary,
          }}
        >
          {(icon as React.ReactElement).props.children}
        </Typography>
      </Box>
    );
  };

  // タブのデフォルト設定
  const defaultTabConfigs = [
    { id: 0, show: true }, // 表示設定
    { id: 1, show: true }, // スタイル設定
    { id: 2, show: true }, // フィルター設定
    { id: 3, show: true }, // タグライン設定
    { id: 4, show: true }, // 商品フィルター
    { id: 5, show: true }, // シェルフオーダー
  ];

  // タブの表示・非表示設定を取得
  const getTabConfigs = () => {
    return tabConfigs || defaultTabConfigs;
  };

  // 表示するタブのリストを作成
  const getVisibleTabs = () => {
    const configs = getTabConfigs();
    return configs.filter((tab) => tab.show).map((tab) => tab.id);
  };

  // タブの表示順序を決定
  const getTabOrder = () => {
    const visibleTabs = getVisibleTabs();

    // tabOrderが指定されていて、かつそれが表示するタブと一致している場合は、その順序を使用
    if (
      tabOrder &&
      tabOrder.every((id) => visibleTabs.includes(id)) &&
      tabOrder.length === visibleTabs.length
    ) {
      return tabOrder;
    }

    // 指定されていない場合はデフォルトの順序でフィルタリングして返す
    return visibleTabs.sort((a, b) => a - b);
  };

  // 現在アクティブなタブのインデックスがカスタム順序のどこにあるかを取得
  const getActiveTabIndex = () => {
    const orderedTabs = getTabOrder();
    const index = orderedTabs.indexOf(activeTab);
    return index >= 0 ? index : 0; // インデックスが見つからない場合は最初のタブを選択
  };

  // タブの内容を取得
  const getTabContent = (tabId: number) => {
    switch (tabId) {
      case 0: // 表示設定
        return (
          <Box>
            {getSectionTitleStyle(
              <ViewModuleIcon>表示設定</ViewModuleIcon>,
              theme.palette.primary.main,
            )}
            <Typography variant="body2" color="text.secondary" paragraph>
              選択したシェルフの表示方法を設定します。変更すると右側のプレビューに反映されます。
            </Typography>
            <DisplaySettings
              uiType={config.uiType || 'grid'}
              gridColumns={config.gridColumns || 3}
              columnGap={config.columnGap || 8}
              sliderRows={config.sliderRows || 1}
              maxItems={config.maxItems}
              minItems={config.minItems || 1}
              onChangeUiType={(uiType) =>
                handleDisplaySettingsChange(
                  uiType,
                  config.gridColumns,
                  config.columnGap,
                  config.sliderRows,
                  config.maxItems,
                  config.minItems,
                )
              }
              onChangeGridColumns={(gridColumns) =>
                handleDisplaySettingsChange(
                  config.uiType || 'grid',
                  gridColumns,
                  config.columnGap,
                  config.sliderRows,
                  config.maxItems,
                  config.minItems,
                )
              }
              onChangeColumnGap={(columnGap) =>
                handleDisplaySettingsChange(
                  config.uiType || 'grid',
                  config.gridColumns,
                  columnGap,
                  config.sliderRows,
                  config.maxItems,
                  config.minItems,
                )
              }
              onChangeSliderRows={(sliderRows) =>
                handleDisplaySettingsChange(
                  config.uiType || 'grid',
                  config.gridColumns,
                  config.columnGap,
                  sliderRows,
                  config.maxItems,
                  config.minItems,
                )
              }
              onChangeMaxItems={(maxItems) =>
                handleDisplaySettingsChange(
                  config.uiType || 'grid',
                  config.gridColumns,
                  config.columnGap,
                  config.sliderRows,
                  maxItems,
                  config.minItems,
                )
              }
              onChangeMinItems={(minItems) =>
                handleDisplaySettingsChange(
                  config.uiType || 'grid',
                  config.gridColumns,
                  config.columnGap,
                  config.sliderRows,
                  config.maxItems,
                  minItems,
                )
              }
            />
          </Box>
        );

      case 1: // スタイル設定
        return (
          <Box>
            {getSectionTitleStyle(
              <PaletteIcon>スタイル設定</PaletteIcon>,
              theme.palette.secondary.dark,
            )}
            <Typography variant="body2" color="text.secondary" paragraph>
              シェルフの見た目をカスタマイズします。変更はプレビューに反映されます。
            </Typography>
            <StyleSettings
              shelfOption={config.shelfOption}
              taglineOption={config.taglineOption}
              itemOption={config.itemOption}
              onChangeShelfOption={(shelfOption) =>
                handleStyleSettingsChange(
                  shelfOption,
                  config.taglineOption,
                  config.itemOption,
                )
              }
              onChangeTaglineOption={(taglineOption) =>
                handleStyleSettingsChange(
                  config.shelfOption,
                  taglineOption,
                  config.itemOption,
                )
              }
              onChangeItemOption={(itemOption) =>
                handleStyleSettingsChange(
                  config.shelfOption,
                  config.taglineOption,
                  itemOption,
                )
              }
            />
          </Box>
        );

      case 2: // フィルター設定
        return (
          <Box>
            {getSectionTitleStyle(
              <FilterIcon>フィルター設定</FilterIcon>,
              theme.palette.info.main,
            )}
            <Typography variant="body2" color="text.secondary" paragraph>
              表示する商品のフィルター条件を設定します。
            </Typography>
            <FilterSettings
              brandNames={config.brandNames || []}
              categories={config.categories || []}
              subCategories={config.subCategories || []}
              onChangeBrandNames={(brandNames) =>
                handleFilterSettingsChange(
                  brandNames,
                  config.categories || [],
                  config.subCategories || [],
                )
              }
              onChangeCategories={(categories) =>
                handleFilterSettingsChange(
                  config.brandNames || [],
                  categories,
                  config.subCategories || [],
                )
              }
              onChangeSubCategories={(subCategories) =>
                handleFilterSettingsChange(
                  config.brandNames || [],
                  config.categories || [],
                  subCategories,
                )
              }
              onResetFilters={() => handleFilterSettingsChange([], [], [])}
              showBrandFilter={true}
              showCategoryFilter={true}
              showSubCategoryFilter={true}
            />
          </Box>
        );

      case 3: // タグライン設定
        return (
          <Box>
            {getSectionTitleStyle(
              <TextFieldsIcon>タグライン設定</TextFieldsIcon>,
              theme.palette.secondary.main,
            )}
            <Typography variant="body2" color="text.secondary" paragraph>
              各シェルフのタグラインをカスタマイズできます。編集したいシェルフをクリックして展開してください。
            </Typography>
            {config.shelfOrders && config.shelfOrders.length > 0 && (
              <TaglineOverridePanel
                shelves={
                  config.shelves?.map((shelf: any) => ({
                    shelf_id: shelf.shelf_id || shelf.shelfId,
                    tagline1: shelf.tagline1,
                    tagline2: shelf.tagline2,
                    items: shelf.items,
                  })) || []
                }
                shelfOverrides={config.shelfOverrides || []}
                onUpdateOverrides={handleTaglineOverrideChange}
              />
            )}
          </Box>
        );

      case 4: // 商品フィルター
        return (
          <Box>
            {getSectionTitleStyle(
              <ItemCategoryIcon>商品フィルター</ItemCategoryIcon>,
              theme.palette.success.main,
            )}
            <Typography variant="body2" color="text.secondary" paragraph>
              特定の商品を表示から除外する場合は選択してください。プレビュー表示を更新すると商品一覧が取得されます。
            </Typography>
            <ItemSelectionPanel
              items={previewItems}
              selectedAids={config.filteredAids || []}
              onSelectedAidsChange={handleSelectedAidsChange}
              isLoading={isPreviewLoading}
            />
          </Box>
        );

      case 5: // シェルフオーダー
        return (
          <Box>
            {getSectionTitleStyle(
              <TuneIcon>シェルフオーダー</TuneIcon>,
              theme.palette.warning.main,
            )}
            <Typography variant="body2" color="text.secondary" paragraph>
              シェルフの構成を設定します。シェルフタイプを選択して追加してください。
            </Typography>
            <ShelfOrderPanel
              shelfOrders={config.shelfOrders || []}
              onUpdateShelfOrders={(orders) => {
                onConfigChange({
                  ...config,
                  shelfOrders: orders,
                });
              }}
              globalMaxItems={config.maxItems}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  // 設定パネルの内容
  const orderedTabs = getTabOrder();
  const activeTabIndex = getActiveTabIndex();

  const settingsPanel = (
    <Box>
      <Tabs
        value={activeTabIndex}
        onChange={(e, newIndex) => {
          // カスタム順序のインデックスから実際のタブIDに変換
          setActiveTab(orderedTabs[newIndex]);
        }}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mb: 2,
        }}
      >
        {orderedTabs.map((tabId) => {
          switch (tabId) {
            case 0: // 表示設定
              return (
                <Tab
                  key={tabId}
                  icon={<ViewModuleIcon fontSize="small" />}
                  iconPosition="start"
                  label="表示設定"
                  sx={{ fontSize: '0.8rem' }}
                />
              );
            case 1: // スタイル設定
              return (
                <Tab
                  key={tabId}
                  icon={<PaletteIcon fontSize="small" />}
                  iconPosition="start"
                  label="スタイル設定"
                  sx={{ fontSize: '0.8rem' }}
                />
              );
            case 2: // フィルター設定
              return (
                <Tab
                  key={tabId}
                  icon={<FilterIcon fontSize="small" />}
                  iconPosition="start"
                  label="フィルター設定"
                  sx={{ fontSize: '0.8rem' }}
                />
              );
            case 3: // タグライン設定
              return (
                <Tab
                  key={tabId}
                  icon={<TextFieldsIcon fontSize="small" />}
                  iconPosition="start"
                  label="タグライン設定"
                  sx={{ fontSize: '0.8rem' }}
                />
              );
            case 4: // 商品フィルター
              return (
                <Tab
                  key={tabId}
                  icon={<ItemCategoryIcon fontSize="small" />}
                  iconPosition="start"
                  label="商品フィルター"
                  sx={{ fontSize: '0.8rem' }}
                />
              );
            case 5: // シェルフオーダー
              return (
                <Tab
                  key={tabId}
                  icon={<TuneIcon fontSize="small" />}
                  iconPosition="start"
                  label="シェルフオーダー"
                  sx={{ fontSize: '0.8rem' }}
                />
              );
            default:
              return null;
          }
        })}
      </Tabs>

      <Box sx={{ p: 2 }}>{getTabContent(activeTab)}</Box>
    </Box>
  );

  return (
    <ShelfConfigPreviewLayout
      settingsPanel={settingsPanel}
      previewHtml={previewHtml}
      isPreviewLoading={isPreviewLoading}
      previewError={previewError}
      deviceMode={deviceMode}
      onDeviceModeChange={handleDeviceModeChange}
      onUpdatePreview={updatePreview}
      panelWidthRatio={{ settings: 45, preview: 55 }}
    />
  );
};

export default ShelfConfigBuilder;
