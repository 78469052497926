import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Search from '@mui/icons-material/Search';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import FreeWordSearch from './components/FreeWordSearch';
import { PromptBuilder } from './components/PromptBuilder';
import { PromptModuleList } from './components/PromptModuleList';
import SearchForm from './components/SearchForm';
import SearchResults from './components/SearchResults';
import { GptContextService } from './services/gptContext.service';
import {
  BuiltPrompt,
  PromptModule,
  SearchRequest,
  SearchResponse,
  SearchSession,
  Tag,
} from './services/types';

const GptContextPage: React.FC = () => {
  const [sessions, setSessions] = useState<SearchSession[]>([]);
  const [selectedSessionIds, setSelectedSessionIds] = useState<string[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedModuleIds, setSelectedModuleIds] = useState<string[]>([]);
  const [notification, setNotification] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const theme = useTheme();

  // 検索履歴の取得
  const { data: historyData, isLoading: isHistoryLoading } =
    useQuery<SearchResponse>({
      queryKey: ['searchHistory'],
      queryFn: GptContextService.getSearchHistory,
    });

  // タグ一覧の取得
  const { data: tagsData } = useQuery<Tag[]>({
    queryKey: ['tags'],
    queryFn: GptContextService.getTags,
  });

  // プロンプトモジュール一覧の取得
  const { data: modulesData, refetch: refetchModules } = useQuery<
    PromptModule[]
  >({
    queryKey: ['promptModules'],
    queryFn: GptContextService.getPromptModules,
  });

  // プロンプトの取得
  const { data: promptsData } = useQuery<BuiltPrompt[]>({
    queryKey: ['prompts'],
    queryFn: GptContextService.getPrompts,
  });

  useEffect(() => {
    if (historyData && !sessions.length) {
      setSessions(historyData.sessions);
    }
  }, [historyData, sessions.length]);

  useEffect(() => {
    if (tagsData) {
      setTags(tagsData);
    }
  }, [tagsData]);

  // プロンプト選択時にモジュールも選択
  useEffect(() => {
    if (promptsData && selectedSessionIds.length > 0) {
      const selectedPrompt = promptsData.find((prompt) =>
        prompt.session_ids.some((id) => selectedSessionIds.includes(id)),
      );
      if (selectedPrompt?.module_ids) {
        setSelectedModuleIds(selectedPrompt.module_ids);
      } else {
        setSelectedModuleIds([]); // module_idsが存在しない場合は空配列をセット
      }
    }
  }, [promptsData, selectedSessionIds]);

  const searchMutation = useMutation({
    mutationFn: GptContextService.search,
    onSuccess: (data) => {
      if (data.sessions.length > 0) {
        setSessions((prev) => [data.sessions[0], ...prev]);
      }
    },
  });

  const handleSearch = (request: SearchRequest) => {
    // タグによるフィルタリングを追加
    const searchRequest = {
      ...request,
      tag_ids: selectedTags.length > 0 ? selectedTags : undefined,
    };
    searchMutation.mutate(searchRequest);
  };

  const handleSessionUpdate = (updatedSession: SearchSession) => {
    setSessions((prev) =>
      prev.map((session) =>
        session.id === updatedSession.id ? updatedSession : session,
      ),
    );
  };

  const handleSessionSelect = (sessionId: string) => {
    setSelectedSessionIds((prev) => {
      if (prev.includes(sessionId)) {
        return prev.filter((id) => id !== sessionId);
      } else {
        return [...prev, sessionId];
      }
    });
  };

  const handleMultipleSessionSelect = (sessionIds: string[]) => {
    setSelectedSessionIds(sessionIds);
  };

  const handleModuleSelect = (moduleId: string) => {
    setSelectedModuleIds((prev) => {
      if (prev.includes(moduleId)) {
        return prev.filter((id) => id !== moduleId);
      } else {
        return [...prev, moduleId];
      }
    });
  };

  const selectedSessions = sessions.filter((session) =>
    selectedSessionIds.includes(session.id),
  );

  const selectedModules =
    modulesData?.filter((module) => selectedModuleIds.includes(module.id)) ||
    [];

  const handleFreeWordSearchSave = async (
    sessionName: string,
    selectedResults: any[],
  ) => {
    try {
      await GptContextService.saveFreeWordSearchResults(
        sessionName,
        selectedResults[0].type,
        selectedResults,
      );
      // 検索履歴を再取得
      await refetchModules();
      setNotification({
        open: true,
        message: '検索結果を保存しました',
        severity: 'success',
      });
    } catch (err) {
      console.error('検索結果の保存に失敗:', err);
      setNotification({
        open: true,
        message: '検索結果の保存に失敗しました',
        severity: 'error',
      });
    }
  };

  return (
    <Container sx={{ mt: 4, mb: 4, maxWidth: '100% !important' }}>
      {/* プロンプトビルダー */}
      <Paper sx={{ mb: 2, bgcolor: '#fff' }}>
        <PromptBuilder
          selectedSessions={selectedSessions}
          onSessionsSelect={handleMultipleSessionSelect}
          selectedModules={selectedModules}
        />
      </Paper>

      {/* コンテキスト検索&コピー */}
      <Paper sx={{ mb: 2, bgcolor: '#fff' }}>
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontWeight: 700,
              fontSize: '1.2rem',
              mb: 2,
            }}
          >
            <Search sx={{ color: 'primary.main' }} />
            コンテキスト検索&コピー
          </Typography>
          <FreeWordSearch onSaveSession={handleFreeWordSearchSave} />
          <Box sx={{ mt: 3 }}>
            <PromptModuleList
              modules={modulesData || []}
              selectedModuleIds={selectedModuleIds}
              onModuleSelect={handleModuleSelect}
              onModulesUpdate={refetchModules}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Accordion
              defaultExpanded={false}
              sx={{
                '&:hover': {
                  boxShadow: theme.shadows[2],
                },
                transition: 'box-shadow 0.2s ease-in-out',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Search
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: '1.2rem',
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 700, fontSize: '1rem' }}
                  >
                    LLM/正規表現で検索
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ mb: 2 }}>
                  <SearchForm
                    onSearch={handleSearch}
                    isLoading={searchMutation.isPending}
                    tags={tags}
                    selectedTags={selectedTags}
                    onTagsChange={setSelectedTags}
                  />
                </Box>
                <SearchResults
                  sessions={sessions}
                  isLoading={searchMutation.isPending || isHistoryLoading}
                  onSessionUpdate={handleSessionUpdate}
                  selectedSessionIds={selectedSessionIds}
                  onSessionSelect={handleSessionSelect}
                  tags={tags}
                  notification={notification}
                  setNotification={setNotification}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default GptContextPage;
