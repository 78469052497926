import { Code as CodeIcon, Palette as PaletteIcon } from '@mui/icons-material';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { IShelfOption } from '../../../../../types/plp-builder.type';
import { StylePropertyMapping } from '../types';
import AdvancedStyleEditor from './components/AdvancedStyleEditor';
import SimpleStyleEditor from './components/SimpleStyleEditor';

// シェルフスタイルのプロパティマッピング
const shelfStyleMapping: StylePropertyMapping = {
  backgroundColor: {
    type: 'color',
    label: '背景色',
    description: 'シェルフ全体の背景色を設定します',
  },
  borderRadius: {
    type: 'size',
    label: '角丸',
    min: 0,
    max: 20,
    step: 1,
    unit: 'px',
    description: 'シェルフの角を丸くします',
  },
  padding: {
    type: 'size',
    label: '内側の余白',
    min: 0,
    max: 40,
    step: 4,
    unit: 'px',
    description: 'シェルフ内側の余白を設定します',
  },
  margin: {
    type: 'size',
    label: '外側の余白',
    min: 0,
    max: 40,
    step: 4,
    unit: 'px',
    description: 'シェルフ外側の余白を設定します',
  },
  boxShadow: {
    type: 'select',
    label: '影',
    options: [
      { value: 'none', label: 'なし' },
      { value: '0 2px 4px rgba(0,0,0,0.1)', label: '弱い' },
      { value: '0 4px 8px rgba(0,0,0,0.1)', label: '標準' },
      { value: '0 8px 16px rgba(0,0,0,0.1)', label: '強い' },
    ],
    description: 'シェルフに影をつけます',
  },
  borderWidth: {
    type: 'size',
    label: '枠線の太さ',
    min: 0,
    max: 10,
    step: 1,
    unit: 'px',
    description: 'シェルフの枠線の太さを設定します',
  },
  borderColor: {
    type: 'color',
    label: '枠線の色',
    description: 'シェルフの枠線の色を設定します',
  },
  borderStyle: {
    type: 'select',
    label: '枠線のスタイル',
    options: [
      { value: 'none', label: 'なし' },
      { value: 'solid', label: '実線' },
      { value: 'dashed', label: '破線' },
      { value: 'dotted', label: '点線' },
    ],
    description: 'シェルフの枠線のスタイルを設定します',
  },
};

// フォントファミリーの選択肢
const fontFamilyOptions = [
  { value: 'inherit', label: 'デフォルト' },
  { value: "'Noto Sans JP', sans-serif", label: 'Noto Sans JP' },
  { value: "'Hiragino Kaku Gothic ProN', sans-serif", label: 'ヒラギノ角ゴ' },
  { value: "'YuGothic', 'Yu Gothic', sans-serif", label: '游ゴシック' },
  { value: "'Meiryo', sans-serif", label: 'メイリオ' },
  { value: "'Roboto', sans-serif", label: 'Roboto' },
  { value: "'Helvetica Neue', Arial, sans-serif", label: 'Helvetica' },
];

interface ShelfStylePanelProps {
  shelfOption: IShelfOption;
  onShelfOptionChange: (option: IShelfOption) => void;
}

/**
 * シェルフスタイルパネルコンポーネント
 * シェルフ全体のスタイルを設定するUIを提供します
 */
const ShelfStylePanel: React.FC<ShelfStylePanelProps> = ({
  shelfOption,
  onShelfOptionChange,
}) => {
  const [editMode, setEditMode] = useState<'simple' | 'advanced'>('simple');

  // シェルフスタイルの取得
  const shelfStyle = shelfOption.shelfStyle || {};

  // フォントファミリーの取得
  const fontFamily = shelfOption.fontFamily || 'inherit';

  // シェルフスタイル更新ハンドラー
  const handleShelfStyleChange = (newStyle: Record<string, string>) => {
    onShelfOptionChange({
      ...shelfOption,
      shelfStyle: newStyle,
    });
  };

  // フォントファミリー更新ハンドラー
  const handleFontFamilyChange = (event: SelectChangeEvent<string>) => {
    onShelfOptionChange({
      ...shelfOption,
      fontFamily: event.target.value,
    });
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          編集モード
        </Typography>
        <Tabs
          value={editMode}
          onChange={(_, newValue) => setEditMode(newValue)}
          aria-label="スタイル編集モード"
          sx={{ mb: 2 }}
        >
          <Tab
            value="simple"
            label="簡易モード"
            icon={<PaletteIcon fontSize="small" />}
            iconPosition="start"
          />
          <Tab
            value="advanced"
            label="上級者モード"
            icon={<CodeIcon fontSize="small" />}
            iconPosition="start"
          />
        </Tabs>

        {editMode === 'simple' ? (
          <>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ fontWeight: 'bold', mt: 2 }}
            >
              フォント設定
            </Typography>
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel id="font-family-label">フォントファミリー</InputLabel>
              <Select
                labelId="font-family-label"
                id="font-family"
                value={fontFamily}
                label="フォントファミリー"
                onChange={handleFontFamilyChange}
              >
                {fontFamilyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Divider sx={{ my: 2 }} />

            <SimpleStyleEditor
              style={shelfStyle}
              onStyleChange={handleShelfStyleChange}
              propertyMapping={shelfStyleMapping}
              title="シェルフスタイル"
            />
          </>
        ) : (
          <AdvancedStyleEditor
            style={shelfStyle}
            onStyleChange={handleShelfStyleChange}
            title="シェルフスタイル (CSS)"
          />
        )}
      </Box>
    </Box>
  );
};

export default ShelfStylePanel;
