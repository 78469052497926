import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  Subject as SubjectIcon,
  Title as TitleIcon,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  Modal,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ShelfTypeEnum } from '../../../../enums/shelf-type.enum';
import { itemSearchService } from '../../../../services/item-search-service';
import { useClient } from '../../../../store/client.store';
import { IItemSearchRequest, Item } from '../../../../types/item-search.type';
import {
  IPlpBlockShelfConfig,
  IShelfBlock,
} from '../../../../types/plp-builder.type';
import ShelfConfigBuilder from '../../components/ShelfConfigBuilder';
import FilterPanel from './FilterPanel';
import ItemSearchResults from './ItemSearchResults';
import SelectedItems from './SelectedItems';
import { FilterSettings, ItemSearchModalProps } from './types';

/**
 * タブパネル共通コンポーネント
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`item-search-tabpanel-${index}`}
      aria-labelledby={`item-search-tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  );
};

/**
 * 商品検索モーダルコンポーネント
 */
const ItemSearchModal: React.FC<ItemSearchModalProps> = ({
  open,
  onClose,
  onAdd,
  isEditMode = false,
  existingBlock,
  onUpdate,
}) => {
  const muiTheme = useTheme();
  const { client: clientState } = useClient();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoadingExistingData, setIsLoadingExistingData] = useState(false);

  // 関連商品検索の状態
  const [isRelatedItemsSearch, setIsRelatedItemsSearch] = useState(false);
  const [relatedItemSeedAid, setRelatedItemSeedAid] = useState<string | null>(
    null,
  );
  const [relatedItemSeedName, setRelatedItemSeedName] = useState<string | null>(
    null,
  );

  // タブ管理
  const [activeTab, setActiveTab] = useState(0);

  // シェルフ設定
  const [shelfConfig, setShelfConfig] = useState<IPlpBlockShelfConfig | null>(
    null,
  );

  // フィルター設定
  const [filterSettings, setFilterSettings] = useState<FilterSettings>({
    brandNames: [],
    categories: [],
    subCategories: [],
    itemGenders: [],
    itemTitleKeyword: '',
    descriptionKeyword: '',
    searchInDescription: true,
  });

  // フィルターパネル表示フラグ
  const [showFilterPanel, setShowFilterPanel] = useState(true);

  // ShelfConfigBuilder用のconfig生成
  const createShelfConfig = useCallback((): IPlpBlockShelfConfig => {
    // 既存の設定があればそれを使用
    if (shelfConfig) {
      // 選択した商品のAIDは更新する（常に最新の選択状態を反映）
      return {
        ...shelfConfig,
        shelfOrders: [
          {
            ...(shelfConfig.shelfOrders?.[0] || {}),
            shelfType: ShelfTypeEnum.manual,
            shelfNum: 1,
            aids: selectedItems.map((item) => item.aid),
          },
        ],
        shelves: [
          {
            shelf_id: 'manual',
            tagline1:
              shelfConfig.shelfOrders?.[0]?.tagline1Template || 'おすすめ商品',
            tagline2:
              shelfConfig.shelfOrders?.[0]?.tagline2Template ||
              '人気アイテムをピックアップ',
            items: selectedItems,
          },
        ],
      };
    }

    // 初期設定
    return {
      shelfOrders: [
        {
          shelfType: ShelfTypeEnum.manual,
          shelfNum: 1,
          aids: selectedItems.map((item) => item.aid),
          maxItems: 6,
          tagline1Template: 'おすすめ商品',
          tagline2Template: '人気アイテムをピックアップ',
        },
      ],
      uiType: 'grid',
      gridColumns: 3,
      columnGap: 8,
      sliderRows: 1,
      shelves: [
        {
          shelf_id: 'manual',
          tagline1: 'おすすめ商品',
          tagline2: '人気アイテムをピックアップ',
          items: selectedItems,
        },
      ],
    };
  }, [selectedItems, shelfConfig]);

  // ShelfConfigBuilderからのconfig変更を処理
  const handleShelfConfigChange = useCallback(
    (newConfig: IPlpBlockShelfConfig) => {
      // 設定を保存
      setShelfConfig(newConfig);
      console.log('ShelfConfig updated:', newConfig);
    },
    [],
  );

  // フィルターのアクティブ状態
  const hasActiveFilters = (settings?: FilterSettings) => {
    // 引数で渡された設定または現在の設定を使用
    const currentSettings = settings || filterSettings;

    return (
      currentSettings.brandNames.length > 0 ||
      currentSettings.categories.length > 0 ||
      currentSettings.subCategories.length > 0 ||
      currentSettings.itemGenders.length > 0 ||
      !!currentSettings.itemTitleKeyword ||
      !!currentSettings.descriptionKeyword
    );
  };

  // モーダルが開かれたときに初期検索を実行
  useEffect(() => {
    if (open && !isEditMode) {
      // モーダルが開かれたら初期検索を実行
      handleSearch();
    }
  }, [open]);

  // 既存のアイテムシェルフデータをロード（編集モード時）
  useEffect(() => {
    if (isEditMode && existingBlock && open) {
      loadExistingItemShelfData();
    }
  }, [isEditMode, existingBlock, open]);

  // 既存のアイテムシェルフデータをロードする
  const loadExistingItemShelfData = async () => {
    if (!existingBlock) return;

    try {
      setIsLoadingExistingData(true);
      setError(null);

      // 既存の設定を保存
      setShelfConfig(existingBlock.shelfConfig);

      // 商品AIDリストを取得
      const shelfConfig = existingBlock.shelfConfig;
      const shelfOrder = shelfConfig.shelfOrders[0]; // 最初のシェルフオーダーを取得
      const aids = shelfOrder.aids || [];

      if (aids.length > 0) {
        // 既存AIDで商品検索を実行
        try {
          const response = await itemSearchService.searchItemsByAids(aids);
          if (response && response.items) {
            setSelectedItems(response.items);
          } else {
            // API応答がない場合は仮データを表示
            const dummyItems: Item[] = aids.map((aid) => ({
              aid,
              ID: aid,
              key: aid,
              brand_name: '（商品情報取得中）',
              item_title: '商品情報は読み込み中です',
            }));
            setSelectedItems(dummyItems);
            setError(
              '商品詳細情報の取得に失敗しました。仮データを表示しています。',
            );
          }
        } catch (apiErr) {
          console.error('商品詳細取得エラー:', apiErr);
          // APIエラー時は仮データを表示
          const dummyItems: Item[] = aids.map((aid) => ({
            aid,
            ID: aid,
            key: aid,
            brand_name: '（商品情報取得エラー）',
            item_title: aid,
          }));
          setSelectedItems(dummyItems);
          setError(
            '商品詳細APIでエラーが発生しました。仮データを表示しています。',
          );
        }
      }
    } catch (err) {
      console.error('既存アイテムシェルフデータのロードエラー:', err);
      setError('既存アイテムシェルフデータの取得に失敗しました');
    } finally {
      setIsLoadingExistingData(false);
    }
  };

  // 検索を実行
  const handleSearch = async (customFilterSettings?: FilterSettings) => {
    // 検索中はリクエストを重複させない
    if (isSearching) return;

    setIsSearching(true);
    setError(null);
    // 検索前に結果をクリア
    setSearchResults([]);

    try {
      // 関連商品検索かどうかで処理を分岐
      if (isRelatedItemsSearch && relatedItemSeedAid) {
        // 関連商品検索を実行
        const response =
          await itemSearchService.searchRelatedItems(relatedItemSeedAid);

        // 検索結果を設定
        setSearchResults(response.items);
        console.log('関連商品検索結果:', response);
      } else {
        // 通常の検索処理
        // 引数で渡されたフィルター設定または現在のフィルター設定を使用
        const currentFilterSettings = customFilterSettings || filterSettings;

        // 検索リクエストパラメータの構築
        const searchRequest: IItemSearchRequest = {
          brand_names:
            currentFilterSettings.brandNames.length > 0
              ? currentFilterSettings.brandNames
              : undefined,
          categories:
            currentFilterSettings.categories.length > 0
              ? currentFilterSettings.categories
              : undefined,
          sub_categories:
            currentFilterSettings.subCategories.length > 0
              ? currentFilterSettings.subCategories
              : undefined,
          item_genders:
            currentFilterSettings.itemGenders.length > 0
              ? currentFilterSettings.itemGenders
              : undefined,
          item_title_keyword:
            currentFilterSettings.itemTitleKeyword || undefined,
          description_keyword: currentFilterSettings.searchInDescription
            ? currentFilterSettings.descriptionKeyword || undefined
            : undefined,
          sort_by: 'popularity', // 人気度順
          page: 1,
          limit: 50,
        };

        // APIリクエスト実行
        console.log('検索リクエスト:', searchRequest);
        const response = await itemSearchService.searchItems(searchRequest);

        // 検索結果を設定
        setSearchResults(response.items);
        console.log('検索結果:', response);
      }
    } catch (err) {
      console.error('検索エラー:', err);
      setError(
        isRelatedItemsSearch
          ? '関連商品の検索中にエラーが発生しました'
          : '商品の検索中にエラーが発生しました',
      );
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  // 関連商品検索を実行
  const handleSearchRelatedItems = async (item: Item) => {
    // 関連商品検索モードにする
    setIsRelatedItemsSearch(true);
    setRelatedItemSeedAid(item.aid);
    setRelatedItemSeedName(item.item_title);

    // 通常検索のフィルターをクリア
    const resetSettings: FilterSettings = {
      brandNames: [],
      categories: [],
      subCategories: [],
      itemGenders: [],
      itemTitleKeyword: '',
      descriptionKeyword: '',
      searchInDescription: true,
    };
    setFilterSettings(resetSettings);
    setSearchKeyword('');

    // 検索を実行
    setIsSearching(true);
    setError(null);
    setSearchResults([]);

    try {
      // 関連商品検索APIを呼び出し
      const response = await itemSearchService.searchRelatedItems(item.aid);

      // 検索結果を設定
      setSearchResults(response.items);
      console.log('関連商品検索結果:', response);

      // 検索結果がない場合はエラーメッセージを表示
      if (response.items.length === 0) {
        setError(`「${item.item_title}」の関連商品が見つかりませんでした`);
      }
    } catch (err) {
      console.error('関連商品検索エラー:', err);
      setError('関連商品の検索中にエラーが発生しました');
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  // 通常検索に戻る
  const resetToNormalSearch = () => {
    setIsRelatedItemsSearch(false);
    setRelatedItemSeedAid(null);
    setRelatedItemSeedName(null);
    setSearchResults([]);
    handleSearch(filterSettings);
  };

  // キーワード入力時の処理
  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newKeyword = event.target.value;
    setSearchKeyword(newKeyword);

    // 関連商品検索モードを解除
    if (isRelatedItemsSearch) {
      setIsRelatedItemsSearch(false);
      setRelatedItemSeedAid(null);
      setRelatedItemSeedName(null);
    }

    // フィルター設定にも検索キーワードを反映
    const newFilterSettings = {
      ...filterSettings,
      itemTitleKeyword: newKeyword,
    };

    // 説明文検索が有効な場合は説明文キーワードも設定
    if (filterSettings.searchInDescription) {
      newFilterSettings.descriptionKeyword = newKeyword;
    }

    setFilterSettings(newFilterSettings);
  };

  // キーワード入力時にEnterキーで検索
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      // 関連商品検索モードを解除
      if (isRelatedItemsSearch) {
        setIsRelatedItemsSearch(false);
        setRelatedItemSeedAid(null);
        setRelatedItemSeedName(null);
      }
      handleSearch(filterSettings);
    }
  };

  // ヘッダー検索ボタンクリック
  const handleHeaderSearchClick = () => {
    // 関連商品検索モードを解除
    if (isRelatedItemsSearch) {
      setIsRelatedItemsSearch(false);
      setRelatedItemSeedAid(null);
      setRelatedItemSeedName(null);
    }
    handleSearch(filterSettings);
  };

  // 商品選択の切り替え
  const handleToggleItem = (item: Item) => {
    setSelectedItems((prev) => {
      // 既に選択されているか確認
      const existingIndex = prev.findIndex((i) => i.aid === item.aid);

      if (existingIndex >= 0) {
        // 選択解除 (配列から削除)
        return prev.filter((i) => i.aid !== item.aid);
      } else {
        // 選択 (配列に追加)
        return [...prev, item];
      }
    });
  };

  // 選択した商品の順序を変更する
  const handleMoveItem = (fromIndex: number, toIndex: number) => {
    setSelectedItems((prev) => {
      const newOrder = [...prev];

      // 移動元のアイテムを取得
      const movedItem = newOrder[fromIndex];

      // 配列から削除
      newOrder.splice(fromIndex, 1);

      // 移動先に挿入
      newOrder.splice(toIndex, 0, movedItem);

      return newOrder;
    });
  };

  // 選択した商品をブロックとして追加または更新
  const handleAddSelectedItems = () => {
    if (selectedItems.length === 0) {
      setError('商品を選択してください');
      return;
    }

    try {
      // 最新のシェルフ設定を取得
      const config = createShelfConfig();

      // シェルフブロックの作成
      const shelfBlock: IShelfBlock = {
        blockType: 'shelf',
        shelfConfig: config,
      };

      // 編集モードの場合は更新、そうでなければ追加
      if (isEditMode && onUpdate) {
        onUpdate(shelfBlock);
      } else {
        onAdd(shelfBlock);
      }
      handleClose();
    } catch (err) {
      console.error('商品シェルフブロック追加エラー:', err);
      setError('ブロックの追加に失敗しました');
    }
  };

  // モーダルクローズ時の処理
  const handleClose = () => {
    // 状態をリセット
    setSearchKeyword('');
    setSearchResults([]);
    setSelectedItems([]);
    setError(null);
    setShelfConfig(null);
    setFilterSettings({
      brandNames: [],
      categories: [],
      subCategories: [],
      itemGenders: [],
      itemTitleKeyword: '',
      descriptionKeyword: '',
      searchInDescription: true,
    });
    setActiveTab(0);
    setShowFilterPanel(true);

    // 関連商品検索の状態もリセット
    setIsRelatedItemsSearch(false);
    setRelatedItemSeedAid(null);
    setRelatedItemSeedName(null);

    // モーダルを閉じる
    onClose();
  };

  // フィルター設定の更新と検索実行
  const updateFilterSettings = (newSettings: Partial<FilterSettings>) => {
    // 関連商品検索モードを解除
    if (isRelatedItemsSearch) {
      setIsRelatedItemsSearch(false);
      setRelatedItemSeedAid(null);
      setRelatedItemSeedName(null);
    }

    // 最新のフィルター設定を生成
    const updatedSettings = { ...filterSettings, ...newSettings };

    // フィルター設定の状態を更新
    setFilterSettings(updatedSettings);

    // モーダルが開いていて、初期ロード中でない場合
    if (open && !isLoadingExistingData) {
      // 初期値以外の場合は検索を実行
      const hasActiveFilters =
        updatedSettings.brandNames.length > 0 ||
        updatedSettings.categories.length > 0 ||
        updatedSettings.subCategories.length > 0 ||
        updatedSettings.itemGenders.length > 0 ||
        !!updatedSettings.itemTitleKeyword ||
        !!updatedSettings.descriptionKeyword;

      if (hasActiveFilters) {
        // 更新されたフィルター設定を直接handleSearchに渡す
        // これによりReactの状態更新を待たずに最新の値を使用できる
        handleSearch(updatedSettings);
      }
    }
  };

  // フィルターのリセット
  const resetFilters = () => {
    // 関連商品検索モードを解除
    if (isRelatedItemsSearch) {
      setIsRelatedItemsSearch(false);
      setRelatedItemSeedAid(null);
      setRelatedItemSeedName(null);
    }

    // リセットされたフィルター設定
    const resetSettings: FilterSettings = {
      brandNames: [],
      categories: [],
      subCategories: [],
      itemGenders: [],
      itemTitleKeyword: '',
      descriptionKeyword: '',
      searchInDescription: true,
    };

    // フィルター設定をリセット
    setFilterSettings(resetSettings);

    // リセット後に検索を実行
    if (open && !isLoadingExistingData) {
      // リセットされたフィルター設定を直接handleSearchに渡す
      handleSearch(resetSettings);
    }
  };

  // タブ切り替え
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // モーダルのタイトルを取得
  const getModalTitle = () => {
    if (isEditMode) {
      const count = selectedItems.length;
      return `手動商品シェルフの編集 (${count}件選択中)`;
    }
    return '商品検索（手動選択）';
  };

  // 選択済みAIDのリストを表示用テキストとして取得
  const getSelectedAidsText = () => {
    if (!isEditMode || selectedItems.length === 0) return null;

    const aids = selectedItems.map((item) => item.aid);
    if (aids.length <= 5) {
      return aids.join(', ');
    }
    // 多すぎる場合は省略形で表示
    return `${aids.slice(0, 3).join(', ')}... 他${aids.length - 3}件`;
  };

  // シェルフビルダーのタブカスタマイズ設定
  const shelfBuilderConfig = {
    // 「表示設定」「タグライン設定」「スタイル設定」のタブのみを表示し、
    // フィルター設定と商品フィルターのタブを非表示にするための設定
    tabConfigs: [
      { id: 0, show: true }, // 表示設定
      { id: 1, show: true }, // スタイル設定
      { id: 2, show: false }, // フィルター設定
      { id: 3, show: true }, // タグライン設定
      { id: 4, show: false }, // 商品フィルター
    ],
    // タブの表示順序をカスタマイズする設定
    tabOrder: [3, 0, 1],
    defaultActiveTab: 3,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="item-search-modal-title"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          height: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: '4px 4px 0 4px',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* ヘッダー */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" id="item-search-modal-title">
              {getModalTitle()}
            </Typography>

            {isEditMode && selectedItems.length > 0 && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ mt: 0.5 }}
              >
                選択済みAID: {getSelectedAidsText()}
              </Typography>
            )}

            {error && (
              <Typography variant="caption" color="error" sx={{ mt: 0.5 }}>
                {error}
              </Typography>
            )}
          </Box>

          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* 検索ボックス (検索タブのみ表示) */}
        {activeTab === 0 && (
          <>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="商品を検索"
                value={searchKeyword}
                onChange={handleKeywordChange}
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* 関連商品検索モードのリセットボタン */}
                        {isRelatedItemsSearch && (
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={resetToNormalSearch}
                            sx={{ mr: 1, py: 0.5, px: 1, fontSize: '0.75rem' }}
                          >
                            通常検索に戻る
                          </Button>
                        )}

                        {/* 検索範囲切り替えボタン */}
                        <Tooltip title="商品名のみで検索">
                          <ToggleButtonGroup
                            value={
                              filterSettings.searchInDescription
                                ? 'both'
                                : 'name'
                            }
                            exclusive
                            onChange={(e, value) => {
                              if (value === null) return; // 何も選択されていない状態は無視
                              const newSettings = {
                                ...filterSettings,
                                searchInDescription: value === 'both',
                                descriptionKeyword:
                                  value === 'both' ? searchKeyword : '',
                              };
                              setFilterSettings(newSettings);
                            }}
                            aria-label="検索範囲"
                            size="small"
                            sx={{ mr: 1 }}
                          >
                            <ToggleButton
                              value="name"
                              sx={{
                                minWidth: 'auto',
                                padding: '4px',
                              }}
                            >
                              <Tooltip title="商品名のみで検索">
                                <TitleIcon fontSize="small" />
                              </Tooltip>
                            </ToggleButton>
                            <ToggleButton
                              value="both"
                              sx={{
                                minWidth: 'auto',
                                padding: '4px',
                              }}
                            >
                              <Tooltip title="商品名と説明文で検索">
                                <SubjectIcon fontSize="small" />
                              </Tooltip>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Tooltip>

                        {/* 検索ボタン */}
                        <IconButton
                          onClick={handleHeaderSearchClick}
                          size="small"
                        >
                          {isSearching ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SearchIcon />
                          )}
                        </IconButton>

                        {/* フィルターボタン */}
                        <Badge
                          badgeContent={hasActiveFilters() ? '!' : undefined}
                          color="primary"
                        >
                          <IconButton
                            onClick={() => setShowFilterPanel(!showFilterPanel)}
                            color={hasActiveFilters() ? 'primary' : 'default'}
                            size="small"
                            disabled={isRelatedItemsSearch}
                          >
                            <FilterListIcon />
                          </IconButton>
                        </Badge>
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* フィルターパネル */}
            <Collapse in={showFilterPanel && !isRelatedItemsSearch}>
              <Box
                sx={{
                  mt: 1,
                  bgcolor: 'white',
                  borderRadius: 1,
                  boxShadow: 1,
                  mb: 2,
                }}
              >
                <FilterPanel
                  brandNames={filterSettings.brandNames}
                  categories={filterSettings.categories}
                  subCategories={filterSettings.subCategories}
                  itemGenders={filterSettings.itemGenders}
                  onChangeBrandNames={(brandNames) =>
                    updateFilterSettings({ brandNames })
                  }
                  onChangeCategories={(categories) =>
                    updateFilterSettings({ categories })
                  }
                  onChangeSubCategories={(subCategories) =>
                    updateFilterSettings({ subCategories })
                  }
                  onChangeItemGenders={(itemGenders) =>
                    updateFilterSettings({ itemGenders })
                  }
                  onResetFilters={resetFilters}
                  onSearch={() => handleSearch()}
                />
              </Box>
            </Collapse>
          </>
        )}

        {/* タブ */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="商品選択" icon={<SearchIcon />} iconPosition="start" />
            <Tab
              label="シェルフ設定"
              icon={<SettingsIcon />}
              iconPosition="start"
            />
          </Tabs>
        </Box>

        {/* タブパネルコンテナ - 固定の高さを持ち、オーバーフローをスクロール可能に */}
        <Box sx={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
          {/* タブコンテンツ - 商品選択 */}
          <TabPanel value={activeTab} index={0}>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              {isLoadingExistingData ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CircularProgress />
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    データを読み込み中...
                  </Typography>
                </Box>
              ) : (
                <>
                  {/* 左側: 検索結果 */}
                  <ItemSearchResults
                    searchResults={searchResults}
                    selectedItems={selectedItems}
                    isSearching={isSearching}
                    onToggleItem={handleToggleItem}
                    hasActiveFilters={hasActiveFilters()}
                    onSearchRelatedItems={handleSearchRelatedItems}
                    isRelatedItemsSearch={isRelatedItemsSearch}
                    relatedItemSeedAid={relatedItemSeedAid || undefined}
                    relatedItemSeedName={relatedItemSeedName || undefined}
                  />

                  {/* 右側: 選択済み商品 */}
                  <SelectedItems
                    selectedItems={selectedItems}
                    onToggleItem={handleToggleItem}
                    onMoveItem={handleMoveItem}
                    onClearAll={() => setSelectedItems([])}
                    onSearchRelatedItems={handleSearchRelatedItems}
                  />
                </>
              )}
            </Box>
          </TabPanel>

          {/* タブコンテンツ - シェルフ設定 (ShelfConfigBuilderを使用) */}
          <TabPanel value={activeTab} index={1}>
            <Box sx={{ height: '100%', overflow: 'auto' }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, px: 2 }}
              >
                商品シェルフの設定を変更できます。表示設定、タグライン設定、スタイル設定を行ってください。
              </Typography>
              <ShelfConfigBuilder
                config={createShelfConfig()}
                onConfigChange={handleShelfConfigChange}
                clientId={clientState?.selectedClient?.clientId}
                tabConfigs={shelfBuilderConfig.tabConfigs}
                tabOrder={shelfBuilderConfig.tabOrder}
                defaultActiveTab={shelfBuilderConfig.defaultActiveTab}
              />
            </Box>
          </TabPanel>
        </Box>

        {/* フッター部分 - 固定位置 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 2,
            borderTop: `1px solid ${muiTheme.palette.divider}`,
            bgcolor: muiTheme.palette.background.default,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mr: 'auto' }}
          >
            {selectedItems.length}件の商品を選択中
          </Typography>
          <Button onClick={handleClose} sx={{ mr: 1 }} color="inherit">
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleAddSelectedItems}
            disabled={selectedItems.length === 0}
            startIcon={isEditMode ? <EditIcon /> : <AddIcon />}
          >
            {isEditMode ? 'シェルフを更新' : 'カスタムシェルフを作成'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ItemSearchModal;
