import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ElementType } from './index';

interface ElementEditorProps {
  element: ElementType | null;
  onUpdateElement: (id: number, updates: Partial<ElementType>) => void;
  onUpdateStyle: (id: number, property: string, value: string) => void;
  onUpdateGridConfig?: (
    id: number,
    config: Partial<{ columns: number; gap: number }>,
  ) => void;
  mode: 'normal' | 'advanced';
}

/**
 * 要素編集コンポーネント
 */
const ElementEditor: React.FC<ElementEditorProps> = ({
  element,
  onUpdateElement,
  onUpdateStyle,
  onUpdateGridConfig,
  mode,
}) => {
  // 現在のCSSテキスト（上級モード用）
  const [cssText, setCssText] = useState('');

  // 要素が変更されたときにCSSテキストを更新
  useEffect(() => {
    if (element && mode === 'advanced') {
      const styleText = Object.entries(element.style)
        .map(([key, value]) => `${key}: ${value}`)
        .join(';\n');
      setCssText(styleText);
    }
  }, [element, mode]);

  // 要素がない場合
  if (!element) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          編集する要素を選択してください
        </Typography>
      </Box>
    );
  }

  // CSSテキストの更新（上級モード用）
  const handleCssTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCssText(e.target.value);
  };

  // CSSテキストの適用（上級モード用）
  const handleApplyCss = () => {
    if (!element) return;

    // CSSテキストをオブジェクトに変換
    const styleObj: Record<string, string> = {};
    cssText.split(';').forEach((rule) => {
      if (!rule.trim()) return;
      const [property, value] = rule.split(':').map((item) => item.trim());
      if (property && value) {
        styleObj[property] = value;
      }
    });

    // 要素のスタイルを更新
    onUpdateElement(element.id, { style: styleObj });
  };

  // 属性の更新
  const handleAttributeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!element || !element.attributes) return;

    const { name, value } = e.target;
    const updatedAttributes = { ...element.attributes, [name]: value };
    onUpdateElement(element.id, { attributes: updatedAttributes });
  };

  // コンテンツの更新（テキスト要素用）
  const handleContentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!element) return;
    onUpdateElement(element.id, { content: e.target.value });
  };

  // グリッド設定の更新
  const handleGridConfigChange = (
    property: 'columns' | 'gap',
    value: number,
  ) => {
    if (!element || !onUpdateGridConfig) return;
    onUpdateGridConfig(element.id, { [property]: value });
  };

  // 通常モードの編集UI
  const renderNormalModeEditor = () => {
    return (
      <Box>
        <Typography variant="subtitle1" gutterBottom>
          {getElementTypeLabel(element.type)}の編集
        </Typography>

        {/* 要素タイプ別のコンテンツ編集（最上部に表示） */}
        {element.type === 'text' && (
          <Paper elevation={0} variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              テキスト内容
            </Typography>
            <TextField
              value={element.content || ''}
              onChange={handleContentChange}
              multiline
              rows={4}
              fullWidth
              placeholder="テキストを入力してください"
              variant="outlined"
              size="small"
              sx={{ mb: 1 }}
            />
          </Paper>
        )}

        {element.type === 'img' && (
          <Paper elevation={0} variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              画像設定
            </Typography>
            <TextField
              label="画像URL"
              name="src"
              value={element.attributes?.src || ''}
              onChange={handleAttributeChange}
              fullWidth
              size="small"
              sx={{ mb: 2 }}
              placeholder="https://example.com/image.jpg"
            />
            <TextField
              label="代替テキスト"
              name="alt"
              value={element.attributes?.alt || ''}
              onChange={handleAttributeChange}
              fullWidth
              size="small"
              placeholder="画像の説明"
            />
          </Paper>
        )}

        {/* 共通スタイル編集 */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            基本設定
          </Typography>

          {/* パディング */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" gutterBottom>
              パディング
            </Typography>
            <Slider
              value={parseInt(element.style.padding || '0')}
              onChange={(_, value) =>
                onUpdateStyle(element.id, 'padding', `${value}px`)
              }
              min={0}
              max={50}
              step={1}
              valueLabelDisplay="auto"
              marks={[
                { value: 0, label: '0' },
                { value: 10, label: '10px' },
                { value: 20, label: '20px' },
                { value: 30, label: '30px' },
                { value: 50, label: '50px' },
              ]}
            />
          </Box>

          {/* マージン */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" gutterBottom>
              マージン
            </Typography>
            <Slider
              value={parseInt(element.style.margin || '0')}
              onChange={(_, value) =>
                onUpdateStyle(element.id, 'margin', `${value}px`)
              }
              min={0}
              max={50}
              step={1}
              valueLabelDisplay="auto"
              marks={[
                { value: 0, label: '0' },
                { value: 5, label: '5px' },
                { value: 10, label: '10px' },
                { value: 20, label: '20px' },
                { value: 50, label: '50px' },
              ]}
            />
          </Box>

          {/* 背景色 */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" gutterBottom>
              背景色
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="color"
                value={element.style.backgroundColor || '#ffffff'}
                onChange={(e) =>
                  onUpdateStyle(element.id, 'backgroundColor', e.target.value)
                }
                style={{ width: 40, height: 40, marginRight: 8 }}
              />
              <TextField
                value={element.style.backgroundColor || ''}
                onChange={(e) =>
                  onUpdateStyle(element.id, 'backgroundColor', e.target.value)
                }
                placeholder="#ffffff"
                size="small"
              />
            </Box>
          </Box>

          {/* 角丸 */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" gutterBottom>
              角丸
            </Typography>
            <Slider
              value={parseInt(element.style.borderRadius || '0')}
              onChange={(_, value) =>
                onUpdateStyle(element.id, 'borderRadius', `${value}px`)
              }
              min={0}
              max={30}
              step={1}
              valueLabelDisplay="auto"
              marks={[
                { value: 0, label: '0' },
                { value: 5, label: '5px' },
                { value: 10, label: '10px' },
                { value: 20, label: '20px' },
                { value: 30, label: '30px' },
              ]}
            />
          </Box>

          {/* テキスト配置（画像要素以外） */}
          {element.type !== 'img' && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" gutterBottom>
                テキスト配置
              </Typography>
              <RadioGroup
                row
                value={element.style.textAlign || 'left'}
                onChange={(e) =>
                  onUpdateStyle(element.id, 'textAlign', e.target.value)
                }
              >
                <FormControlLabel
                  value="left"
                  control={<Radio />}
                  label="左揃え"
                />
                <FormControlLabel
                  value="center"
                  control={<Radio />}
                  label="中央揃え"
                />
                <FormControlLabel
                  value="right"
                  control={<Radio />}
                  label="右揃え"
                />
              </RadioGroup>
            </Box>
          )}
        </Box>

        {/* 要素タイプ別のスタイル編集 */}
        {element.type === 'container' && renderContainerEditor()}
        {element.type === 'text' && renderTextEditor()}
        {element.type === 'img' && renderImageEditor()}
        {element.type === 'grid' && renderGridEditor()}
      </Box>
    );
  };

  // コンテナ要素の編集UI
  const renderContainerEditor = () => {
    return (
      <Box>
        <Typography variant="subtitle2" gutterBottom>
          コンテナ設定
        </Typography>

        {/* ボーダー */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth size="small">
            <FormLabel>ボーダースタイル</FormLabel>
            <Select
              value={getBorderStyle(element.style)}
              onChange={(e) => {
                const value = e.target.value;
                switch (value) {
                  case 'none':
                    onUpdateStyle(element.id, 'border', 'none');
                    break;
                  case 'solid-thin':
                    onUpdateStyle(element.id, 'border', '1px solid #ccc');
                    break;
                  case 'solid-thick':
                    onUpdateStyle(element.id, 'border', '2px solid #aaa');
                    break;
                  case 'dashed':
                    onUpdateStyle(element.id, 'border', '1px dashed #999');
                    break;
                }
              }}
            >
              <MenuItem value="none">なし</MenuItem>
              <MenuItem value="solid-thin">細い実線</MenuItem>
              <MenuItem value="solid-thick">太い実線</MenuItem>
              <MenuItem value="dashed">破線</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* レイアウト方向 */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth size="small">
            <FormLabel>レイアウト方向</FormLabel>
            <RadioGroup
              row
              value={element.style.flexDirection || 'column'}
              onChange={(e) =>
                onUpdateStyle(element.id, 'flexDirection', e.target.value)
              }
            >
              <FormControlLabel
                value="column"
                control={<Radio />}
                label="縦方向"
              />
              <FormControlLabel
                value="row"
                control={<Radio />}
                label="横方向"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        {/* 幅の設定 */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth size="small">
            <FormLabel>幅</FormLabel>
            <Select
              value={element.style.width || 'auto'}
              onChange={(e) =>
                onUpdateStyle(element.id, 'width', e.target.value)
              }
            >
              <MenuItem value="auto">自動</MenuItem>
              <MenuItem value="100%">100%</MenuItem>
              <MenuItem value="75%">75%</MenuItem>
              <MenuItem value="50%">50%</MenuItem>
              <MenuItem value="300px">300px</MenuItem>
              <MenuItem value="500px">500px</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };

  // テキスト要素の編集UI
  const renderTextEditor = () => {
    return (
      <Box>
        <Typography variant="subtitle2" gutterBottom>
          テキストのスタイル
        </Typography>

        {/* フォントサイズ */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" gutterBottom>
            フォントサイズ
          </Typography>
          <Slider
            value={parseInt(element.style.fontSize || '16')}
            onChange={(_, value) =>
              onUpdateStyle(element.id, 'fontSize', `${value}px`)
            }
            min={8}
            max={72}
            step={1}
            valueLabelDisplay="auto"
            marks={[
              { value: 8, label: '8px' },
              { value: 16, label: '16px' },
              { value: 24, label: '24px' },
              { value: 36, label: '36px' },
              { value: 48, label: '48px' },
              { value: 72, label: '72px' },
            ]}
          />
        </Box>

        {/* フォントの太さ */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" gutterBottom>
            フォントの太さ
          </Typography>
          <RadioGroup
            row
            value={element.style.fontWeight || 'normal'}
            onChange={(e) =>
              onUpdateStyle(element.id, 'fontWeight', e.target.value)
            }
          >
            <FormControlLabel value="normal" control={<Radio />} label="標準" />
            <FormControlLabel value="bold" control={<Radio />} label="太字" />
            <FormControlLabel
              value="bolder"
              control={<Radio />}
              label="より太く"
            />
            <FormControlLabel
              value="lighter"
              control={<Radio />}
              label="より細く"
            />
          </RadioGroup>
        </Box>

        {/* テキストの色 */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" gutterBottom>
            テキストの色
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="color"
              value={element.style.color || '#000000'}
              onChange={(e) =>
                onUpdateStyle(element.id, 'color', e.target.value)
              }
              style={{ width: 40, height: 40, marginRight: 8 }}
            />
            <TextField
              value={element.style.color || ''}
              onChange={(e) =>
                onUpdateStyle(element.id, 'color', e.target.value)
              }
              placeholder="#000000"
              size="small"
            />
          </Box>
        </Box>

        {/* 行の高さ */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" gutterBottom>
            行の高さ
          </Typography>
          <Slider
            value={parseFloat(element.style.lineHeight || '1.5')}
            onChange={(_, value) =>
              onUpdateStyle(element.id, 'lineHeight', value.toString())
            }
            min={1}
            max={3}
            step={0.1}
            valueLabelDisplay="auto"
            marks={[
              { value: 1, label: '1.0' },
              { value: 1.5, label: '1.5' },
              { value: 2, label: '2.0' },
              { value: 2.5, label: '2.5' },
              { value: 3, label: '3.0' },
            ]}
          />
        </Box>
      </Box>
    );
  };

  // 画像要素の編集UI
  const renderImageEditor = () => {
    if (!element.attributes) return null;

    return (
      <Box>
        <Typography variant="subtitle2" gutterBottom>
          画像設定
        </Typography>

        {/* 画像URL */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <FormLabel>画像URL</FormLabel>
            <TextField
              name="src"
              value={element.attributes.src || ''}
              onChange={handleAttributeChange}
              placeholder="https://example.com/image.jpg"
              fullWidth
              margin="dense"
            />
          </FormControl>
        </Box>

        {/* 代替テキスト */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <FormLabel>代替テキスト</FormLabel>
            <TextField
              name="alt"
              value={element.attributes.alt || ''}
              onChange={handleAttributeChange}
              placeholder="画像の説明"
              fullWidth
              margin="dense"
            />
          </FormControl>
        </Box>

        {/* 表示幅 */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth size="small">
            <FormLabel>表示幅</FormLabel>
            <Select
              value={element.style.maxWidth || '100%'}
              onChange={(e) =>
                onUpdateStyle(element.id, 'maxWidth', e.target.value)
              }
            >
              <MenuItem value="100%">100%</MenuItem>
              <MenuItem value="75%">75%</MenuItem>
              <MenuItem value="50%">50%</MenuItem>
              <MenuItem value="25%">25%</MenuItem>
              <MenuItem value="300px">300px</MenuItem>
              <MenuItem value="200px">200px</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* 配置 */}
        <Box sx={{ mb: 2 }}>
          <FormLabel>配置</FormLabel>
          <RadioGroup
            row
            value={getImageAlignment(element.style)}
            onChange={(e) => {
              const value = e.target.value;
              // スタイルをリセット
              const baseStyle = { ...element.style };
              delete baseStyle.marginLeft;
              delete baseStyle.marginRight;

              // 選択した配置に基づいてスタイルを適用
              switch (value) {
                case 'left':
                  onUpdateElement(element.id, {
                    style: {
                      ...baseStyle,
                      marginLeft: '0',
                      marginRight: 'auto',
                    },
                  });
                  break;
                case 'center':
                  onUpdateElement(element.id, {
                    style: {
                      ...baseStyle,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    },
                  });
                  break;
                case 'right':
                  onUpdateElement(element.id, {
                    style: {
                      ...baseStyle,
                      marginLeft: 'auto',
                      marginRight: '0',
                    },
                  });
                  break;
              }
            }}
          >
            <FormControlLabel value="left" control={<Radio />} label="左揃え" />
            <FormControlLabel
              value="center"
              control={<Radio />}
              label="中央揃え"
            />
            <FormControlLabel
              value="right"
              control={<Radio />}
              label="右揃え"
            />
          </RadioGroup>
        </Box>

        {/* ボーダー */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth size="small">
            <FormLabel>ボーダー</FormLabel>
            <Select
              value={getBorderStyle(element.style)}
              onChange={(e) => {
                const value = e.target.value;
                switch (value) {
                  case 'none':
                    onUpdateStyle(element.id, 'border', 'none');
                    break;
                  case 'solid-thin':
                    onUpdateStyle(element.id, 'border', '1px solid #ccc');
                    break;
                  case 'solid-thick':
                    onUpdateStyle(element.id, 'border', '2px solid #aaa');
                    break;
                }
              }}
            >
              <MenuItem value="none">なし</MenuItem>
              <MenuItem value="solid-thin">細い枠線</MenuItem>
              <MenuItem value="solid-thick">太い枠線</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };

  // グリッド要素の編集UI
  const renderGridEditor = () => {
    if (!element.gridConfig) return null;

    return (
      <Box>
        <Typography variant="subtitle2" gutterBottom>
          グリッド設定
        </Typography>

        {/* 列数 */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" gutterBottom>
            列数: {element.gridConfig.columns}
          </Typography>
          <Slider
            value={element.gridConfig.columns}
            onChange={(_, value) =>
              handleGridConfigChange('columns', value as number)
            }
            min={1}
            max={6}
            step={1}
            marks={[
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 6, label: '6' },
            ]}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* 間隔 */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" gutterBottom>
            間隔: {element.gridConfig.gap}px
          </Typography>
          <Slider
            value={element.gridConfig.gap}
            onChange={(_, value) =>
              handleGridConfigChange('gap', value as number)
            }
            min={0}
            max={30}
            step={1}
            marks={[
              { value: 0, label: '0' },
              { value: 10, label: '10px' },
              { value: 20, label: '20px' },
              { value: 30, label: '30px' },
            ]}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* ボーダースタイル */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth size="small">
            <FormLabel>ボーダースタイル</FormLabel>
            <Select
              value={getBorderStyle(element.style)}
              onChange={(e) => {
                const value = e.target.value;
                switch (value) {
                  case 'none':
                    onUpdateStyle(element.id, 'border', 'none');
                    break;
                  case 'solid-thin':
                    onUpdateStyle(element.id, 'border', '1px solid #ccc');
                    break;
                  case 'dashed':
                    onUpdateStyle(element.id, 'border', '1px dashed #999');
                    break;
                }
              }}
            >
              <MenuItem value="none">なし</MenuItem>
              <MenuItem value="solid-thin">細い実線</MenuItem>
              <MenuItem value="dashed">破線</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* 幅の設定 */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth size="small">
            <FormLabel>幅</FormLabel>
            <Select
              value={element.style.width || 'auto'}
              onChange={(e) =>
                onUpdateStyle(element.id, 'width', e.target.value)
              }
            >
              <MenuItem value="auto">自動</MenuItem>
              <MenuItem value="100%">100%</MenuItem>
              <MenuItem value="75%">75%</MenuItem>
              <MenuItem value="50%">50%</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };

  // 上級モードの編集UI
  const renderAdvancedModeEditor = () => {
    return (
      <Box>
        <Typography variant="subtitle1" gutterBottom>
          上級モード: {getElementTypeLabel(element.type)}
        </Typography>

        {/* 要素の内容編集 - 上級モードでも先頭に表示 */}
        {element.type === 'text' && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              テキスト内容
            </Typography>
            <TextField
              value={element.content || ''}
              onChange={handleContentChange}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              size="small"
              sx={{ mb: 1 }}
            />
          </Box>
        )}

        {element.type === 'img' && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              画像属性
            </Typography>
            <TextField
              label="src"
              name="src"
              value={element.attributes?.src || ''}
              onChange={handleAttributeChange}
              fullWidth
              size="small"
              sx={{ mb: 1 }}
            />
            <TextField
              label="alt"
              name="alt"
              value={element.attributes?.alt || ''}
              onChange={handleAttributeChange}
              fullWidth
              size="small"
              sx={{ mb: 1 }}
            />
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        {/* CSS直接編集 */}
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            CSSスタイル
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            直接CSSプロパティを編集できます。例：color: #ff0000;
          </Typography>
          <TextField
            value={cssText}
            onChange={handleCssTextChange}
            multiline
            rows={8}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ mb: 2, fontFamily: 'monospace' }}
            placeholder="プロパティ: 値;"
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleApplyCss}
          >
            スタイルを適用
          </Button>
        </Box>
      </Box>
    );
  };

  // 要素タイプに応じたラベルを取得
  const getElementTypeLabel = (type: string): string => {
    switch (type) {
      case 'container':
        return 'コンテナ';
      case 'text':
        return 'テキスト';
      case 'img':
        return '画像';
      case 'grid':
        return 'グリッド';
      default:
        return '要素';
    }
  };

  // ボーダースタイルを判定
  const getBorderStyle = (style: Record<string, string>): string => {
    const border = style.border || '';
    if (!border || border === 'none') return 'none';
    if (border.includes('dashed')) return 'dashed';
    if (border.includes('2px')) return 'solid-thick';
    return 'solid-thin';
  };

  // 画像の配置を判定
  const getImageAlignment = (style: Record<string, string>): string => {
    const marginLeft = style.marginLeft || '';
    const marginRight = style.marginRight || '';

    if (marginLeft === 'auto' && marginRight === 'auto') return 'center';
    if (marginLeft === 'auto') return 'right';
    return 'left';
  };

  return mode === 'normal'
    ? renderNormalModeEditor()
    : renderAdvancedModeEditor();
};

export default ElementEditor;
