import {
  EditNote as EditIcon,
  ExpandMore as ExpandMoreIcon,
  AutoFixHighRounded as MagicWandIcon,
  TextFields as TextFieldsIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IPlpShelfOverride } from '../../../../types/plp-builder.type';
import AITaglineGenerator from '../../components/AITaglineGenerator';
import { TaglineOverridePanelProps } from './types';

/**
 * タグラインオーバーライドパネルコンポーネント
 * 各シェルフのタグラインをカスタマイズするためのUIを提供します
 */
const TaglineOverridePanel: React.FC<TaglineOverridePanelProps> = ({
  shelves,
  shelfOverrides,
  onUpdateOverrides,
}) => {
  const theme = useTheme();
  const [expandedShelf, setExpandedShelf] = useState<string | false>(false);
  const [localOverrides, setLocalOverrides] = useState<IPlpShelfOverride[]>([]);

  // AIタグライン生成モーダル用の状態
  const [aiModalOpen, setAiModalOpen] = useState(false);
  const [currentShelfId, setCurrentShelfId] = useState<string>('');

  // 選択されたシェルフまたはオーバーライドが変更されたら、ローカルの状態を更新
  useEffect(() => {
    setLocalOverrides(shelfOverrides);
  }, [shelfOverrides]);

  // アコーディオンの展開状態を変更
  const handleAccordionChange =
    (shelfId: string) =>
    (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedShelf(isExpanded ? shelfId : false);
    };

  // オーバーライドを更新
  const handleOverrideChange = (
    shelfId: string,
    field: 'tagline1' | 'tagline2',
    value: string,
  ) => {
    const newOverrides = [...localOverrides];
    const overrideIndex = newOverrides.findIndex((o) => o.shelfId === shelfId);

    if (overrideIndex >= 0) {
      // 既存のオーバーライドを更新
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        [field]: value,
      };
    } else {
      // 新しいオーバーライドを追加
      newOverrides.push({
        shelfId,
        [field]: value,
      });
    }

    setLocalOverrides(newOverrides);
    onUpdateOverrides(newOverrides);
  };

  // AIタグライン生成モーダルを開く
  const handleOpenAiModal = (shelfId: string) => {
    setCurrentShelfId(shelfId);
    setAiModalOpen(true);
  };

  // AIタグライン生成結果を適用
  const handleApplyAiTagline = (tagline1?: string, tagline2?: string) => {
    if (!currentShelfId || !tagline2) return;

    const newOverrides = [...localOverrides];
    const overrideIndex = newOverrides.findIndex(
      (o) => o.shelfId === currentShelfId,
    );

    if (overrideIndex >= 0) {
      // 既存のオーバーライドを更新
      if (tagline1) {
        newOverrides[overrideIndex].tagline1 = tagline1;
      }
      newOverrides[overrideIndex].tagline2 = tagline2;
    } else {
      // 新しいオーバーライドを追加
      const override: IPlpShelfOverride = {
        shelfId: currentShelfId,
        tagline2: tagline2,
      };
      if (tagline1) {
        override.tagline1 = tagline1;
      }
      newOverrides.push(override);
    }

    setLocalOverrides(newOverrides);
    onUpdateOverrides(newOverrides);
  };

  // シェルフに対するオーバーライド情報を取得
  const getOverrideForShelf = (shelfId: string) => {
    return localOverrides.find((o) => o.shelfId === shelfId);
  };

  // シェルフが空かどうかを確認
  if (shelves.length === 0) {
    return (
      <Box sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
        <TextFieldsIcon sx={{ fontSize: 40, opacity: 0.3, mb: 1 }} />
        <Typography>
          シェルフ情報が読み込まれると、タグラインを編集できます
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {shelves.map((shelf) => {
        const override = getOverrideForShelf(shelf.shelf_id || '');
        return (
          <Accordion
            key={shelf.shelf_id}
            expanded={expandedShelf === shelf.shelf_id}
            onChange={handleAccordionChange(shelf.shelf_id || '')}
            disableGutters
            sx={{
              mb: 1,
              '&:before': { display: 'none' },
              boxShadow: 'none',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderBottom:
                  expandedShelf === shelf.shelf_id
                    ? `1px solid ${theme.palette.divider}`
                    : 'none',
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <EditIcon
                  fontSize="small"
                  sx={{ mr: 1, color: theme.palette.info.main }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    flex: 1,
                    fontWeight: 500,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {shelf.tagline2}
                </Typography>
                {override && (
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      bgcolor: theme.palette.warning.main,
                      ml: 1,
                    }}
                  />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 2 }}>
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ display: 'block', mb: 0.5 }}
                >
                  現在のタグライン:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    p: 1,
                    bgcolor: theme.palette.grey[50],
                    borderRadius: 1,
                    borderLeft: `4px solid ${theme.palette.info.light}`,
                  }}
                >
                  {shelf.tagline2}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mr: 1 }}
                >
                  タグラインを編集:
                </Typography>
                <Tooltip title="AIでタグラインを生成">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleOpenAiModal(shelf.shelf_id || '')}
                    sx={{
                      ml: 'auto',
                      background:
                        'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                      color: 'white',
                      '&:hover': {
                        background:
                          'linear-gradient(45deg, #1976D2 30%, #2196F3 90%)',
                      },
                      boxShadow: '0 2px 4px rgba(33, 150, 243, 0.3)',
                    }}
                  >
                    <MagicWandIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>

              <TextField
                fullWidth
                label="タグライン1 (オプション)"
                variant="outlined"
                size="small"
                value={override?.tagline1 || ''}
                onChange={(e) =>
                  handleOverrideChange(
                    shelf.shelf_id || '',
                    'tagline1',
                    e.target.value,
                  )
                }
                placeholder="タグライン1を入力"
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="タグライン2"
                variant="outlined"
                size="small"
                value={override?.tagline2 || shelf.tagline2 || ''}
                onChange={(e) =>
                  handleOverrideChange(
                    shelf.shelf_id || '',
                    'tagline2',
                    e.target.value,
                  )
                }
                placeholder="タグライン2を入力"
                sx={{ mb: 2 }}
              />

              {override?.tagline2 !== shelf.tagline2 && (
                <Button
                  variant="outlined"
                  size="small"
                  color="warning"
                  sx={{ mt: 1 }}
                  onClick={() => {
                    const newOverrides = localOverrides.filter(
                      (o) => o.shelfId !== shelf.shelf_id,
                    );
                    setLocalOverrides(newOverrides);
                    onUpdateOverrides(newOverrides);
                  }}
                >
                  リセット
                </Button>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}

      {/* AIタグライン生成モーダル */}
      <AITaglineGenerator
        open={aiModalOpen}
        onClose={() => setAiModalOpen(false)}
        onSelect={handleApplyAiTagline}
        shelfId={currentShelfId}
        currentTagline1={
          localOverrides.find((o) => o.shelfId === currentShelfId)?.tagline1
        }
        currentTagline2={
          localOverrides.find((o) => o.shelfId === currentShelfId)?.tagline2 ||
          shelves.find((s) => s.shelf_id === currentShelfId)?.tagline2
        }
        items={shelves.find((s) => s.shelf_id === currentShelfId)?.items}
      />
    </Box>
  );
};

export default TaglineOverridePanel;
