import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
  Bookmarks as BookmarksIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { SelectedShelvesProps } from './types';

/**
 * 選択済みシェルフ表示コンポーネント
 */
const SelectedShelves: React.FC<SelectedShelvesProps> = ({
  selectedShelves,
  onToggleShelf,
  onMoveShelf,
  onClearAll,
}) => {
  const muiTheme = useTheme();

  return (
    <Box
      sx={{
        width: '35%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          p: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: muiTheme.palette.background.default,
          borderBottom: `1px solid ${muiTheme.palette.divider}`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BookmarksIcon
            fontSize="small"
            sx={{ mr: 1, color: muiTheme.palette.secondary.main }}
          />
          <Typography variant="subtitle1" fontWeight="bold">
            選択済みシェルフ ({selectedShelves.length}件)
          </Typography>
        </Box>
        {selectedShelves.length > 0 && (
          <Button
            variant="outlined"
            size="small"
            onClick={onClearAll}
            color="error"
            sx={{ fontSize: '0.75rem', py: 0.2 }}
            startIcon={<ClearIcon fontSize="small" />}
          >
            すべて解除
          </Button>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          p: 1.5,
          bgcolor: muiTheme.palette.grey[50],
        }}
      >
        {selectedShelves.length > 0 ? (
          selectedShelves.map((shelf, index) => (
            <Card
              key={shelf.shelf_id || shelf.tagline2}
              sx={{
                mb: 1.5,
                border: `1px solid #e0e0e0`,
                boxShadow: 1,
                borderRadius: 1.5,
                position: 'relative',
                overflow: 'hidden',
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: 3,
                  borderColor: muiTheme.palette.primary.light,
                },
              }}
              onClick={() => onToggleShelf(shelf)}
            >
              <CardHeader
                sx={{ pt: 1.5, pb: 1, px: 1.5 }}
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={true}
                      onChange={(e) => {
                        e.stopPropagation();
                        onToggleShelf(shelf);
                      }}
                      disabled={!shelf.shelf_id}
                      size="small"
                      sx={{ ml: -1, mr: 0.5 }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        flex: 1,
                        fontWeight: 500,
                        lineHeight: 1.4,
                        fontSize: '0.95rem',
                      }}
                    >
                      {shelf.tagline2}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                      <IconButton
                        size="small"
                        disabled={index === 0}
                        onClick={(e) => {
                          e.stopPropagation();
                          onMoveShelf(index, 'up');
                        }}
                        sx={{
                          p: 0.5,
                          opacity: index === 0 ? 0.3 : 1,
                          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
                        }}
                      >
                        <ArrowUpIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        disabled={index === selectedShelves.length - 1}
                        onClick={(e) => {
                          e.stopPropagation();
                          onMoveShelf(index, 'down');
                        }}
                        sx={{
                          p: 0.5,
                          opacity:
                            index === selectedShelves.length - 1 ? 0.3 : 1,
                          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
                        }}
                      >
                        <ArrowDownIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                }
              />
              {shelf.items && shelf.items.length > 0 && (
                <>
                  <Divider />
                  <CardContent
                    sx={{ pt: 1, pb: 1, px: 1.5 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        pb: 0.5,
                        '&::-webkit-scrollbar': {
                          height: 4,
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgba(0,0,0,0.2)',
                          borderRadius: 3,
                        },
                      }}
                    >
                      {shelf.items.slice(0, 5).map((item: any, idx: number) => (
                        <Box
                          key={idx}
                          sx={{
                            mr: 1,
                            minWidth: 70,
                            maxWidth: 70,
                            textAlign: 'center',
                            position: 'relative',
                          }}
                        >
                          <Box sx={{ position: 'relative' }}>
                            {item.url ? (
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <img
                                  src={item.image_url || '/placeholder.png'}
                                  alt={
                                    item.title || item.item_title || '商品画像'
                                  }
                                  style={{
                                    width: '100%',
                                    borderRadius: 4,
                                  }}
                                  loading="lazy"
                                />
                              </a>
                            ) : (
                              <img
                                src={item.image_url || '/placeholder.png'}
                                alt={
                                  item.title || item.item_title || '商品画像'
                                }
                                style={{
                                  width: '100%',
                                  borderRadius: 4,
                                }}
                                loading="lazy"
                              />
                            )}
                            {item.pv_7d_rank && (
                              <Chip
                                label={`${item.pv_7d_rank}位`}
                                size="small"
                                sx={{
                                  position: 'absolute',
                                  bottom: 2,
                                  right: 2,
                                  height: 18,
                                  fontSize: '0.65rem',
                                  backgroundColor: 'rgba(0,0,0,0.7)',
                                  color: '#fff',
                                  '& .MuiChip-label': {
                                    px: 0.5,
                                  },
                                }}
                              />
                            )}
                          </Box>
                          {item.brand_name && (
                            <Typography
                              variant="caption"
                              sx={{
                                display: 'block',
                                fontWeight: 'bold',
                                color: muiTheme.palette.text.secondary,
                                opacity: 0.8,
                                fontSize: '0.6rem',
                                mt: 0.5,
                                mb: -0.3,
                              }}
                            >
                              {item.brand_name}
                            </Typography>
                          )}
                          <Typography
                            variant="caption"
                            sx={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: '0.7rem',
                              mt: item.brand_name ? 0 : 0.5,
                              minHeight: '2.6em',
                              maxHeight: '2.8em',
                              lineHeight: '1.4em',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.title || item.item_title || ''}
                          </Typography>
                          {item.price && (
                            <Typography
                              variant="caption"
                              sx={{
                                display: 'block',
                                fontWeight: 'bold',
                                color: muiTheme.palette.primary.main,
                                fontSize: '0.65rem',
                              }}
                            >
                              ¥{item.price.toLocaleString()}
                            </Typography>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </>
              )}
            </Card>
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              color: muiTheme.palette.text.secondary,
            }}
          >
            <BookmarksIcon sx={{ fontSize: 50, opacity: 0.3, mb: 2 }} />
            <Typography variant="body1" align="center">
              まだシェルフが選択されていません
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ mt: 1 }}
            >
              検索結果からシェルフを選択してください
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SelectedShelves;
