import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CategoryIcon from '@mui/icons-material/Category';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StyleIcon from '@mui/icons-material/Style';
import WcIcon from '@mui/icons-material/Wc';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { itemSearchService } from '../../../../services/item-search-service';
import { MetadataItem } from '../../../../types/item-search.type';

export interface FilterPanelProps {
  brandNames: string[];
  categories: string[];
  subCategories: string[];
  itemGenders: string[];
  filterItems: boolean;
  onChangeBrandNames: (brandNames: string[]) => void;
  onChangeCategories: (categories: string[]) => void;
  onChangeSubCategories: (subCategories: string[]) => void;
  onChangeItemGenders: (itemGenders: string[]) => void;
  onChangeFilterItems: (filterItems: boolean) => void;
  onResetFilters: () => void;
  // 表示するフィルターの設定
  showBrandFilter?: boolean;
  showCategoryFilter?: boolean;
  showSubCategoryFilter?: boolean;
  showGenderFilter?: boolean;
  open: boolean;
}

/**
 * フィルターパネルコンポーネント
 */
const FilterPanel: React.FC<FilterPanelProps> = ({
  brandNames,
  categories,
  subCategories,
  itemGenders,
  filterItems,
  onChangeBrandNames,
  onChangeCategories,
  onChangeSubCategories,
  onChangeItemGenders,
  onChangeFilterItems,
  onResetFilters,
  // デフォルトでは全てのフィルターを表示
  showBrandFilter = true,
  showCategoryFilter = true,
  showSubCategoryFilter = true,
  showGenderFilter = false, // 性別フィルターはデフォルトで非表示に変更
  open,
}) => {
  // メタデータ取得状態
  const [loading, setLoading] = useState({
    brand_name: false,
    category: false,
    sub_category: false,
    item_gender: false,
  });

  // メタデータオプション
  const [options, setOptions] = useState<{
    brand_name: MetadataItem[];
    category: MetadataItem[];
    sub_category: MetadataItem[];
    item_gender: MetadataItem[];
  }>({
    brand_name: [],
    category: [],
    sub_category: [],
    item_gender: [],
  });

  // メタデータ取得
  const fetchMetadata = async (field: string) => {
    setLoading((prev) => ({ ...prev, [field]: true }));
    try {
      const response = await itemSearchService.getMetadata(field);
      setOptions((prev) => ({ ...prev, [field]: response.metadata }));
    } catch (error) {
      console.error(`${field}のメタデータ取得エラー:`, error);
    } finally {
      setLoading((prev) => ({ ...prev, [field]: false }));
    }
  };

  // 初回レンダリング時にメタデータを取得
  useEffect(() => {
    // オープン状態でない場合は取得しない
    if (!open) return;

    // 表示するフィルターに応じてメタデータを取得
    if (
      showBrandFilter &&
      options.brand_name.length === 0 &&
      !loading.brand_name
    ) {
      fetchMetadata('brand_name');
    }
    if (
      showCategoryFilter &&
      options.category.length === 0 &&
      !loading.category
    ) {
      fetchMetadata('category');
    }
    if (
      showSubCategoryFilter &&
      options.sub_category.length === 0 &&
      !loading.sub_category
    ) {
      fetchMetadata('sub_category');
    }
    if (
      showGenderFilter &&
      options.item_gender.length === 0 &&
      !loading.item_gender
    ) {
      fetchMetadata('item_gender');
    }
  }, [
    open,
    showBrandFilter,
    showCategoryFilter,
    showSubCategoryFilter,
    showGenderFilter,
    options.brand_name.length,
    options.category.length,
    options.sub_category.length,
    options.item_gender.length,
    loading.brand_name,
    loading.category,
    loading.sub_category,
    loading.item_gender,
  ]);

  // マルチセレクト値変更ハンドラー
  const handleMultiSelectChange = (
    event: SelectChangeEvent<string[]>,
    setter: (value: string[]) => void,
  ) => {
    const value = event.target.value;
    setter(typeof value === 'string' ? value.split(',') : value);
  };

  // リセット可能かどうか
  const canReset =
    brandNames.length > 0 ||
    categories.length > 0 ||
    subCategories.length > 0 ||
    itemGenders.length > 0 ||
    !filterItems;

  return (
    <Box sx={{ p: 1.5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        {/* ブランド名フィルター */}
        {showBrandFilter && (
          <FormControl size="small" sx={{ minWidth: 150, flex: 1 }}>
            <InputLabel id="brand-names-label">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BrandingWatermarkIcon
                  fontSize="small"
                  sx={{ mr: 0.5, opacity: 0.7 }}
                />
                ブランド名
              </Box>
            </InputLabel>
            <Select
              labelId="brand-names-label"
              id="brand-names-select"
              multiple
              value={brandNames}
              onChange={(e) => handleMultiSelectChange(e, onChangeBrandNames)}
              input={
                <OutlinedInput
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BrandingWatermarkIcon
                        fontSize="small"
                        sx={{ mr: 0.5, opacity: 0.7 }}
                      />
                      ブランド名
                    </Box>
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              disabled={loading.brand_name}
            >
              {loading.brand_name ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                options.brand_name.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key} ({item.item_count})
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}

        {/* カテゴリフィルター */}
        {showCategoryFilter && (
          <FormControl size="small" sx={{ minWidth: 150, flex: 1 }}>
            <InputLabel id="categories-label">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CategoryIcon fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                カテゴリ
              </Box>
            </InputLabel>
            <Select
              labelId="categories-label"
              id="categories-select"
              multiple
              value={categories}
              onChange={(e) => handleMultiSelectChange(e, onChangeCategories)}
              input={
                <OutlinedInput
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CategoryIcon
                        fontSize="small"
                        sx={{ mr: 0.5, opacity: 0.7 }}
                      />
                      カテゴリ
                    </Box>
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              disabled={loading.category}
            >
              {loading.category ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                options.category.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key} ({item.item_count})
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}

        {/* サブカテゴリフィルター */}
        {showSubCategoryFilter && (
          <FormControl size="small" sx={{ minWidth: 150, flex: 1 }}>
            <InputLabel id="sub-categories-label">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StyleIcon fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                サブカテゴリ
              </Box>
            </InputLabel>
            <Select
              labelId="sub-categories-label"
              id="sub-categories-select"
              multiple
              value={subCategories}
              onChange={(e) =>
                handleMultiSelectChange(e, onChangeSubCategories)
              }
              input={
                <OutlinedInput
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StyleIcon
                        fontSize="small"
                        sx={{ mr: 0.5, opacity: 0.7 }}
                      />
                      サブカテゴリ
                    </Box>
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              disabled={loading.sub_category}
            >
              {loading.sub_category ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                options.sub_category.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key} ({item.item_count})
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}

        {/* 性別フィルター */}
        {showGenderFilter && (
          <FormControl size="small" sx={{ minWidth: 100, flex: 0.6 }}>
            <InputLabel id="item-genders-label">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <WcIcon fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                性別
              </Box>
            </InputLabel>
            <Select
              labelId="item-genders-label"
              id="item-genders-select"
              multiple
              value={itemGenders}
              onChange={(e) => handleMultiSelectChange(e, onChangeItemGenders)}
              input={
                <OutlinedInput
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <WcIcon fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                      性別
                    </Box>
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              disabled={loading.item_gender}
            >
              {loading.item_gender ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                options.item_gender.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key} ({item.item_count})
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}

        {/* 商品フィルタリングトグルボタン */}
        <Tooltip
          title={
            filterItems ? '商品フィルタリング: ON' : '商品フィルタリング: OFF'
          }
        >
          <IconButton
            color={filterItems ? 'primary' : 'default'}
            onClick={() => onChangeFilterItems(!filterItems)}
            size="small"
            sx={{ ml: 0.5 }}
          >
            {filterItems ? <FilterAltIcon /> : <FilterListOffIcon />}
          </IconButton>
        </Tooltip>

        {/* リセットボタン */}
        <Tooltip title="フィルターリセット">
          <span>
            <IconButton
              onClick={onResetFilters}
              disabled={!canReset}
              size="small"
              color="error"
            >
              <RestartAltIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default FilterPanel;
