import { IPlp, IPlpBuildResponse } from '../types/plp-builder.type';
import api from './api';

// PLPデータ型定義
export interface IPlpSaveRequest {
  title: string;
  plp_data: IPlp;
  description?: string;
  tags?: string[];
}

export interface IPlpUpdateRequest extends IPlpSaveRequest {
  plp_id: string;
}

export interface IPlpData {
  plp_id: string;
  title: string;
  plp_data: IPlp;
  description?: string;
  tags?: string[];
  client_id: string;
  created_at: string;
  updated_at: string;
}

export interface IPlpListRequest {
  limit?: number;
  offset?: number;
  order_by?: 'created_at' | 'updated_at' | 'title';
  order_direction?: 'asc' | 'desc';
  tags?: string[];
}

export interface IPlpListResponse {
  items: IPlpData[];
  total: number;
  limit: number;
  offset: number;
}

export interface IPlpDeleteResponse {
  success: boolean;
  plp_id: string;
}

export const plpService = {
  /**
   * PLPデータをビルドしてHTMLを取得します
   * @param plpData PLPデータ
   * @param clientId クライアントID
   * @param uuId UUID（オプション）
   */
  async buildPlp(
    plpData: IPlp,
    clientId: string,
    uuId?: string,
  ): Promise<IPlpBuildResponse> {
    // バックエンドが期待する形式に変換
    const requestData = {
      clientId: clientId,
      uuId: uuId || undefined,
      plp: plpData,
    };

    const response = await api.post('/plp/build', requestData);
    return response.data;
  },

  /**
   * PLPデータを保存します
   * @param request 保存リクエスト
   */
  async savePlp(request: IPlpSaveRequest): Promise<IPlpData> {
    const response = await api.post('/plp/save', request);
    return response.data;
  },

  /**
   * PLPデータを更新します
   * @param request 更新リクエスト
   */
  async updatePlp(request: IPlpUpdateRequest): Promise<IPlpData> {
    const response = await api.post('/plp/update', request);
    return response.data;
  },

  /**
   * PLPデータをIDで取得します
   * @param plpId PLP ID
   */
  async getPlp(plpId: string): Promise<IPlpData> {
    const response = await api.get(`/plp/${plpId}`);
    return response.data;
  },

  /**
   * PLPデータの一覧を取得します
   * @param request 一覧取得リクエスト
   */
  async listPlp(request: IPlpListRequest = {}): Promise<IPlpListResponse> {
    const response = await api.post('/plp/list', request);
    return response.data;
  },

  /**
   * PLPデータを削除します
   * @param plpId PLP ID
   */
  async deletePlp(plpId: string): Promise<IPlpDeleteResponse> {
    const response = await api.post('/plp/delete', { plp_id: plpId });
    return response.data;
  },

  /**
   * 一意のPLP IDを生成します
   * @returns 一意のPLP ID
   */
  generatePlpId(): string {
    return `plp_${Math.random().toString(36).substring(2, 15)}_${Date.now()}`;
  },

  /**
   * 新しい空のPLPオブジェクトを作成します
   * @returns 空のPLPオブジェクト
   */
  createEmptyPlp(): IPlp {
    return {
      plpId: this.generatePlpId(),
      plpBlocks: [],
    };
  },
};
