import {
  ViewQuilt as GridBannerIcon,
  CropOriginal as OneItemIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { DisplaySettingsProps } from './types';

/**
 * 表示設定コンポーネント
 * シェルフの表示形式や各種パラメータを設定するUIを提供します
 */
const DisplaySettings: React.FC<DisplaySettingsProps> = ({
  uiType = 'grid',
  gridColumns = 3,
  columnGap = 8,
  sliderRows = 1,
  maxItems,
  minItems = 1,
  onChangeUiType,
  onChangeGridColumns,
  onChangeColumnGap,
  onChangeSliderRows,
  onChangeMaxItems,
  onChangeMinItems,
}) => {
  const theme = useTheme();

  // UIタイプに応じてデフォルトの最大商品数を設定
  useEffect(() => {
    if (maxItems === undefined) {
      const defaultMaxItems = uiType === 'slider' ? 15 : 6;
      onChangeMaxItems(defaultMaxItems);
    }
  }, [uiType, maxItems, onChangeMaxItems]);

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
        表示設定
      </Typography>

      {/* UI表示タイプ選択 */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
          表示形式:
        </Typography>
        <ToggleButtonGroup
          value={uiType}
          exclusive
          onChange={(_, newValue) => {
            if (newValue !== null) onChangeUiType(newValue);
          }}
          aria-label="表示形式"
          sx={{ width: '100%' }}
        >
          <ToggleButton value="grid" aria-label="グリッド表示" sx={{ flex: 1 }}>
            <Tooltip title="グリッド表示">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ViewModuleIcon sx={{ mb: 0.5 }} />
                <Typography variant="caption">グリッド</Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value="slider"
            aria-label="スライダー表示"
            sx={{ flex: 1 }}
          >
            <Tooltip title="スライダー表示">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ViewListIcon sx={{ mb: 0.5 }} />
                <Typography variant="caption">スライダー</Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value="oneitem"
            aria-label="1アイテム表示"
            sx={{ flex: 1 }}
          >
            <Tooltip title="1アイテム表示">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <OneItemIcon sx={{ mb: 0.5 }} />
                <Typography variant="caption">1アイテム</Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value="gridbanner"
            aria-label="グリッドバナー表示"
            sx={{ flex: 1 }}
          >
            <Tooltip title="グリッドバナー表示">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <GridBannerIcon sx={{ mb: 0.5 }} />
                <Typography variant="caption">グリッドバナー</Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* 商品数の設定 */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
          商品数の設定:
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="最小商品数"
            type="number"
            value={minItems}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value) && value >= 0) {
                onChangeMinItems(value);
              }
            }}
            size="small"
            InputProps={{ inputProps: { min: 0 } }}
            sx={{ width: '50%' }}
          />
          <FormControl size="small" sx={{ width: '50%' }}>
            <InputLabel id="max-items-label">最大商品数</InputLabel>
            <Select
              labelId="max-items-label"
              value={maxItems || ''}
              onChange={(e) => onChangeMaxItems(Number(e.target.value))}
              label="最大商品数"
            >
              {Array.from({ length: 30 }, (_, i) => i + 1).map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ mt: 0.5, display: 'block' }}
        >
          ※表示形式に応じて最適な最大商品数が設定されます
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* 表示形式別の詳細設定 */}
      {uiType === 'grid' || uiType === 'gridbanner' ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* グリッド列数設定 */}
          <Box>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
              グリッド列数:
            </Typography>
            <ToggleButtonGroup
              value={gridColumns.toString()}
              exclusive
              onChange={(_, newValue) => {
                if (newValue !== null) onChangeGridColumns(Number(newValue));
              }}
              aria-label="グリッド列数"
              size="small"
              sx={{ width: '100%' }}
            >
              {[2, 3, 4, 5].map((cols) => (
                <ToggleButton
                  key={cols}
                  value={cols.toString()}
                  sx={{ flex: 1 }}
                >
                  {cols}列
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          {/* カラム間隔設定 */}
          <Box>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
              商品間隔:
            </Typography>
            <FormControl fullWidth size="small">
              <Select
                value={columnGap.toString()}
                onChange={(e) => onChangeColumnGap(Number(e.target.value))}
                displayEmpty
              >
                {[4, 8, 12, 16, 20, 24].map((gap) => (
                  <MenuItem key={gap} value={gap.toString()}>
                    {gap}px
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      ) : uiType === 'slider' ? (
        <Box>
          <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
            スライダー行数:
          </Typography>
          <ToggleButtonGroup
            value={sliderRows.toString()}
            exclusive
            onChange={(_, newValue) => {
              if (newValue !== null) onChangeSliderRows(Number(newValue));
            }}
            aria-label="スライダー行数"
            size="small"
            sx={{ width: '100%' }}
          >
            {[1, 2, 3].map((rows) => (
              <ToggleButton key={rows} value={rows.toString()} sx={{ flex: 1 }}>
                {rows}行
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      ) : (
        <Typography variant="body2" color="text.secondary">
          この表示形式には追加設定がありません
        </Typography>
      )}
    </Paper>
  );
};

export default DisplaySettings;
