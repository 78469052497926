import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { plpService } from '../../../../services/plp-service';
import { useClient } from '../../../../store/client.store';
import {
  IPlpBlockShelfConfig,
  IShelfBlock,
} from '../../../../types/plp-builder.type';
import ShelfConfigBuilder from '../ShelfConfigBuilder';

interface UserPersonalizedShelfModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (block: IShelfBlock) => void;
  isEditMode?: boolean;
  existingBlock?: IShelfBlock;
  onUpdate?: (block: IShelfBlock) => void;
}

/**
 * ユーザーパーソナライズシェルフモーダルコンポーネント
 */
const UserPersonalizedShelfModal: React.FC<UserPersonalizedShelfModalProps> = ({
  open,
  onClose,
  onAdd,
  isEditMode = false,
  existingBlock,
  onUpdate,
}) => {
  const muiTheme = useTheme();
  const { client: clientState } = useClient();
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoadingExistingData, setIsLoadingExistingData] = useState(false);

  // ShelfConfigBuilderに渡すconfig
  const [shelfConfig, setShelfConfig] = useState<IPlpBlockShelfConfig>({
    shelfOrders: [],
    uiType: 'grid',
    gridColumns: 3,
    columnGap: 8,
    sliderRows: 1,
  });

  // 編集モードの場合、既存のブロック設定をロード
  useEffect(() => {
    if (isEditMode && existingBlock && open) {
      try {
        setIsLoadingExistingData(true);
        setShelfConfig(existingBlock.shelfConfig);
      } catch (err) {
        console.error('既存データロードエラー:', err);
        setError('既存の設定データの読み込みに失敗しました');
      } finally {
        setIsLoadingExistingData(false);
      }
    } else if (open) {
      // 新規作成時は初期化
      setShelfConfig({
        shelfOrders: [],
        uiType: 'grid',
        gridColumns: 3,
        columnGap: 8,
        sliderRows: 1,
      });
    }
  }, [isEditMode, existingBlock, open]);

  // モーダルを閉じる
  const handleClose = () => {
    onClose();
  };

  // ShelfConfigBuilderからの設定変更を反映
  const handleShelfConfigChange = useCallback(
    (newConfig: IPlpBlockShelfConfig) => {
      setShelfConfig(newConfig);

      // プレビューを更新
      if (clientState.selectedClient) {
        handleUpdatePreview(newConfig);
      }
    },
    [clientState.selectedClient],
  );

  // プレビューを更新
  const handleUpdatePreview = useCallback(
    async (configToPreview?: IPlpBlockShelfConfig) => {
      try {
        if (!clientState.selectedClient?.clientId) {
          console.error('クライアントが選択されていません');
          return;
        }

        setIsPreviewLoading(true);
        setError(null);

        const configForPreview = configToPreview || shelfConfig;

        // PLPブロックを作成してビルド
        const plpData = {
          plpId: `plp_preview_${Date.now()}`,
          plpBlocks: [
            {
              blockType: 'shelf' as const,
              shelfConfig: configForPreview,
            },
          ],
        };

        // プレビュービルド
        await plpService.buildPlp(plpData, clientState.selectedClient.clientId);

        // プレビュー更新完了時の処理があれば追加
      } catch (error) {
        console.error('プレビュー更新エラー:', error);
        setError('プレビューの更新に失敗しました');
      } finally {
        setIsPreviewLoading(false);
      }
    },
    [shelfConfig, clientState.selectedClient],
  );

  // ブロックを追加または更新
  const handleAddOrUpdateBlock = () => {
    if (!shelfConfig.shelfOrders || shelfConfig.shelfOrders.length === 0) {
      setError('少なくとも1つのシェルフオーダーを追加してください');
      return;
    }

    try {
      // シェルフブロックの作成
      const shelfBlock: IShelfBlock = {
        blockType: 'shelf',
        blockName: 'ユーザーパーソナライズシェルフ',
        shelfConfig: shelfConfig,
      };

      // 編集モードの場合は更新、そうでなければ追加
      if (isEditMode && onUpdate) {
        onUpdate(shelfBlock);
      } else {
        onAdd(shelfBlock);
      }

      handleClose();
    } catch (err) {
      console.error('ブロック追加エラー:', err);
      setError('ブロックの追加に失敗しました');
    }
  };

  // シェルフビルダーのタブ設定
  const shelfBuilderTabConfig = {
    // 「シェルフオーダー」「表示設定」「スタイル設定」「タグライン設定」のタブのみ表示
    tabConfigs: [
      { id: 5, show: true }, // シェルフオーダー（新しいタブ）
      { id: 0, show: true }, // 表示設定
      { id: 1, show: true }, // スタイル設定
      { id: 3, show: true }, // タグライン設定
      { id: 2, show: false }, // フィルター設定（ユーザーパーソナライズでは不要）
      { id: 4, show: false }, // 商品フィルター（ユーザーパーソナライズでは不要）
    ],
    // タブの表示順序をカスタマイズ
    tabOrder: [5, 0, 3, 1], // シェルフオーダーを最初に表示
    defaultActiveTab: 5, // 表示順序の最初のタブ（シェルフオーダー）を選択
  };

  // モーダルのタイトルを取得
  const getModalTitle = () => {
    if (isEditMode) {
      return 'ユーザーパーソナライズシェルフの編集';
    }
    return 'ユーザーパーソナライズシェルフを追加';
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="user-personalized-shelf-modal-title"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          height: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: '4px 4px 0 4px',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* ヘッダー */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" id="user-personalized-shelf-modal-title">
              {getModalTitle()}
            </Typography>

            {error && (
              <Typography variant="caption" color="error" sx={{ mt: 0.5 }}>
                {error}
              </Typography>
            )}
          </Box>

          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* タブパネルコンテナ */}
        <Box sx={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
          {isLoadingExistingData ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress size={40} />
              <Typography variant="body1" sx={{ ml: 2 }}>
                データを読み込み中...
              </Typography>
            </Box>
          ) : (
            <Box sx={{ height: '100%' }}>
              <ShelfConfigBuilder
                config={shelfConfig}
                onConfigChange={handleShelfConfigChange}
                clientId={clientState?.selectedClient?.clientId}
                tabConfigs={shelfBuilderTabConfig.tabConfigs}
                tabOrder={shelfBuilderTabConfig.tabOrder}
                defaultActiveTab={shelfBuilderTabConfig.defaultActiveTab}
              />
            </Box>
          )}
        </Box>

        {/* フッター部分 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 2,
            borderTop: `1px solid ${muiTheme.palette.divider}`,
            bgcolor: muiTheme.palette.background.default,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mr: 'auto' }}
          >
            {shelfConfig.shelfOrders?.length || 0}
            個のシェルフオーダーが設定されています
          </Typography>
          <Button onClick={handleClose} sx={{ mr: 1 }} color="inherit">
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleAddOrUpdateBlock}
            disabled={
              !shelfConfig.shelfOrders ||
              shelfConfig.shelfOrders.length === 0 ||
              isPreviewLoading
            }
            startIcon={isEditMode ? <EditIcon /> : <AddIcon />}
          >
            {isEditMode ? 'シェルフを更新' : 'シェルフを追加'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserPersonalizedShelfModal;
