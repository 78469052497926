import {
  IGenerateTaglinesReq,
  IGenerateTaglinesRes,
  IShelfDetailRequest,
  IShelfDetailResponse,
  IShelfSearchRequest,
  IShelfSearchResponse,
} from '../types/shelf-search.type';
import api from './api';

class ShelfSearchService {
  /**
   * テーマに基づいてシェルフを検索
   * @param theme 検索テーマ
   * @param limit 最大取得件数（デフォルト15件）
   */
  async searchShelves(
    theme: string,
    limit: number = 15,
  ): Promise<IShelfSearchResponse> {
    const request: IShelfSearchRequest = { theme, limit };
    const response = await api.post<IShelfSearchResponse>(
      '/shelf-search/search',
      request,
    );
    return response.data;
  }

  /**
   * シェルフID または 商品メタデータからシェルフ詳細情報を取得
   * @param request シェルフ検索条件
   */
  async getShelfDetails(
    request: IShelfDetailRequest,
  ): Promise<IShelfDetailResponse> {
    const response = await api.post<IShelfDetailResponse>(
      '/shelf-search/detail',
      request,
    );
    return response.data;
  }

  /**
   * シェルフIDリストからシェルフ詳細情報を取得
   * @param shelfIds シェルフIDのリスト
   */
  async getShelfDetailsByIds(
    shelfIds: string[],
  ): Promise<IShelfDetailResponse> {
    return this.getShelfDetails({ shelf_ids: shelfIds });
  }

  /**
   * AIを使用してシェルフのタグラインを生成
   * @param params 生成パラメータ
   */
  async generateTaglines(
    params: IGenerateTaglinesReq,
  ): Promise<IGenerateTaglinesRes> {
    const response = await api.post<IGenerateTaglinesRes>(
      '/shelf/generate_taglines',
      params,
    );
    return response.data;
  }
}

export const shelfSearchService = new ShelfSearchService();
