import {
  Code as CodeIcon,
  Inventory as InventoryIcon,
  PersonOutline as PersonIcon,
  Search as SearchIcon,
  LocalOffer as TagIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Modal,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import HtmlBuilderModal from './HtmlBuilderModal/index';
import ItemSearchModal from './ItemSearchModal/index';
import PersonalizedItemShelfModal from './PersonalizedItemShelfModal/index';
import ShelfSearchModal from './ShelfSearchModal/index';
import UserPersonalizedShelfModal from './UserPersonalizedShelfModal/index';

interface AddBlockModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (block: any) => void;
}

/**
 * ブロック追加モーダルコンポーネント
 */
const AddBlockModal: React.FC<AddBlockModalProps> = ({
  open,
  onClose,
  onAdd,
}) => {
  const [showShelfSearchModal, setShowShelfSearchModal] = useState(false);
  const [showItemSearchModal, setShowItemSearchModal] = useState(false);
  const [showHtmlBuilderModal, setShowHtmlBuilderModal] = useState(false);
  const [showUserPersonalizedShelfModal, setShowUserPersonalizedShelfModal] =
    useState(false);
  const [showPersonalizedItemShelfModal, setShowPersonalizedItemShelfModal] =
    useState(false);

  // モーダルを閉じる
  const handleClose = () => {
    onClose();
  };

  // シェルフ検索モーダルを表示
  const handleShowShelfSearchModal = () => {
    setShowShelfSearchModal(true);
  };

  // シェルフ検索モーダルを閉じる
  const handleCloseShelfSearchModal = () => {
    setShowShelfSearchModal(false);
    handleClose();
  };

  // 商品検索モーダルを表示
  const handleShowItemSearchModal = () => {
    setShowItemSearchModal(true);
  };

  // 商品検索モーダルを閉じる
  const handleCloseItemSearchModal = () => {
    setShowItemSearchModal(false);
    handleClose();
  };

  // HTMLビルダーモーダルを表示
  const handleShowHtmlBuilderModal = () => {
    setShowHtmlBuilderModal(true);
  };

  // HTMLビルダーモーダルを閉じる
  const handleCloseHtmlBuilderModal = () => {
    setShowHtmlBuilderModal(false);
    handleClose();
  };

  // ユーザーパーソナライズシェルフモーダルを表示
  const handleShowUserPersonalizedShelfModal = () => {
    setShowUserPersonalizedShelfModal(true);
  };

  // ユーザーパーソナライズシェルフモーダルを閉じる
  const handleCloseUserPersonalizedShelfModal = () => {
    setShowUserPersonalizedShelfModal(false);
    handleClose();
  };

  // パーソナライズ商品シェルフモーダルを表示
  const handleShowPersonalizedItemShelfModal = () => {
    setShowPersonalizedItemShelfModal(true);
  };

  // パーソナライズ商品シェルフモーダルを閉じる
  const handleClosePersonalizedItemShelfModal = () => {
    setShowPersonalizedItemShelfModal(false);
    handleClose();
  };

  // シェルフ検索から選択されたシェルフを追加
  const handleAddSearchedShelf = (block: any) => {
    onAdd(block);
    setShowShelfSearchModal(false);
    handleClose();
  };

  // 商品検索から作成されたシェルフを追加
  const handleAddItemShelf = (block: any) => {
    onAdd(block);
    setShowItemSearchModal(false);
    handleClose();
  };

  // HTMLブロックを追加
  const handleAddHtmlBlock = (block: any) => {
    onAdd(block);
    setShowHtmlBuilderModal(false);
    handleClose();
  };

  // ユーザーパーソナライズシェルフを追加
  const handleAddUserPersonalizedShelf = (block: any) => {
    onAdd(block);
    setShowUserPersonalizedShelfModal(false);
    handleClose();
  };

  // パーソナライズ商品シェルフを追加
  const handleAddPersonalizedItemShelf = (block: any) => {
    onAdd(block);
    setShowPersonalizedItemShelfModal(false);
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-block-modal-title"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <Typography
            id="add-block-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            ブロックを追加
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            追加するブロックの種類を選択してください
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Card
              sx={{
                border: '1px solid #e0e0e0',
                transition: 'all 0.2s',
                flex: 1,
                minWidth: '170px',
                '&:hover': {
                  boxShadow: 3,
                  borderColor: 'primary.main',
                },
              }}
            >
              <CardActionArea onClick={handleShowShelfSearchModal}>
                <CardContent sx={{ textAlign: 'center', py: 3 }}>
                  <SearchIcon
                    sx={{ fontSize: 50, mb: 1, color: 'warning.main' }}
                  />
                  <Typography variant="h6" gutterBottom>
                    シェルフ検索
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    テーマに基づいてシェルフを検索し、PLPに追加します
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card
              sx={{
                border: '1px solid #e0e0e0',
                transition: 'all 0.2s',
                flex: 1,
                minWidth: '170px',
                '&:hover': {
                  boxShadow: 3,
                  borderColor: 'primary.main',
                },
              }}
            >
              <CardActionArea onClick={handleShowItemSearchModal}>
                <CardContent sx={{ textAlign: 'center', py: 3 }}>
                  <InventoryIcon
                    sx={{ fontSize: 50, mb: 1, color: 'success.main' }}
                  />
                  <Typography variant="h6" gutterBottom>
                    商品シェルフ
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    商品を検索し、カスタムシェルフを作成します
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card
              sx={{
                border: '1px solid #e0e0e0',
                transition: 'all 0.2s',
                flex: 1,
                minWidth: '170px',
                '&:hover': {
                  boxShadow: 3,
                  borderColor: 'primary.main',
                },
              }}
            >
              <CardActionArea onClick={handleShowUserPersonalizedShelfModal}>
                <CardContent sx={{ textAlign: 'center', py: 3 }}>
                  <PersonIcon
                    sx={{ fontSize: 50, mb: 1, color: 'secondary.main' }}
                  />
                  <Typography variant="h6" gutterBottom>
                    ユーザー別
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ユーザーごとにカスタマイズされたシェルフを表示します
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card
              sx={{
                border: '1px solid #e0e0e0',
                transition: 'all 0.2s',
                flex: 1,
                minWidth: '170px',
                '&:hover': {
                  boxShadow: 3,
                  borderColor: 'primary.main',
                },
              }}
            >
              <CardActionArea onClick={handleShowPersonalizedItemShelfModal}>
                <CardContent sx={{ textAlign: 'center', py: 3 }}>
                  <TagIcon sx={{ fontSize: 50, mb: 1, color: 'error.main' }} />
                  <Typography variant="h6" gutterBottom>
                    商品別
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    特定の商品をベースにしたパーソナライズシェルフを作成します
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card
              sx={{
                border: '1px solid #e0e0e0',
                transition: 'all 0.2s',
                flex: 1,
                minWidth: '170px',
                '&:hover': {
                  boxShadow: 3,
                  borderColor: 'primary.main',
                },
              }}
            >
              <CardActionArea onClick={handleShowHtmlBuilderModal}>
                <CardContent sx={{ textAlign: 'center', py: 3 }}>
                  <CodeIcon sx={{ fontSize: 50, mb: 1, color: 'info.main' }} />
                  <Typography variant="h6" gutterBottom>
                    HTMLブロック
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    カスタムHTMLコンテンツを作成し、PLPに追加します
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>

          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 3 }}
          >
            <Button onClick={handleClose}>キャンセル</Button>
          </Box>
        </Box>
      </Modal>

      {/* シェルフ検索モーダル */}
      <ShelfSearchModal
        open={showShelfSearchModal}
        onClose={handleCloseShelfSearchModal}
        onAdd={handleAddSearchedShelf}
      />

      {/* 商品検索モーダル */}
      <ItemSearchModal
        open={showItemSearchModal}
        onClose={handleCloseItemSearchModal}
        onAdd={handleAddItemShelf}
      />

      {/* HTMLビルダーモーダル */}
      <HtmlBuilderModal
        open={showHtmlBuilderModal}
        onClose={handleCloseHtmlBuilderModal}
        onAdd={handleAddHtmlBlock}
      />

      {/* ユーザーパーソナライズシェルフモーダル */}
      <UserPersonalizedShelfModal
        open={showUserPersonalizedShelfModal}
        onClose={handleCloseUserPersonalizedShelfModal}
        onAdd={handleAddUserPersonalizedShelf}
      />

      {/* パーソナライズ商品シェルフモーダル */}
      <PersonalizedItemShelfModal
        open={showPersonalizedItemShelfModal}
        onClose={handleClosePersonalizedItemShelfModal}
        onAdd={handleAddPersonalizedItemShelf}
      />
    </>
  );
};

export default AddBlockModal;
