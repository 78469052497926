import {
  Code as CodeIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  Edit as EditIcon,
  AutoFixHigh as MagicWandIcon,
  ShoppingBasket as ProductIcon,
  DriveFileRenameOutline as RenameIcon,
  ViewModule as ShelfIcon,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { ShelfTypeEnum } from '../../../enums/shelf-type.enum';
import { IHtmlBlock, IShelfBlock } from '../../../types/plp-builder.type';

interface PlpBlockListProps {
  blocks: (IHtmlBlock | IShelfBlock)[];
  onDelete: (index: number) => void;
  onUpdate: (index: number, block: IHtmlBlock | IShelfBlock) => void;
  onMove: (fromIndex: number, toIndex: number) => void;
  onEdit?: (index: number) => void;
}

/**
 * PLPブロックリストコンポーネント
 */
const PlpBlockList: React.FC<PlpBlockListProps> = ({
  blocks,
  onDelete,
  onUpdate,
  onMove,
  onEdit,
}) => {
  const [editingNameIndex, setEditingNameIndex] = useState<number | null>(null);
  const [editingNameValue, setEditingNameValue] = useState('');
  const [isComposing, setIsComposing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // ドラッグ＆ドロップ完了ハンドラー
  const handleDragEnd = (result: DropResult) => {
    // ドロップ位置がない場合やドロップエリア外の場合は何もしない
    if (!result.destination) return;

    // 同じ位置にドロップされた場合は何もしない
    if (result.destination.index === result.source.index) return;

    // 移動処理
    onMove(result.source.index, result.destination.index);
  };

  // HTMLブロックのタイトルを取得
  const getHtmlBlockTitle = (block: IHtmlBlock) => {
    if (block.htmlConfig.htmlContent) {
      // HTMLの最初の50文字を表示
      const content = block.htmlConfig.htmlContent.slice(0, 50);
      return content + (block.htmlConfig.htmlContent.length > 50 ? '...' : '');
    }
    return 'HTMLブロック';
  };

  // シェルフブロックのタイトルを取得
  const getShelfBlockTitle = (block: IShelfBlock) => {
    // シェルフタイプを取得
    const shelfTypes = block.shelfConfig.shelfOrders.map(
      (order) => order.shelfType,
    );

    // シェルフタイプに基づいてタイトルを生成
    if (shelfTypes.includes(ShelfTypeEnum.manual)) {
      // 商品検索（手動選択）の場合
      return '商品手動選択シェルフ';
    } else if (shelfTypes.includes(ShelfTypeEnum.search)) {
      // AI検索シェルフの場合
      return 'AI検索シェルフ';
    } else {
      return `シェルフ (${shelfTypes.join(', ')})`;
    }
  };

  // ブロック名を取得（カスタム名または自動生成）
  const getBlockDisplayName = (block: IHtmlBlock | IShelfBlock) => {
    // カスタム名が設定されている場合はそれを使用
    if (block.blockName) {
      return block.blockName;
    }

    // デフォルト名を生成
    if (block.blockType === 'html') {
      return getHtmlBlockTitle(block as IHtmlBlock);
    } else {
      return getShelfBlockTitle(block as IShelfBlock);
    }
  };

  // ブロック名の編集を開始
  const handleStartEditName = (index: number) => {
    const block = blocks[index];
    setEditingNameValue(block.blockName || getBlockDisplayName(block));
    setEditingNameIndex(index);
  };

  // 編集中のインプットにフォーカスを当てる
  useEffect(() => {
    if (editingNameIndex !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingNameIndex]);

  // ブロック名の編集を保存
  const handleSaveBlockName = (index: number) => {
    if (index !== null && index >= 0 && index < blocks.length) {
      const block = { ...blocks[index] };
      block.blockName = editingNameValue;
      onUpdate(index, block);
      setEditingNameIndex(null);
    }
  };

  // キーボードイベントのハンドラー
  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    // 日本語入力中はキー処理をスキップ
    if (isComposing) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      handleSaveBlockName(index);
    } else if (e.key === 'Escape') {
      setEditingNameIndex(null);
    }
  };

  // 日本語入力の開始と終了を検知
  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };

  // シェルフブロックのアイコンを取得
  const getShelfBlockIcon = (block: IShelfBlock) => {
    const shelfTypes = block.shelfConfig.shelfOrders.map(
      (order) => order.shelfType,
    );

    if (shelfTypes.includes(ShelfTypeEnum.manual)) {
      // 商品検索（手動選択）の場合
      return <ProductIcon color="success" />;
    } else if (shelfTypes.includes(ShelfTypeEnum.search)) {
      // AI検索シェルフの場合
      return <MagicWandIcon color="primary" />;
    } else {
      return <ShelfIcon color="action" />;
    }
  };

  // シェルフブロックの編集可能性を確認
  const isShelfBlockEditable = (block: IShelfBlock) => {
    if (!onEdit) return false;

    const shelfTypes = block.shelfConfig.shelfOrders.map(
      (order) => order.shelfType,
    );
    // manual か search タイプなら編集可能
    return (
      shelfTypes.includes(ShelfTypeEnum.manual) ||
      shelfTypes.includes(ShelfTypeEnum.search)
    );
  };

  // ブロックの編集可能性を確認
  const isBlockEditable = (block: IHtmlBlock | IShelfBlock) => {
    if (!onEdit) return false;

    if (block.blockType === 'html') {
      // HTMLブロックは常に編集可能
      return true;
    } else {
      // シェルフブロックは特定のタイプのみ編集可能
      return isShelfBlockEditable(block as IShelfBlock);
    }
  };

  // シェルフブロック詳細情報テキストを取得
  const getShelfBlockDetailText = (block: IShelfBlock) => {
    const shelfOrder = block.shelfConfig.shelfOrders[0];
    const shelfType = shelfOrder.shelfType;

    if (shelfType === ShelfTypeEnum.manual) {
      // 選択されているAID数を表示
      const aidCount = shelfOrder.aids?.length || 0;
      return `個別商品選択 (${aidCount}件選択中)`;
    } else if (shelfType === ShelfTypeEnum.search) {
      // 選択されているシェルフ数を表示
      const shelfCount = shelfOrder.shelfIds?.length || 0;
      return `AI検索シェルフ (${shelfCount}件選択中)`;
    }

    return 'その他のシェルフ';
  };

  // HTMLブロック詳細情報テキストを取得
  const getHtmlBlockDetailText = (block: IHtmlBlock) => {
    // HTMLの長さを取得
    const htmlLength = block.htmlConfig.htmlContent?.length || 0;
    // CSSの長さを取得
    const cssLength = block.htmlConfig.cssContent?.length || 0;

    return `HTML (${htmlLength}文字) / CSS (${cssLength}文字)`;
  };

  // シェルフタイプに応じたチップカラーを取得
  const getShelfTypeChipColor = (block: IShelfBlock) => {
    const shelfType = block.shelfConfig.shelfOrders[0].shelfType;

    if (shelfType === ShelfTypeEnum.manual) {
      return 'success';
    } else if (shelfType === ShelfTypeEnum.search) {
      return 'primary';
    }

    return 'default';
  };

  // 編集ボタンのツールチップテキストを取得
  const getEditButtonTooltip = (block: IHtmlBlock | IShelfBlock) => {
    if (block.blockType === 'html') {
      return 'HTMLを編集';
    }

    const shelfBlock = block as IShelfBlock;
    const shelfType = shelfBlock.shelfConfig.shelfOrders[0].shelfType;

    if (shelfType === ShelfTypeEnum.manual) {
      return '商品を編集';
    } else if (shelfType === ShelfTypeEnum.search) {
      return 'AIシェルフを編集';
    }

    return '編集';
  };

  return (
    <Box>
      {blocks.length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="body2" color="text.secondary">
            ブロックがありません。「追加」ボタンからブロックを追加してください。
          </Typography>
        </Box>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="plp-blocks">
            {(provided) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ width: '100%', p: 0 }}
              >
                {blocks.map((block, index) => (
                  <Draggable
                    key={`block-${index}`}
                    draggableId={`block-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        elevation={snapshot.isDragging ? 4 : 1}
                        sx={{
                          mb: 2,
                          borderRadius: 1,
                          transition: 'all 0.2s',
                          border: snapshot.isDragging
                            ? '1px solid rgba(25, 118, 210, 0.5)'
                            : '1px solid transparent',
                          backgroundColor: snapshot.isDragging
                            ? 'rgba(25, 118, 210, 0.04)'
                            : 'background.paper',
                        }}
                      >
                        <ListItem
                          sx={{
                            display: 'flex',
                            p: 1.5,
                          }}
                        >
                          <Box
                            {...provided.dragHandleProps}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mr: 1,
                              color: 'text.secondary',
                              cursor: 'grab',
                              '&:hover': { color: 'primary.main' },
                            }}
                          >
                            <DragIcon />
                          </Box>

                          {block.blockType === 'shelf' && (
                            <Box
                              sx={{
                                mr: 1,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {getShelfBlockIcon(block as IShelfBlock)}
                            </Box>
                          )}
                          {block.blockType === 'html' && (
                            <Box
                              sx={{
                                mr: 1,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <CodeIcon color="info" />
                            </Box>
                          )}

                          <ListItemText
                            primary={
                              editingNameIndex === index ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                  }}
                                >
                                  <TextField
                                    inputRef={inputRef}
                                    size="small"
                                    fullWidth
                                    value={editingNameValue}
                                    onChange={(e) =>
                                      setEditingNameValue(e.target.value)
                                    }
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onCompositionStart={handleCompositionStart}
                                    onCompositionEnd={handleCompositionEnd}
                                    onBlur={() => handleSaveBlockName(index)}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="medium"
                                  >
                                    {getBlockDisplayName(block)}
                                  </Typography>
                                  <Tooltip title="名前を編集">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleStartEditName(index)}
                                    >
                                      <RenameIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )
                            }
                            secondary={
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mt: 0.5,
                                }}
                              >
                                <Chip
                                  label={block.blockType}
                                  size="small"
                                  color={
                                    block.blockType === 'html'
                                      ? 'info'
                                      : getShelfTypeChipColor(
                                          block as IShelfBlock,
                                        )
                                  }
                                  sx={{ height: 20, fontSize: '0.7rem', mr: 1 }}
                                />
                                {block.blockType === 'shelf' && (
                                  <Typography
                                    variant="caption"
                                    color="text.secondary"
                                  >
                                    {getShelfBlockDetailText(
                                      block as IShelfBlock,
                                    )}
                                  </Typography>
                                )}
                                {block.blockType === 'html' && (
                                  <Typography
                                    variant="caption"
                                    color="text.secondary"
                                  >
                                    {getHtmlBlockDetailText(
                                      block as IHtmlBlock,
                                    )}
                                  </Typography>
                                )}
                              </Box>
                            }
                          />

                          <ListItemSecondaryAction>
                            <Box sx={{ display: 'flex' }}>
                              <Tooltip title={getEditButtonTooltip(block)}>
                                <IconButton
                                  edge="end"
                                  size="small"
                                  onClick={() => onEdit && onEdit(index)}
                                  disabled={!onEdit || !isBlockEditable(block)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="削除">
                                <IconButton
                                  edge="end"
                                  onClick={() => onDelete(index)}
                                  size="small"
                                  color="error"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Paper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Box>
  );
};

export default PlpBlockList;
