import LinkIcon from '@mui/icons-material/Link';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { ItemSearchResultsProps } from './types';

/**
 * 商品検索結果コンポーネント
 */
const ItemSearchResults: React.FC<ItemSearchResultsProps> = ({
  searchResults,
  selectedItems,
  isSearching,
  onToggleItem,
  hasActiveFilters,
  onSearchRelatedItems,
  isRelatedItemsSearch,
  relatedItemSeedName,
}) => {
  const muiTheme = useTheme();

  // 商品が選択されているかどうかを確認
  const isItemSelected = (aid: string) => {
    return selectedItems.some((item) => item.aid === aid);
  };

  return (
    <Box
      sx={{
        width: '65%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${muiTheme.palette.divider}`,
        bgcolor: muiTheme.palette.background.default,
      }}
    >
      <Box
        sx={{
          p: 1.5,
          backgroundColor: muiTheme.palette.background.default,
          borderBottom: `1px solid ${muiTheme.palette.divider}`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          検索結果 {searchResults.length > 0 && `(${searchResults.length}件)`}
        </Typography>
        {isRelatedItemsSearch && relatedItemSeedName && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 0.5,
              p: 0.5,
              borderRadius: 1,
              bgcolor: muiTheme.palette.primary.light + '20', // 薄い背景色
            }}
          >
            <LinkIcon fontSize="small" color="primary" sx={{ mr: 0.5 }} />
            <Typography
              variant="caption"
              color="primary.main"
              sx={{ fontWeight: 'medium' }}
            >
              「{relatedItemSeedName}」の関連商品
            </Typography>
          </Box>
        )}
        {(hasActiveFilters || searchResults.length > 0) && (
          <Typography variant="caption" color="text.secondary">
            商品をクリックして選択してください。複数選択可能です。
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          p: 2,
        }}
      >
        {isSearching ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <CircularProgress color="primary" size={60} thickness={4} />
            <Typography variant="h6" color="text.secondary" sx={{ mt: 3 }}>
              商品を検索中...
            </Typography>
          </Box>
        ) : searchResults.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              color: muiTheme.palette.text.secondary,
            }}
          >
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {hasActiveFilters
                ? '検索条件に一致する商品がありません'
                : isRelatedItemsSearch
                  ? '関連商品が見つかりませんでした'
                  : '検索してください'}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ maxWidth: 400 }}
            >
              {hasActiveFilters
                ? '検索条件を変更して再度お試しください'
                : isRelatedItemsSearch
                  ? '別の商品で関連商品を検索してみてください'
                  : '検索するにはキーワードを入力するか、フィルターを使用してください'}
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {searchResults.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={item.aid}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: isItemSelected(item.aid)
                      ? `2px solid ${muiTheme.palette.primary.main}`
                      : '1px solid #e0e0e0',
                    boxShadow: isItemSelected(item.aid) ? 3 : 1,
                    borderRadius: 1,
                    transition: 'all 0.2s',
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: 3,
                      borderColor: isItemSelected(item.aid)
                        ? muiTheme.palette.primary.main
                        : muiTheme.palette.primary.light,
                    },
                    fontSize: '0.9rem',
                    position: 'relative', // アイコンボタンの絶対位置のために必要
                  }}
                  onClick={() => onToggleItem(item)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      px: 0.75,
                      pt: 0.75,
                    }}
                  >
                    <Checkbox
                      checked={isItemSelected(item.aid)}
                      onChange={(e) => {
                        e.stopPropagation();
                        onToggleItem(item);
                      }}
                      size="small"
                      sx={{ p: 0.25 }}
                    />
                    {item.pv_7d_rank && (
                      <Chip
                        label={`${item.pv_7d_rank}位`}
                        size="small"
                        color="primary"
                        sx={{
                          ml: 'auto',
                          height: 18,
                          fontSize: '0.65rem',
                        }}
                      />
                    )}

                    {/* 関連商品検索ボタン */}
                    {onSearchRelatedItems && (
                      <Tooltip title="この商品の関連商品を検索">
                        <IconButton
                          size="small"
                          color="primary"
                          sx={{
                            p: 0.25,
                            ml: 0.5,
                            '&:hover': {
                              backgroundColor:
                                muiTheme.palette.primary.light + '30',
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onSearchRelatedItems(item);
                          }}
                        >
                          <LinkIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  <CardMedia
                    component="img"
                    sx={{
                      width: '100%',
                      objectFit: 'contain',
                      p: 0.75,
                      aspectRatio: '1/1',
                    }}
                    image={item.image_url || '/placeholder.png'}
                    alt={item.item_title}
                  />
                  <CardContent sx={{ p: 1, flex: 1 }}>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        display: 'block',
                        fontWeight: 'bold',
                        mb: 0.25,
                        fontSize: '0.65rem',
                      }}
                    >
                      {item.brand_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        mb: 0.5,
                        lineHeight: 1.2,
                        height: '2.4em',
                        fontSize: '0.75rem',
                      }}
                    >
                      {item.item_title}
                    </Typography>
                    <Divider sx={{ my: 0.5 }} />
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.25,
                        mt: 0.25,
                      }}
                    >
                      {item.category && (
                        <Chip
                          label={item.category}
                          size="small"
                          sx={{
                            height: 16,
                            fontSize: '0.6rem',
                            bgcolor: muiTheme.palette.grey[100],
                          }}
                        />
                      )}
                      {item.sub_category && (
                        <Chip
                          label={item.sub_category}
                          size="small"
                          sx={{
                            height: 16,
                            fontSize: '0.6rem',
                            bgcolor: muiTheme.palette.grey[100],
                          }}
                        />
                      )}
                      {item.item_gender && (
                        <Chip
                          label={item.item_gender}
                          size="small"
                          sx={{
                            height: 16,
                            fontSize: '0.6rem',
                            bgcolor: muiTheme.palette.grey[100],
                          }}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ItemSearchResults;
