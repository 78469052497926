import {
  Computer as ComputerIcon,
  Preview as PreviewIcon,
  Refresh as RefreshIcon,
  Smartphone as SmartphoneIcon,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { ShelfConfigPreviewLayoutProps } from './types';

/**
 * プレビュー内のiframeの高さが自動的に調整されるようにするためのスクリプト
 */
const iframeResizeScript = `
  <script>
    function adjustIframeHeight() {
      const height = document.body.scrollHeight;
      window.parent.postMessage({ type: 'resize', height: height }, '*');
    }

    window.addEventListener('load', adjustIframeHeight);
    window.addEventListener('resize', adjustIframeHeight);

    // MutationObserverを使用してDOM変更時にも高さを調整
    const observer = new MutationObserver(adjustIframeHeight);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });
  </script>
`;

/**
 * シェルフ設定＆プレビューレイアウトコンポーネント
 * 設定パネルとプレビューを横に並べて表示する共通レイアウト
 */
const ShelfConfigPreviewLayout: React.FC<ShelfConfigPreviewLayoutProps> = ({
  settingsPanel,
  previewHtml,
  // previewJsonData, // 現在は使用していないためコメントアウト
  isPreviewLoading,
  previewError,
  deviceMode,
  onDeviceModeChange,
  onUpdatePreview,
  panelWidthRatio = { settings: 40, preview: 60 },
}) => {
  const theme = useTheme();
  const [iframeHeight, setIframeHeight] = useState<number | null>(null);

  // デバイスモード切り替えハンドラー
  const handleDeviceModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: 'sp' | 'pc' | null,
  ) => {
    if (newMode !== null) {
      onDeviceModeChange(newMode);
    }
  };

  // iframeからのメッセージハンドラー（高さ自動調整用）
  React.useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === 'resize') {
        setIframeHeight(event.data.height);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  // デバイスモードに応じたiframeのスタイルを取得
  const getIframeStyle = () => {
    if (deviceMode === 'sp') {
      return {
        width: '442px',
        height: '640px',
        border: 'none',
        margin: '0',
        display: 'block',
        background: 'white',
        position: 'relative' as const,
        zIndex: 2,
      };
    }
    return {
      width: '100%',
      height: '100%',
      border: 'none',
      margin: '0',
    };
  };

  // SPモード用のiPhoneフレームスタイル
  const getPhoneFrameStyle = () => {
    return {
      width: '488px',
      minHeight: '700px',
      height: iframeHeight ? `${iframeHeight + 60}px` : 'auto',
      background: '#111',
      borderRadius: '36px',
      padding: '26px 10px',
      boxShadow: '0 0 0 2px #ccc, 0 10px 20px rgba(0, 0, 0, 0.2)',
      position: 'relative' as const,
      margin: '10px auto',
      boxSizing: 'border-box' as const,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
    };
  };

  // SPモード用のノッチとボタン
  const renderPhoneDetails = () => {
    return (
      <>
        <div
          style={{
            position: 'absolute',
            width: '150px',
            height: '25px',
            background: '#111',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            borderBottomLeftRadius: '14px',
            borderBottomRightRadius: '14px',
            zIndex: 5,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '40px',
            height: '5px',
            background: '#444',
            bottom: '10px',
            borderRadius: '3px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 5,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '3px',
            height: '30px',
            background: '#444',
            top: '90px',
            right: '-1px',
            borderTopLeftRadius: '2px',
            borderBottomLeftRadius: '2px',
            zIndex: 5,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '3px',
            height: '60px',
            background: '#444',
            top: '150px',
            left: '-1px',
            borderTopRightRadius: '2px',
            borderBottomRightRadius: '2px',
            zIndex: 5,
          }}
        />
      </>
    );
  };

  // HTMLをiframeに表示するための準備
  const getHtmlContent = () => {
    return `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <style>
            body {
              margin: 0;
              padding: 0;
              font-family: sans-serif;
              ${deviceMode === 'sp' ? 'width: 100%; overflow-x: hidden;' : ''}
            }
          </style>
          ${iframeResizeScript}
        </head>
        <body>
          ${previewHtml || ''}
        </body>
      </html>
    `;
  };

  // プレビュー部分のレンダリング
  const renderPreview = () => {
    // HTMLがなく、ローディング中でもエラーでもない場合
    if (!previewHtml && !isPreviewLoading && !previewError) {
      return (
        <Paper
          elevation={0}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            bgcolor: '#f5f5f5',
            border: '1px dashed #ccc',
            borderRadius: 1,
            flex: 1,
          }}
        >
          <Typography variant="body1" color="text.secondary" align="center">
            シェルフのプレビューがここに表示されます。
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 1 }}
          >
            設定を変更すると、リアルタイムでプレビューが更新されます。
          </Typography>
          <Box sx={{ mt: 2 }}>
            <IconButton
              color="primary"
              onClick={onUpdatePreview}
              size="large"
              sx={{
                border: `1px dashed ${theme.palette.primary.main}`,
                p: 1.5,
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
        </Paper>
      );
    }

    // ローディング中の表示
    if (isPreviewLoading) {
      return (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            flex: 1,
          }}
        >
          <CircularProgress size={40} sx={{ mb: 2 }} />
          <Typography variant="body1" color="text.secondary">
            プレビューを読み込み中...
          </Typography>
        </Box>
      );
    }

    // エラー表示
    if (previewError) {
      return (
        <Paper
          elevation={0}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            bgcolor: '#fff8f8',
            border: '1px solid #ffcdd2',
            borderRadius: 1,
            flex: 1,
          }}
        >
          <Typography variant="body1" color="error" align="center">
            {previewError}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <IconButton color="primary" onClick={onUpdatePreview} size="medium">
              <RefreshIcon />
            </IconButton>
          </Box>
        </Paper>
      );
    }

    // HTMLプレビュー表示（PCモード）
    if (deviceMode === 'pc') {
      return (
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #e0e0e0',
            borderRadius: 1,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              p: 1,
              bgcolor: '#f5f5f5',
              borderBottom: '1px solid #e0e0e0',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" color="text.secondary">
              PCプレビュー表示（実際の表示と多少異なる場合があります）
            </Typography>
          </Box>

          {/* サニタイズ済みHTMLをiframeで表示 */}
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <iframe
              srcDoc={getHtmlContent()}
              style={getIframeStyle()}
              title="シェルフプレビュー"
              sandbox="allow-scripts"
            />
          </Box>
        </Box>
      );
    }

    // HTMLプレビュー表示（スマホモード）
    return (
      <Box
        sx={{
          flex: 1,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        {/* iPhoneライクなフレーム */}
        <div style={getPhoneFrameStyle()}>
          {renderPhoneDetails()}
          <iframe
            srcDoc={getHtmlContent()}
            style={getIframeStyle()}
            title="シェルフプレビュー（スマホ表示）"
            sandbox="allow-scripts"
          />
        </div>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {/* 左側: 設定パネル */}
      <Box
        sx={{
          width: `${panelWidthRatio.settings}%`,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRight: `1px solid ${theme.palette.divider}`,
          overflow: 'auto',
        }}
      >
        {settingsPanel}
      </Box>

      {/* 右側: プレビュー */}
      <Box
        sx={{
          width: `${panelWidthRatio.preview}%`,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <PreviewIcon sx={{ mr: 1, color: theme.palette.info.main }} />
            プレビュー
            {isPreviewLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="プレビュー更新">
              <IconButton
                size="small"
                onClick={onUpdatePreview}
                disabled={isPreviewLoading}
                sx={{ mr: 1 }}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {/* デバイス表示切替ボタン */}
            <ToggleButtonGroup
              value={deviceMode}
              exclusive
              onChange={handleDeviceModeChange}
              aria-label="デバイス表示モード"
              size="small"
            >
              <ToggleButton value="sp" aria-label="スマホ表示">
                <SmartphoneIcon />
              </ToggleButton>
              <ToggleButton value="pc" aria-label="PC表示">
                <ComputerIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>

        {/* プレビュー表示エリア */}
        <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>{renderPreview()}</Box>
      </Box>
    </Box>
  );
};

export default ShelfConfigPreviewLayout;
