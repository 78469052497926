import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { ComponentMetricsV2 } from '../../../services/component-service';
import CustomPageTabs from '../CustomPageTabs';
import MetricsTable from '../component-comparison/MetricsTable';
import ShelfDisplaySection from './ShelfDisplaySection';

interface ComparisonSectionProps {
  metrics: ComponentMetricsV2[];
  selectedPageType: string;
  onPageTypeChange: (event: React.SyntheticEvent, newValue: string) => void;
  startDate: string;
  endDate: string;
  shelfUnitPrice: number;
  otherUnitPrice: number;
  isLoading: boolean;
}

const ComparisonSection: React.FC<ComparisonSectionProps> = ({
  metrics,
  selectedPageType,
  onPageTypeChange,
  startDate,
  endDate,
  shelfUnitPrice,
  otherUnitPrice,
  isLoading,
}) => {
  const metricsByPageType: Record<string, ComponentMetricsV2[]> = {};

  // メトリクスが存在する場合のみ処理
  if (metrics && metrics.length > 0) {
    metrics.forEach((metric) => {
      const pageType = metric.page_name;
      if (!metricsByPageType[pageType]) {
        metricsByPageType[pageType] = [];
      }
      metricsByPageType[pageType].push(metric);
    });
  }

  const desiredPageTypeOrder = ['トップ', '商品詳細', '商品一覧', 'ブランド'];
  const pageTypes = Object.keys(metricsByPageType).sort((a, b) => {
    const indexA = desiredPageTypeOrder.indexOf(a);
    const indexB = desiredPageTypeOrder.indexOf(b);
    if (indexA === -1 && indexB === -1) return a.localeCompare(b);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  // データがない場合は何も表示しない
  if (pageTypes.length === 0) {
    return null;
  }

  // 選択されているページタイプがない場合は最初のページタイプを選択
  const effectivePageType = selectedPageType || pageTypes[0];

  return (
    <Box
      sx={{
        mb: 6,
        bgcolor: 'background.paper',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
        minHeight: '200px',
      }}
    >
      <Box
        sx={{
          p: '8px 16px',
          bgcolor: '#1a1a1a',
          color: 'white',
          mx: '2px',
          borderRadius: '3px 3px 0 0',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', fontSize: '1.8rem' }}
        >
          他コンポーネントとの比較
        </Typography>
      </Box>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ px: 1, pt: 1 }}>
            <CustomPageTabs
              pageTypes={pageTypes}
              selectedPageType={effectivePageType}
              onChange={onPageTypeChange}
              metrics={metrics}
            />
          </Box>

          <Box sx={{ px: 1 }}>
            {effectivePageType && metricsByPageType[effectivePageType] && (
              <>
                <ShelfDisplaySection selectedPageType={effectivePageType} />
                <MetricsTable
                  metrics={metricsByPageType[effectivePageType]}
                  startDate={startDate}
                  endDate={endDate}
                  shelfUnitPrice={shelfUnitPrice}
                  otherUnitPrice={otherUnitPrice}
                />
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ComparisonSection;
