import {
  BrandingWatermark as BrandingWatermarkIcon,
  Category as CategoryIcon,
  RestartAlt as RestartAltIcon,
  Style as StyleIcon,
  Tune as TuneIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { itemSearchService } from '../../../../services/item-search-service';
import { MetadataItem } from '../../../../types/item-search.type';
import { FilterSettingsProps } from './types';

/**
 * フィルター設定コンポーネント
 * ブランド、カテゴリ、サブカテゴリなどのフィルターを設定するUIを提供します
 */
const FilterSettings: React.FC<FilterSettingsProps> = ({
  brandNames,
  categories,
  subCategories,
  onChangeBrandNames,
  onChangeCategories,
  onChangeSubCategories,
  showBrandFilter = true,
  showCategoryFilter = true,
  showSubCategoryFilter = true,
  onChangeShowBrandFilter,
  onChangeShowCategoryFilter,
  onChangeShowSubCategoryFilter,
  onResetFilters,
}) => {
  const theme = useTheme();

  // メタデータ取得状態
  const [loading, setLoading] = useState({
    brand_name: false,
    category: false,
    sub_category: false,
  });

  // メタデータオプション
  const [options, setOptions] = useState<{
    brand_name: MetadataItem[];
    category: MetadataItem[];
    sub_category: MetadataItem[];
  }>({
    brand_name: [],
    category: [],
    sub_category: [],
  });

  // メタデータ取得
  const fetchMetadata = async (field: string) => {
    setLoading((prev) => ({ ...prev, [field]: true }));
    try {
      const response = await itemSearchService.getMetadata(field);
      setOptions((prev) => ({ ...prev, [field]: response.metadata }));
    } catch (error) {
      console.error(`${field}のメタデータ取得エラー:`, error);
    } finally {
      setLoading((prev) => ({ ...prev, [field]: false }));
    }
  };

  // 初回レンダリング時にメタデータを取得
  useEffect(() => {
    // 表示するフィルターに応じてメタデータを取得
    if (showBrandFilter) fetchMetadata('brand_name');
    if (showCategoryFilter) fetchMetadata('category');
    if (showSubCategoryFilter) fetchMetadata('sub_category');
  }, [showBrandFilter, showCategoryFilter, showSubCategoryFilter]);

  // マルチセレクト値変更ハンドラー
  const handleMultiSelectChange = (
    event: SelectChangeEvent<string[]>,
    setter: (value: string[]) => void,
  ) => {
    const value = event.target.value;
    setter(typeof value === 'string' ? value.split(',') : value);
  };

  // リセット可能かどうか
  const canReset =
    brandNames.length > 0 || categories.length > 0 || subCategories.length > 0;

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TuneIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
          <Typography variant="subtitle1" fontWeight="bold">
            フィルター設定
          </Typography>
        </Box>

        <Tooltip title="フィルターリセット">
          <span>
            <Button
              onClick={onResetFilters}
              disabled={!canReset}
              size="small"
              color="error"
              startIcon={<RestartAltIcon />}
            >
              リセット
            </Button>
          </span>
        </Tooltip>
      </Box>

      {/* フィルターの表示・非表示設定 */}
      <Box sx={{ mb: 3, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {onChangeShowBrandFilter && (
          <FormControlLabel
            control={
              <Switch
                checked={showBrandFilter}
                onChange={(e) => onChangeShowBrandFilter(e.target.checked)}
                color="primary"
                size="small"
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BrandingWatermarkIcon
                  fontSize="small"
                  sx={{ mr: 0.5, opacity: 0.7 }}
                />
                <Typography variant="body2">ブランドフィルター</Typography>
              </Box>
            }
          />
        )}

        {onChangeShowCategoryFilter && (
          <FormControlLabel
            control={
              <Switch
                checked={showCategoryFilter}
                onChange={(e) => onChangeShowCategoryFilter(e.target.checked)}
                color="primary"
                size="small"
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CategoryIcon fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                <Typography variant="body2">カテゴリフィルター</Typography>
              </Box>
            }
          />
        )}

        {onChangeShowSubCategoryFilter && (
          <FormControlLabel
            control={
              <Switch
                checked={showSubCategoryFilter}
                onChange={(e) =>
                  onChangeShowSubCategoryFilter(e.target.checked)
                }
                color="primary"
                size="small"
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StyleIcon fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                <Typography variant="body2">サブカテゴリフィルター</Typography>
              </Box>
            }
          />
        )}
      </Box>

      {/* フィルターコントロール */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* ブランド名フィルター */}
        {showBrandFilter && (
          <FormControl fullWidth size="small">
            <InputLabel id="brand-names-label">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BrandingWatermarkIcon
                  fontSize="small"
                  sx={{ mr: 0.5, opacity: 0.7 }}
                />
                ブランド名
              </Box>
            </InputLabel>
            <Select
              labelId="brand-names-label"
              id="brand-names-select"
              multiple
              value={brandNames}
              onChange={(e) => handleMultiSelectChange(e, onChangeBrandNames)}
              input={
                <OutlinedInput
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BrandingWatermarkIcon
                        fontSize="small"
                        sx={{ mr: 0.5, opacity: 0.7 }}
                      />
                      ブランド名
                    </Box>
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              disabled={loading.brand_name}
            >
              {loading.brand_name ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                options.brand_name.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key} ({item.item_count})
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}

        {/* カテゴリフィルター */}
        {showCategoryFilter && (
          <FormControl fullWidth size="small">
            <InputLabel id="categories-label">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CategoryIcon fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                カテゴリ
              </Box>
            </InputLabel>
            <Select
              labelId="categories-label"
              id="categories-select"
              multiple
              value={categories}
              onChange={(e) => handleMultiSelectChange(e, onChangeCategories)}
              input={
                <OutlinedInput
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CategoryIcon
                        fontSize="small"
                        sx={{ mr: 0.5, opacity: 0.7 }}
                      />
                      カテゴリ
                    </Box>
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              disabled={loading.category}
            >
              {loading.category ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                options.category.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key} ({item.item_count})
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}

        {/* サブカテゴリフィルター */}
        {showSubCategoryFilter && (
          <FormControl fullWidth size="small">
            <InputLabel id="sub-categories-label">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StyleIcon fontSize="small" sx={{ mr: 0.5, opacity: 0.7 }} />
                サブカテゴリ
              </Box>
            </InputLabel>
            <Select
              labelId="sub-categories-label"
              id="sub-categories-select"
              multiple
              value={subCategories}
              onChange={(e) =>
                handleMultiSelectChange(e, onChangeSubCategories)
              }
              input={
                <OutlinedInput
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StyleIcon
                        fontSize="small"
                        sx={{ mr: 0.5, opacity: 0.7 }}
                      />
                      サブカテゴリ
                    </Box>
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              disabled={loading.sub_category}
            >
              {loading.sub_category ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                options.sub_category.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.key} ({item.item_count})
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
      </Box>
    </Paper>
  );
};

export default FilterSettings;
