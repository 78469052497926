import {
  ChevronRight as ChevronRightIcon,
  ViewInAr as ContainerIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  GridView as GridIcon,
  Image as ImageIcon,
  TextFields as TextFieldsIcon,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ElementType } from './index';

interface ElementTreeProps {
  elements: ElementType[];
  selectedElementId: number | null;
  onSelectElement: (id: number) => void;
  onRemoveElement: (id: number) => void;
}

/**
 * 要素ツリーコンポーネント
 */
const ElementTree: React.FC<ElementTreeProps> = ({
  elements,
  selectedElementId,
  onSelectElement,
  onRemoveElement,
}) => {
  // 展開された要素のIDを管理する状態
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  // 要素の展開/折りたたみを切り替える
  const toggleExpand = (id: number) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // 要素のアイコンを取得
  const getElementIcon = (type: string) => {
    switch (type) {
      case 'container':
        return <ContainerIcon />;
      case 'text':
        return <TextFieldsIcon />;
      case 'img':
        return <ImageIcon />;
      case 'grid':
        return <GridIcon />;
      default:
        return <ChevronRightIcon />;
    }
  };

  // 要素の名前を取得
  const getElementName = (element: ElementType): string => {
    switch (element.type) {
      case 'container':
        return 'コンテナ';
      case 'text':
        return `テキスト: ${element.content?.substring(0, 20)}${element.content && element.content.length > 20 ? '...' : ''}`;
      case 'img':
        return element.attributes?.alt
          ? `画像: ${element.attributes.alt}`
          : '画像';
      case 'grid':
        return `グリッド (${element.gridConfig?.columns || 0}列)`;
      default:
        return '要素';
    }
  };

  // 再帰的に要素ツリーをレンダリング
  const renderElementTree = (elements: ElementType[], level = 0) => {
    return (
      <List sx={{ pl: level > 0 ? 2 : 0 }}>
        {elements.map((element) => {
          const hasChildren = element.children && element.children.length > 0;
          const isExpanded = expanded[element.id] === true;
          const isSelected = selectedElementId === element.id;

          return (
            <React.Fragment key={element.id}>
              <ListItem
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveElement(element.id);
                    }}
                    size="small"
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                }
                sx={{
                  mb: 0.5,
                  bgcolor: isSelected ? 'action.selected' : 'transparent',
                  borderRadius: 1,
                }}
              >
                <ListItemButton
                  onClick={() => onSelectElement(element.id)}
                  dense
                  sx={{ pr: 6 }}
                >
                  {hasChildren && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleExpand(element.id);
                      }}
                      sx={{ mr: 1, p: 0 }}
                    >
                      {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </IconButton>
                  )}
                  {!hasChildren && <Box sx={{ width: 28 }} />}
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    {getElementIcon(element.type)}
                  </ListItemIcon>
                  <ListItemText primary={getElementName(element)} />
                </ListItemButton>
              </ListItem>
              {hasChildren && (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  {renderElementTree(element.children, level + 1)}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  // 要素が空の場合
  if (elements.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <Typography variant="body2" color="text.secondary">
          要素がありません。上部の「要素を追加」ボタンから追加してください。
        </Typography>
      </Box>
    );
  }

  return <Box>{renderElementTree(elements)}</Box>;
};

export default ElementTree;
