import { Box, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import {
  ShelfDisplayInfo,
  shelfService,
} from '../../../services/shelf-service';
import { useClient } from '../../../store/client.store';

interface ShelfDisplaySectionProps {
  selectedPageType: string;
}

// シェルフカードコンポーネント
const ShelfCard: React.FC<{ shelf: ShelfDisplayInfo; level: number }> = ({
  shelf,
  level,
}) => {
  const isChild = level > 0;
  const hasNestedShelves = shelf.nested_shelves.length > 0;
  const [showImageOverlay, setShowImageOverlay] = React.useState(false);

  // 基本のカード幅を設定
  const baseWidth = isChild ? 200 : 280;

  return (
    <Box
      sx={{
        bgcolor: isChild ? '#fff' : '#f8f8f8',
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: isChild
          ? '0 2px 8px rgba(0, 0, 0, 0.08)'
          : '0 2px 6px rgba(0, 0, 0, 0.04)',
        width: hasNestedShelves ? 'fit-content' : baseWidth,
        maxWidth: hasNestedShelves ? '100%' : baseWidth,
        flexShrink: 0,
        px: 2,
        pt: 1.5,
        pb: 2,
        border: isChild ? '1px solid #e0e0e0' : 'none',
        position: 'relative',
      }}
    >
      {/* 画像オーバーレイ */}
      {showImageOverlay && !hasNestedShelves && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 1300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            p: 4,
          }}
          onClick={() => setShowImageOverlay(false)}
        >
          <Box
            sx={{
              position: 'relative',
              width: 'auto',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '1200px',
              maxHeight: '95vh',
            }}
          >
            <Box
              component="img"
              src={shelf.image_url}
              alt={shelf.name}
              sx={{
                maxWidth: '90vw',
                maxHeight: '90vh',
                objectFit: 'contain',
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <Box
              sx={{
                position: 'absolute',
                top: -40,
                left: 0,
                right: 0,
                textAlign: 'center',
                color: '#fff',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
              }}
            >
              {shelf.name}
            </Box>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          position: 'relative',
        }}
      >
        {isChild && (
          <Box
            sx={{
              position: 'absolute',
              top: -8,
              left: '50%',
              transform: 'translateX(-50%)',
              bgcolor: '#666',
              color: '#fff',
              fontSize: '0.6rem',
              py: 0.2,
              px: 1,
              borderRadius: 1,
              whiteSpace: 'nowrap',
            }}
          >
            候補シェルフ
          </Box>
        )}

        {/* タイトル */}
        <Typography
          variant="h6"
          sx={{
            fontSize: isChild ? '0.9rem' : '1rem',
            fontWeight: 'bold',
            color: '#333',
            textAlign: 'center',
            mt: isChild ? 1 : 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {shelf.name}
            {!isChild && shelf.shelf_rows && (
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  bgcolor: '#e3f2fd',
                  color: '#1976d2',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  px: 1,
                  py: 0.2,
                  borderRadius: 1,
                  border: '1px solid #90caf9',
                }}
              >
                {shelf.shelf_rows}
              </Box>
            )}
          </Box>
          {!isChild && shelf.display_position && (
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                bgcolor: '#f3f6fb',
                color: '#4a5b7d',
                fontSize: '0.7rem',
                fontWeight: 500,
                px: 1.5,
                py: 0.1,
                borderRadius: 12,
                border: '1px solid #e5eaf2',
                mt: 0.5,
                letterSpacing: '0.02em',
                opacity: 0.95,
              }}
            >
              {shelf.display_position}
            </Box>
          )}
          {shelf.event_labels &&
            shelf.event_labels.map((label) => (
              <Box
                key={label}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  bgcolor: '#fdf2fa',
                  color: '#9c27b0',
                  fontSize: '0.7rem',
                  fontWeight: 500,
                  px: 1.5,
                  py: 0.1,
                  borderRadius: 12,
                  border: '1px solid #f48fb1',
                  mt: 0.5,
                  letterSpacing: '0.02em',
                  opacity: 0.95,
                  mx: 0.25,
                }}
              >
                {label}
              </Box>
            ))}
        </Typography>

        {/* 説明文 */}
        <Typography
          variant="body2"
          sx={{
            color: '#666',
            lineHeight: 1.4,
            fontSize: isChild ? '0.75rem' : '0.85rem',
            fontWeight: 700,
            textAlign: 'center',
            maxWidth: baseWidth - 32, // パディングを考慮
            mt: 0.5,
          }}
        >
          {shelf.description}
        </Typography>

        {/* 画像 */}
        {!hasNestedShelves && (
          <Box
            sx={{
              aspectRatio: '3/5',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 1,
              p: 1,
              bgcolor: isChild ? '#fafafa' : 'transparent',
              borderRadius: 1,
              cursor: 'pointer',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => setShowImageOverlay(true)}
          >
            <Box
              component="img"
              src={shelf.image_url}
              alt={shelf.name}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
              }}
            />
          </Box>
        )}

        {/* ネストされたシェルフ */}
        {hasNestedShelves && (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              overflowX: 'auto',
              pb: 1,
              mt: 2,
              pt: 1,
              px: 1,
              bgcolor: '#f0f0f0',
              borderRadius: 2,
              width: 'fit-content',
              maxWidth: '100%',
              mx: 'auto',
              '&::-webkit-scrollbar': {
                height: '6px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
                borderRadius: '3px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#ccc',
                borderRadius: '3px',
                '&:hover': {
                  backgroundColor: '#999',
                },
              },
            }}
          >
            {shelf.nested_shelves.map((nestedShelf) => (
              <ShelfCard
                key={nestedShelf.name}
                shelf={nestedShelf}
                level={level + 1}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ShelfDisplaySection: React.FC<ShelfDisplaySectionProps> = ({
  selectedPageType,
}) => {
  const { client: clientState } = useClient();

  const { data, isLoading, error } = useQuery({
    queryKey: [
      'shelfDisplay',
      selectedPageType,
      clientState.selectedClient?.clientId,
    ],
    queryFn: () =>
      shelfService.getShelfDisplayInfo(
        selectedPageType,
        clientState.selectedClient?.clientId,
      ),
    enabled: !!selectedPageType,
  });

  if (!data?.shelves?.length || isLoading || error) {
    return null;
  }

  return (
    <Paper
      elevation={2}
      sx={{
        mt: 2,
        mb: 3,
        borderRadius: 3,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '1.1rem',
            fontWeight: 'bold',
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box
            component="img"
            src="https://storage.googleapis.com/insightx-images/dashboard/public/assets/insightx_logo_black.png"
            alt="InsightX"
            sx={{ width: 30, height: 30 }}
          />
          {selectedPageType.replace('ページ', '')}
          ページで表示中のInsightXシェルフ
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            overflowX: 'auto',
            pb: 1.5,
            px: 0.5,
            pt: 0.5,
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#ccc',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#999',
              },
            },
          }}
        >
          {data.shelves.map((shelf) => (
            <ShelfCard key={shelf.name} shelf={shelf} level={0} />
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default ShelfDisplaySection;
