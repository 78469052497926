import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { itemSearchService } from '../../../../services/item-search-service';
import { MetadataItem } from '../../../../types/item-search.type';

export interface FilterPanelProps {
  brandNames: string[];
  categories: string[];
  subCategories: string[];
  itemGenders: string[];
  onChangeBrandNames: (brandNames: string[]) => void;
  onChangeCategories: (categories: string[]) => void;
  onChangeSubCategories: (subCategories: string[]) => void;
  onChangeItemGenders: (itemGenders: string[]) => void;
  onResetFilters: () => void;
  onSearch: () => void;
}

/**
 * フィルターパネルコンポーネント
 */
const FilterPanel: React.FC<FilterPanelProps> = ({
  brandNames,
  categories,
  subCategories,
  itemGenders,
  onChangeBrandNames,
  onChangeCategories,
  onChangeSubCategories,
  onChangeItemGenders,
  onResetFilters,
  onSearch,
}) => {
  // メタデータ取得状態
  const [loading, setLoading] = useState({
    brand_name: false,
    category: false,
    sub_category: false,
    item_gender: false,
  });

  // メタデータオプション
  const [options, setOptions] = useState<{
    brand_name: MetadataItem[];
    category: MetadataItem[];
    sub_category: MetadataItem[];
    item_gender: MetadataItem[];
  }>({
    brand_name: [],
    category: [],
    sub_category: [],
    item_gender: [],
  });

  // メタデータ取得
  const fetchMetadata = async (field: string) => {
    setLoading((prev) => ({ ...prev, [field]: true }));
    try {
      const response = await itemSearchService.getMetadata(field);
      setOptions((prev) => ({ ...prev, [field]: response.metadata }));
    } catch (error) {
      console.error(`${field}のメタデータ取得エラー:`, error);
    } finally {
      setLoading((prev) => ({ ...prev, [field]: false }));
    }
  };

  // 初回レンダリング時にメタデータを取得
  useEffect(() => {
    fetchMetadata('brand_name');
    fetchMetadata('category');
    fetchMetadata('sub_category');
    fetchMetadata('item_gender');
  }, []);

  // マルチセレクト値変更ハンドラー
  const handleMultiSelectChange = (
    event: SelectChangeEvent<string[]>,
    setter: (value: string[]) => void,
  ) => {
    const value = event.target.value;
    const newValue = typeof value === 'string' ? value.split(',') : value;

    // 値を更新するだけ - 検索はここで実行しない
    setter(newValue);

    // 通常の検索ボタンクリックでのみ検索を実行する
    // Reactの状態更新が確実に完了してから検索するため
  };

  // リセット可能かどうか
  const canReset =
    brandNames.length > 0 ||
    categories.length > 0 ||
    subCategories.length > 0 ||
    itemGenders.length > 0;

  // 検索ボタンクリック時の処理
  const handleSearchClick = () => {
    // 親コンポーネントの検索関数を呼び出す
    onSearch();
  };

  return (
    <Box sx={{ p: 1.5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
        {/* ブランド名フィルター */}
        <FormControl size="small" sx={{ width: '22%', mr: 1 }}>
          <InputLabel id="brand-names-label">ブランド名</InputLabel>
          <Select
            labelId="brand-names-label"
            id="brand-names-select"
            multiple
            value={brandNames}
            onChange={(e) => handleMultiSelectChange(e, onChangeBrandNames)}
            input={<OutlinedInput label="ブランド名" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            disabled={loading.brand_name}
          >
            {loading.brand_name ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              options.brand_name.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.key} ({item.item_count})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        {/* カテゴリフィルター */}
        <FormControl size="small" sx={{ width: '22%', mr: 1 }}>
          <InputLabel id="categories-label">カテゴリ</InputLabel>
          <Select
            labelId="categories-label"
            id="categories-select"
            multiple
            value={categories}
            onChange={(e) => handleMultiSelectChange(e, onChangeCategories)}
            input={<OutlinedInput label="カテゴリ" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            disabled={loading.category}
          >
            {loading.category ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              options.category.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.key} ({item.item_count})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        {/* サブカテゴリフィルター */}
        <FormControl size="small" sx={{ width: '22%', mr: 1 }}>
          <InputLabel id="sub-categories-label">サブカテゴリ</InputLabel>
          <Select
            labelId="sub-categories-label"
            id="sub-categories-select"
            multiple
            value={subCategories}
            onChange={(e) => handleMultiSelectChange(e, onChangeSubCategories)}
            input={<OutlinedInput label="サブカテゴリ" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            disabled={loading.sub_category}
          >
            {loading.sub_category ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              options.sub_category.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.key} ({item.item_count})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        {/* 性別フィルター */}
        <FormControl size="small" sx={{ width: '18%', mr: 1.5 }}>
          <InputLabel id="item-genders-label">性別</InputLabel>
          <Select
            labelId="item-genders-label"
            id="item-genders-select"
            multiple
            value={itemGenders}
            onChange={(e) => handleMultiSelectChange(e, onChangeItemGenders)}
            input={<OutlinedInput label="性別" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            disabled={loading.item_gender}
          >
            {loading.item_gender ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              options.item_gender.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.key} ({item.item_count})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        {/* ボタン類 */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* リセットボタン */}
          <Tooltip title="フィルターリセット">
            <span>
              <Button
                onClick={onResetFilters}
                disabled={!canReset}
                size="small"
                color="error"
                startIcon={<RestartAltIcon />}
                sx={{ mr: 1 }}
              >
                リセット
              </Button>
            </span>
          </Tooltip>

          {/* 検索ボタン */}
          <Button
            variant="contained"
            color="success"
            onClick={handleSearchClick}
            size="small"
            startIcon={<SearchIcon />}
          >
            検索
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterPanel;
