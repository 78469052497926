import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { PROMPT_TEMPLATES } from '../constants/promptTemplates';
import { GptContextService } from '../services/gptContext.service';
import { BuiltPrompt, PromptModule, SearchSession } from '../services/types';

interface PromptBuilderProps {
  selectedSessions: SearchSession[];
  onSessionsSelect: (sessionIds: string[]) => void;
  selectedModules: PromptModule[];
}

export const PromptBuilder: React.FC<PromptBuilderProps> = ({
  selectedSessions,
  onSessionsSelect,
  selectedModules,
}) => {
  const theme = useTheme();
  const [customPrompt, setCustomPrompt] = useState(
    PROMPT_TEMPLATES[0].template,
  );
  const [copied, setCopied] = useState(false);
  const [notification, setNotification] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({ open: false, message: '', severity: 'success' });
  const [savedPrompts, setSavedPrompts] = useState<BuiltPrompt[]>([]);

  // 保存済みプロンプトを読み込む
  useEffect(() => {
    const loadSavedPrompts = async () => {
      try {
        const prompts = await GptContextService.getPrompts();
        setSavedPrompts(prompts);
      } catch (error) {
        console.error('保存済みプロンプトの読み込みに失敗しました:', error);
        showNotification('保存済みプロンプトの読み込みに失敗しました', 'error');
      }
    };
    loadSavedPrompts();
  }, []);

  // 通知を表示
  const showNotification = (message: string, severity: 'success' | 'error') => {
    setNotification({ open: true, message, severity });
  };

  // 通知を閉じる
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // コンテキストを生成
  const generateContext = () => {
    let context = '';

    // プロンプトモジュールを追加
    if (selectedModules.length > 0) {
      context += '# プロンプトモジュール\n\n';
      selectedModules.forEach((module) => {
        context += `## ${module.name}\n${module.content}\n\n`;
      });
    }

    // セッションのコンテキストを追加
    if (selectedSessions.length > 0) {
      context += '# セッション\n\n';
      context += selectedSessions
        .map((session) => {
          // pre_promptがある場合は先頭に追加
          let sessionText = '';
          if (session.pre_prompt) {
            sessionText += `前提条件:\n${session.pre_prompt}\n\n`;
          }

          // 検索クエリ
          sessionText += `検索クエリ: ${session.searchMessage}\n`;

          // メモがある場合は追加
          if (session.memo) {
            sessionText += `メモ:\n${session.memo}\n\n`;
          }

          // 結果の処理
          const resultsText = session.results
            .map((result) => {
              let text = `## ${result.title}\n`;

              // 検索タイプの表示を追加
              const typeDisplay =
                {
                  discussion: 'ディスカッション',
                  analysis: '分析',
                  meeting: 'ミーティング',
                  slack: 'Slack',
                  sql_template: 'SQLテンプレート',
                  analysis_table: '分析テーブル',
                  analysis_template: '分析テンプレート',
                }[result.type] || result.type;

              text += `タイプ: ${typeDisplay}\n\n`;

              // 検索タイプに応じたプロパティの追加
              switch (result.type) {
                case 'discussion':
                  text += `### プロパティ\n`;
                  text += `- ディスカッションタイプ: ${result.properties['ディスカッションタイプ'] || '未設定'}\n`;
                  text += `- 対象領域: ${result.properties['対象領域'] || '未設定'}\n`;
                  text += `- ステークホルダー: ${result.properties['ステークホルダー'] || '未設定'}\n`;
                  text += `- キーワード: ${result.properties['キーワード'] || '未設定'}\n`;
                  text += `- 決定事項: ${result.properties['決定事項'] || '未設定'}\n`;
                  text += `- ネクストアクション: ${result.properties['ネクストアクション'] || '未設定'}\n`;
                  text += `- 学び: ${result.properties['学び'] || '未設定'}\n`;
                  break;

                case 'analysis':
                  text += `### プロパティ\n`;
                  text += `- Metrics: ${result.properties['Metrics'] || '未設定'}\n`;
                  text += `- Variants: ${result.properties['Variants'] || '未設定'}\n`;
                  text += `- 仮説: ${result.properties['仮説'] || '未設定'}\n`;
                  text += `- 分析アプローチ: ${result.properties['分析アプローチ'] || '未設定'}\n`;
                  text += `- 目的: ${result.properties['目的'] || '未設定'}\n`;
                  text += `- 結論: ${result.properties['結論'] || '未設定'}\n`;
                  text += `- 重要な発見: ${result.properties['重要な発見'] || '未設定'}\n`;
                  text += `- 推奨対象者: ${result.properties['推奨対象者'] || '未設定'}\n`;
                  break;

                case 'meeting':
                  text += `### プロパティ\n`;
                  text += `- 議題: ${result.properties['議題'] || '未設定'}\n`;
                  text += `- 決定事項: ${result.properties['決定事項'] || '未設定'}\n`;
                  text += `- ネクストアクション: ${result.properties['ネクストアクション'] || '未設定'}\n`;
                  text += `- 重要な議論: ${result.properties['重要な議論'] || '未設定'}\n`;
                  text += `- フォローアップ: ${result.properties['フォローアップ'] || '未設定'}\n`;
                  break;

                case 'slack':
                  text += `### プロパティ\n`;
                  text += `- チャンネル: ${result.properties['チャンネル'] || '未設定'}\n`;
                  text += `- 送信者: ${result.properties['送信者'] || '未設定'}\n`;
                  text += `- メッセージタイプ: ${result.properties['メッセージタイプ'] || '未設定'}\n`;
                  text += `- 初期意図: ${result.properties['初期意図'] || '未設定'}\n`;
                  text += `- 結論: ${result.properties['結論'] || '未設定'}\n`;
                  break;

                case 'sql_template':
                  text += `### プロパティ\n`;
                  text += `- SQL: ${result.properties['SQL'] || '未設定'}\n`;
                  text += `- 使用テーブル: ${result.properties['使用テーブル'] || '未設定'}\n`;
                  text += `- 利用シーン: ${result.properties['利用シーン'] || '未設定'}\n`;
                  break;

                case 'analysis_table':
                  text += `### プロパティ\n`;
                  text += `- DDL: ${result.properties['DDL'] || '未設定'}\n`;
                  text += `- 列名と列の説明: ${result.properties['列名と列の説明'] || '未設定'}\n`;
                  text += `- 更新頻度: ${result.properties['更新頻度'] || '未設定'}\n`;
                  text += `- 利用用途: ${result.properties['利用用途'] || '未設定'}\n`;
                  text += `- 日本語テーブル名: ${result.properties['日本語テーブル名'] || '未設定'}\n`;
                  break;

                case 'analysis_template':
                  text += `### プロパティ\n`;
                  text += `- SQL: ${result.properties['SQL'] || '未設定'}\n`;
                  text += `- 分析タイプ: ${result.properties['分析タイプ'] || '未設定'}\n`;
                  text += `- 使用テーブル: ${result.properties['使用テーブル'] || '未設定'}\n`;
                  text += `- 結果データの列名と列の説明: ${result.properties['結果データの列名と列の説明'] || '未設定'}\n`;
                  text += `- 利用シーン: ${result.properties['利用シーン'] || '未設定'}\n`;
                  text += `- 結果の解釈方法: ${result.properties['結果の解釈方法'] || '未設定'}\n`;
                  text += `- データの粒度と期間: ${result.properties['データの粒度と期間'] || '未設定'}\n`;
                  text += `- 前提条件と制約: ${result.properties['前提条件と制約'] || '未設定'}\n`;
                  text += `- 追加分析の提案: ${result.properties['追加分析の提案'] || '未設定'}\n`;
                  text += `- ビジネスインパクト: ${result.properties['ビジネスインパクト'] || '未設定'}\n`;
                  text += `- 技術的な注意点: ${result.properties['技術的な注意点'] || '未設定'}\n`;
                  break;

                default:
                  // 既存のプロパティを表示（念のため残しておく）
                  if (Object.keys(result.properties).length > 0) {
                    text += `### プロパティ\n`;
                    Object.entries(result.properties).forEach(
                      ([key, value]) => {
                        if (value) {
                          text += `- ${key}: ${value}\n`;
                        }
                      },
                    );
                  }
              }

              // サマリーとコンテンツの追加
              if (result.summary) {
                text += `\n### サマリー\n${result.summary}\n`;
              }
              if (result.content) {
                text += `\n### コンテンツ\n${result.content}\n`;
              }

              return text;
            })
            .join('\n\n');

          return sessionText + resultsText;
        })
        .join('\n\n');
    }

    return context;
  };

  // プロンプトをコピー
  const handleCopy = async () => {
    const context = generateContext();
    const finalPrompt = customPrompt.replace('{{CONTEXT}}', context);
    await navigator.clipboard.writeText(finalPrompt);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // プロンプトの文字数を計算
  const calculatePromptLength = () => {
    const context = generateContext();
    return customPrompt.replace('{{CONTEXT}}', context).length;
  };

  // プロンプトを保存
  const handleSave = async () => {
    try {
      const builtPrompt = generateContext();
      const finalPrompt = customPrompt.replace('{{CONTEXT}}', builtPrompt);
      await GptContextService.savePrompt(
        finalPrompt,
        selectedSessions.map((session) => session.id),
        'default',
        selectedModules.map((module) => module.id),
      );
      showNotification('プロンプトを保存しました', 'success');

      // 保存済みプロンプト一覧を更新
      const prompts = await GptContextService.getPrompts();
      setSavedPrompts(prompts);
    } catch (error) {
      console.error('プロンプトの保存に失敗しました:', error);
      showNotification('プロンプトの保存に失敗しました', 'error');
    }
  };

  // 保存済みプロンプトを読み込む
  const handleLoadPrompt = (prompt: BuiltPrompt) => {
    setCustomPrompt(prompt.prompt_text);
    onSessionsSelect(prompt.session_ids);
    showNotification('プロンプトを読み込みました', 'success');
  };

  // 文字数に応じた色を返す関数
  const getCharacterCountColor = (count: number) => {
    if (count >= 300000) return theme.palette.error.dark;
    if (count >= 200000) return theme.palette.error.main;
    if (count >= 100000) return theme.palette.warning.dark;
    if (count >= 50000) return theme.palette.warning.main;
    return theme.palette.success.main;
  };

  // 文字数に応じたプログレスバーの値を計算（0-100）
  const calculateProgress = (count: number) => {
    return Math.min((count / 300000) * 100, 100);
  };

  // プロンプトをリセット
  const handleReset = () => {
    setCustomPrompt(PROMPT_TEMPLATES[0].template);
    onSessionsSelect([]);
    showNotification('プロンプトをリセットしました', 'success');
  };

  // プロンプトを削除
  const handleDelete = async (promptId: string) => {
    try {
      await GptContextService.deletePrompt(promptId);
      const prompts = await GptContextService.getPrompts();
      setSavedPrompts(prompts);
      showNotification('プロンプトを削除しました', 'success');
    } catch (error) {
      console.error('プロンプトの削除に失敗しました:', error);
      showNotification('プロンプトの削除に失敗しました', 'error');
    }
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2} alignItems="flex-start">
        {/* 左側のセクション */}
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AutoAwesomeMotionIcon
              sx={{
                mr: 1,
                color: theme.palette.primary.main,
                fontSize: 28,
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              プロンプトビルダー
            </Typography>
            <Tooltip title="プロンプトをリセット">
              <IconButton onClick={handleReset} size="small" sx={{ ml: 1 }}>
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <TextField
            multiline
            fullWidth
            minRows={12}
            maxRows={20}
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
            sx={{ mb: 2 }}
            placeholder="プロンプトを入力してください。{{CONTEXT}}は自動的にコンテキストに置き換えられます。"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mb: 2,
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 0.5,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: getCharacterCountColor(calculatePromptLength()),
                    fontWeight: 'medium',
                  }}
                >
                  推定文字数: {calculatePromptLength().toLocaleString()}文字
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  最大: 300,000文字
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={calculateProgress(calculatePromptLength())}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  bgcolor: theme.palette.grey[200],
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 4,
                    bgcolor: getCharacterCountColor(calculatePromptLength()),
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSave}
                sx={{ color: 'white' }}
              >
                保存
              </Button>
              <Button
                variant="contained"
                startIcon={<ContentCopyIcon />}
                onClick={handleCopy}
                disabled={copied}
                sx={{ color: 'white' }}
              >
                {copied ? 'コピーしました！' : 'プロンプトをコピー'}
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* 右側のセクション */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <HistoryIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              履歴
            </Typography>
          </Box>
          <Paper
            elevation={0}
            sx={{
              maxHeight: '500px',
              overflow: 'auto',
              p: 2,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              bgcolor: theme.palette.background.default,
            }}
          >
            <Grid container spacing={1}>
              {savedPrompts.map((prompt) => (
                <Grid item xs={12} key={prompt.prompt_id}>
                  <Card
                    sx={{
                      cursor: 'pointer',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        bgcolor: theme.palette.action.hover,
                        transform: 'translateY(-2px)',
                        boxShadow: theme.shadows[4],
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        py: 1,
                        '&:last-child': { pb: 1 },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Box
                        onClick={() => handleLoadPrompt(prompt)}
                        sx={{ flex: 1, cursor: 'pointer' }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {prompt.prompt_text}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {new Date(prompt.created_at).toLocaleString()}
                        </Typography>
                      </Box>
                      <Tooltip title="プロンプトを削除">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(prompt.prompt_id);
                          }}
                          sx={{ ml: 1 }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* 通知 */}
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={notification.severity}
          onClose={handleCloseNotification}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </Paper>
  );
};
