import {
  AddCircleOutline as AddIcon,
  Close as CloseIcon,
  DeleteOutline as DeleteIcon,
  List as ListIcon,
  Save as SaveIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';
import {
  IPlpData,
  IPlpListRequest,
  IPlpSaveRequest,
  plpService,
} from '../../services/plp-service';
import { useClient } from '../../store/client.store';
import {
  IHtmlBlock,
  IPlp,
  IPlpBuildResponse,
  IShelfBlock,
} from '../../types/plp-builder.type';
import AddBlockModal from './components/AddBlockModal';
import HtmlBuilderModal from './components/HtmlBuilderModal';
import ItemSearchModal from './components/ItemSearchModal/index';
import PersonalizedItemShelfModal from './components/PersonalizedItemShelfModal/index';
import PlpBlockList from './components/PlpBlockList';
import PlpPreview from './components/PlpPreview';
import ShelfSearchModal from './components/ShelfSearchModal/index';
import UserPersonalizedShelfModal from './components/UserPersonalizedShelfModal/index';

/**
 * PLPビルダーページ
 */
const PlpBuilderPage: React.FC = () => {
  // クライアント情報を取得
  const { client: clientState } = useClient();
  const location = useLocation();
  const navigate = useNavigate();

  // ステート
  const [plp, setPlp] = useState<IPlp>({
    plpId: `plp_${Date.now()}`, // 新しいPLP IDを生成
    plpBlocks: [], // 空のブロックリストからスタート
  });
  // 元のPLPデータを保持するステート（比較用）
  const [originalPlp, setOriginalPlp] = useState<IPlp | null>(null);
  const [buildResponse, setBuildResponse] = useState<IPlpBuildResponse | null>(
    null,
  );
  const [isAddBlockModalOpen, setIsAddBlockModalOpen] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  // シェルフ編集用の状態
  const [isShelfSearchModalOpen, setIsShelfSearchModalOpen] = useState(false);
  const [isItemSearchModalOpen, setIsItemSearchModalOpen] = useState(false);
  // HTML編集用の状態
  const [isHtmlBuilderModalOpen, setIsHtmlBuilderModalOpen] = useState(false);
  const [editingBlockIndex, setEditingBlockIndex] = useState<number | null>(
    null,
  );

  // 保存用の状態
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [saveTitle, setSaveTitle] = useState('');
  const [saveDescription, setSaveDescription] = useState('');
  const [saveTags, setSaveTags] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [savedPlp, setSavedPlp] = useState<IPlpData | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success',
  );

  // PLPリスト用の状態
  const [isPlpListDrawerOpen, setIsPlpListDrawerOpen] = useState(false);
  const [plpList, setPlpList] = useState<IPlpData[]>([]);
  const [isLoadingPlpList, setIsLoadingPlpList] = useState(false);
  const [plpListTotal, setPlpListTotal] = useState(0);
  const [plpSearchKeyword, setPlpSearchKeyword] = useState('');
  const [selectedPlpForDeletion, setSelectedPlpForDeletion] = useState<
    string | null
  >(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  // ユーザーパーソナライズシェルフ編集用のステート
  const [
    isUserPersonalizedShelfModalOpen,
    setIsUserPersonalizedShelfModalOpen,
  ] = useState(false);

  // パーソナライズ商品シェルフモーダルの状態
  const [
    isPersonalizedItemShelfModalOpen,
    setIsPersonalizedItemShelfModalOpen,
  ] = useState(false);
  const [
    personalizedItemShelfBlockToEdit,
    setPersonalizedItemShelfBlockToEdit,
  ] = useState<IShelfBlock | null>(null);

  // URLパラメータからplpIdを取得する関数
  const getPlpIdFromUrl = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('plpId');
  }, [location.search]);

  // URLのplpIdパラメータを更新する関数
  const updateUrlPlpId = useCallback(
    (plpId: string | null) => {
      const searchParams = new URLSearchParams(location.search);

      if (plpId) {
        searchParams.set('plpId', plpId);
      } else {
        searchParams.delete('plpId');
      }

      // URLを更新（置換）
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [location.pathname, location.search, navigate],
  );

  // PLPが空かどうかをチェック
  const isPlpEmpty = useCallback(() => {
    return plp.plpBlocks.length === 0;
  }, [plp.plpBlocks]);

  // PLPが変更されたかどうかをチェック
  const hasPlpChanged = useCallback(() => {
    // 元のPLPがない場合は新規作成と見なす
    if (!originalPlp) {
      // 新規作成の場合、ブロックが追加されていれば変更あり
      return !isPlpEmpty();
    }

    // ブロック数が異なる場合は変更あり
    if (originalPlp.plpBlocks.length !== plp.plpBlocks.length) {
      return true;
    }

    // 各ブロックの内容を比較（簡易的な比較）
    for (let i = 0; i < plp.plpBlocks.length; i++) {
      // JSONに変換して比較（ディープな比較）
      if (
        JSON.stringify(originalPlp.plpBlocks[i]) !==
        JSON.stringify(plp.plpBlocks[i])
      ) {
        return true;
      }
    }

    return false;
  }, [originalPlp, plp, isPlpEmpty]);

  // 保存ボタンが有効かどうか
  const isSaveButtonEnabled = useCallback(() => {
    // クライアントが選択されていない場合は無効
    if (!clientState.selectedClient) {
      return false;
    }

    // プレビュー更新中は無効
    if (isPreviewLoading) {
      return false;
    }

    // PLPが空の場合は無効
    if (isPlpEmpty()) {
      return false;
    }

    // 変更がない場合は無効
    if (!hasPlpChanged()) {
      return false;
    }

    return true;
  }, [clientState.selectedClient, isPreviewLoading, isPlpEmpty, hasPlpChanged]);

  // プレビューの更新
  const handleUpdatePreview = useCallback(async () => {
    try {
      // selectedClientからクライアントIDを取得
      if (!clientState.selectedClient?.clientId) {
        console.error('クライアントが選択されていません');
        return;
      }

      setIsPreviewLoading(true);

      const response = await plpService.buildPlp(
        plp,
        clientState.selectedClient.clientId,
      );
      setBuildResponse(response);
    } catch (error) {
      console.error('プレビュー更新エラー:', error);
    } finally {
      setIsPreviewLoading(false);
    }
  }, [clientState.selectedClient, plp]);

  // 保存後の処理
  const handleAfterSave = useCallback(
    (savedPlpData: IPlpData) => {
      setSavedPlp(savedPlpData);
      // 保存したPLPを元のPLPとして設定（差分計算用）
      setOriginalPlp(savedPlpData.plp_data);
      // URLのplpIdパラメータを更新
      updateUrlPlpId(savedPlpData.plp_id);
    },
    [updateUrlPlpId],
  );

  // クライアントが変更されたときにプレビュー更新
  useEffect(() => {
    if (clientState.selectedClient) {
      handleUpdatePreview();
    }
  }, [clientState.selectedClient, handleUpdatePreview]);

  // コンポーネント初回マウント時のみのプレビュー更新
  useEffect(() => {
    if (clientState.selectedClient) {
      handleUpdatePreview();
    }
  }, []);

  // 追加モーダルを開く
  const handleOpenAddBlockModal = () => {
    setIsAddBlockModalOpen(true);
  };

  // 追加モーダルを閉じる
  const handleCloseAddBlockModal = () => {
    setIsAddBlockModalOpen(false);
    // モーダルが閉じたときにプレビュー更新
    if (clientState.selectedClient) {
      handleUpdatePreview();
    }
  };

  // ブロックの追加
  const handleAddBlock = (block: any) => {
    setPlp((prev) => ({
      ...prev,
      plpBlocks: [...prev.plpBlocks, block],
    }));
    // プレビュー更新はhandleCloseAddBlockModalで実行
  };

  // ブロックの削除
  const handleDeleteBlock = (index: number) => {
    setPlp((prev) => ({
      ...prev,
      plpBlocks: prev.plpBlocks.filter((_, i) => i !== index),
    }));
    // ブロック削除時にプレビュー更新
    if (clientState.selectedClient) {
      setTimeout(() => handleUpdatePreview(), 0);
    }
  };

  // ブロックの更新
  const handleUpdateBlock = (index: number, updatedBlock: any) => {
    setPlp((prev) => {
      const newBlocks = [...prev.plpBlocks];
      newBlocks[index] = updatedBlock;
      return { ...prev, plpBlocks: newBlocks };
    });
    // ブロック更新時にプレビュー更新
    if (clientState.selectedClient) {
      setTimeout(() => handleUpdatePreview(), 0);
    }
  };

  // ブロックの順序変更
  const handleMoveBlock = (fromIndex: number, toIndex: number) => {
    setPlp((prev) => {
      const newBlocks = [...prev.plpBlocks];
      const [removed] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, removed);
      return { ...prev, plpBlocks: newBlocks };
    });
    // ブロック並び替え時にプレビュー更新
    if (clientState.selectedClient) {
      setTimeout(() => handleUpdatePreview(), 0);
    }
  };

  // ブロック編集ハンドラー
  const handleEditBlock = (index: number) => {
    setEditingBlockIndex(index);

    // 編集対象のブロックを取得
    const block = plp.plpBlocks[index];

    // ブロックタイプに応じて適切なモーダルを開く
    if (block.blockType === 'shelf') {
      const shelfBlock = block as IShelfBlock;
      if (
        shelfBlock.shelfConfig.shelfOrders &&
        shelfBlock.shelfConfig.shelfOrders.length > 0
      ) {
        const shelfType = shelfBlock.shelfConfig.shelfOrders[0].shelfType;

        if (shelfType === ShelfTypeEnum.manual) {
          // 商品検索（手動選択）シェルフの場合
          setIsItemSearchModalOpen(true);
        } else if (shelfType === ShelfTypeEnum.search) {
          // AI検索シェルフの場合
          setIsShelfSearchModalOpen(true);
        } else if (
          shelfBlock.blockName === 'ユーザーパーソナライズシェルフ' ||
          (shelfBlock.shelfConfig.shelfOrders &&
            shelfBlock.shelfConfig.shelfOrders.length > 1 &&
            !shelfBlock.shelfConfig.seedAids)
        ) {
          // ユーザーパーソナライズシェルフの場合
          setIsUserPersonalizedShelfModalOpen(true);
        } else if (
          shelfBlock.blockName === 'パーソナライズ商品シェルフ' ||
          (shelfBlock.shelfConfig.seedAids &&
            shelfBlock.shelfConfig.seedAids.length > 0)
        ) {
          // パーソナライズ商品シェルフの場合
          setPersonalizedItemShelfBlockToEdit(shelfBlock);
          setIsPersonalizedItemShelfModalOpen(true);
        }
      }
    } else if (block.blockType === 'html') {
      // HTMLブロックの場合
      setIsHtmlBuilderModalOpen(true);
    }
  };

  // シェルフ検索モーダルを閉じる
  const handleCloseShelfSearchModal = () => {
    setIsShelfSearchModalOpen(false);
    setEditingBlockIndex(null);
  };

  // 商品検索モーダルを閉じる
  const handleCloseItemSearchModal = () => {
    setIsItemSearchModalOpen(false);
    setEditingBlockIndex(null);
  };

  // HTMLビルダーモーダルを閉じる
  const handleCloseHtmlBuilderModal = () => {
    setIsHtmlBuilderModalOpen(false);
    setEditingBlockIndex(null);
  };

  // 編集したシェルフブロックを更新
  const handleUpdateShelfBlock = (updatedBlock: IShelfBlock) => {
    if (editingBlockIndex !== null) {
      // blockNameが未設定の場合はここでデフォルト名を設定
      if (!updatedBlock.blockName) {
        const shelfOrder = updatedBlock.shelfConfig.shelfOrders[0];
        const shelfType = shelfOrder.shelfType;

        if (shelfType === ShelfTypeEnum.manual) {
          updatedBlock.blockName = '商品手動選択シェルフ';
        } else if (shelfType === ShelfTypeEnum.search) {
          updatedBlock.blockName = 'AI検索シェルフ';
        } else {
          updatedBlock.blockName = `シェルフ (${shelfType})`;
        }
      }

      handleUpdateBlock(editingBlockIndex, updatedBlock);
    }
  };

  // 編集したHTMLブロックを更新
  const handleUpdateHtmlBlock = (updatedBlock: IHtmlBlock) => {
    if (editingBlockIndex !== null) {
      // blockNameが未設定の場合はここでデフォルト名を設定
      if (!updatedBlock.blockName) {
        updatedBlock.blockName = 'HTMLブロック';
      }

      handleUpdateBlock(editingBlockIndex, updatedBlock);
    }
  };

  // 保存モーダルを開く
  const handleOpenSaveModal = () => {
    // 既存のPLPからタイトルなどを設定
    if (savedPlp) {
      setSaveTitle(savedPlp.title);
      setSaveDescription(savedPlp.description || '');
      setSaveTags(savedPlp.tags?.join(', ') || '');
    } else {
      setSaveTitle(`PLPデータ_${new Date().toLocaleDateString('ja-JP')}`);
      setSaveDescription('');
      setSaveTags('');
    }
    setIsSaveModalOpen(true);
  };

  // 保存モーダルを閉じる
  const handleCloseSaveModal = () => {
    setIsSaveModalOpen(false);
  };

  // PLPデータを保存
  const handleSavePlp = async () => {
    try {
      if (!saveTitle) {
        setSnackbarMessage('タイトルを入力してください');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      setIsSaving(true);

      // タグ文字列を配列に変換
      const tagsArray = saveTags
        ? saveTags
            .split(',')
            .map((tag) => tag.trim())
            .filter(Boolean)
        : [];

      const saveRequest: IPlpSaveRequest = {
        title: saveTitle,
        description: saveDescription || undefined,
        tags: tagsArray.length > 0 ? tagsArray : undefined,
        plp_data: plp,
      };

      // 新規保存または更新
      let result: IPlpData;
      if (savedPlp) {
        result = await plpService.updatePlp({
          ...saveRequest,
          plp_id: savedPlp.plp_id,
        });
        setSnackbarMessage('PLPデータを更新しました');
      } else {
        result = await plpService.savePlp(saveRequest);
        setSnackbarMessage('PLPデータを保存しました');
      }

      // 保存後の処理
      handleAfterSave(result);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleCloseSaveModal();
    } catch (error) {
      console.error('保存エラー:', error);
      setSnackbarMessage('保存に失敗しました');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  };

  // PLPデータをロード
  const handleLoadPlp = useCallback(
    async (plpId: string) => {
      try {
        const loadedPlp = await plpService.getPlp(plpId);
        setPlp(loadedPlp.plp_data);
        setSavedPlp(loadedPlp);
        // 元のPLPとして設定（差分計算用）
        setOriginalPlp(loadedPlp.plp_data);

        // URLのplpIdパラメータを更新
        updateUrlPlpId(plpId);

        // プレビュー更新
        if (clientState.selectedClient) {
          handleUpdatePreview();
        }

        setSnackbarMessage('PLPデータをロードしました');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('ロードエラー:', error);
        setSnackbarMessage('PLPデータのロードに失敗しました');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    },
    [clientState.selectedClient, handleUpdatePreview, updateUrlPlpId],
  );

  // スナックバーを閉じる
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // PLPリストを取得
  const fetchPlpList = useCallback(async () => {
    try {
      if (!clientState.selectedClient) {
        return;
      }

      setIsLoadingPlpList(true);

      const request: IPlpListRequest = {
        limit: 50,
        offset: 0,
        order_by: 'updated_at',
        order_direction: 'desc',
      };

      if (plpSearchKeyword) {
        // タイトルまたはタグで検索
        const tagKeywords = plpSearchKeyword
          .split(',')
          .map((k) => k.trim())
          .filter(Boolean);
        if (tagKeywords.length > 0) {
          request.tags = tagKeywords;
        }
      }

      const response = await plpService.listPlp(request);
      setPlpList(response.items);
      setPlpListTotal(response.total);
    } catch (error) {
      console.error('PLPリスト取得エラー:', error);
      setSnackbarMessage('PLPリストの取得に失敗しました');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoadingPlpList(false);
    }
  }, [clientState.selectedClient, plpSearchKeyword]);

  // クライアントが変更されたときにPLPリストを取得
  useEffect(() => {
    if (isPlpListDrawerOpen && clientState.selectedClient) {
      fetchPlpList();
    }
  }, [clientState.selectedClient, isPlpListDrawerOpen, fetchPlpList]);

  // PLPリストドロワーを開く
  const handleOpenPlpListDrawer = () => {
    setIsPlpListDrawerOpen(true);
    if (clientState.selectedClient) {
      fetchPlpList();
    }
  };

  // PLPリストドロワーを閉じる
  const handleClosePlpListDrawer = () => {
    setIsPlpListDrawerOpen(false);
  };

  // 選択したPLPを読み込む
  const handleSelectPlp = async (plpId: string) => {
    try {
      await handleLoadPlp(plpId);
      handleClosePlpListDrawer();
    } catch (error) {
      console.error('PLPロードエラー:', error);
    }
  };

  // PLPの削除確認ダイアログを開く
  const handleOpenDeleteConfirm = (plpId: string, event: React.MouseEvent) => {
    event.stopPropagation(); // カードのクリックイベントが発火するのを防止
    setSelectedPlpForDeletion(plpId);
    setIsDeleteConfirmOpen(true);
  };

  // PLPの削除確認ダイアログを閉じる
  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
    setSelectedPlpForDeletion(null);
  };

  // PLPを削除
  const handleDeletePlp = async () => {
    try {
      if (!selectedPlpForDeletion) return;

      await plpService.deletePlp(selectedPlpForDeletion);

      // 削除したPLPが現在表示中のPLPだった場合、新規作成状態にリセット
      if (savedPlp && savedPlp.plp_id === selectedPlpForDeletion) {
        setPlp({
          plpId: `plp_${Date.now()}`,
          plpBlocks: [],
        });
        setSavedPlp(null);
        setOriginalPlp(null);
        // URLからplpIdパラメータを削除
        updateUrlPlpId(null);
      }

      // リストを再取得
      fetchPlpList();

      setSnackbarMessage('PLPデータを削除しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error('削除エラー:', error);
      setSnackbarMessage('削除に失敗しました');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // 検索キーワードの変更処理
  const handleSearchKeywordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPlpSearchKeyword(event.target.value);
  };

  // 検索実行
  const handleSearch = () => {
    fetchPlpList();
  };

  // 日付フォーマット
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  // URLパラメータからplpIdを読み取り、PLPデータをロード
  useEffect(() => {
    const plpIdFromUrl = getPlpIdFromUrl();

    // plpIdがURLに存在し、現在のPLPと異なる場合のみロード
    if (plpIdFromUrl && (!savedPlp || savedPlp.plp_id !== plpIdFromUrl)) {
      handleLoadPlp(plpIdFromUrl);
    }
  }, [getPlpIdFromUrl, handleLoadPlp, savedPlp]);

  // ユーザーパーソナライズシェルフモーダルを閉じる
  const handleCloseUserPersonalizedShelfModal = () => {
    setIsUserPersonalizedShelfModalOpen(false);
    setEditingBlockIndex(null);
  };

  // ユーザーパーソナライズシェルフを追加
  const handleAddUserPersonalizedShelf = (block: any) => {
    handleAddBlock(block);
    setIsUserPersonalizedShelfModalOpen(false);
  };

  // パーソナライズ商品シェルフモーダルを閉じる
  const handleClosePersonalizedItemShelfModal = () => {
    setIsPersonalizedItemShelfModalOpen(false);
    setEditingBlockIndex(null);
  };

  // パーソナライズ商品シェルフを追加
  const handleAddPersonalizedItemShelf = (block: any) => {
    handleAddBlock(block);
    setIsPersonalizedItemShelfModalOpen(false);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          PLPビルダー
          {clientState.selectedClient
            ? ` - ${clientState.selectedClient.name}`
            : ''}
          {savedPlp && ` - ${savedPlp.title}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenPlpListDrawer}
            startIcon={<ListIcon />}
            disabled={!clientState.selectedClient}
          >
            PLP一覧
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenSaveModal}
            disabled={!isSaveButtonEnabled()}
            startIcon={<SaveIcon />}
          >
            保存
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdatePreview}
            disabled={isPreviewLoading || !clientState.selectedClient}
            startIcon={
              isPreviewLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : undefined
            }
          >
            {isPreviewLoading ? 'プレビュー更新中...' : 'プレビュー更新'}
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, height: 'calc(100vh - 200px)' }}>
        {/* 左側: ブロックリスト */}
        <Paper
          elevation={3}
          sx={{ width: '30%', p: 2, overflowY: 'auto', height: '100%' }}
        >
          <Box
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">ブロック一覧</Typography>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOpenAddBlockModal}
            >
              追加
            </Button>
          </Box>
          <Divider sx={{ mb: 2 }} />

          <PlpBlockList
            blocks={plp.plpBlocks}
            onDelete={handleDeleteBlock}
            onUpdate={handleUpdateBlock}
            onMove={handleMoveBlock}
            onEdit={handleEditBlock}
          />
        </Paper>

        {/* 右側: プレビュー */}
        <Paper
          elevation={3}
          sx={{ width: '70%', p: 2, overflowY: 'auto', height: '100%' }}
        >
          <PlpPreview
            buildResponse={buildResponse}
            isLoading={isPreviewLoading}
          />
        </Paper>
      </Box>

      {/* PLPリストドロワー */}
      <Drawer
        anchor="right"
        open={isPlpListDrawerOpen}
        onClose={handleClosePlpListDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '450px' },
            p: 2,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">保存済みPLP一覧</Typography>
          <IconButton onClick={handleClosePlpListDrawer} aria-label="閉じる">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            placeholder="タイトルまたはタグで検索"
            value={plpSearchKeyword}
            onChange={handleSearchKeywordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch} edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {plpListTotal}件のPLPが見つかりました
          </Typography>
        </Box>

        <Box sx={{ overflowY: 'auto', flex: 1 }}>
          {isLoadingPlpList ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : plpList.length === 0 ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary">
                保存されたPLPがありません
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {plpList.map((plp) => (
                <Card
                  key={plp.plp_id}
                  sx={{
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    '&:hover': { boxShadow: 3 },
                    bgcolor:
                      savedPlp?.plp_id === plp.plp_id
                        ? 'rgba(25, 118, 210, 0.08)'
                        : 'inherit',
                  }}
                  onClick={() => handleSelectPlp(plp.plp_id)}
                >
                  <CardContent sx={{ pb: 1 }}>
                    <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                      {plp.title}
                    </Typography>

                    {plp.description && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        {plp.description}
                      </Typography>
                    )}

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1,
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        更新: {formatDate(plp.updated_at)}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        作成: {formatDate(plp.created_at)}
                      </Typography>
                    </Box>

                    {plp.tags && plp.tags.length > 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                          mt: 1,
                        }}
                      >
                        {plp.tags.map((tag) => (
                          <Chip key={tag} label={tag} size="small" />
                        ))}
                      </Box>
                    )}
                  </CardContent>

                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Tooltip title="削除">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => handleOpenDeleteConfirm(plp.plp_id, e)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </Card>
              ))}
            </Box>
          )}
        </Box>
      </Drawer>

      {/* PLP削除確認ダイアログ */}
      <Dialog open={isDeleteConfirmOpen} onClose={handleCloseDeleteConfirm}>
        <DialogTitle>PLPデータの削除</DialogTitle>
        <DialogContent>
          <Typography>
            このPLPデータを削除してもよろしいですか？
            この操作は元に戻すことができません。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirm}>キャンセル</Button>
          <Button onClick={handleDeletePlp} color="error" variant="contained">
            削除する
          </Button>
        </DialogActions>
      </Dialog>

      {/* 保存モーダル */}
      <Dialog
        open={isSaveModalOpen}
        onClose={handleCloseSaveModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>PLPデータを保存</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="タイトル"
            fullWidth
            variant="outlined"
            value={saveTitle}
            onChange={(e) => setSaveTitle(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="説明"
            fullWidth
            variant="outlined"
            value={saveDescription}
            onChange={(e) => setSaveDescription(e.target.value)}
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="タグ（カンマ区切り）"
            fullWidth
            variant="outlined"
            value={saveTags}
            onChange={(e) => setSaveTags(e.target.value)}
            placeholder="タグ1, タグ2, タグ3"
            helperText="複数のタグはカンマで区切ってください"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaveModal}>キャンセル</Button>
          <Button
            onClick={handleSavePlp}
            variant="contained"
            disabled={isSaving}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            {isSaving ? '保存中...' : savedPlp ? '更新' : '保存'}
          </Button>
        </DialogActions>
      </Dialog>

      <AddBlockModal
        open={isAddBlockModalOpen}
        onClose={handleCloseAddBlockModal}
        onAdd={handleAddBlock}
      />

      {/* シェルフ検索（編集）モーダル */}
      {editingBlockIndex !== null && (
        <>
          {isShelfSearchModalOpen && (
            <ShelfSearchModal
              open={isShelfSearchModalOpen}
              onClose={handleCloseShelfSearchModal}
              onAdd={handleAddBlock}
              isEditMode={true}
              existingBlock={plp.plpBlocks[editingBlockIndex] as IShelfBlock}
              onUpdate={handleUpdateShelfBlock}
            />
          )}

          {isItemSearchModalOpen && (
            <ItemSearchModal
              open={isItemSearchModalOpen}
              onClose={handleCloseItemSearchModal}
              onAdd={handleAddBlock}
              isEditMode={true}
              existingBlock={plp.plpBlocks[editingBlockIndex] as IShelfBlock}
              onUpdate={handleUpdateShelfBlock}
            />
          )}

          {isHtmlBuilderModalOpen && (
            <HtmlBuilderModal
              open={isHtmlBuilderModalOpen}
              onClose={handleCloseHtmlBuilderModal}
              onAdd={handleAddBlock}
              isEditMode={true}
              existingBlock={plp.plpBlocks[editingBlockIndex] as IHtmlBlock}
              onUpdate={handleUpdateHtmlBlock}
            />
          )}

          {/* ユーザーパーソナライズシェルフモーダル（編集時） */}
          {isUserPersonalizedShelfModalOpen && (
            <UserPersonalizedShelfModal
              open={isUserPersonalizedShelfModalOpen}
              onClose={handleCloseUserPersonalizedShelfModal}
              onAdd={handleAddUserPersonalizedShelf}
              isEditMode={true}
              existingBlock={plp.plpBlocks[editingBlockIndex] as IShelfBlock}
              onUpdate={handleUpdateShelfBlock}
            />
          )}

          {/* パーソナライズ商品シェルフモーダル（編集時） */}
          {isPersonalizedItemShelfModalOpen && (
            <PersonalizedItemShelfModal
              open={isPersonalizedItemShelfModalOpen}
              onClose={handleClosePersonalizedItemShelfModal}
              onAdd={handleAddPersonalizedItemShelf}
              isEditMode={true}
              existingBlock={personalizedItemShelfBlockToEdit}
              onUpdate={handleUpdateShelfBlock}
            />
          )}
        </>
      )}

      {/* 操作結果通知 */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PlpBuilderPage;
