import {
  Computer as ComputerIcon,
  ExpandMore as ExpandMoreIcon,
  Smartphone as SmartphoneIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { IPlpBuildResponse } from '../../../types/plp-builder.type';
import JsonEditor from './JsonEditor';

interface PlpPreviewProps {
  buildResponse: IPlpBuildResponse | null;
  isLoading: boolean;
}

/**
 * PLPプレビューコンポーネント
 */
const PlpPreview: React.FC<PlpPreviewProps> = ({
  buildResponse,
  isLoading,
}) => {
  // プレビューモードの状態（0: プレビュー, 1: HTML, 2: JSON）
  const [previewMode, setPreviewMode] = useState(0);
  // デバイス表示モードの状態（'sp': スマホ表示, 'pc': PC表示）
  const [deviceMode, setDeviceMode] = useState<'sp' | 'pc'>('sp');
  // アコーディオンの展開状態
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    false,
  );

  // プレビューモード切り替えハンドラー
  const handlePreviewModeChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setPreviewMode(newValue);
  };

  // デバイスモード切り替えハンドラー
  const handleDeviceModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: 'sp' | 'pc' | null,
  ) => {
    if (newMode !== null) {
      setDeviceMode(newMode);
    }
  };

  // アコーディオンの展開状態変更ハンドラー
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  // デバイスモードに応じたiframeのスタイル
  const getIframeStyle = () => {
    if (deviceMode === 'sp') {
      return {
        width: '355px',
        height: '667px',
        border: 'none',
        margin: '0',
        display: 'block',
        background: 'white',
        position: 'relative' as const,
        zIndex: 2,
      };
    }
    return {
      width: '100%',
      height: '600px',
      border: 'none',
    };
  };

  // SPモード用のiPhoneフレームスタイル
  const getPhoneFrameStyle = () => {
    return {
      width: '375px',
      height: '720px',
      background: '#111',
      borderRadius: '36px',
      padding: '26px 10px',
      boxShadow: '0 0 0 2px #ccc, 0 10px 20px rgba(0, 0, 0, 0.2)',
      position: 'relative' as const,
      margin: '10px auto',
      boxSizing: 'border-box' as const,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
    };
  };

  // SPモード用のノッチとボタン
  const renderPhoneDetails = () => {
    return (
      <>
        <div
          style={{
            position: 'absolute',
            width: '150px',
            height: '25px',
            background: '#111',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            borderBottomLeftRadius: '14px',
            borderBottomRightRadius: '14px',
            zIndex: 5,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '40px',
            height: '5px',
            background: '#444',
            bottom: '10px',
            borderRadius: '3px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 5,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '3px',
            height: '30px',
            background: '#444',
            top: '90px',
            right: '-1px',
            borderTopLeftRadius: '2px',
            borderBottomLeftRadius: '2px',
            zIndex: 5,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: '3px',
            height: '60px',
            background: '#444',
            top: '150px',
            left: '-1px',
            borderTopRightRadius: '2px',
            borderBottomRightRadius: '2px',
            zIndex: 5,
          }}
        />
      </>
    );
  };

  // JSONタブの内容をレンダリング
  const renderJsonContent = () => {
    if (!buildResponse) return null;

    // レスポンスに含まれる可能性のあるキー
    const sections = [
      { key: 'dto', label: 'DTO データ', value: buildResponse.dto },
      { key: 'plp', label: 'PLP データ', value: buildResponse.plp },
      {
        key: 'renderContext',
        label: 'レンダリングコンテキスト',
        value: (buildResponse as any).renderContext,
      },
      { key: 'html', label: 'HTML', value: buildResponse.html, isHtml: true },
    ];

    return (
      <Box sx={{ maxHeight: '600px', overflow: 'auto' }}>
        {sections.map((section) => {
          // そのセクションのデータが存在しない場合はスキップ
          if (section.value === undefined) return null;

          return (
            <Accordion
              key={section.key}
              expanded={expandedAccordion === section.key}
              onChange={handleAccordionChange(section.key)}
              sx={{
                mb: 1,
                '&:before': { display: 'none' }, // アコーディオン間の区切り線を削除
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  bgcolor: 'action.selected',
                  borderRadius:
                    expandedAccordion === section.key ? '4px 4px 0 0' : 4,
                }}
              >
                <Typography fontWeight="bold">{section.label}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {section.isHtml ? (
                  <Box sx={{ p: 2, maxHeight: '500px', overflow: 'auto' }}>
                    <pre
                      style={{
                        margin: 0,
                        whiteSpace: 'pre-wrap',
                        fontSize: '12px',
                      }}
                    >
                      {section.value}
                    </pre>
                  </Box>
                ) : (
                  <Box sx={{ height: '400px', border: '1px solid #eee' }}>
                    <JsonEditor
                      json={JSON.stringify(section.value, null, 2)}
                      onChange={() => {}} // 読み取り専用
                      readOnly={true}
                    />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!buildResponse) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Typography variant="body1" color="text.secondary">
          「プレビュー更新」ボタンをクリックしてHTMLを生成してください
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Tabs value={previewMode} onChange={handlePreviewModeChange}>
          <Tab label="プレビュー" />
          <Tab label="HTML" />
          <Tab label="JSON" />
        </Tabs>

        {previewMode === 0 && (
          <ToggleButtonGroup
            value={deviceMode}
            exclusive
            onChange={handleDeviceModeChange}
            aria-label="デバイス表示モード"
            size="small"
          >
            <ToggleButton value="sp" aria-label="スマホ表示">
              <SmartphoneIcon />
            </ToggleButton>
            <ToggleButton value="pc" aria-label="PC表示">
              <ComputerIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>

      {previewMode === 0 && (
        <Paper
          elevation={0}
          sx={{
            p: deviceMode === 'sp' ? 0 : 2,
            border: '1px solid #ddd',
            borderRadius: 1,
            overflow: 'auto',
            textAlign: 'center',
          }}
        >
          {deviceMode === 'sp' ? (
            <div style={getPhoneFrameStyle()}>
              {renderPhoneDetails()}
              <iframe
                title="PLPプレビュー"
                srcDoc={buildResponse.html}
                style={getIframeStyle()}
              />
            </div>
          ) : (
            <iframe
              title="PLPプレビュー"
              srcDoc={buildResponse.html}
              style={getIframeStyle()}
            />
          )}
        </Paper>
      )}

      {previewMode === 1 && (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            border: '1px solid #ddd',
            borderRadius: 1,
            maxHeight: '600px',
            overflow: 'auto',
          }}
        >
          <pre style={{ margin: 0, whiteSpace: 'pre-wrap', fontSize: '12px' }}>
            {buildResponse.html}
          </pre>
        </Paper>
      )}

      {previewMode === 2 && (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            border: '1px solid #ddd',
            borderRadius: 1,
          }}
        >
          {renderJsonContent()}
        </Paper>
      )}
    </Box>
  );
};

export default PlpPreview;
