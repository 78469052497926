import {
  ArrowDownward as ArrowDownIcon,
  ArrowUpward as ArrowUpIcon,
  Close as CloseIcon,
  ViewInAr as ContainerIcon,
  GridView as GridIcon,
  Home as HomeIcon,
  Image as ImageIcon,
  NavigateNext as NavigateNextIcon,
  Save as SaveIcon,
  TextFields as TextFieldsIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  IconButton,
  Link,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { IHtmlBlock } from '../../../../types/plp-builder.type';
import ElementEditor from './ElementEditor';
import ElementTree from './ElementTree';
import HtmlPreview from './HtmlPreview';

interface HtmlBuilderModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (block: IHtmlBlock) => void;
  isEditMode?: boolean;
  existingBlock?: IHtmlBlock;
  onUpdate?: (block: IHtmlBlock) => void;
}

// 要素の型定義
export interface ElementType {
  id: number;
  type: 'container' | 'text' | 'img' | 'grid';
  parentId: number | null;
  children: ElementType[];
  style: Record<string, string>;
  content?: string;
  attributes?: Record<string, string>;
  gridConfig?: {
    columns: number;
    gap: number;
  };
}

/**
 * HTMLビルダーモーダルコンポーネント
 */
const HtmlBuilderModal: React.FC<HtmlBuilderModalProps> = ({
  open,
  onClose,
  onAdd,
  isEditMode = false,
  existingBlock,
  onUpdate,
}) => {
  // モードの状態
  const [mode, setMode] = useState<'normal' | 'advanced'>('normal');

  // 要素の状態
  const [elements, setElements] = useState<ElementType[]>([]);
  const [selectedElementId, setSelectedElementId] = useState<number | null>(
    null,
  );
  const [nextId, setNextId] = useState(1);

  // タブの状態
  const [activeTab, setActiveTab] = useState(0);

  // 生成されたCSS
  const [generatedCss, setGeneratedCss] = useState('');

  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // 選択された要素のパス（階層）を取得
  const getElementPath = useCallback(
    (
      elements: ElementType[],
      id: number | null,
      path: ElementType[] = [],
    ): ElementType[] => {
      if (id === null) return [];

      for (const element of elements) {
        if (element.id === id) {
          return [...path, element];
        }

        if (element.children && element.children.length > 0) {
          const foundPath = getElementPath(element.children, id, [
            ...path,
            element,
          ]);
          if (foundPath.length > 0) {
            return foundPath;
          }
        }
      }

      return [];
    },
    [],
  );

  // 現在選択されている要素のパス
  const selectedElementPath = getElementPath(elements, selectedElementId);

  // 既存ブロックのロード
  useEffect(() => {
    if (isEditMode && existingBlock && open) {
      try {
        // 既存のHTMLコンテンツからデータを復元
        if (existingBlock.htmlConfig.htmlContent) {
          // 構造化情報が保存されていれば使用する
          if (
            existingBlock.htmlConfig.builderElements &&
            existingBlock.htmlConfig.builderElements.length > 0
          ) {
            setElements(existingBlock.htmlConfig.builderElements);
            // 次のIDも設定（未設定の場合は最大IDから計算）
            if (existingBlock.htmlConfig.nextElementId) {
              setNextId(existingBlock.htmlConfig.nextElementId);
            } else {
              // 要素から最大IDを取得して設定
              const findMaxId = (elements: ElementType[]): number => {
                let maxId = 0;
                elements.forEach((element) => {
                  maxId = Math.max(maxId, element.id);
                  if (element.children && element.children.length > 0) {
                    maxId = Math.max(maxId, findMaxId(element.children));
                  }
                });
                return maxId;
              };

              const maxId = findMaxId(existingBlock.htmlConfig.builderElements);
              setNextId(maxId + 1);
            }
          } else {
            console.log('構造化情報がないため、HTMLから再構築が必要です');
            // TODO: 将来的な対応として、HTML文字列から要素ツリーへの変換処理を実装
          }

          setGeneratedCss(existingBlock.htmlConfig.cssContent || '');
        }
      } catch (error) {
        console.error('既存HTMLブロックのロードエラー:', error);
        setErrorMessage('HTMLブロックの読み込み中にエラーが発生しました。');
      }
    }
  }, [isEditMode, existingBlock, open]);

  // モーダルを閉じる
  const handleClose = () => {
    // 状態をリセット
    setElements([]);
    setSelectedElementId(null);
    setNextId(1);
    setMode('normal');
    setActiveTab(0);
    setGeneratedCss('');
    setErrorMessage(null);

    // モーダルを閉じる
    onClose();
  };

  // 要素タイプの定義
  const elementTypes = [
    { type: 'container', name: 'コンテナ', icon: <ContainerIcon /> },
    { type: 'text', name: 'テキスト', icon: <TextFieldsIcon /> },
    { type: 'img', name: '画像', icon: <ImageIcon /> },
    { type: 'grid', name: 'グリッド', icon: <GridIcon /> },
  ];

  // 新しい要素を作成する
  const createNewElement = useCallback(
    (
      type: 'container' | 'text' | 'img' | 'grid',
      parentId: number | null = null,
    ): ElementType => {
      const baseStyles = {
        padding: '10px',
        margin: '5px',
        minHeight: '30px',
        minWidth: '50px',
      };

      const element: ElementType = {
        id: nextId,
        type,
        parentId,
        children: [],
        style: { ...baseStyles },
      };

      // 要素タイプ別の初期設定
      switch (type) {
        case 'container':
          element.style = {
            ...baseStyles,
            border: '1px solid #ccc',
            backgroundColor: '#f9f9f9',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            borderRadius: '0px',
          };
          break;
        case 'text':
          element.content = 'テキストを入力してください';
          element.style = {
            ...baseStyles,
            color: '#333',
            fontSize: '16px',
            textAlign: 'left',
            fontWeight: 'normal',
            lineHeight: '1.5',
          };
          break;
        case 'img':
          element.attributes = {
            src: 'https://via.placeholder.com/300x200',
            alt: '画像の説明',
          };
          element.style = {
            ...baseStyles,
            maxWidth: '100%',
            borderRadius: '0px',
            display: 'block',
            margin: '0 auto',
          };
          break;
        case 'grid':
          element.style = {
            ...baseStyles,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '10px',
            border: '1px dashed #999',
            borderRadius: '0px',
            textAlign: 'left',
          };
          element.gridConfig = {
            columns: 2,
            gap: 10,
          };
          break;
      }

      return element;
    },
    [nextId],
  );

  // 要素を追加する
  const addElement = useCallback(
    (
      type: 'container' | 'text' | 'img' | 'grid',
      parentId: number | null = null,
    ) => {
      const newElement = createNewElement(type, parentId);

      // IDをインクリメント
      setNextId((prev) => prev + 1);

      if (!parentId) {
        // ルートレベルに追加
        setElements((prev) => [...prev, newElement]);
        setSelectedElementId(newElement.id);
        return;
      }

      // 子要素として追加（再帰的に処理）
      const addChildToParent = (elements: ElementType[]): ElementType[] => {
        return elements.map((element) => {
          if (element.id === parentId) {
            return {
              ...element,
              children: [...element.children, newElement],
            };
          }
          if (element.children && element.children.length > 0) {
            return {
              ...element,
              children: addChildToParent(element.children),
            };
          }
          return element;
        });
      };

      setElements((prev) => addChildToParent(prev));
      setSelectedElementId(newElement.id);
    },
    [createNewElement],
  );

  // 要素を検索する（再帰的に）
  const findElementById = useCallback(
    (elements: ElementType[], id: number): ElementType | null => {
      for (const element of elements) {
        if (element.id === id) {
          return element;
        }
        if (element.children && element.children.length > 0) {
          const found = findElementById(element.children, id);
          if (found) return found;
        }
      }
      return null;
    },
    [],
  );

  // 要素を更新する
  const updateElement = useCallback(
    (id: number, updates: Partial<ElementType>) => {
      const updateElementById = (elements: ElementType[]): ElementType[] => {
        return elements.map((element) => {
          if (element.id === id) {
            return { ...element, ...updates };
          }
          if (element.children && element.children.length > 0) {
            return {
              ...element,
              children: updateElementById(element.children),
            };
          }
          return element;
        });
      };

      setElements(updateElementById);
    },
    [],
  );

  // 要素を削除する
  const removeElement = useCallback(
    (id: number) => {
      const removeElementById = (elements: ElementType[]): ElementType[] => {
        return elements.filter((element) => {
          if (element.id === id) return false;
          if (element.children && element.children.length > 0) {
            element.children = removeElementById(element.children);
          }
          return true;
        });
      };

      setElements(removeElementById);
      if (selectedElementId === id) {
        setSelectedElementId(null);
      }
    },
    [selectedElementId],
  );

  // 親要素に移動する（階層を上がる）
  const moveToParent = useCallback(() => {
    if (!selectedElementId) return;

    const element = findElementById(elements, selectedElementId);
    if (!element || element.parentId === null) return;

    setSelectedElementId(element.parentId);
  }, [elements, findElementById, selectedElementId]);

  // 要素を上に移動する（同階層内での順序変更）
  const moveElementUp = useCallback(() => {
    if (!selectedElementId) return;

    const moveElementUpInArray = (
      elements: ElementType[],
      parentId: number | null,
    ): ElementType[] => {
      // ルートレベルか子要素レベルの配列を取得
      const targetElements =
        parentId === null
          ? elements
          : findElementById(elements, parentId)?.children || [];

      // 選択された要素のインデックスを探す
      const index = targetElements.findIndex((e) => e.id === selectedElementId);
      if (index <= 0) return elements; // 既に最上部にある場合は何もしない

      // 要素の入れ替え
      if (parentId === null) {
        // ルートレベルの場合
        const newElements = [...elements];
        [newElements[index], newElements[index - 1]] = [
          newElements[index - 1],
          newElements[index],
        ];
        return newElements;
      } else {
        // 子要素の場合
        return elements.map((element) => {
          if (element.id === parentId) {
            const newChildren = [...element.children];
            [newChildren[index], newChildren[index - 1]] = [
              newChildren[index - 1],
              newChildren[index],
            ];
            return { ...element, children: newChildren };
          }
          if (element.children && element.children.length > 0) {
            return {
              ...element,
              children: moveElementUpInArray(element.children, parentId),
            };
          }
          return element;
        });
      }
    };

    // 選択要素の親IDを取得
    const selectedElement = findElementById(elements, selectedElementId);
    if (!selectedElement) return;

    setElements((prev) => moveElementUpInArray(prev, selectedElement.parentId));
  }, [elements, findElementById, selectedElementId]);

  // 要素を下に移動する（同階層内での順序変更）
  const moveElementDown = useCallback(() => {
    if (!selectedElementId) return;

    const moveElementDownInArray = (
      elements: ElementType[],
      parentId: number | null,
    ): ElementType[] => {
      // ルートレベルか子要素レベルの配列を取得
      const targetElements =
        parentId === null
          ? elements
          : findElementById(elements, parentId)?.children || [];

      // 選択された要素のインデックスを探す
      const index = targetElements.findIndex((e) => e.id === selectedElementId);
      if (index === -1 || index >= targetElements.length - 1) return elements; // 既に最下部にある場合は何もしない

      // 要素の入れ替え
      if (parentId === null) {
        // ルートレベルの場合
        const newElements = [...elements];
        [newElements[index], newElements[index + 1]] = [
          newElements[index + 1],
          newElements[index],
        ];
        return newElements;
      } else {
        // 子要素の場合
        return elements.map((element) => {
          if (element.id === parentId) {
            const newChildren = [...element.children];
            [newChildren[index], newChildren[index + 1]] = [
              newChildren[index + 1],
              newChildren[index],
            ];
            return { ...element, children: newChildren };
          }
          if (element.children && element.children.length > 0) {
            return {
              ...element,
              children: moveElementDownInArray(element.children, parentId),
            };
          }
          return element;
        });
      }
    };

    // 選択要素の親IDを取得
    const selectedElement = findElementById(elements, selectedElementId);
    if (!selectedElement) return;

    setElements((prev) =>
      moveElementDownInArray(prev, selectedElement.parentId),
    );
  }, [elements, findElementById, selectedElementId]);

  // スタイルを更新する
  const updateStyle = useCallback(
    (id: number, property: string, value: string) => {
      const element = findElementById(elements, id);
      if (!element) return;

      updateElement(id, {
        style: {
          ...element.style,
          [property]: value,
        },
      });
    },
    [elements, findElementById, updateElement],
  );

  // グリッド設定を更新する
  const updateGridConfig = useCallback(
    (id: number, config: Partial<{ columns: number; gap: number }>) => {
      const element = findElementById(elements, id);
      if (!element || element.type !== 'grid') return;

      const updatedConfig = {
        ...element.gridConfig,
        ...config,
      };

      // グリッドのスタイルも更新
      const updatedStyle = {
        ...element.style,
        gridTemplateColumns: `repeat(${updatedConfig.columns}, 1fr)`,
        gap: `${updatedConfig.gap}px`,
      };

      updateElement(id, {
        gridConfig: updatedConfig,
        style: updatedStyle,
      });
    },
    [elements, findElementById, updateElement],
  );

  // HTMLの生成
  const generateHTML = useCallback((elements: ElementType[]): string => {
    if (!elements || elements.length === 0) return '';

    return elements
      .map((element) => {
        const { type, content, style, attributes, children } = element;

        // スタイル属性の生成
        const styleAttr =
          Object.keys(style).length > 0
            ? ` style="${Object.entries(style)
                .map(([k, v]) => `${k}:${v}`)
                .join(';')}"`
            : '';

        // その他の属性の生成
        const attrs = attributes
          ? Object.entries(attributes)
              .map(([k, v]) => ` ${k}="${v}"`)
              .join('')
          : '';

        let html = '';

        switch (type) {
          case 'container':
            html += `<div${styleAttr}>\n`;
            if (children && children.length > 0) {
              html += generateHTML(children);
            }
            html += `</div>\n`;
            break;

          case 'text':
            html += `<div${styleAttr}>${content || ''}</div>\n`;
            break;

          case 'img':
            html += `<img${attrs}${styleAttr} />\n`;
            break;

          case 'grid':
            html += `<div${styleAttr}>\n`;
            if (children && children.length > 0) {
              html += generateHTML(children);
            }
            html += `</div>\n`;
            break;
        }

        return html;
      })
      .join('');
  }, []);

  // HTMLブロックの追加または更新
  const handleSave = useCallback(() => {
    try {
      // バリデーション
      if (elements.length === 0) {
        setErrorMessage(
          '要素が追加されていません。少なくとも1つの要素を追加してください。',
        );
        return;
      }

      // HTMLコンテンツの生成
      const htmlContent = generateHTML(elements);

      // HTMLブロックの作成（構造化情報も保存）
      const htmlBlock: IHtmlBlock = {
        blockType: 'html',
        htmlConfig: {
          htmlContent,
          cssContent: generatedCss,
          jsContent: '', // 今回はJS未対応
          isVisible: true,
          // HTML Builder用の構造化情報を保存
          builderElements: elements,
          nextElementId: nextId,
        },
      };

      // 編集モードの場合は更新、そうでなければ追加
      if (isEditMode && onUpdate) {
        onUpdate(htmlBlock);
      } else {
        onAdd(htmlBlock);
      }

      handleClose();
    } catch (err) {
      console.error('HTMLブロック保存エラー:', err);
      setErrorMessage('HTMLブロックの保存中にエラーが発生しました。');
    }
  }, [
    elements,
    generateHTML,
    generatedCss,
    nextId,
    isEditMode,
    onAdd,
    onUpdate,
    handleClose,
  ]);

  // タブの切り替え
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // モードの切り替え
  const handleModeChange = (newMode: 'normal' | 'advanced') => {
    setMode(newMode);
  };

  // 選択した要素がコンテナまたはグリッドかどうか
  const isSelectedElementContainer = useCallback(() => {
    if (!selectedElementId) return false;

    const element = findElementById(elements, selectedElementId);
    return element && (element.type === 'container' || element.type === 'grid');
  }, [selectedElementId, elements, findElementById]);

  // 要素タイプの名前を取得
  const getElementTypeName = useCallback((type: string): string => {
    switch (type) {
      case 'container':
        return 'コンテナ';
      case 'text':
        return 'テキスト';
      case 'img':
        return '画像';
      case 'grid':
        return 'グリッド';
      default:
        return '要素';
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="html-builder-modal-title"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          height: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: '4px 4px 0 4px',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {/* ヘッダー */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Typography variant="h6" id="html-builder-modal-title">
            {isEditMode ? 'HTMLブロックを編集' : 'HTMLブロックを作成'}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant={mode === 'normal' ? 'contained' : 'outlined'}
                size="small"
                onClick={() => handleModeChange('normal')}
                sx={{ mr: 1 }}
              >
                通常モード
              </Button>
              <Button
                variant={mode === 'advanced' ? 'contained' : 'outlined'}
                size="small"
                onClick={() => handleModeChange('advanced')}
              >
                上級モード
              </Button>
            </Box>

            <Button
              variant="contained"
              color="success"
              onClick={handleSave}
              startIcon={<SaveIcon />}
            >
              保存
            </Button>

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {/* エラーメッセージ */}
        {errorMessage && (
          <Alert
            severity="error"
            onClose={() => setErrorMessage(null)}
            sx={{ m: 2 }}
          >
            {errorMessage}
          </Alert>
        )}

        {/* メインコンテンツ */}
        <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
          {/* 左側: 編集パネル */}
          <Box
            sx={{
              width: '35%',
              borderRight: '1px solid',
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* タブ */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="要素" />
                <Tab label="スタイル" />
                <Tab label="HTML" />
              </Tabs>
            </Box>

            {/* タブの中身 */}
            <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
              {/* 要素タブ */}
              {activeTab === 0 && (
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    要素を追加
                  </Typography>

                  <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
                    {elementTypes.map((type) => (
                      <Button
                        key={type.type}
                        variant="outlined"
                        size="small"
                        startIcon={type.icon}
                        onClick={() => addElement(type.type as any)}
                      >
                        {type.name}
                      </Button>
                    ))}
                  </Box>

                  {selectedElementId && isSelectedElementContainer() && (
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        子要素を追加
                      </Typography>

                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {elementTypes.map((type) => (
                          <Button
                            key={type.type}
                            variant="outlined"
                            size="small"
                            startIcon={type.icon}
                            onClick={() =>
                              addElement(type.type as any, selectedElementId)
                            }
                          >
                            {type.name}
                          </Button>
                        ))}
                      </Box>
                    </Box>
                  )}

                  {/* 階層パスとナビゲーション */}
                  {selectedElementId && (
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        現在の位置
                      </Typography>
                      <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="element-hierarchy"
                        sx={{ mb: 1 }}
                      >
                        <Link
                          component="button"
                          underline="hover"
                          color="inherit"
                          onClick={() => setSelectedElementId(null)}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                          ルート
                        </Link>
                        {selectedElementPath.map((element, index) => {
                          // 最後の要素（現在選択中の要素）以外は、クリックできるリンクにする
                          const isLast =
                            index === selectedElementPath.length - 1;
                          return isLast ? (
                            <Typography
                              key={element.id}
                              color="text.primary"
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              {getElementTypeName(element.type)} ({element.id})
                            </Typography>
                          ) : (
                            <Link
                              key={element.id}
                              component="button"
                              underline="hover"
                              color="inherit"
                              onClick={() => setSelectedElementId(element.id)}
                            >
                              {getElementTypeName(element.type)} ({element.id})
                            </Link>
                          );
                        })}
                      </Breadcrumbs>

                      {/* 要素操作ボタン */}
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<ArrowUpIcon />}
                          onClick={moveElementUp}
                          disabled={!selectedElementId}
                        >
                          上へ
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<ArrowDownIcon />}
                          onClick={moveElementDown}
                          disabled={!selectedElementId}
                        >
                          下へ
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={moveToParent}
                          disabled={
                            !selectedElementId ||
                            selectedElementPath.length <= 1
                          }
                        >
                          親へ
                        </Button>
                      </Box>
                    </Box>
                  )}

                  <Divider sx={{ my: 2 }} />

                  <Typography variant="subtitle1" gutterBottom>
                    要素の階層
                  </Typography>

                  <ElementTree
                    elements={elements}
                    selectedElementId={selectedElementId}
                    onSelectElement={setSelectedElementId}
                    onRemoveElement={removeElement}
                  />
                </Box>
              )}

              {/* スタイルタブ */}
              {activeTab === 1 && selectedElementId && (
                <ElementEditor
                  element={findElementById(elements, selectedElementId)}
                  onUpdateElement={updateElement}
                  onUpdateStyle={updateStyle}
                  onUpdateGridConfig={updateGridConfig}
                  mode={mode}
                />
              )}

              {/* HTMLタブ */}
              {activeTab === 2 && (
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    生成されたHTML
                  </Typography>

                  <Box
                    sx={{
                      p: 2,
                      bgcolor: '#f5f5f5',
                      borderRadius: 1,
                      maxHeight: '400px',
                      overflow: 'auto',
                      fontFamily: 'monospace',
                      fontSize: '0.875rem',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {generateHTML(elements) ||
                      '<div>HTMLコンテンツがありません</div>'}
                  </Box>

                  <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                    追加のCSS
                  </Typography>
                  <TextField
                    multiline
                    rows={6}
                    fullWidth
                    value={generatedCss}
                    onChange={(e) => setGeneratedCss(e.target.value)}
                    placeholder="CSS コードを入力してください"
                    sx={{ fontFamily: 'monospace' }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          {/* 右側: プレビュー */}
          <Box sx={{ flex: 1, p: 2, overflow: 'auto' }}>
            <Typography variant="subtitle1" gutterBottom>
              プレビュー
            </Typography>

            <Box
              sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                p: 2,
                minHeight: '80vh',
                bgcolor: 'white',
              }}
            >
              <HtmlPreview
                elements={elements}
                selectedElementId={selectedElementId}
                onSelectElement={setSelectedElementId}
                css={generatedCss}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default HtmlBuilderModal;
