import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { ShelfStatus } from '../../types/stg-search-shelf-query.type';
import { SimilarShelvesState } from './ShelfBuilder';

const SimilarShelfItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const WhereCondition = styled(Typography)`
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 12px !important;
  white-space: pre-wrap;
  word-break: break-all;
`;

const MAX_SIMILAR_SHELVES = 3;

interface SimilarShelvesProps {
  similarShelves: SimilarShelvesState;
}

const SimilarShelves: React.FC<SimilarShelvesProps> = ({ similarShelves }) => {
  if (!similarShelves || similarShelves.length === 0) return null;

  const shelves = similarShelves
    .filter((similar) => similar.status !== ShelfStatus.DELETED)
    .slice(0, MAX_SIMILAR_SHELVES);

  const getStatusChip = (status: string) => {
    switch (status) {
      case ShelfStatus.DRAFT:
        return (
          <Chip
            label="下書き"
            size="small"
            color="default"
            icon={<EditNoteIcon />}
            sx={{ height: '20px' }}
          />
        );
      case ShelfStatus.APPROVED:
        return (
          <Chip
            label="承認済み"
            size="small"
            color="success"
            icon={<CheckCircleIcon />}
            sx={{ height: '20px' }}
          />
        );
      case ShelfStatus.DEPLOYED:
        return (
          <Chip
            label="デプロイ済み"
            size="small"
            color="info"
            icon={<RocketLaunchIcon />}
            sx={{ height: '20px' }}
          />
        );
      case ShelfStatus.DELETED:
        return (
          <Chip
            label="削除済み"
            size="small"
            color="error"
            icon={<DeleteIcon />}
            sx={{ height: '20px' }}
          />
        );
      default:
        return <Box>{status}</Box>;
    }
  };

  return (
    <Box>
      {shelves.map((similar, index) => (
        <SimilarShelfItem key={index}>
          <HeaderRow>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {similar.text}
              <Box sx={{ display: 'inline-block', ml: 1 }}>
                {getStatusChip(similar.status)}
              </Box>
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                類似度: {(similar.cossim_score * 100).toFixed(2)}%
              </Typography>
            </Box>
          </HeaderRow>
          {similar.where_condition && (
            <WhereCondition variant="body2">
              {similar.where_condition}
            </WhereCondition>
          )}
        </SimilarShelfItem>
      ))}
    </Box>
  );
};

export default SimilarShelves;
