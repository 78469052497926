import { Box, Typography } from '@mui/material';
import React from 'react';
import { ElementType } from './index';

interface HtmlPreviewProps {
  elements: ElementType[];
  selectedElementId: number | null;
  onSelectElement: (id: number) => void;
  css?: string;
}

/**
 * HTMLプレビューコンポーネント
 */
const HtmlPreview: React.FC<HtmlPreviewProps> = ({
  elements,
  selectedElementId,
  onSelectElement,
  css = '',
}) => {
  // 要素をレンダリングする（再帰的に）
  const renderElement = (element: ElementType) => {
    const { id, type, style, content, attributes, children } = element;
    const isSelected = selectedElementId === id;

    // 基本的なプロパティ
    const commonProps = {
      key: id,
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
        onSelectElement(id);
      },
      style: {
        ...style,
        outline: isSelected ? '2px solid #3498db' : 'none',
        cursor: 'pointer',
        position: 'relative' as const,
      },
      'data-id': id,
      'data-type': type,
    };

    // 要素タイプラベルを表示（選択時）
    const elementTypeLabel = isSelected ? (
      <div
        style={{
          position: 'absolute',
          top: '-20px',
          left: '0',
          background: '#3498db',
          color: 'white',
          padding: '2px 6px',
          fontSize: '10px',
          borderRadius: '2px',
          zIndex: 100,
          pointerEvents: 'none',
        }}
      >
        {getElementTypeLabel(type)} ID: {id}
      </div>
    ) : null;

    switch (type) {
      case 'container':
        return (
          <div {...commonProps}>
            {elementTypeLabel}
            {children && children.length > 0 ? (
              children.map(renderElement)
            ) : (
              <div
                className="empty-container"
                style={{
                  color: '#aaa',
                  textAlign: 'center',
                  padding: '10px',
                  border: '1px dashed #ccc',
                  borderRadius: '4px',
                  margin: '5px',
                }}
              >
                コンテナ（子要素を追加してください）
              </div>
            )}
          </div>
        );

      case 'text':
        return (
          <div {...commonProps}>
            {elementTypeLabel}
            {content || 'テキストがありません'}
          </div>
        );

      case 'img':
        if (!attributes) return null;
        return (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            {elementTypeLabel}
            <img
              {...commonProps}
              src={attributes.src || 'https://via.placeholder.com/300x200'}
              alt={attributes.alt || ''}
            />
          </div>
        );

      case 'grid':
        return (
          <div {...commonProps}>
            {elementTypeLabel}
            {children && children.length > 0
              ? children.map(renderElement)
              : Array.from({ length: element.gridConfig?.columns || 2 }).map(
                  (_, i) => (
                    <div
                      key={`grid-cell-${i}`}
                      className="grid-placeholder"
                      style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        textAlign: 'center',
                        color: '#aaa',
                        border: '1px dashed #ccc',
                        borderRadius: '4px',
                      }}
                    >
                      グリッドセル {i + 1}
                    </div>
                  ),
                )}
          </div>
        );

      default:
        return null;
    }
  };

  // 要素タイプのラベルを取得
  const getElementTypeLabel = (type: string): string => {
    switch (type) {
      case 'container':
        return 'コンテナ';
      case 'text':
        return 'テキスト';
      case 'img':
        return '画像';
      case 'grid':
        return 'グリッド';
      default:
        return '要素';
    }
  };

  // 要素が空の場合
  if (elements.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          color: 'text.secondary',
          border: '2px dashed #ccc',
          borderRadius: '8px',
          padding: '20px',
        }}
      >
        <Typography variant="body1" align="center">
          要素を追加してプレビューを表示
        </Typography>
        <Typography variant="body2" align="center" sx={{ mt: 1 }}>
          左側の「要素を追加」ボタンから要素を追加してください
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        padding: '20px',
        backgroundColor: '#fafafa',
        borderRadius: '4px',
      }}
      onClick={() => onSelectElement(-1)} // 背景クリックで選択解除
    >
      {/* CSS スタイルシート */}
      {css && <style dangerouslySetInnerHTML={{ __html: css }} />}

      {/* 要素のレンダリング */}
      {elements.map(renderElement)}
    </Box>
  );
};

export default HtmlPreview;
