import AnalyticsIcon from '@mui/icons-material/Analytics';
import ChatIcon from '@mui/icons-material/Chat';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import StorageIcon from '@mui/icons-material/Storage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { GptContextService } from '../services/gptContext.service';
import { SearchType, SLACK_CHANNELS } from '../services/types';

const INITIAL_DISPLAY_COUNT = 12;
const LOAD_MORE_COUNT = 30;
const NOTION_BASE_URL = 'https://www.notion.so/insightx-tech/';

interface FreeWordSearchProps {
  onSaveSession: (sessionName: string, selectedResults: any[]) => void;
}

export const FreeWordSearch: React.FC<FreeWordSearchProps> = ({
  onSaveSession,
}) => {
  const theme = useTheme();
  const [searchType, setSearchType] = useState<SearchType>(
    SearchType.DISCUSSION,
  );
  const [keyword, setKeyword] = useState('');
  const [allResults, setAllResults] = useState<any[]>([]);
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [displayCount, setDisplayCount] = useState(INITIAL_DISPLAY_COUNT);
  const [selectedResults, setSelectedResults] = useState<any[]>([]);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [sessionName, setSessionName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [isChannelSelectOpen, setIsChannelSelectOpen] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  // 検索タイプが変更されたときに全件取得
  useEffect(() => {
    const fetchAllResults = async () => {
      setIsLoading(true);
      try {
        const results = await GptContextService.getAllByType(searchType);
        // Slackの場合、選択されたチャンネルでフィルタリング
        const filteredByChannel =
          searchType === SearchType.SLACK && selectedChannels.length > 0
            ? results.filter((result) => {
                const channelNames = selectedChannels
                  .map(
                    (channelId) =>
                      SLACK_CHANNELS.find((c) => c.id === channelId)?.name,
                  )
                  .filter(Boolean);
                return channelNames.some(
                  (name) => result.properties?.['チャンネル'] === name,
                );
              })
            : results;
        setAllResults(filteredByChannel);
        setFilteredResults(filteredByChannel);
        setDisplayCount(INITIAL_DISPLAY_COUNT);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllResults();
  }, [searchType, selectedChannels]);

  // キーワード検索の最適化
  const debouncedSearch = useCallback(
    debounce((searchKeyword: string, results: any[]) => {
      if (!searchKeyword) {
        setFilteredResults(results);
        return;
      }

      // 半角または全角スペースで分割してキーワードの配列を作成
      const keywords = searchKeyword
        .split(/[ 　]+/)
        .filter((k) => k.length > 0)
        .map((k) => k.toLowerCase());

      const searchInProperties = (result: any) => {
        const searchTarget = JSON.stringify(result).toLowerCase();
        // すべてのキーワードにマッチすればtrue（AND検索）
        return keywords.every((keyword) => searchTarget.includes(keyword));
      };

      const filtered = results.filter(searchInProperties);
      setFilteredResults(filtered);
      setDisplayCount(INITIAL_DISPLAY_COUNT);
    }, 500),
    [],
  );

  // キーワードが変更されたときの処理
  useEffect(() => {
    debouncedSearch(keyword, allResults);
    return () => {
      debouncedSearch.cancel();
    };
  }, [keyword, allResults, debouncedSearch]);

  // 結果の選択を処理
  const handleResultSelect = (result: any) => {
    setSelectedResults((prev) => {
      if (prev.some((r) => r.id === result.id)) {
        return prev.filter((r) => r.id !== result.id);
      }
      return [...prev, result];
    });
  };

  // セッションの保存を処理
  const handleSave = () => {
    if (sessionName && selectedResults.length > 0) {
      onSaveSession(sessionName, selectedResults);
      setSaveDialogOpen(false);
      setSessionName('');
      setSelectedResults([]);
    }
  };

  // キーワードを含む部分を見つける
  const findKeywordPosition = (
    text: string,
    searchKeywords: string[],
  ): number => {
    let firstPos = text.length;
    searchKeywords.forEach((k) => {
      const pos = text.toLowerCase().indexOf(k);
      if (pos !== -1 && pos < firstPos) {
        firstPos = pos;
      }
    });
    return firstPos === text.length ? -1 : firstPos;
  };

  // テキストを適切な位置で切り取る
  const truncateAroundKeyword = (text: string, maxLength: number): string => {
    if (!keyword) return text.slice(0, maxLength);

    const keywords = keyword
      .split(/[ 　]+/)
      .filter((k) => k.length > 0)
      .map((k) => k.toLowerCase());

    const pos = findKeywordPosition(text, keywords);
    if (pos === -1) return text.slice(0, maxLength);

    const halfLength = Math.floor(maxLength / 2);
    if (text.length <= maxLength) return text;

    if (pos < halfLength) {
      return text.slice(0, maxLength - 2) + '...';
    } else if (pos > text.length - halfLength) {
      return '...' + text.slice(text.length - maxLength + 3);
    } else {
      return '...' + text.slice(pos - halfLength, pos + halfLength) + '...';
    }
  };

  // テキスト内のキーワードをハイライト
  const highlightText = (text: string) => {
    if (!keyword || !text) return text;

    // 半角または全角スペースで分割してキーワードの配列を作成
    const keywords = keyword
      .split(/[ 　]+/)
      .filter((k) => k.length > 0)
      .map((k) => k.toLowerCase());

    // すべてのキーワードを|で結合して、一つの正規表現にする
    const pattern = keywords
      .map((k) => k.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|');
    const regex = new RegExp(`(${pattern})`, 'gi');

    // テキストを分割し、マッチした部分をハイライト
    const parts = text.split(regex);
    return parts.map((part, i) => {
      if (keywords.some((k) => part.toLowerCase() === k)) {
        return (
          <span key={`${i}-${part}`} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  // さらに読み込むボタンの表示制御
  const hasMore = filteredResults.length > displayCount;

  // さらに読み込むボタンのクリックハンドラ
  const handleLoadMore = () => {
    setDisplayCount((prev) => prev + LOAD_MORE_COUNT);
  };

  // コピー機能の追加
  const handleCopyContent = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopySnackbarOpen(true);
    } catch (error) {
      console.error('コピーに失敗しました:', error);
    }
  };

  // 検索タイプを日本語に変換する関数
  const formatSearchType = (type: string) => {
    const typeMap: { [key: string]: string } = {
      discussion: 'ディスカッション',
      analysis: '分析',
      meeting: 'ミーティング',
      slack: 'Slack',
      sql_template: 'SQLテンプレート',
      analysis_table: '分析テーブル',
      analysis_template: '分析テンプレート',
    };
    return typeMap[type] || type;
  };

  // 選択された結果をコピー
  const handleCopySelectedResults = async () => {
    setIsCopying(true);
    try {
      // テキストコンテンツを取得
      const { texts } = await GptContextService.getTexts(
        searchType,
        selectedResults.map((result) => result.id),
      );

      const lines: string[] = [
        `フリーワード検索結果`,
        `検索タイプ: ${formatSearchType(searchType)}`,
        `検索キーワード: ${keyword}`,
        `検索日時: ${new Date().toLocaleString('ja-JP')}`,
      ];

      // Slackの場合はチャンネル情報を追加
      if (searchType === SearchType.SLACK && selectedChannels.length > 0) {
        const channelNames = selectedChannels
          .map(
            (channelId) => SLACK_CHANNELS.find((c) => c.id === channelId)?.name,
          )
          .filter(Boolean);
        lines.push(
          `検索対象チャンネル: ${channelNames.map((ch) => `#${ch}`).join(', ')}`,
        );
      }

      lines.push('', '選択された結果:');
      selectedResults.forEach((result, index) => {
        lines.push('', `[結果 ${index + 1}]`);
        lines.push(`タイトル: ${result.title || '無題'}`);
        lines.push(`タイプ: ${formatSearchType(result.type)}`);
        lines.push(`作成日: ${result.created_at || '不明'}`);

        // プロパティの追加
        if (result.properties) {
          Object.entries(result.properties).forEach(([key, value]) => {
            if (value) {
              lines.push(`${key}: ${value}`);
            }
          });
        }

        // サマリーの追加
        if (result.summary) {
          lines.push('', 'サマリー:', result.summary);
        }

        // コンテンツの追加（取得したテキストを使用）
        const content = texts[result.id];
        if (content) {
          const truncatedContent =
            content.length > 20000 ? content.slice(0, 19997) + '...' : content;
          lines.push('', 'コンテンツ:', truncatedContent);
        }
      });

      await handleCopyContent(lines.join('\n'));
    } catch (error) {
      console.error('テキストの取得に失敗しました:', error);
    } finally {
      setIsCopying(false);
    }
  };

  // 個別の結果をコピー
  const handleCopyResult = async (result: any) => {
    setIsCopying(true);
    try {
      // テキストコンテンツを取得
      const { texts } = await GptContextService.getTexts(searchType, [
        result.id,
      ]);

      const lines: string[] = [
        `タイトル: ${result.title || '無題'}`,
        `タイプ: ${formatSearchType(result.type)}`,
        `作成日: ${result.created_at || '不明'}`,
      ];

      // プロパティの追加
      if (result.properties) {
        Object.entries(result.properties).forEach(([key, value]) => {
          if (value) {
            lines.push(`${key}: ${value}`);
          }
        });
      }

      // サマリーの追加
      if (result.summary) {
        lines.push('', 'サマリー:', result.summary);
      }

      // コンテンツの追加（取得したテキストを使用）
      const content = texts[result.id];
      if (content) {
        const truncatedContent =
          content.length > 20000 ? content.slice(0, 19997) + '...' : content;
        lines.push('', 'コンテンツ:', truncatedContent);
      }

      await handleCopyContent(lines.join('\n'));
    } catch (error) {
      console.error('テキストの取得に失敗しました:', error);
    } finally {
      setIsCopying(false);
    }
  };

  // タイプごとの設定を追加
  const typeConfig: {
    [key: string]: { color: string; icon: React.ReactNode };
  } = {
    discussion: { color: '#42a5f5', icon: <ChatIcon /> },
    analysis: { color: '#66bb6a', icon: <AnalyticsIcon /> },
    meeting: { color: '#ffa726', icon: <GroupsIcon /> },
    slack: { color: '#ec407a', icon: <ChatIcon /> },
    sql_template: { color: '#7e57c2', icon: <CodeIcon /> },
    analysis_table: { color: '#26a69a', icon: <StorageIcon /> },
    analysis_template: { color: '#5c6bc0', icon: <QueryStatsIcon /> },
  };

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        '&:hover': {
          boxShadow: theme.shadows[2],
        },
        transition: 'box-shadow 0.2s ease-in-out',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ManageSearchIcon
            sx={{ color: theme.palette.primary.main, fontSize: '1.2rem' }}
          />
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700, fontSize: '1rem' }}
          >
            フリーワード検索でコンテキスト生成
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 2 }}>
          <InfoOutlinedIcon
            sx={{
              color: theme.palette.text.secondary,
              fontSize: '1rem',
              mt: 0.2,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              fontSize: '0.875rem',
              flex: 1,
            }}
          >
            キーワードなどで各種ドキュメントを検索し、コピーできます
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1.5, mb: 1.5 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>検索タイプ</InputLabel>
            <Select
              value={searchType}
              label="検索タイプ"
              onChange={(e) => {
                setSearchType(e.target.value as SearchType);
                if (e.target.value !== SearchType.SLACK) {
                  setSelectedChannels([]);
                }
              }}
            >
              <MenuItem value={SearchType.DISCUSSION}>
                ディスカッション
              </MenuItem>
              <MenuItem value={SearchType.ANALYSIS}>分析</MenuItem>
              <MenuItem value={SearchType.MEETING}>ミーティング</MenuItem>
              <MenuItem value={SearchType.SLACK}>Slack</MenuItem>
              <MenuItem value={SearchType.SQL_TEMPLATE}>
                SQLテンプレート
              </MenuItem>
              <MenuItem value={SearchType.ANALYSIS_TABLE}>
                分析テーブル
              </MenuItem>
              <MenuItem value={SearchType.ANALYSIS_TEMPLATE}>
                分析テンプレート
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="キーワード"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            sx={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            disabled={selectedResults.length === 0 || isCopying}
            onClick={handleCopySelectedResults}
            startIcon={
              isCopying ? <CircularProgress size={20} /> : <ContentCopyIcon />
            }
            sx={{ ml: 1 }}
          >
            {isCopying ? 'コピー中...' : '選択項目をコピー'}
          </Button>
          <Button
            variant="contained"
            disabled={selectedResults.length === 0}
            onClick={() => setSaveDialogOpen(true)}
          >
            選択項目を保存
          </Button>
        </Box>

        {/* Slackチャンネル選択 */}
        {searchType === SearchType.SLACK && (
          <Box sx={{ mb: 1.5 }}>
            <Autocomplete
              size="small"
              multiple
              open={isChannelSelectOpen}
              onOpen={() => setIsChannelSelectOpen(true)}
              onClose={() => setIsChannelSelectOpen(false)}
              disableCloseOnSelect
              options={SLACK_CHANNELS}
              getOptionLabel={(option) => option.name}
              value={SLACK_CHANNELS.filter((channel) =>
                selectedChannels.includes(channel.id),
              )}
              onChange={(_, newValue) => {
                setSelectedChannels(newValue.map((channel) => channel.id));
                setIsChannelSelectOpen(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="検索対象チャンネル"
                  placeholder="チャンネルを選択"
                  onClick={() => setIsChannelSelectOpen(true)}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    label={option.name}
                    {...getTagProps({ index })}
                    key={option.id}
                    onDelete={() => {
                      const newSelectedChannels = selectedChannels.filter(
                        (id) => id !== option.id,
                      );
                      setSelectedChannels(newSelectedChannels);
                      setIsChannelSelectOpen(true);
                    }}
                  />
                ))
              }
            />
          </Box>
        )}

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {filteredResults.slice(0, displayCount).map((result) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={result.id}>
                  <Box
                    sx={{
                      p: 1.5,
                      height: '100%',
                      border: '1px solid #e0e0e0',
                      borderRadius: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: '#fff',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                      transition: 'box-shadow 0.2s, transform 0.2s',
                      '&:hover': {
                        boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mb: 1,
                      }}
                    >
                      <Checkbox
                        checked={selectedResults.some(
                          (r) => r.id === result.id,
                        )}
                        onChange={() => handleResultSelect(result)}
                        sx={{ p: 0.5, mr: 1 }}
                      />
                      <Box sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 0.5,
                          }}
                        >
                          {typeConfig[result.type]?.icon || <DescriptionIcon />}
                          <Link
                            href={`${NOTION_BASE_URL}${result.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              ml: 1,
                              color:
                                typeConfig[result.type]?.color ||
                                'primary.main',
                              textDecoration: 'none',
                              fontWeight: 600,
                              fontSize: '0.9rem',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                            }}
                          >
                            {highlightText(
                              truncateAroundKeyword(
                                result.title || '無題',
                                100,
                              ),
                            )}
                          </Link>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 0.5,
                            color: 'text.secondary',
                            fontSize: '0.75rem',
                          }}
                        >
                          <EventIcon sx={{ mr: 0.5, fontSize: 14 }} />
                          {result.created_at}
                        </Box>
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() => handleCopyResult(result)}
                        disabled={isCopying}
                        sx={{ ml: 1 }}
                      >
                        {isCopying ? (
                          <CircularProgress size={20} />
                        ) : (
                          <ContentCopyIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Box>

                    <Box sx={{ flex: 1, overflow: 'hidden' }}>
                      {Object.entries(result.properties || {}).map(
                        ([key, value]) => (
                          <Typography
                            key={key}
                            variant="body2"
                            sx={{
                              color: 'text.secondary',
                              mb: 0.5,
                              fontSize: '0.75rem',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {key}:{' '}
                            {highlightText(
                              truncateAroundKeyword(value as string, 50),
                            )}
                          </Typography>
                        ),
                      )}
                      {result.summary && (
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 0.5,
                            p: 0.5,
                            backgroundColor: 'grey.50',
                            borderRadius: 1,
                            fontSize: '0.75rem',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                          }}
                        >
                          {highlightText(
                            truncateAroundKeyword(result.summary || '', 200),
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {hasMore && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleLoadMore}
                  startIcon={<ExpandMoreIcon />}
                >
                  さらに{LOAD_MORE_COUNT}件を表示
                </Button>
              </Box>
            )}
          </Box>
        )}
      </AccordionDetails>

      <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
        <DialogTitle>検索結果の保存</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="セッション名"
            fullWidth
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleSave} variant="contained">
            保存
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setCopySnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setCopySnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          コンテンツをコピーしました
        </Alert>
      </Snackbar>
    </Accordion>
  );
};

export default FreeWordSearch;
