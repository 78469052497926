import {
  FormatPaint as FormatPaintIcon,
  Layers as LayersIcon,
  TextFields as TextFieldsIcon,
} from '@mui/icons-material';
import { Paper, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import {
  IItemOption,
  IShelfOption,
  ITaglineOption,
} from '../../../../../types/plp-builder.type';
import { StyleSettingsProps } from '../types';
import ItemStylePanel from './ItemStylePanel';
import ShelfStylePanel from './ShelfStylePanel';
import TaglineStylePanel from './TaglineStylePanel';

/**
 * タブパネルコンポーネント
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`style-tabpanel-${index}`}
      aria-labelledby={`style-tab-${index}`}
      {...other}
      style={{ padding: '16px 0' }}
    >
      {value === index && children}
    </div>
  );
};

/**
 * スタイル設定コンポーネント
 * シェルフ、タグライン、アイテムのスタイル設定を管理するUIを提供します
 */
const StyleSettings: React.FC<StyleSettingsProps> = ({
  shelfOption = {},
  taglineOption = {},
  itemOption = {},
  onChangeShelfOption,
  onChangeTaglineOption,
  onChangeItemOption,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  // タブ切り替えハンドラー
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // シェルフオプション更新ハンドラー
  const handleShelfOptionChange = (newOption: IShelfOption) => {
    onChangeShelfOption({ ...shelfOption, ...newOption });
  };

  // タグラインオプション更新ハンドラー
  const handleTaglineOptionChange = (newOption: ITaglineOption) => {
    onChangeTaglineOption({ ...taglineOption, ...newOption });
  };

  // アイテムオプション更新ハンドラー
  const handleItemOptionChange = (newOption: IItemOption) => {
    onChangeItemOption({ ...itemOption, ...newOption });
  };

  return (
    <Paper elevation={0} sx={{}}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="スタイル設定タブ"
        variant="fullWidth"
      >
        <Tab
          icon={<LayersIcon fontSize="small" />}
          iconPosition="start"
          label="シェルフ"
          id="style-tab-0"
          aria-controls="style-tabpanel-0"
        />
        <Tab
          icon={<TextFieldsIcon fontSize="small" />}
          iconPosition="start"
          label="タグライン"
          id="style-tab-1"
          aria-controls="style-tabpanel-1"
        />
        <Tab
          icon={<FormatPaintIcon fontSize="small" />}
          iconPosition="start"
          label="商品アイテム"
          id="style-tab-2"
          aria-controls="style-tabpanel-2"
        />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <ShelfStylePanel
          shelfOption={shelfOption}
          onShelfOptionChange={handleShelfOptionChange}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <TaglineStylePanel
          taglineOption={taglineOption}
          onTaglineOptionChange={handleTaglineOptionChange}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <ItemStylePanel
          itemOption={itemOption}
          onItemOptionChange={handleItemOptionChange}
        />
      </TabPanel>
    </Paper>
  );
};

export default StyleSettings;
