export enum SearchType {
  DISCUSSION = 'discussion',
  ANALYSIS = 'analysis',
  MEETING = 'meeting',
  SLACK = 'slack',
  SQL_TEMPLATE = 'sql_template',
  ANALYSIS_TABLE = 'analysis_table',
  ANALYSIS_TEMPLATE = 'analysis_template',
}

export type SearchMode = 'llm' | 'regexp';

// 検索タイプごとのデフォルト取得件数
export const DEFAULT_SEARCH_LIMITS: Record<SearchType, number> = {
  discussion: 3,
  analysis: 3,
  meeting: 3,
  slack: 10,
  sql_template: 20,
  analysis_table: 10,
  analysis_template: 5,
};

export interface Tag {
  id: string;
  name: string;
  color: string;
}

export interface SearchRequest {
  query: string;
  type: SearchType;
  mode: SearchMode;
  skip_cache?: boolean;
  limit?: number;
  channels?: string[];
  tag_ids?: string[];
}

export interface SearchSession {
  id: string;
  searchMessage: string;
  searchType: SearchType;
  createdAt: string;
  searchKey: string;
  channels?: string[];
  results: SearchResult[];
  debug?: {
    prompt?: string;
    response?: string;
    cost?: number;
  };
  tag_ids?: string[];
  pre_prompt?: string;
  memo?: string;
}

export interface SearchResponse {
  sessions: SearchSession[];
}

export interface SearchResult {
  id: string;
  title: string;
  content: string;
  summary: string;
  url: string;
  createdAt: string | null;
  lastEditedAt: string | null;
  type: SearchType;
  properties: {
    [key: string]: string;
  };
}

export interface SlackChannel {
  id: string;
  name: string;
}

export const SLACK_CHANNELS: SlackChannel[] = [
  { id: 'C07LN4K05EK', name: 'プロダクトマニア' },
  { id: 'C06V6SEDECE', name: 'クライアントコミュニケーションコンペ' },
  { id: 'C075LH989BN', name: 'シェルフ機能研究コンペ' },
  { id: 'C06G7T1FC91', name: 'シェルフ量産コンペ' },
  { id: 'C078XJ1TKS6', name: 'データ分析コンペ' },
  { id: 'C074J6AS673', name: 'リクルーティングコンペ' },
  { id: 'C07QY2D3SR3', name: '顧客獲得' },
  { id: 'C076A37U7SM', name: 'internal-palさん' },
  { id: 'C075UAKUGLV', name: 'internal-tokyo-baseさん' },
  { id: 'C075ZCB2LQL', name: 'internal-アーバンさん' },
  { id: 'C0769DR6LH2', name: 'internal-オンワードさん' },
  { id: 'C076427EXMK', name: 'internal-キャンさん' },
  { id: 'C0766UK6P5Y', name: 'internal-ベルーナさん' },
  { id: 'C075X0S30SX', name: 'internal-ルミネさん' },
  { id: 'C0764H350AD', name: 'internal-丸井さん' },
  { id: 'C06RBPH90H4', name: 'アーバンリサーチ様-insightx' },
  { id: 'C04DQUE0A3G', name: 'キャン様-insightx' },
  { id: 'C066KTAGB0D', name: 'パル様-insightx' },
  { id: 'C06HHGNUQ4Q', name: '丸井様-insightx' },
];

export interface SavePromptRequest {
  prompt_text: string;
  session_ids: string[];
  template_id: string;
}

export interface SavePromptResponse {
  prompt_id: string;
}

export interface BuiltPrompt {
  prompt_id: string;
  prompt_text: string;
  session_ids: string[];
  template_id: string;
  created_at: string;
  module_ids?: string[];
}

export interface CreateTagRequest {
  name: string;
  color: string;
}

export interface UpdateSessionTagsRequest {
  search_key: string;
  tag_ids: string[];
}

export interface UpdateSessionPrePromptRequest {
  search_key: string;
  pre_prompt: string;
}

export interface UpdateSessionMemoRequest {
  search_key: string;
  memo: string;
}

export interface PromptModule {
  id: string;
  name: string;
  content: string;
  created_at: string;
}

export interface CreatePromptModuleRequest {
  name: string;
  content: string;
}

export interface UpdatePromptModuleRequest {
  name: string;
  content: string;
}
