import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExtensionIcon from '@mui/icons-material/Extension';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { GptContextService } from '../services/gptContext.service';
import { PromptModule } from '../services/types';

interface PromptModuleListProps {
  modules: PromptModule[];
  selectedModuleIds: string[];
  onModuleSelect: (moduleId: string) => void;
  onModulesUpdate: () => void;
}

export const PromptModuleList: React.FC<PromptModuleListProps> = ({
  modules,
  selectedModuleIds,
  onModuleSelect,
  onModulesUpdate,
}) => {
  const theme = useTheme();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newModuleName, setNewModuleName] = useState('');
  const [newModuleContent, setNewModuleContent] = useState('');
  const [selectedModule, setSelectedModule] = useState<PromptModule | null>(
    null,
  );
  const [editedName, setEditedName] = useState('');
  const [editedContent, setEditedContent] = useState('');
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  const handleCreateModule = async () => {
    try {
      await GptContextService.createPromptModule({
        name: newModuleName,
        content: newModuleContent,
      });
      onModulesUpdate();
      setCreateDialogOpen(false);
      setNewModuleName('');
      setNewModuleContent('');
    } catch (err) {
      console.error('プロンプトモジュールの作成に失敗しました:', err);
    }
  };

  const handleViewModule = (module: PromptModule) => {
    setSelectedModule(module);
    setEditedName(module.name);
    setEditedContent(module.content);
    setEditMode(false);
    setViewDialogOpen(true);
  };

  const handleUpdateModule = async () => {
    if (!selectedModule) return;

    try {
      await GptContextService.updatePromptModule(selectedModule.id, {
        name: editedName,
        content: editedContent,
      });
      onModulesUpdate();
      setViewDialogOpen(false);
      setEditMode(false);
    } catch (err) {
      console.error('プロンプトモジュールの更新に失敗しました:', err);
    }
  };

  const handleCopyContent = (content: string) => {
    navigator.clipboard.writeText(content);
    setCopySnackbarOpen(true);
  };

  const handleCopySelectedModules = () => {
    const selectedContents = modules
      .filter((module) => selectedModuleIds.includes(module.id))
      .map((module) => module.content)
      .join('\n\n');
    if (selectedContents) {
      handleCopyContent(selectedContents);
    }
  };

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        '&:hover': {
          boxShadow: theme.shadows[2],
        },
        transition: 'box-shadow 0.2s ease-in-out',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Badge badgeContent={selectedModuleIds.length} color="primary">
            <ExtensionIcon
              sx={{ color: theme.palette.primary.main, fontSize: '1.2rem' }}
            />
          </Badge>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700, fontSize: '1rem' }}
          >
            プロンプトモジュール
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Tooltip title="選択したモジュールをコピー">
            <span>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopySelectedModules();
                }}
                disabled={selectedModuleIds.length === 0}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ pt: 0.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 2 }}>
          <InfoOutlinedIcon
            sx={{
              color: theme.palette.text.secondary,
              fontSize: '1rem',
              mt: 0.2,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              fontSize: '0.875rem',
              flex: 1,
            }}
          >
            よく使う便利プロンプト集。どんどん追加大歓迎です
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={(e) => {
              e.stopPropagation();
              setCreateDialogOpen(true);
            }}
            size="small"
            sx={{ color: 'white' }}
          >
            新規作成
          </Button>
        </Box>
        <Grid container spacing={2}>
          {modules.map((module) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={module.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: theme.shadows[4],
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, pb: 1 }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <Tooltip title="コンテンツをコピー">
                      <IconButton
                        size="small"
                        onClick={() => handleCopyContent(module.content)}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Checkbox
                      checked={selectedModuleIds.includes(module.id)}
                      onChange={() => onModuleSelect(module.id)}
                      size="small"
                    />
                  </Box>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ pr: 4, fontWeight: 'medium' }}
                  >
                    {module.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      mb: 1,
                    }}
                  >
                    {module.content}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(module.created_at).toLocaleString('ja-JP')}
                  </Typography>
                </CardContent>
                <CardActions sx={{ pt: 0 }}>
                  <Button
                    size="small"
                    onClick={() => handleViewModule(module)}
                    sx={{ ml: 'auto' }}
                  >
                    詳細
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>

      {/* 新規作成ダイアログ */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>プロンプトモジュールを作成</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
            <TextField
              label="名前"
              value={newModuleName}
              onChange={(e) => setNewModuleName(e.target.value)}
              fullWidth
            />
            <TextField
              label="プロンプト内容"
              value={newModuleContent}
              onChange={(e) => setNewModuleContent(e.target.value)}
              multiline
              rows={4}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)}>キャンセル</Button>
          <Button
            onClick={handleCreateModule}
            disabled={!newModuleName || !newModuleContent}
            variant="contained"
          >
            作成
          </Button>
        </DialogActions>
      </Dialog>

      {/* 表示・編集ダイアログ */}
      <Dialog
        open={viewDialogOpen}
        onClose={() => {
          setViewDialogOpen(false);
          setEditMode(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {editMode ? 'プロンプトモジュールを編集' : 'プロンプトモジュール'}
          {!editMode && (
            <Tooltip title="編集">
              <IconButton onClick={() => setEditMode(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
            {editMode ? (
              <>
                <TextField
                  label="名前"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  fullWidth
                />
                <TextField
                  label="プロンプト内容"
                  value={editedContent}
                  onChange={(e) => setEditedContent(e.target.value)}
                  multiline
                  rows={8}
                  fullWidth
                />
              </>
            ) : (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  {selectedModule?.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    bgcolor: 'action.hover',
                    p: 2,
                    borderRadius: 1,
                  }}
                >
                  {selectedModule?.content}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  作成日:{' '}
                  {selectedModule?.created_at &&
                    new Date(selectedModule.created_at).toLocaleString('ja-JP')}
                </Typography>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {editMode ? (
            <>
              <Button onClick={() => setEditMode(false)}>キャンセル</Button>
              <Button
                onClick={handleUpdateModule}
                disabled={!editedName || !editedContent}
                variant="contained"
              >
                更新
              </Button>
            </>
          ) : (
            <Button onClick={() => setViewDialogOpen(false)}>閉じる</Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setCopySnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setCopySnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          コンテンツをコピーしました
        </Alert>
      </Snackbar>
    </Accordion>
  );
};
