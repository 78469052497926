import { Box, Card, CardContent, Typography } from '@mui/material';
import { format, parseISO, startOfMonth } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DailyShelfMetricsV2 } from '../../../services/shelf-service';
import { getPageTypeColor } from '../../../utils/page-type-utils';
import { TimeUnit } from './TimeUnitSelector';

interface PageTypeMetricsChartsProps {
  data: DailyShelfMetricsV2[];
  compareData?: DailyShelfMetricsV2[];
  showComparison?: boolean;
  timeUnit: TimeUnit;
}

interface ChartDataItem {
  date: string;
  [key: string]: string | number;
}

const PageTypeMetricsCharts: React.FC<PageTypeMetricsChartsProps> = ({
  data,
  compareData = [],
  showComparison = false,
  timeUnit,
}) => {
  const sortedPageTypes = useMemo(() => {
    const allData = [...data, ...(showComparison ? compareData : [])];
    const pageTypeShownTotals = allData.reduce(
      (acc, curr) => {
        acc[curr.page_name] = (acc[curr.page_name] || 0) + curr.shown_ss;
        return acc;
      },
      {} as { [key: string]: number },
    );

    return Object.entries(pageTypeShownTotals)
      .sort(([, a], [, b]) => b - a)
      .map(([pageType]) => pageType);
  }, [data, compareData, showComparison]);

  const charts = [
    {
      title: 'ページ別シェルフIMP SS数',
      dataKey: 'shown_ss',
    },
    {
      title: 'ページ別シェルフクリックSS数',
      dataKey: 'click_ss',
    },
    {
      title: 'ページ別シェルフ経由CV SS数',
      dataKey: 'shelf_cv_ss',
    },
  ];

  const getTimeUnitStartDate = (date: string) => {
    const dateObj = parseISO(date);
    switch (timeUnit) {
      case 'week': {
        // 全データの中で最新の日付を取得
        const allData = [...data, ...(showComparison ? compareData : [])];
        const maxDate = parseISO(
          allData.reduce(
            (max, curr) => (curr.ymd > max ? curr.ymd : max),
            allData[0].ymd,
          ),
        );
        // 最新の日付から何週目かを計算
        const diffDays = Math.floor(
          (maxDate.getTime() - dateObj.getTime()) / (1000 * 60 * 60 * 24),
        );
        const weekNumber = Math.floor(diffDays / 7);
        // 最新の日付からweekNumber週前の日付を計算
        const weekStartDate = new Date(maxDate);
        weekStartDate.setDate(weekStartDate.getDate() - weekNumber * 7);
        return format(weekStartDate, 'yyyy-MM-dd');
      }
      case 'month':
        return format(startOfMonth(dateObj), 'yyyy-MM-dd');
      default:
        return date;
    }
  };

  const processData = (metrics: DailyShelfMetricsV2[], metric: string) => {
    const groupedData = metrics.reduce(
      (acc, curr) => {
        const date = curr.ymd;
        const key = getTimeUnitStartDate(date);

        if (!acc[key]) {
          acc[key] = { date: key } as ChartDataItem;
          sortedPageTypes.forEach((pageType) => {
            acc[key][pageType] = 0;
          });
        }

        acc[key][curr.page_name] =
          (Number(acc[key][curr.page_name]) || 0) + Number(curr[metric]);

        return acc;
      },
      {} as Record<string, ChartDataItem>,
    );

    return Object.values(groupedData).sort((a, b) =>
      a.date.localeCompare(b.date),
    );
  };

  // 月次表示の場合は同じ月のデータを集約
  const mergeMonthlyData = (data: ChartDataItem[]) => {
    if (timeUnit !== 'month') return data;

    const monthlyData = data.reduce(
      (acc, curr) => {
        const monthKey = curr.date.substring(0, 7); // YYYY-MM
        if (!acc[monthKey]) {
          acc[monthKey] = {
            ...curr,
            date: curr.date, // 月の最初の日付を保持
          };
          return acc;
        }

        // 同じ月のデータを集約
        sortedPageTypes.forEach((pageType) => {
          const currentValue = Number(acc[monthKey][pageType]) || 0;
          const addValue = Number(curr[pageType]) || 0;
          acc[monthKey][pageType] = currentValue + addValue;
        });

        return acc;
      },
      {} as Record<string, ChartDataItem>,
    );

    return Object.values(monthlyData).sort((a, b) =>
      a.date.localeCompare(b.date),
    );
  };

  const formatXAxisTick = (date: string) => {
    switch (timeUnit) {
      case 'week':
        return format(parseISO(date), 'M/d週', { locale: ja });
      case 'month':
        return format(parseISO(date), 'yyyy/M月', { locale: ja });
      default:
        return format(parseISO(date), 'M/d', { locale: ja });
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: 2,
      }}
    >
      {charts.map((chart) => {
        const mainData = processData(data, chart.dataKey);
        const compareData2 = showComparison
          ? processData(compareData, chart.dataKey)
          : [];
        const chartData = mergeMonthlyData(
          [...mainData, ...compareData2].sort((a, b) =>
            a.date.localeCompare(b.date),
          ),
        );

        return (
          <Card
            key={chart.title}
            sx={{
              height: '100%',
              borderRadius: 2,
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
              bgcolor: '#FFFFFF',
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  mb: 2,
                  textAlign: 'left',
                }}
              >
                {chart.title}
              </Typography>
              <Box sx={{ width: '100%', height: 280 }}>
                <ResponsiveContainer>
                  <AreaChart
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 0,
                      bottom: 60,
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      vertical={false}
                      stroke="rgba(0, 0, 0, 0.1)"
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={formatXAxisTick}
                      tick={{ fontSize: 12, fill: '#666' }}
                      tickLine={{ stroke: '#ccc' }}
                      axisLine={{ stroke: '#ccc' }}
                    />
                    <YAxis
                      tick={{ fontSize: 12, fill: '#666' }}
                      tickLine={{ stroke: '#ccc' }}
                      axisLine={{ stroke: '#ccc' }}
                    />
                    <Tooltip
                      formatter={(value: number) => value.toLocaleString()}
                      labelFormatter={(label: string) =>
                        format(parseISO(label), 'yyyy/M/d (E)', {
                          locale: ja,
                        })
                      }
                    />
                    <Legend
                      verticalAlign="bottom"
                      height={36}
                      iconType="circle"
                      iconSize={8}
                      wrapperStyle={{
                        fontSize: '11px',
                      }}
                    />
                    {sortedPageTypes.map((pageType) => {
                      const color = getPageTypeColor(pageType);
                      return (
                        <Area
                          key={pageType}
                          type="monotone"
                          dataKey={pageType}
                          name={pageType}
                          stackId="1"
                          stroke={color}
                          fill={color}
                          fillOpacity={0.3}
                        />
                      );
                    })}
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default PageTypeMetricsCharts;
