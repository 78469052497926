import {
  Code as CodeIcon,
  Palette as PaletteIcon,
  TextFields as TextFieldsIcon,
} from '@mui/icons-material';
import { Box, Divider, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ITaglineOption } from '../../../../../types/plp-builder.type';
import { StylePropertyMapping } from '../types';
import AdvancedStyleEditor from './components/AdvancedStyleEditor';
import SimpleStyleEditor from './components/SimpleStyleEditor';

// タグライン1スタイルのプロパティマッピング
const tagline1StyleMapping: StylePropertyMapping = {
  color: {
    type: 'color',
    label: '文字色',
    description: 'タグライン1の文字色を設定します',
  },
  fontSize: {
    type: 'size',
    label: 'フォントサイズ',
    min: 8,
    max: 32,
    step: 1,
    unit: 'px',
    description: 'タグライン1の文字サイズを設定します',
  },
  fontWeight: {
    type: 'select',
    label: '太さ',
    options: [
      { value: 'normal', label: '標準' },
      { value: 'bold', label: '太字' },
      { value: '300', label: '細め' },
      { value: '500', label: '少し太め' },
      { value: '700', label: '太め' },
    ],
    description: 'タグライン1の文字の太さを設定します',
  },
  textAlign: {
    type: 'select',
    label: 'テキスト配置',
    options: [
      { value: 'left', label: '左揃え' },
      { value: 'center', label: '中央揃え' },
      { value: 'right', label: '右揃え' },
    ],
    description: 'タグライン1のテキスト配置を設定します',
  },
  margin: {
    type: 'size',
    label: '余白',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: 'タグライン1の外側の余白を設定します',
  },
  padding: {
    type: 'size',
    label: '内側の余白',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: 'タグライン1の内側の余白を設定します',
  },
};

// タグライン2スタイルのプロパティマッピング
const tagline2StyleMapping: StylePropertyMapping = {
  color: {
    type: 'color',
    label: '文字色',
    description: 'タグライン2の文字色を設定します',
  },
  fontSize: {
    type: 'size',
    label: 'フォントサイズ',
    min: 10,
    max: 36,
    step: 1,
    unit: 'px',
    description: 'タグライン2の文字サイズを設定します',
  },
  fontWeight: {
    type: 'select',
    label: '太さ',
    options: [
      { value: 'normal', label: '標準' },
      { value: 'bold', label: '太字' },
      { value: '300', label: '細め' },
      { value: '500', label: '少し太め' },
      { value: '700', label: '太め' },
    ],
    description: 'タグライン2の文字の太さを設定します',
  },
  textAlign: {
    type: 'select',
    label: 'テキスト配置',
    options: [
      { value: 'left', label: '左揃え' },
      { value: 'center', label: '中央揃え' },
      { value: 'right', label: '右揃え' },
    ],
    description: 'タグライン2のテキスト配置を設定します',
  },
  margin: {
    type: 'size',
    label: '余白',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: 'タグライン2の外側の余白を設定します',
  },
  padding: {
    type: 'size',
    label: '内側の余白',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: 'タグライン2の内側の余白を設定します',
  },
};

// タグラインコンテナスタイルのプロパティマッピング
const taglinesStyleMapping: StylePropertyMapping = {
  backgroundColor: {
    type: 'color',
    label: '背景色',
    description: 'タグライン全体の背景色を設定します',
  },
  textAlign: {
    type: 'select',
    label: 'テキスト配置',
    options: [
      { value: 'left', label: '左揃え' },
      { value: 'center', label: '中央揃え' },
      { value: 'right', label: '右揃え' },
    ],
    description: 'タグライン全体のテキスト配置を設定します',
  },
  padding: {
    type: 'size',
    label: '内側の余白',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: 'タグライン全体の内側の余白を設定します',
  },
  margin: {
    type: 'size',
    label: '外側の余白',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: 'タグライン全体の外側の余白を設定します',
  },
  borderRadius: {
    type: 'size',
    label: '角丸',
    min: 0,
    max: 16,
    step: 1,
    unit: 'px',
    description: 'タグライン全体の角を丸くします',
  },
  borderWidth: {
    type: 'size',
    label: '枠線の太さ',
    min: 0,
    max: 4,
    step: 1,
    unit: 'px',
    description: 'タグライン全体の枠線の太さを設定します',
  },
  borderColor: {
    type: 'color',
    label: '枠線の色',
    description: 'タグライン全体の枠線の色を設定します',
  },
  borderStyle: {
    type: 'select',
    label: '枠線のスタイル',
    options: [
      { value: 'none', label: 'なし' },
      { value: 'solid', label: '実線' },
      { value: 'dashed', label: '破線' },
      { value: 'dotted', label: '点線' },
    ],
    description: 'タグライン全体の枠線のスタイルを設定します',
  },
};

interface TaglineStylePanelProps {
  taglineOption: ITaglineOption;
  onTaglineOptionChange: (option: ITaglineOption) => void;
}

/**
 * タグラインスタイルパネルコンポーネント
 * タグラインのスタイルを設定するUIを提供します
 */
const TaglineStylePanel: React.FC<TaglineStylePanelProps> = ({
  taglineOption,
  onTaglineOptionChange,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState<'simple' | 'advanced'>('simple');

  // タグラインスタイルの取得
  const tagline1Style = taglineOption.tagline1Style || {};
  const tagline2Style = taglineOption.tagline2Style || {};
  const taglinesStyle = taglineOption.taglinesStyle || {};

  // タグラインテンプレートの取得
  const tagline1Template = taglineOption.tagline1Template || '';
  const tagline2Template = taglineOption.tagline2Template || '';

  // タグラインスタイル更新ハンドラー
  const handleTagline1StyleChange = (newStyle: Record<string, string>) => {
    onTaglineOptionChange({
      ...taglineOption,
      tagline1Style: newStyle,
    });
  };

  const handleTagline2StyleChange = (newStyle: Record<string, string>) => {
    onTaglineOptionChange({
      ...taglineOption,
      tagline2Style: newStyle,
    });
  };

  const handleTaglinesStyleChange = (newStyle: Record<string, string>) => {
    onTaglineOptionChange({
      ...taglineOption,
      taglinesStyle: newStyle,
    });
  };

  // タグラインテンプレート更新ハンドラー
  const handleTagline1TemplateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onTaglineOptionChange({
      ...taglineOption,
      tagline1Template: event.target.value,
    });
  };

  const handleTagline2TemplateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onTaglineOptionChange({
      ...taglineOption,
      tagline2Template: event.target.value,
    });
  };

  return (
    <Box>
      {/* タグラインテンプレート設定 */}
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
        >
          <TextFieldsIcon fontSize="small" sx={{ mr: 1 }} />
          タグラインテンプレート
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          タグラインのデフォルトテキストを設定します。空欄の場合はシェルフの元の値が使用されます。
        </Typography>

        <TextField
          fullWidth
          label="タグライン1テンプレート"
          placeholder="例: BRAND COLLECTION"
          value={tagline1Template}
          onChange={handleTagline1TemplateChange}
          size="small"
          margin="normal"
        />

        <TextField
          fullWidth
          label="タグライン2テンプレート"
          placeholder="例: 新着アイテムをチェック"
          value={tagline2Template}
          onChange={handleTagline2TemplateChange}
          size="small"
          margin="normal"
        />
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* スタイル編集モード選択 */}
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          編集モード
        </Typography>
        <Tabs
          value={editMode}
          onChange={(_, newValue) => setEditMode(newValue)}
          aria-label="スタイル編集モード"
        >
          <Tab
            value="simple"
            label="簡易モード"
            icon={<PaletteIcon fontSize="small" />}
            iconPosition="start"
          />
          <Tab
            value="advanced"
            label="上級者モード"
            icon={<CodeIcon fontSize="small" />}
            iconPosition="start"
          />
        </Tabs>
      </Box>

      {/* スタイル編集タブ */}
      {editMode === 'simple' ? (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              aria-label="タグラインスタイルタブ"
            >
              <Tab
                label="タグライン1"
                id="tagline-tab-0"
                aria-controls="tagline-tabpanel-0"
                sx={{ fontSize: '0.8rem' }}
              />
              <Tab
                label="タグライン2"
                id="tagline-tab-1"
                aria-controls="tagline-tabpanel-1"
                sx={{ fontSize: '0.8rem' }}
              />
              <Tab
                label="コンテナ"
                id="tagline-tab-2"
                aria-controls="tagline-tabpanel-2"
                sx={{ fontSize: '0.8rem' }}
              />
            </Tabs>
          </Box>

          <div
            role="tabpanel"
            hidden={activeTab !== 0}
            id="tagline-tabpanel-0"
            aria-labelledby="tagline-tab-0"
          >
            {activeTab === 0 && (
              <SimpleStyleEditor
                style={tagline1Style}
                onStyleChange={handleTagline1StyleChange}
                propertyMapping={tagline1StyleMapping}
                title="タグライン1スタイル"
              />
            )}
          </div>

          <div
            role="tabpanel"
            hidden={activeTab !== 1}
            id="tagline-tabpanel-1"
            aria-labelledby="tagline-tab-1"
          >
            {activeTab === 1 && (
              <SimpleStyleEditor
                style={tagline2Style}
                onStyleChange={handleTagline2StyleChange}
                propertyMapping={tagline2StyleMapping}
                title="タグライン2スタイル"
              />
            )}
          </div>

          <div
            role="tabpanel"
            hidden={activeTab !== 2}
            id="tagline-tabpanel-2"
            aria-labelledby="tagline-tab-2"
          >
            {activeTab === 2 && (
              <SimpleStyleEditor
                style={taglinesStyle}
                onStyleChange={handleTaglinesStyleChange}
                propertyMapping={taglinesStyleMapping}
                title="タグラインコンテナスタイル"
              />
            )}
          </div>
        </Box>
      ) : (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              aria-label="タグラインスタイルタブ"
            >
              <Tab
                label="タグライン1"
                id="tagline-adv-tab-0"
                aria-controls="tagline-adv-tabpanel-0"
                sx={{ fontSize: '0.8rem' }}
              />
              <Tab
                label="タグライン2"
                id="tagline-adv-tab-1"
                aria-controls="tagline-adv-tabpanel-1"
                sx={{ fontSize: '0.8rem' }}
              />
              <Tab
                label="コンテナ"
                id="tagline-adv-tab-2"
                aria-controls="tagline-adv-tabpanel-2"
                sx={{ fontSize: '0.8rem' }}
              />
            </Tabs>
          </Box>

          <div
            role="tabpanel"
            hidden={activeTab !== 0}
            id="tagline-adv-tabpanel-0"
            aria-labelledby="tagline-adv-tab-0"
          >
            {activeTab === 0 && (
              <AdvancedStyleEditor
                style={tagline1Style}
                onStyleChange={handleTagline1StyleChange}
                title="タグライン1スタイル (CSS)"
              />
            )}
          </div>

          <div
            role="tabpanel"
            hidden={activeTab !== 1}
            id="tagline-adv-tabpanel-1"
            aria-labelledby="tagline-adv-tab-1"
          >
            {activeTab === 1 && (
              <AdvancedStyleEditor
                style={tagline2Style}
                onStyleChange={handleTagline2StyleChange}
                title="タグライン2スタイル (CSS)"
              />
            )}
          </div>

          <div
            role="tabpanel"
            hidden={activeTab !== 2}
            id="tagline-adv-tabpanel-2"
            aria-labelledby="tagline-adv-tab-2"
          >
            {activeTab === 2 && (
              <AdvancedStyleEditor
                style={taglinesStyle}
                onStyleChange={handleTaglinesStyleChange}
                title="タグラインコンテナスタイル (CSS)"
              />
            )}
          </div>
        </Box>
      )}
    </Box>
  );
};

export default TaglineStylePanel;
