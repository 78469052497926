import {
  Close as CloseIcon,
  AutoFixHighRounded as MagicWandIcon,
  NumbersRounded as NumberOneIcon,
  NumbersOutlined as NumberTwoIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { shelfSearchService } from '../../../../services/shelf-search-service';
import { ITaglineCandidate } from '../../../../types/shelf-search.type';

interface AITaglineGeneratorProps {
  open: boolean;
  onClose: () => void;
  onSelect: (tagline1?: string, tagline2?: string) => void;
  shelfId: string;
  currentTagline1?: string;
  currentTagline2?: string;
  items?: any[]; // 商品メタデータ
}

/**
 * AIタグライン生成コンポーネント
 */
const AITaglineGenerator: React.FC<AITaglineGeneratorProps> = ({
  open,
  onClose,
  onSelect,
  shelfId,
  currentTagline1,
  currentTagline2,
  items,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [candidates, setCandidates] = useState<ITaglineCandidate[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<number | null>(
    null,
  );
  const [lineCount, setLineCount] = useState<number>(currentTagline1 ? 2 : 1);
  const [customInstruction, setCustomInstruction] = useState('');

  // 現在のタグラインを表示するためのメッセージを作成
  const getCurrentTaglineMessage = () => {
    if (!currentTagline1 && !currentTagline2) return null;

    return (
      <Box
        sx={{
          mb: 2,
          p: 1.5,
          bgcolor: 'rgba(249, 249, 249, 0.8)',
          borderRadius: 1,
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          gutterBottom
          sx={{ display: 'block' }}
        >
          現在のタグライン:
        </Typography>
        {currentTagline1 && (
          <Typography variant="body2" fontWeight="medium">
            {currentTagline1}
          </Typography>
        )}
        {currentTagline2 && (
          <Typography variant="body2">{currentTagline2}</Typography>
        )}
      </Box>
    );
  };

  // タグライン生成処理
  const handleGenerateTaglines = async () => {
    setLoading(true);
    setError(null);
    setCandidates([]);
    setSelectedCandidate(null);

    try {
      const response = await shelfSearchService.generateTaglines({
        shelf_id: shelfId,
        line_count: lineCount,
        custom_instruction: customInstruction || undefined,
        items: items, // 商品メタデータをリクエストに含める
      });

      setCandidates(response.candidates);
    } catch (err) {
      console.error('タグライン生成エラー:', err);
      setError(
        'タグラインの生成中にエラーが発生しました。再試行してください。',
      );
    } finally {
      setLoading(false);
    }
  };

  // 選択処理
  const handleSelectCandidate = () => {
    if (selectedCandidate === null || !candidates[selectedCandidate]) {
      return;
    }

    const candidate = candidates[selectedCandidate];
    if (!candidate.tagline2) return;

    onSelect(candidate.tagline1, candidate.tagline2);
    onClose();
  };

  // 行数切り替え処理
  const handleLineCountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLineCount(Number(event.target.value));
    setCandidates([]);
    setSelectedCandidate(null);
  };

  // キーワード入力でEnterキー押下時の処理
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !loading) {
      handleGenerateTaglines();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'hidden',
        },
      }}
    >
      {/* ヘッダー */}
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pb: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MagicWandIcon sx={{ mr: 1.5, transform: 'rotate(30deg)' }} />
          <Typography variant="h6" component="div">
            AIタグライン生成
          </Typography>
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pt: 3, pb: 0 }}>
        {/* 現在のタグライン表示 */}
        {getCurrentTaglineMessage()}

        {/* 設定部分 */}
        <Box
          sx={{
            mb: 3,
            p: 2,
            backgroundColor: 'rgba(236, 246, 255, 0.5)',
            borderRadius: 2,
            border: '1px solid rgba(25, 118, 210, 0.2)',
          }}
        >
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            タグラインの設定
          </Typography>

          {/* 行数選択 */}
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              タグラインの行数:
            </Typography>
            <RadioGroup row value={lineCount} onChange={handleLineCountChange}>
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    icon={<NumberOneIcon />}
                    checkedIcon={<NumberOneIcon color="primary" />}
                  />
                }
                label="1行タグライン"
              />
              <FormControlLabel
                value={2}
                control={
                  <Radio
                    icon={<NumberTwoIcon />}
                    checkedIcon={<NumberTwoIcon color="primary" />}
                  />
                }
                label="2行タグライン"
              />
            </RadioGroup>
          </FormControl>

          {/* カスタム指示入力 */}
          <TextField
            fullWidth
            label="AIへの指示 (オプション)"
            placeholder="例: 40代女性向け、高級感を出して、季節感を出して など"
            value={customInstruction}
            onChange={(e) => setCustomInstruction(e.target.value)}
            onKeyPress={handleKeyPress}
            InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  onClick={handleGenerateTaglines}
                  disabled={loading}
                  startIcon={
                    loading ? <CircularProgress size={20} /> : <MagicWandIcon />
                  }
                  sx={{
                    ml: 1,
                    background:
                      'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                  }}
                >
                  {loading ? '生成中...' : '生成'}
                </Button>
              ),
            }}
            sx={{ mb: 1 }}
          />

          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>

        {/* 候補表示部分 */}
        <Box sx={{ minHeight: 200 }}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 200,
              }}
            >
              <CircularProgress />
            </Box>
          ) : candidates.length > 0 ? (
            <Box>
              <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                タグライン候補
              </Typography>
              {candidates.map((candidate, index) => (
                <Paper
                  key={index}
                  elevation={selectedCandidate === index ? 3 : 1}
                  sx={{
                    p: 2,
                    mb: 2,
                    cursor: 'pointer',
                    borderRadius: 2,
                    position: 'relative',
                    transition: 'all 0.2s',
                    border:
                      selectedCandidate === index
                        ? '2px solid transparent'
                        : '2px solid transparent',
                    background:
                      selectedCandidate === index
                        ? `linear-gradient(white, white) padding-box, linear-gradient(45deg, #FF9800, #F44336, #9C27B0, #3F51B5, #2196F3, #00BCD4, #009688, #4CAF50) border-box`
                        : 'white',
                    '&:hover': {
                      border: '2px solid transparent',
                      background: `linear-gradient(white, white) padding-box, linear-gradient(45deg, #FF9800, #F44336, #9C27B0, #3F51B5, #2196F3, #00BCD4, #009688, #4CAF50) border-box`,
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    },
                  }}
                  onClick={() => setSelectedCandidate(index)}
                >
                  {candidate.tagline1 && (
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      {candidate.tagline1}
                    </Typography>
                  )}
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {candidate.tagline2}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {candidate.reason}
                  </Typography>
                </Paper>
              ))}
            </Box>
          ) : (
            !loading && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 200,
                  color: 'text.secondary',
                }}
              >
                <MagicWandIcon sx={{ fontSize: 40, opacity: 0.5, mb: 2 }} />
                <Typography variant="body1">
                  「生成」ボタンをクリックして、AIにタグラインを作成してもらいましょう。
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  必要に応じて、AIへの指示を追加することもできます。
                </Typography>
              </Box>
            )
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          borderTop: `1px solid ${theme.palette.divider}`,
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {candidates.length > 0 &&
            '候補から選択するか、閉じてキャンセルしてください'}
        </Typography>
        <Box>
          <Button onClick={onClose} color="inherit" sx={{ mr: 1 }}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={handleSelectCandidate}
            disabled={selectedCandidate === null}
            color="primary"
          >
            選択して適用
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AITaglineGenerator;
