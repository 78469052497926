import {
  Clear as ClearIcon,
  FilterAlt as FilterIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ItemSelectionPanelProps } from './types';

/**
 * 商品選択パネルコンポーネント
 * 商品一覧から特定の商品を選択して除外するためのコンポーネント
 */
const ItemSelectionPanel: React.FC<ItemSelectionPanelProps> = ({
  items,
  selectedAids,
  onSelectedAidsChange,
  isLoading = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  // 表示する商品の並び順
  const [sortOrder, setSortOrder] = useState<
    'selected' | 'brand' | 'itemTitle'
  >('selected');

  // 検索条件と並び順に基づいてフィルタリングされた商品リスト
  const filteredItems = useMemo(() => {
    if (!items || items.length === 0) return [];

    // 検索条件に基づくフィルタリング
    let filtered = items;
    if (searchTerm.trim()) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = items.filter((item) => {
        // 商品データの項目名が異なる可能性があるため、両方のプロパティ名をチェック
        const brandName = item.brand_name || '';
        const itemTitle = item.item_title || '';
        const aid = item.aid || '';

        return (
          brandName.toLowerCase().includes(lowerSearchTerm) ||
          itemTitle.toLowerCase().includes(lowerSearchTerm) ||
          aid.toLowerCase().includes(lowerSearchTerm)
        );
      });
    }

    // 並び順に基づくソート
    return [...filtered].sort((a, b) => {
      if (sortOrder === 'selected') {
        const aSelected = selectedAids.includes(a.aid);
        const bSelected = selectedAids.includes(b.aid);
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;
      }

      if (sortOrder === 'brand') {
        const aBrand = a.brand_name || '';
        const bBrand = b.brand_name || '';
        return aBrand.localeCompare(bBrand);
      }

      if (sortOrder === 'itemTitle') {
        const aTitle = a.item_title || '';
        const bTitle = b.item_title || '';
        return aTitle.localeCompare(bTitle);
      }

      return 0;
    });
  }, [items, searchTerm, sortOrder, selectedAids]);

  // 検索入力変更ハンドラー
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // 商品選択状態変更ハンドラー
  const handleItemSelectionChange = (event: any) => {
    const value = event.target.value;
    onSelectedAidsChange(typeof value === 'string' ? value.split(',') : value);
  };

  // 選択解除ハンドラー
  const handleClearSelection = () => {
    onSelectedAidsChange([]);
  };

  // 並び順変更ハンドラー
  const handleSortOrderChange = (order: 'selected' | 'brand' | 'itemTitle') => {
    setSortOrder(order);
  };

  // 単一商品の選択/解除ハンドラー
  const handleToggleItem = (aid: string) => {
    if (selectedAids.includes(aid)) {
      onSelectedAidsChange(selectedAids.filter((id) => id !== aid));
    } else {
      onSelectedAidsChange([...selectedAids, aid]);
    }
  };

  // 検索ボックスのクリアハンドラー
  const handleClearSearch = () => {
    setSearchTerm('');
  };

  // ローディング中の表示
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  // 商品がない場合の表示
  if (!items || items.length === 0) {
    return (
      <Paper
        elevation={0}
        sx={{ p: 2, textAlign: 'center', bgcolor: '#f8f8f8' }}
      >
        <Typography variant="body2" color="text.secondary">
          商品データが読み込まれていません。プレビューを更新すると商品情報が取得されます。
        </Typography>
      </Paper>
    );
  }

  // 選択中の商品情報を表示するコンポーネント
  const renderSelectedItems = () => {
    if (selectedAids.length === 0) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="body2"
          color="primary"
          sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
        >
          <FilterIcon fontSize="small" sx={{ mr: 0.5 }} />
          以下の{selectedAids.length}件の商品が表示から除外されます
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedAids.map((aid) => {
            const item = items.find((i) => i.aid === aid);
            if (!item) return null;

            const brandName = item.brand_name || '-';
            const itemTitle = item.item_title || '不明な商品';

            return (
              <Chip
                key={aid}
                label={`${brandName} ${itemTitle}`}
                size="small"
                onDelete={() => handleToggleItem(aid)}
                sx={{
                  maxWidth: '100%',
                  '& .MuiChip-label': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  // 商品リストアイテムをレンダリングする関数
  const renderMenuItem = (item: any) => {
    const aid = item.aid || '';
    const brandName = item.brand_name || '-';
    const itemTitle = item.item_title || '不明な商品';
    const imageUrl = item.image_url || '';
    const isSelected = selectedAids.includes(aid);

    return (
      <MenuItem
        key={aid}
        value={aid}
        dense
        sx={{
          pr: 4,
          bgcolor: isSelected ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
          '&:hover': {
            bgcolor: isSelected
              ? 'rgba(25, 118, 210, 0.12)'
              : 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <Checkbox checked={isSelected} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: 0,
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {imageUrl && (
            <Box
              component="img"
              src={imageUrl}
              alt={itemTitle}
              sx={{
                width: 40,
                height: 40,
                mr: 1,
                objectFit: 'contain',
                border: '1px solid #eee',
                borderRadius: 1,
                bgcolor: 'white',
              }}
            />
          )}
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    fontWeight: isSelected ? 'bold' : 'normal',
                  }}
                >
                  {itemTitle}
                </Typography>
                <Typography variant="caption" color="text.secondary" noWrap>
                  {brandName}
                </Typography>
              </Box>
            }
            secondary={
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ opacity: 0.7 }}
              >
                ID: {aid}
              </Typography>
            }
            sx={{
              '& .MuiTypography-root': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </Box>
      </MenuItem>
    );
  };

  return (
    <Box>
      {/* 検索と並び替え */}
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="商品名/ブランド/IDで検索..."
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" color="action" />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={handleClearSearch}
                  edge="end"
                  aria-label="検索をクリア"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* 選択状態カウンター */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1,
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {filteredItems.length}件の商品中 {selectedAids.length}件選択中
        </Typography>

        {selectedAids.length > 0 && (
          <Button
            variant="text"
            color="error"
            size="small"
            onClick={handleClearSelection}
            startIcon={<ClearIcon fontSize="small" />}
          >
            選択解除
          </Button>
        )}
      </Box>

      {/* 並び替えボタン */}
      <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
        <Button
          variant={sortOrder === 'selected' ? 'contained' : 'outlined'}
          size="small"
          color="primary"
          onClick={() => handleSortOrderChange('selected')}
          sx={{ fontSize: '0.75rem', py: 0.5 }}
        >
          選択順
        </Button>
        <Button
          variant={sortOrder === 'brand' ? 'contained' : 'outlined'}
          size="small"
          color="primary"
          onClick={() => handleSortOrderChange('brand')}
          sx={{ fontSize: '0.75rem', py: 0.5 }}
        >
          ブランド順
        </Button>
        <Button
          variant={sortOrder === 'itemTitle' ? 'contained' : 'outlined'}
          size="small"
          color="primary"
          onClick={() => handleSortOrderChange('itemTitle')}
          sx={{ fontSize: '0.75rem', py: 0.5 }}
        >
          商品名順
        </Button>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* マルチセレクトボックス */}
      <FormControl fullWidth>
        <InputLabel id="multiple-item-checkbox-label">
          表示から除外する商品
        </InputLabel>
        <Select
          labelId="multiple-item-checkbox-label"
          id="multiple-item-checkbox"
          multiple
          value={selectedAids}
          onChange={handleItemSelectionChange}
          input={<OutlinedInput label="表示から除外する商品" />}
          renderValue={() => `${selectedAids.length}件の商品を選択中`}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 'auto',
              },
            },
          }}
        >
          {filteredItems.map(renderMenuItem)}
        </Select>
      </FormControl>

      {/* 選択商品の表示 */}
      {renderSelectedItems()}
    </Box>
  );
};

export default ItemSelectionPanel;
