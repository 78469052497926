import { Box, CircularProgress, Typography } from '@mui/material';
import { differenceInMonths, parseISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import {
  DailyShelfMetricsV2,
  DailySSMetricsV2,
} from '../../../services/shelf-service';
import MetricsSummaryCards from '../ix-summary-table/MetricsSummaryCards';
import OverallMetricsCharts from '../ix-summary-table/OverallMetricsCharts';
import PageTypeMetricsCharts from '../ix-summary-table/PageTypeMetricsCharts';
import TimeUnitSelector, {
  TimeUnit,
} from '../ix-summary-table/TimeUnitSelector';

interface IXSummarySectionProps {
  dailyMetrics: DailyShelfMetricsV2[];
  dailySSMetrics: DailySSMetricsV2[];
  compareDailySSMetrics: DailySSMetricsV2[];
  compareDailyMetrics: DailyShelfMetricsV2[];
  showComparison: boolean;
  shelfUnitPrice: number;
  otherUnitPrice: number;
  isLoading: boolean;
}

const IXSummarySection: React.FC<IXSummarySectionProps> = ({
  dailyMetrics,
  dailySSMetrics,
  compareDailySSMetrics,
  compareDailyMetrics,
  showComparison,
  shelfUnitPrice,
  otherUnitPrice,
  isLoading,
}) => {
  // 期間に応じたデフォルトのTimeUnitを決定
  const defaultTimeUnit = useMemo(() => {
    const allDates = [
      ...dailySSMetrics.map((d) => d.ymd),
      ...(showComparison ? compareDailySSMetrics.map((d) => d.ymd) : []),
    ];

    if (allDates.length === 0) return 'week';

    const minDate = parseISO(
      allDates.reduce((min, curr) => (curr < min ? curr : min)),
    );
    const maxDate = parseISO(
      allDates.reduce((max, curr) => (curr > max ? curr : max)),
    );

    const monthsDiff = differenceInMonths(maxDate, minDate);
    return monthsDiff >= 2 ? 'month' : 'week';
  }, [dailySSMetrics, compareDailySSMetrics, showComparison]);

  const [timeUnit, setTimeUnit] = useState<TimeUnit>(defaultTimeUnit);

  return (
    <Box
      sx={{
        mb: 6,
        bgcolor: 'background.paper',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
        minHeight: '200px',
      }}
    >
      <Box
        sx={{
          p: '8px 16px',
          bgcolor: '#1a1a1a',
          color: 'white',
          mx: '2px',
          borderRadius: '3px 3px 0 0',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', fontSize: '1.8rem' }}
        >
          InsightXのページ横断パフォーマンス
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <MetricsSummaryCards
              data={dailySSMetrics}
              compareData={compareDailySSMetrics}
              showComparison={showComparison}
              shelfUnitPrice={shelfUnitPrice}
              otherUnitPrice={otherUnitPrice}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <TimeUnitSelector value={timeUnit} onChange={setTimeUnit} />
            </Box>
            <OverallMetricsCharts
              data={dailySSMetrics}
              compareData={compareDailySSMetrics}
              showComparison={showComparison}
              timeUnit={timeUnit}
            />
            <PageTypeMetricsCharts
              data={dailyMetrics}
              compareData={compareDailyMetrics}
              showComparison={showComparison}
              timeUnit={timeUnit}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default IXSummarySection;
