import { Box, Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { differenceInDays, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { componentService } from '../../../services/component-service';
import { shelfService } from '../../../services/shelf-service';
import { useClient } from '../../../store/client.store';
import ComparisonSection from './ComparisonSection';
import IXSummarySection from './IXSummarySection';
import MetricsHeader from './MetricsHeader';
import ShelfInsightsSection from './ShelfInsightsSection';

const getDefaultUnitPrice = (client: string | null): number => {
  if (!client) return 10000;

  const clientName = client.toLowerCase();
  switch (clientName) {
    case 'can-1c558cb2-089b-4176-ae80-0df05def6848':
      return 9700;
    case 'pal-7e490282-fe4b-4260-b635-cb387b175f27':
      return 8700;
    case 'onward-a1737baa-85fc-458b-9951-0205f9c26311':
      return 15000;
    default:
      return 10000;
  }
};

const MetricsViewer: React.FC = () => {
  const { client: clientState } = useClient();
  const [startDate, setStartDate] = useState(
    format(new Date().setDate(new Date().getDate() - 31), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState(
    format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd'),
  );
  const [selectedPageType, setSelectedPageType] = useState('');
  const [shelfUnitPrice, setShelfUnitPrice] = useState<number>(
    getDefaultUnitPrice(clientState.selectedClient?.clientId),
  );
  const [otherUnitPrice, setOtherUnitPrice] = useState<number>(
    getDefaultUnitPrice(clientState.selectedClient?.clientId),
  );
  const [hasUnitPriceData, setHasUnitPriceData] = useState<boolean>(false);

  // 前期比表示の状態管理
  const [showComparison, setShowComparison] = useState(() => {
    const saved = localStorage.getItem('showComparison');
    return saved ? JSON.parse(saved) : false;
  });

  // 比較期間の状態管理
  const [compareStartDate, setCompareStartDate] = useState('');
  const [compareEndDate, setCompareEndDate] = useState('');

  // 比較期間の自動設定
  useEffect(() => {
    if (startDate && endDate) {
      const days = differenceInDays(new Date(endDate), new Date(startDate));
      const compareEnd = new Date(startDate);
      compareEnd.setDate(compareEnd.getDate() - 1);
      const compareStart = new Date(compareEnd);
      compareStart.setDate(compareStart.getDate() - days);

      setCompareStartDate(format(compareStart, 'yyyy-MM-dd'));
      setCompareEndDate(format(compareEnd, 'yyyy-MM-dd'));
    }
  }, [startDate, endDate]);

  // localStorageへの保存
  useEffect(() => {
    localStorage.setItem('showComparison', JSON.stringify(showComparison));
  }, [showComparison]);

  const { data: unitPriceData } = useQuery({
    queryKey: ['unitPrice', startDate, endDate, clientState.selectedClient],
    queryFn: () =>
      shelfService.getUnitPrice({
        start_date: startDate,
        end_date: endDate,
      }),
    staleTime: 0, // キャッシュを無効化
    gcTime: 0, // キャッシュを無効化
  });

  useEffect(() => {
    if (unitPriceData) {
      // APIからデータが取得できた場合のみ更新
      if (
        typeof unitPriceData.unit_price?.shelf_unit_price === 'number' &&
        typeof unitPriceData.unit_price?.other_unit_price === 'number'
      ) {
        const shelfPrice = Math.round(
          unitPriceData.unit_price.shelf_unit_price,
        );
        const otherPrice = Math.round(
          unitPriceData.unit_price.other_unit_price,
        );
        setShelfUnitPrice(shelfPrice);
        setOtherUnitPrice(otherPrice);
        setHasUnitPriceData(true);
      } else {
        // APIからデータが取得できない場合はデフォルト値を使用
        const defaultPrice = getDefaultUnitPrice(
          clientState.selectedClient?.clientId,
        );
        setShelfUnitPrice(defaultPrice);
        setOtherUnitPrice(defaultPrice);
        setHasUnitPriceData(false);
      }
    } else {
      // APIからデータが取得できない場合はデフォルト値を使用
      const defaultPrice = getDefaultUnitPrice(
        clientState.selectedClient?.clientId,
      );
      setShelfUnitPrice(defaultPrice);
      setOtherUnitPrice(defaultPrice);
      setHasUnitPriceData(false);
    }
  }, [clientState.selectedClient, unitPriceData, startDate, endDate]);

  const handleShelfUnitPriceChange = (newPrice: number) => {
    setShelfUnitPrice(newPrice);
    if (!hasUnitPriceData) {
      setOtherUnitPrice(newPrice);
    }
  };

  const handleOtherUnitPriceChange = (newPrice: number) => {
    setOtherUnitPrice(newPrice);
    if (!hasUnitPriceData) {
      setShelfUnitPrice(newPrice);
    }
  };

  const {
    data: dailyMetricsDataV2,
    isLoading: isLoadingDailyMetricsV2,
    error: dailyMetricsErrorV2,
  } = useQuery({
    queryKey: [
      'dailyMetricsV2',
      startDate,
      endDate,
      clientState.selectedClient,
    ],
    queryFn: () =>
      shelfService.getDailyMetricsV2({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const {
    data: dailySSMetricsDataV2,
    isLoading: isLoadingDailySSMetricsV2,
    error: dailySSMetricsErrorV2,
  } = useQuery({
    queryKey: [
      'dailySSMetricsV2',
      startDate,
      endDate,
      clientState.selectedClient,
    ],
    queryFn: () =>
      shelfService.getDailySSMetricsV2({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const {
    data: dailyMetricsDataV2Compare,
    isLoading: isLoadingDailyMetricsV2Compare,
  } = useQuery({
    queryKey: [
      'dailyMetricsV2Compare',
      compareStartDate,
      compareEndDate,
      clientState.selectedClient,
      showComparison,
    ],
    queryFn: () =>
      showComparison
        ? shelfService.getDailyMetricsV2({
            start_date: compareStartDate,
            end_date: compareEndDate,
          })
        : Promise.resolve({ metrics: [] }),
    enabled: showComparison && !!compareStartDate && !!compareEndDate,
  });

  const {
    data: dailySSMetricsDataV2Compare,
    isLoading: isLoadingDailySSMetricsV2Compare,
  } = useQuery({
    queryKey: [
      'dailySSMetricsV2Compare',
      compareStartDate,
      compareEndDate,
      clientState.selectedClient,
      showComparison,
    ],
    queryFn: () =>
      showComparison
        ? shelfService.getDailySSMetricsV2({
            start_date: compareStartDate,
            end_date: compareEndDate,
          })
        : Promise.resolve({ metrics: [] }),
    enabled: showComparison && !!compareStartDate && !!compareEndDate,
  });

  const {
    data: shelfStatsData,
    isLoading: isLoadingShelfStats,
    error: shelfStatsError,
  } = useQuery({
    queryKey: ['shelfStats', startDate, endDate, clientState.selectedClient],
    queryFn: () =>
      shelfService.getShelfStats({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const {
    data: componentDataV2,
    isLoading: isLoadingComponentV2,
    error: componentErrorV2,
  } = useQuery({
    queryKey: [
      'componentStatsV2',
      startDate,
      endDate,
      clientState.selectedClient,
    ],
    queryFn: () =>
      componentService.getComponentStatsV2({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const handlePageTypeChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setSelectedPageType(newValue);
  };

  // 各セクションのデータ取得状態を個別に管理
  const isIXSummaryLoading =
    isLoadingDailyMetricsV2 ||
    isLoadingDailySSMetricsV2 ||
    (showComparison &&
      (isLoadingDailySSMetricsV2Compare || isLoadingDailyMetricsV2Compare));
  const isComparisonLoading = isLoadingComponentV2;
  const isShelfInsightsLoading = isLoadingShelfStats;

  // エラーチェック
  if (
    componentErrorV2 ||
    dailyMetricsErrorV2 ||
    dailySSMetricsErrorV2 ||
    shelfStatsError
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <Typography color="error">
          データの読み込み中にエラーが発生しました
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ pt: 3, pb: 4 }}>
      <Box mb={4}>
        <MetricsHeader
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          compareStartDate={compareStartDate}
          compareEndDate={compareEndDate}
          onCompareStartDateChange={setCompareStartDate}
          onCompareEndDateChange={setCompareEndDate}
          showComparison={showComparison}
          onShowComparisonChange={setShowComparison}
          shelfUnitPrice={shelfUnitPrice}
          otherUnitPrice={otherUnitPrice}
          onShelfUnitPriceChange={handleShelfUnitPriceChange}
          onOtherUnitPriceChange={handleOtherUnitPriceChange}
          isActualUnitPrice={hasUnitPriceData}
        />
      </Box>

      <IXSummarySection
        dailyMetrics={dailyMetricsDataV2?.metrics ?? []}
        dailySSMetrics={dailySSMetricsDataV2?.metrics ?? []}
        compareDailySSMetrics={dailySSMetricsDataV2Compare?.metrics ?? []}
        compareDailyMetrics={dailyMetricsDataV2Compare?.metrics ?? []}
        showComparison={showComparison}
        shelfUnitPrice={shelfUnitPrice}
        otherUnitPrice={otherUnitPrice}
        isLoading={isIXSummaryLoading}
      />

      <ComparisonSection
        metrics={componentDataV2?.stats ?? []}
        selectedPageType={selectedPageType}
        onPageTypeChange={handlePageTypeChange}
        startDate={startDate}
        endDate={endDate}
        shelfUnitPrice={shelfUnitPrice}
        otherUnitPrice={otherUnitPrice}
        isLoading={isComparisonLoading}
      />

      <ShelfInsightsSection
        metrics={shelfStatsData?.stats ?? []}
        isLoading={isShelfInsightsLoading}
      />
    </Container>
  );
};

export default MetricsViewer;
