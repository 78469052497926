import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ShelfTypeEnum } from '../../../../enums/shelf-type.enum';
import { ShelfUiTypeEnum } from '../../../../enums/shelf-ui-type.enum';
import { itemSearchService } from '../../../../services/item-search-service';
import { plpService } from '../../../../services/plp-service';
import { useClient } from '../../../../store/client.store';
import { Item } from '../../../../types/item-search.type';
import {
  IPlpBlockShelfConfig,
  IShelfBlock,
} from '../../../../types/plp-builder.type';
import ShelfConfigBuilder from '../ShelfConfigBuilder';

interface PersonalizedItemShelfModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (block: IShelfBlock) => void;
  isEditMode?: boolean;
  existingBlock?: IShelfBlock;
  onUpdate?: (block: IShelfBlock) => void;
}

/**
 * パーソナライズ商品シェルフモーダルコンポーネント
 */
const PersonalizedItemShelfModal: React.FC<PersonalizedItemShelfModalProps> = ({
  open,
  onClose,
  onAdd,
  isEditMode = false,
  existingBlock,
  onUpdate,
}) => {
  const muiTheme = useTheme();
  const { client: clientState } = useClient();
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoadingExistingData, setIsLoadingExistingData] = useState(false);
  const [searchResults, setSearchResults] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const searchTimerRef = useRef<NodeJS.Timeout | null>(null);

  // デフォルトのbaseShelfOrdersを作成する関数
  const getDefaultBaseShelfOrders = () => {
    return [
      {
        shelfType: 'related',
        shelfNum: 1,
        recommendLogic: 'covis',
      },
    ];
  };

  // ShelfOrdersに確実にbaseShelfOrdersを適用する補助関数
  const ensureBaseShelfOrders = useCallback((orders) => {
    if (!orders || !Array.isArray(orders)) return [];
    return orders.map((order) => ({
      ...order,
      baseShelfOrders: order.baseShelfOrders || getDefaultBaseShelfOrders(),
    }));
  }, []);

  // ShelfConfigBuilderに渡すconfig
  const [shelfConfig, setShelfConfig] = useState<IPlpBlockShelfConfig>({
    shelfOrders: ensureBaseShelfOrders([
      // デフォルトで「こだわり3段」のシェルフオーダーを設定
      { shelfType: ShelfTypeEnum.search, shelfNum: 3, maxItems: 6 },
    ]),
    seedAids: [],
    uiType: ShelfUiTypeEnum.slider,
    columnGap: 8,
    sliderRows: 1,
  });

  // 編集モードの場合、既存のブロック設定をロード
  useEffect(() => {
    if (isEditMode && existingBlock && open) {
      try {
        setIsLoadingExistingData(true);

        // 既存の設定にbaseShelfOrdersを確実に適用
        const loadedConfig = {
          ...existingBlock.shelfConfig,
          shelfOrders: ensureBaseShelfOrders(
            existingBlock.shelfConfig.shelfOrders || [],
          ),
        };

        console.log(
          '既存ブロック読み込み（baseShelfOrders適用後）:',
          loadedConfig,
        );
        setShelfConfig(loadedConfig);

        // Seed AIDsが存在する場合、対応する商品を取得
        if (
          existingBlock.shelfConfig.seedAids &&
          existingBlock.shelfConfig.seedAids.length > 0
        ) {
          fetchItemsByAids(existingBlock.shelfConfig.seedAids);
        }
      } catch (err) {
        console.error('既存データロードエラー:', err);
        setError('既存の設定データの読み込みに失敗しました');
      } finally {
        setIsLoadingExistingData(false);
      }
    } else if (open) {
      // 新規作成時は初期化
      const defaultConfig: IPlpBlockShelfConfig = {
        shelfOrders: ensureBaseShelfOrders([
          // デフォルトで「こだわり3段」のシェルフオーダーを設定
          { shelfType: ShelfTypeEnum.search, shelfNum: 3, maxItems: 6 },
        ]),
        seedAids: [],
        uiType: ShelfUiTypeEnum.slider,
        gridColumns: 3,
        columnGap: 8,
        sliderRows: 1,
      };
      console.log('新規作成時の初期設定:', defaultConfig);
      setShelfConfig(defaultConfig);
      setSelectedItems([]);
    }
  }, [isEditMode, existingBlock, open, ensureBaseShelfOrders]);

  // コンポーネントがマウントされたときに初期データを読み込む
  useEffect(() => {
    if (open) {
      // モーダルが開かれたときに人気商品を読み込む
      loadPopularItems();

      // 編集モードでない場合かつ商品が選択されていない場合は人気商品から自動選択
      if (!isEditMode && (!selectedItems || selectedItems.length === 0)) {
        // 商品データは loadPopularItems で読み込まれる
        console.log('新規作成モードで商品自動選択準備完了');
      }
    }
  }, [open, isEditMode]);

  // 人気商品を読み込む
  const loadPopularItems = async () => {
    try {
      setIsSearching(true);
      // 人気商品を取得（sort_byパラメータを使用）
      const response = await itemSearchService.searchItems({
        sort_by: 'popularity',
        limit: 100,
      });
      setSearchResults(response.items || []);

      // 未選択の場合は最初の商品を自動的に選択する
      if (
        (!selectedItems || selectedItems.length === 0) &&
        response.items &&
        response.items.length > 0
      ) {
        const firstItem = response.items[0];
        handleItemSelect(firstItem);
      }
    } catch (error) {
      console.error('人気商品読み込みエラー:', error);
      setError('人気商品の読み込みに失敗しました');
    } finally {
      setIsSearching(false);
    }
  };

  // AIDから商品データを取得
  const fetchItemsByAids = async (aids: string[]) => {
    try {
      setIsSearching(true);
      const response = await itemSearchService.searchItemsByAids(aids);
      if (response.items && response.items.length > 0) {
        setSelectedItems(response.items);
      }
    } catch (error) {
      console.error('商品データ取得エラー:', error);
      setError('選択されていた商品の情報取得に失敗しました');
    } finally {
      setIsSearching(false);
    }
  };

  // 商品検索
  const searchItems = async (keyword: string) => {
    try {
      setIsSearching(true);

      // 検索パラメータ
      const searchParams: any = {
        limit: 100,
      };

      // キーワードがある場合は検索条件に追加
      if (keyword.trim()) {
        searchParams.item_title_keyword = keyword;
      } else {
        // キーワードがない場合は人気順でソート
        searchParams.sort_by = 'popularity';
      }

      // 商品検索実行
      const response = await itemSearchService.searchItems(searchParams);
      setSearchResults(response.items || []);

      // 検索結果がある場合、まだ選択されていなければ最初の商品を自動選択
      if (
        !keyword.trim() &&
        (!selectedItems || selectedItems.length === 0) &&
        response.items &&
        response.items.length > 0
      ) {
        const firstItem = response.items[0];
        handleItemSelect(firstItem);
      }
    } catch (error) {
      console.error('商品検索エラー:', error);
      setError('商品検索に失敗しました');
    } finally {
      setIsSearching(false);
    }
  };

  // 検索キーワード変更時の処理（デバウンス機能付き）
  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);

    // 既存のタイマーをクリア
    if (searchTimerRef.current) {
      clearTimeout(searchTimerRef.current);
    }

    // 新しいタイマーをセット（300ms後に検索実行）
    searchTimerRef.current = setTimeout(() => {
      searchItems(keyword);
    }, 300);
  };

  // コンポーネントのアンマウント時にタイマーをクリア
  useEffect(() => {
    return () => {
      if (searchTimerRef.current) {
        clearTimeout(searchTimerRef.current);
      }
    };
  }, []);

  // 商品選択時の処理
  const handleItemSelect = (item: Item | null) => {
    if (item) {
      // 選択した商品のAIDをseedAidsに設定
      const newAids = [item.aid];
      setSelectedItems([item]);

      // shelfConfigを更新（baseShelfOrdersを保持）
      setShelfConfig((prev) => ({
        ...prev,
        seedAids: newAids,
        // 既存のshelfOrdersを保持しながらbaseShelfOrdersを確保
        shelfOrders: ensureBaseShelfOrders(prev.shelfOrders || []),
      }));

      // プレビューを更新
      if (clientState.selectedClient) {
        const updatedConfig = {
          ...shelfConfig,
          seedAids: newAids,
          // プレビュー更新時にもbaseShelfOrdersを確保
          shelfOrders: ensureBaseShelfOrders(shelfConfig.shelfOrders || []),
        };
        handleUpdatePreview(updatedConfig);
      }
    }
  };

  // モーダルを閉じる
  const handleClose = () => {
    onClose();
  };

  // ShelfConfigBuilderからの設定変更を反映
  const handleShelfConfigChange = useCallback(
    (newConfig: IPlpBlockShelfConfig) => {
      // seedAidsは商品選択から設定するため、上書きしないように注意
      const updatedConfig = {
        ...newConfig,
        seedAids: shelfConfig.seedAids, // 既存のseedAidsを維持
      };

      // すべてのシェルフオーダーにbaseShelfOrdersを設定
      if (updatedConfig.shelfOrders && updatedConfig.shelfOrders.length > 0) {
        updatedConfig.shelfOrders = ensureBaseShelfOrders(
          updatedConfig.shelfOrders,
        );
      }

      setShelfConfig(updatedConfig);

      // プレビューを更新（ベース商品が選択されている場合のみ）
      if (
        clientState.selectedClient &&
        shelfConfig.seedAids &&
        shelfConfig.seedAids.length > 0
      ) {
        handleUpdatePreview(updatedConfig);
      }
    },
    [clientState.selectedClient, shelfConfig.seedAids, ensureBaseShelfOrders],
  );

  // アイテム選択セクションを囲む枠の色を決定
  const getBorderColor = () => {
    // 商品が選択されていない場合は赤枠、選択されている場合は通常の枠線
    return selectedItems.length === 0 ? 'error.main' : muiTheme.palette.divider;
  };

  // プレビューを更新
  const handleUpdatePreview = useCallback(
    async (configToPreview?: IPlpBlockShelfConfig) => {
      try {
        if (!clientState.selectedClient?.clientId) {
          console.error('クライアントが選択されていません');
          return;
        }

        setIsPreviewLoading(true);
        setError(null);

        const configForPreview = configToPreview || shelfConfig;

        // プレビュー用の設定にbaseShelfOrdersを確実に適用
        const finalConfigForPreview = {
          ...configForPreview,
          shelfOrders: ensureBaseShelfOrders(
            configForPreview.shelfOrders || [],
          ),
        };

        // PLPブロックを作成してビルド
        const plpData = {
          plpId: `plp_preview_${Date.now()}`,
          plpBlocks: [
            {
              blockType: 'shelf' as const,
              shelfConfig: finalConfigForPreview,
            },
          ],
        };

        // プレビュービルド
        await plpService.buildPlp(plpData, clientState.selectedClient.clientId);

        // プレビュー更新完了時の処理があれば追加
      } catch (error) {
        console.error('プレビュー更新エラー:', error);
        setError('プレビューの更新に失敗しました');
      } finally {
        setIsPreviewLoading(false);
      }
    },
    [shelfConfig, clientState.selectedClient, ensureBaseShelfOrders],
  );

  // ブロックを追加または更新
  const handleAddOrUpdateBlock = () => {
    if (!shelfConfig.shelfOrders || shelfConfig.shelfOrders.length === 0) {
      setError('少なくとも1つのシェルフオーダーを追加してください');
      return;
    }

    if (!shelfConfig.seedAids || shelfConfig.seedAids.length === 0) {
      setError('ベースとなる商品を選択してください');
      return;
    }

    try {
      // シェルフオーダーにbaseShelfOrdersを設定
      const finalShelfConfig = {
        ...shelfConfig,
        shelfOrders: ensureBaseShelfOrders(shelfConfig.shelfOrders),
      };

      // シェルフブロックの作成
      const shelfBlock: IShelfBlock = {
        blockType: 'shelf',
        blockName: 'パーソナライズ商品シェルフ',
        shelfConfig: finalShelfConfig,
      };

      // 編集モードの場合は更新、そうでなければ追加
      if (isEditMode && onUpdate) {
        onUpdate(shelfBlock);
      } else {
        onAdd(shelfBlock);
      }

      handleClose();
    } catch (err) {
      console.error('ブロック追加エラー:', err);
      setError('ブロックの追加に失敗しました');
    }
  };

  // シェルフビルダーのタブ設定
  const shelfBuilderTabConfig = {
    // 「シェルフオーダー」「表示設定」「スタイル設定」「タグライン設定」のタブのみ表示
    tabConfigs: [
      { id: 5, show: true }, // シェルフオーダー
      { id: 0, show: true }, // 表示設定
      { id: 1, show: true }, // スタイル設定
      { id: 3, show: true }, // タグライン設定
      { id: 2, show: false }, // フィルター設定（パーソナライズでは不要）
      { id: 4, show: false }, // 商品フィルター（パーソナライズでは不要）
    ],
    // タブの表示順序をカスタマイズ
    tabOrder: [5, 0, 3, 1], // シェルフオーダーを最初に表示
    defaultActiveTab: 5, // 表示順序の最初のタブ（シェルフオーダー）を選択
  };

  // モーダルのタイトルを取得
  const getModalTitle = () => {
    if (isEditMode) {
      return 'パーソナライズ商品シェルフの編集';
    }
    return 'パーソナライズ商品シェルフを追加';
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="personalized-item-shelf-modal-title"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          height: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: '4px 4px 0 4px',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* ヘッダー */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" id="personalized-item-shelf-modal-title">
              {getModalTitle()}
            </Typography>

            {error && (
              <Typography variant="caption" color="error" sx={{ mt: 0.5 }}>
                {error}
              </Typography>
            )}
          </Box>

          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* 商品選択セクション */}
        <Paper
          elevation={0}
          sx={{
            p: 2,
            mb: 2,
            border: `2px solid ${getBorderColor()}`,
            borderRadius: 1,
          }}
        >
          {selectedItems.length === 0 && (
            <Typography
              variant="body2"
              color="error"
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              ※ベース商品を選択してください。
            </Typography>
          )}

          <Autocomplete
            id="item-search-autocomplete"
            options={searchResults}
            getOptionLabel={(option) =>
              `${option.brand_name} - ${option.item_title}`
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}
              >
                <Avatar
                  src={option.image_url}
                  alt={option.item_title}
                  variant="rounded"
                  sx={{ width: 40, height: 40 }}
                />
                <Box>
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: 'bold', display: 'block' }}
                  >
                    {option.brand_name}
                  </Typography>
                  <Typography variant="body2" sx={{ display: 'block' }}>
                    {option.item_title}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    AID: {option.aid}
                  </Typography>
                </Box>
              </Box>
            )}
            loading={isSearching}
            loadingText="商品を検索中..."
            noOptionsText="商品が見つかりませんでした"
            onChange={(_, value) => handleItemSelect(value)}
            value={selectedItems.length > 0 ? selectedItems[0] : null}
            filterOptions={(x) => x} // カスタム検索を使用するため、フィルタリングをスキップ
            freeSolo={false} // 選択肢以外の値を許可しない
            blurOnSelect={true} // 選択時にフォーカスを外す
            openOnFocus={true} // フォーカス時に選択肢を表示
            renderInput={(params) => (
              <TextField
                {...params}
                label="商品を検索"
                placeholder="ブランド名や商品名で検索"
                variant="outlined"
                value={searchKeyword}
                onChange={(e) => {
                  // キーボード入力があるたびにハンドラーを呼び出す
                  handleSearchChange(e);
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <SearchIcon color="action" sx={{ ml: 1, mr: 0.5 }} />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {isSearching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : selectedItems.length > 0 ? (
                        <Avatar
                          src={selectedItems[0].image_url}
                          alt={selectedItems[0].item_title}
                          variant="rounded"
                          sx={{ width: 32, height: 32, mr: 1 }}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            sx={{ width: '100%' }}
          />
        </Paper>

        {/* タブパネルコンテナ */}
        <Box sx={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
          {isLoadingExistingData ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress size={40} />
              <Typography variant="body1" sx={{ ml: 2 }}>
                データを読み込み中...
              </Typography>
            </Box>
          ) : (
            <Box sx={{ height: '100%' }}>
              <ShelfConfigBuilder
                config={shelfConfig}
                onConfigChange={handleShelfConfigChange}
                clientId={clientState?.selectedClient?.clientId}
                tabConfigs={shelfBuilderTabConfig.tabConfigs}
                tabOrder={shelfBuilderTabConfig.tabOrder}
                defaultActiveTab={shelfBuilderTabConfig.defaultActiveTab}
              />
            </Box>
          )}
        </Box>

        {/* フッター部分 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 2,
            borderTop: `1px solid ${muiTheme.palette.divider}`,
            bgcolor: muiTheme.palette.background.default,
          }}
        >
          <Box sx={{ mr: 'auto', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2" color="text.secondary">
              {shelfConfig.shelfOrders?.length || 0}
              個のシェルフオーダーが設定されています
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {shelfConfig.seedAids?.length || 0}
              個のシード商品が選択されています
            </Typography>
          </Box>
          <Button onClick={handleClose} sx={{ mr: 1 }} color="inherit">
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleAddOrUpdateBlock}
            disabled={
              !shelfConfig.shelfOrders ||
              shelfConfig.shelfOrders.length === 0 ||
              !shelfConfig.seedAids ||
              shelfConfig.seedAids.length === 0 ||
              isPreviewLoading
            }
            startIcon={isEditMode ? <EditIcon /> : <AddIcon />}
          >
            {isEditMode ? 'シェルフを更新' : 'シェルフを追加'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PersonalizedItemShelfModal;
