import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { ShelfItemProps } from './types';

/**
 * シェルフアイテムコンポーネント
 */
const ShelfItem: React.FC<ShelfItemProps> = ({
  shelf,
  isSelected,
  isCompact = false,
  onToggle,
  getRelevanceColor,
}) => {
  const muiTheme = useTheme();
  const relevanceColor = getRelevanceColor(shelf.relevance_score);

  return (
    <Card
      sx={{
        mb: 1.5,
        border: isSelected
          ? `2px solid ${muiTheme.palette.primary.main}`
          : '1px solid #e0e0e0',
        boxShadow: isSelected ? 3 : 1,
        borderRadius: 1.5,
        position: 'relative',
        overflow: 'hidden',
        cursor: shelf.shelf_id ? 'pointer' : 'default',
      }}
      onClick={() => shelf.shelf_id && onToggle(shelf)}
    >
      {/* 左サイドの検索結果では関連度バーを表示、右側の選択済みでは非表示 */}
      {!isCompact && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: 5,
            bgcolor: relevanceColor,
          }}
        />
      )}
      <CardHeader
        sx={{ pt: 1.5, pb: 1, px: isCompact ? 1.5 : 2 }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={isSelected}
              onChange={(e) => {
                e.stopPropagation(); // クリックイベントの伝播を停止
                onToggle(shelf);
              }}
              disabled={!shelf.shelf_id}
              size={isCompact ? 'small' : 'medium'}
              sx={{ ml: -1, mr: 0.5 }}
            />
            <Typography
              variant={isCompact ? 'subtitle1' : 'h6'}
              sx={{
                flex: 1,
                fontWeight: isCompact ? 500 : 600,
                lineHeight: 1.4,
                fontSize: isCompact ? '0.95rem' : '1.1rem',
              }}
            >
              {shelf.tagline2}
            </Typography>
            {/* 左サイドの検索結果では関連度を表示、右側の選択済みでは非表示 */}
            {!isCompact && (
              <Chip
                label={`${(shelf.relevance_score * 100).toFixed(0)}%`}
                size="small"
                sx={{
                  backgroundColor: relevanceColor,
                  color: muiTheme.palette.getContrastText(relevanceColor),
                  fontWeight: 'bold',
                  ml: 1,
                  minWidth: 45,
                }}
              />
            )}
          </Box>
        }
        subheader={
          /* 左サイドの検索結果では理由を表示、右側の選択済みでは非表示 */
          !isCompact && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                mt: 0.5,
                fontSize: isCompact ? '0.8rem' : '0.875rem',
                fontStyle: 'italic',
                lineHeight: 1.4,
              }}
            >
              {shelf.reason}
            </Typography>
          )
        }
      />
      {(!isCompact || !isSelected) && shelf.items && shelf.items.length > 0 && (
        <>
          <Divider />
          <CardContent
            sx={{ pt: 1, pb: isCompact ? 1 : 2, px: isCompact ? 1.5 : 2 }}
            onClick={(e) => e.stopPropagation()} // ここでのクリックはカード選択に影響させない
          >
            <Box
              sx={{
                display: 'flex',
                overflowX: 'auto',
                pb: 0.5,
                '&::-webkit-scrollbar': {
                  height: 4,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: 3,
                },
              }}
            >
              {shelf.items
                .slice(0, isCompact ? 5 : 8)
                .map((item: any, idx: number) => (
                  <Box
                    key={idx}
                    sx={{
                      mr: 1,
                      minWidth: isCompact ? 70 : 90,
                      maxWidth: isCompact ? 70 : 90,
                      textAlign: 'center',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      {item.item_url ? (
                        <a
                          href={item.item_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()} // リンククリックはカード選択に影響させない
                        >
                          <img
                            src={item.image_url || '/placeholder.png'}
                            alt={item.title || item.item_title || '商品画像'}
                            style={{
                              width: '100%',
                              borderRadius: 4,
                            }}
                            loading="lazy"
                          />
                        </a>
                      ) : (
                        <img
                          src={item.image_url || '/placeholder.png'}
                          alt={item.title || item.item_title || '商品画像'}
                          style={{
                            width: '100%',
                            borderRadius: 4,
                          }}
                          loading="lazy"
                        />
                      )}
                      {item.pv_7d_rank && (
                        <Chip
                          label={`${item.pv_7d_rank}位`}
                          size="small"
                          sx={{
                            position: 'absolute',
                            bottom: 2,
                            right: 2,
                            height: 18,
                            fontSize: '0.65rem',
                            backgroundColor: 'rgba(0,0,0,0.7)',
                            color: '#fff',
                            '& .MuiChip-label': {
                              px: 0.5,
                            },
                          }}
                        />
                      )}
                    </Box>
                    {item.brand_name && (
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          fontWeight: 'bold',
                          color: muiTheme.palette.text.secondary,
                          opacity: 0.8,
                          fontSize: isCompact ? '0.6rem' : '0.65rem',
                          mt: 0.5,
                          mb: -0.3,
                        }}
                      >
                        {item.brand_name}
                      </Typography>
                    )}
                    <Typography
                      variant="caption"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: isCompact ? '0.65rem' : '0.75rem',
                        mt: item.brand_name ? 0 : 0.5,
                        minHeight: '2.6em',
                        maxHeight: '2.8em',
                        lineHeight: '1.4em',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {item.title || item.item_title || ''}
                    </Typography>
                    {item.price && (
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          fontWeight: 'bold',
                          color: muiTheme.palette.primary.main,
                          fontSize: isCompact ? '0.65rem' : '0.7rem',
                        }}
                      >
                        ¥{item.price.toLocaleString()}
                      </Typography>
                    )}
                  </Box>
                ))}
            </Box>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default ShelfItem;
