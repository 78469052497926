import api from '../api';

interface IDeployToProductionRes {
  jobId: string;
}

export const updateShelfVisibility =
  async (): Promise<IDeployToProductionRes> => {
    const res = await api.post('/shelf/update_shelf_visibility');
    return res.data;
  };
