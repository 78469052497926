import {
  AspectRatio as AspectRatioIcon,
  Code as CodeIcon,
  Palette as PaletteIcon,
  TextFields as TextFieldsIcon,
  ViewArray as ViewArrayIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  InputAdornment,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { IItemOption } from '../../../../../types/plp-builder.type';
import { StylePropertyMapping } from '../types';
import AdvancedStyleEditor from './components/AdvancedStyleEditor';
import SimpleStyleEditor from './components/SimpleStyleEditor';

// アイテムスタイルのプロパティマッピング
const itemStyleMapping: StylePropertyMapping = {
  backgroundColor: {
    type: 'color',
    label: '背景色',
    description: '商品アイテムの背景色',
  },
  padding: {
    type: 'size',
    label: '内側の余白',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: '商品アイテム内の余白',
  },
  borderRadius: {
    type: 'size',
    label: '角丸',
    min: 0,
    max: 16,
    step: 1,
    unit: 'px',
    description: '商品アイテムの角を丸くします',
  },
  boxShadow: {
    type: 'select',
    label: '影',
    options: [
      { value: 'none', label: 'なし' },
      { value: '0 2px 4px rgba(0,0,0,0.1)', label: '弱い' },
      { value: '0 4px 8px rgba(0,0,0,0.1)', label: '標準' },
      { value: '0 8px 16px rgba(0,0,0,0.1)', label: '強い' },
    ],
    description: '商品アイテムに影をつけます',
  },
  border: {
    type: 'text',
    label: '枠線',
    description: '商品アイテムの枠線（例: 1px solid #cccccc）',
  },
};

// アイテムコンテナスタイルのプロパティマッピング
const itemsStyleMapping: StylePropertyMapping = {
  gap: {
    type: 'size',
    label: '間隔',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: '商品アイテム間の間隔',
  },
  padding: {
    type: 'size',
    label: '内側の余白',
    min: 0,
    max: 24,
    step: 2,
    unit: 'px',
    description: '商品アイテムコンテナ内の余白',
  },
  backgroundColor: {
    type: 'color',
    label: '背景色',
    description: '商品アイテムコンテナの背景色',
  },
};

// ブランドスタイルのプロパティマッピング
const brandStyleMapping: StylePropertyMapping = {
  color: {
    type: 'color',
    label: '文字色',
    description: 'ブランド名の文字色',
  },
  fontSize: {
    type: 'size',
    label: 'フォントサイズ',
    min: 10,
    max: 20,
    step: 1,
    unit: 'px',
    description: 'ブランド名のフォントサイズ',
  },
  fontWeight: {
    type: 'select',
    label: '太さ',
    options: [
      { value: 'normal', label: '標準' },
      { value: 'bold', label: '太字' },
      { value: '300', label: '細め' },
      { value: '500', label: '少し太め' },
      { value: '700', label: '太め' },
    ],
    description: 'ブランド名の文字の太さ',
  },
  textAlign: {
    type: 'select',
    label: 'テキスト配置',
    options: [
      { value: 'left', label: '左揃え' },
      { value: 'center', label: '中央揃え' },
      { value: 'right', label: '右揃え' },
    ],
    description: 'ブランド名のテキスト配置',
  },
  margin: {
    type: 'size',
    label: '余白',
    min: 0,
    max: 16,
    step: 1,
    unit: 'px',
    description: 'ブランド名の外側の余白',
  },
};

// タイトルスタイルのプロパティマッピング
const titleStyleMapping: StylePropertyMapping = {
  color: {
    type: 'color',
    label: '文字色',
    description: '商品タイトルの文字色',
  },
  fontSize: {
    type: 'size',
    label: 'フォントサイズ',
    min: 10,
    max: 24,
    step: 1,
    unit: 'px',
    description: '商品タイトルのフォントサイズ',
  },
  fontWeight: {
    type: 'select',
    label: '太さ',
    options: [
      { value: 'normal', label: '標準' },
      { value: 'bold', label: '太字' },
      { value: '300', label: '細め' },
      { value: '500', label: '少し太め' },
      { value: '700', label: '太め' },
    ],
    description: '商品タイトルの文字の太さ',
  },
  textAlign: {
    type: 'select',
    label: 'テキスト配置',
    options: [
      { value: 'left', label: '左揃え' },
      { value: 'center', label: '中央揃え' },
      { value: 'right', label: '右揃え' },
    ],
    description: '商品タイトルのテキスト配置',
  },
  margin: {
    type: 'size',
    label: '余白',
    min: 0,
    max: 16,
    step: 1,
    unit: 'px',
    description: '商品タイトルの外側の余白',
  },
};

// 価格スタイルのプロパティマッピング
const priceStyleMapping: StylePropertyMapping = {
  color: {
    type: 'color',
    label: '文字色',
    description: '価格の文字色',
  },
  fontSize: {
    type: 'size',
    label: 'フォントサイズ',
    min: 10,
    max: 24,
    step: 1,
    unit: 'px',
    description: '価格のフォントサイズ',
  },
  fontWeight: {
    type: 'select',
    label: '太さ',
    options: [
      { value: 'normal', label: '標準' },
      { value: 'bold', label: '太字' },
      { value: '300', label: '細め' },
      { value: '500', label: '少し太め' },
      { value: '700', label: '太め' },
    ],
    description: '価格の文字の太さ',
  },
  textAlign: {
    type: 'select',
    label: 'テキスト配置',
    options: [
      { value: 'left', label: '左揃え' },
      { value: 'center', label: '中央揃え' },
      { value: 'right', label: '右揃え' },
    ],
    description: '価格のテキスト配置',
  },
};

// セール価格スタイルのプロパティマッピング
const salePriceStyleMapping: StylePropertyMapping = {
  color: {
    type: 'color',
    label: '文字色',
    description: 'セール価格の文字色',
  },
  fontSize: {
    type: 'size',
    label: 'フォントサイズ',
    min: 10,
    max: 24,
    step: 1,
    unit: 'px',
    description: 'セール価格のフォントサイズ',
  },
  fontWeight: {
    type: 'select',
    label: '太さ',
    options: [
      { value: 'normal', label: '標準' },
      { value: 'bold', label: '太字' },
      { value: '300', label: '細め' },
      { value: '500', label: '少し太め' },
      { value: '700', label: '太め' },
    ],
    description: 'セール価格の文字の太さ',
  },
  textAlign: {
    type: 'select',
    label: 'テキスト配置',
    options: [
      { value: 'left', label: '左揃え' },
      { value: 'center', label: '中央揃え' },
      { value: 'right', label: '右揃え' },
    ],
    description: 'セール価格のテキスト配置',
  },
};

// 画像スタイルのプロパティマッピング
const imageStyleMapping: StylePropertyMapping = {
  borderRadius: {
    type: 'size',
    label: '角丸',
    min: 0,
    max: 16,
    step: 1,
    unit: 'px',
    description: '商品画像の角を丸くします',
  },
  border: {
    type: 'text',
    label: '枠線',
    description: '商品画像の枠線（例: 1px solid #cccccc）',
  },
  objectFit: {
    type: 'select',
    label: '画像フィット',
    options: [
      { value: 'contain', label: '全体表示' },
      { value: 'cover', label: '領域を覆う' },
      { value: 'fill', label: '引き伸ばし' },
    ],
    description: '画像の表示方法',
  },
};

interface ItemStylePanelProps {
  itemOption: IItemOption;
  onItemOptionChange: (option: IItemOption) => void;
}

/**
 * アイテムスタイルパネルコンポーネント
 * 商品アイテムのスタイルを設定するUIを提供します
 */
const ItemStylePanel: React.FC<ItemStylePanelProps> = ({
  itemOption,
  onItemOptionChange,
}) => {
  // 現在のタブと編集モード
  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState<'simple' | 'advanced'>('simple');

  // 各スタイルオブジェクトの取得
  const itemStyle = itemOption.itemStyle || {};
  const itemsStyle = itemOption.itemsStyle || {};
  const brandStyle = itemOption.brandStyle || {};
  const titleStyle = itemOption.titleStyle || {};
  const priceStyle = itemOption.priceStyle || {};
  const salePriceStyle = itemOption.salePriceStyle || {};
  const imageStyle = itemOption.imageStyle || {};

  // 数値プロパティの取得（デフォルト値付き）
  const widthVw = itemOption.widthVw || 30;
  const maxWidth = itemOption.maxWidth || 300;
  const imageRatio = itemOption.imageRatio || 1;
  const titleLineClamp = itemOption.titleLineClamp || 2;
  const brandLineClamp = itemOption.brandLineClamp || 1;

  // スタイル更新ハンドラー
  const handleItemStyleChange = (newStyle: Record<string, string>) => {
    onItemOptionChange({
      ...itemOption,
      itemStyle: newStyle,
    });
  };

  const handleItemsStyleChange = (newStyle: Record<string, string>) => {
    onItemOptionChange({
      ...itemOption,
      itemsStyle: newStyle,
    });
  };

  const handleBrandStyleChange = (newStyle: Record<string, string>) => {
    onItemOptionChange({
      ...itemOption,
      brandStyle: newStyle,
    });
  };

  const handleTitleStyleChange = (newStyle: Record<string, string>) => {
    onItemOptionChange({
      ...itemOption,
      titleStyle: newStyle,
    });
  };

  const handlePriceStyleChange = (newStyle: Record<string, string>) => {
    onItemOptionChange({
      ...itemOption,
      priceStyle: newStyle,
    });
  };

  const handleSalePriceStyleChange = (newStyle: Record<string, string>) => {
    onItemOptionChange({
      ...itemOption,
      salePriceStyle: newStyle,
    });
  };

  const handleImageStyleChange = (newStyle: Record<string, string>) => {
    onItemOptionChange({
      ...itemOption,
      imageStyle: newStyle,
    });
  };

  // 数値プロパティ更新ハンドラー
  const handleWidthVwChange = (value: number) => {
    onItemOptionChange({
      ...itemOption,
      widthVw: value,
    });
  };

  const handleMaxWidthChange = (value: number) => {
    onItemOptionChange({
      ...itemOption,
      maxWidth: value,
    });
  };

  const handleImageRatioChange = (value: number) => {
    onItemOptionChange({
      ...itemOption,
      imageRatio: value,
    });
  };

  const handleTitleLineClampChange = (value: number) => {
    onItemOptionChange({
      ...itemOption,
      titleLineClamp: value,
    });
  };

  const handleBrandLineClampChange = (value: number) => {
    onItemOptionChange({
      ...itemOption,
      brandLineClamp: value,
    });
  };

  // スタイル設定セクションのマッピング
  const styleTabMapping = [
    {
      label: '基本設定',
      icon: <ViewArrayIcon fontSize="small" />,
      content: (
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle2" gutterBottom>
            サイズ設定
          </Typography>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              商品の幅 (vw単位)
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Slider
                value={widthVw}
                min={10}
                max={100}
                step={1}
                onChange={(_, value) => handleWidthVwChange(value as number)}
                sx={{ flexGrow: 1, mr: 2 }}
              />
              <TextField
                size="small"
                value={widthVw}
                type="number"
                onChange={(e) => {
                  const val = parseInt(e.target.value) || 0;
                  handleWidthVwChange(val);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">vw</InputAdornment>
                  ),
                }}
                sx={{ width: 100 }}
              />
            </Box>
            <Typography variant="caption" color="text.secondary">
              ビューポート幅に対する商品の相対的な幅です
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              商品の最大幅
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Slider
                value={maxWidth}
                min={100}
                max={500}
                step={10}
                onChange={(_, value) => handleMaxWidthChange(value as number)}
                sx={{ flexGrow: 1, mr: 2 }}
              />
              <TextField
                size="small"
                value={maxWidth}
                type="number"
                onChange={(e) => {
                  const val = parseInt(e.target.value) || 0;
                  handleMaxWidthChange(val);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">px</InputAdornment>
                  ),
                }}
                sx={{ width: 100 }}
              />
            </Box>
            <Typography variant="caption" color="text.secondary">
              商品の最大幅を制限します
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              画像の縦横比
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Slider
                value={imageRatio}
                min={0.5}
                max={2}
                step={0.1}
                onChange={(_, value) => handleImageRatioChange(value as number)}
                sx={{ flexGrow: 1, mr: 2 }}
              />
              <TextField
                size="small"
                value={imageRatio}
                type="number"
                onChange={(e) => {
                  const val = parseFloat(e.target.value) || 1;
                  handleImageRatioChange(val);
                }}
                inputProps={{ step: 0.1 }}
                sx={{ width: 100 }}
              />
            </Box>
            <Typography variant="caption" color="text.secondary">
              値が1より大きいと縦長、小さいと横長になります
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              タイトル最大行数
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Slider
                value={titleLineClamp}
                min={1}
                max={5}
                step={1}
                onChange={(_, value) =>
                  handleTitleLineClampChange(value as number)
                }
                sx={{ flexGrow: 1, mr: 2 }}
                marks
              />
              <TextField
                size="small"
                value={titleLineClamp}
                type="number"
                onChange={(e) => {
                  const val = parseInt(e.target.value) || 1;
                  handleTitleLineClampChange(val);
                }}
                sx={{ width: 80 }}
                inputProps={{ min: 1, max: 5 }}
              />
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              ブランド名最大行数
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Slider
                value={brandLineClamp}
                min={1}
                max={3}
                step={1}
                onChange={(_, value) =>
                  handleBrandLineClampChange(value as number)
                }
                sx={{ flexGrow: 1, mr: 2 }}
                marks
              />
              <TextField
                size="small"
                value={brandLineClamp}
                type="number"
                onChange={(e) => {
                  const val = parseInt(e.target.value) || 1;
                  handleBrandLineClampChange(val);
                }}
                sx={{ width: 80 }}
                inputProps={{ min: 1, max: 3 }}
              />
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      label: 'アイテム',
      icon: <AspectRatioIcon fontSize="small" />,
      content:
        editMode === 'simple' ? (
          <SimpleStyleEditor
            style={itemStyle}
            onStyleChange={handleItemStyleChange}
            propertyMapping={itemStyleMapping}
            title="商品アイテム"
          />
        ) : (
          <AdvancedStyleEditor
            style={itemStyle}
            onStyleChange={handleItemStyleChange}
            title="商品アイテムスタイル (CSS)"
          />
        ),
    },
    {
      label: 'コンテナ',
      icon: <ViewArrayIcon fontSize="small" />,
      content:
        editMode === 'simple' ? (
          <SimpleStyleEditor
            style={itemsStyle}
            onStyleChange={handleItemsStyleChange}
            propertyMapping={itemsStyleMapping}
            title="アイテムコンテナ"
          />
        ) : (
          <AdvancedStyleEditor
            style={itemsStyle}
            onStyleChange={handleItemsStyleChange}
            title="アイテムコンテナスタイル (CSS)"
          />
        ),
    },
    {
      label: 'ブランド',
      icon: <TextFieldsIcon fontSize="small" />,
      content:
        editMode === 'simple' ? (
          <SimpleStyleEditor
            style={brandStyle}
            onStyleChange={handleBrandStyleChange}
            propertyMapping={brandStyleMapping}
            title="ブランド名"
          />
        ) : (
          <AdvancedStyleEditor
            style={brandStyle}
            onStyleChange={handleBrandStyleChange}
            title="ブランド名スタイル (CSS)"
          />
        ),
    },
    {
      label: 'タイトル',
      icon: <TextFieldsIcon fontSize="small" />,
      content:
        editMode === 'simple' ? (
          <SimpleStyleEditor
            style={titleStyle}
            onStyleChange={handleTitleStyleChange}
            propertyMapping={titleStyleMapping}
            title="商品タイトル"
          />
        ) : (
          <AdvancedStyleEditor
            style={titleStyle}
            onStyleChange={handleTitleStyleChange}
            title="商品タイトルスタイル (CSS)"
          />
        ),
    },
    {
      label: '価格',
      icon: <TextFieldsIcon fontSize="small" />,
      content: (
        <Box>
          {editMode === 'simple' ? (
            <>
              <SimpleStyleEditor
                style={priceStyle}
                onStyleChange={handlePriceStyleChange}
                propertyMapping={priceStyleMapping}
                title="通常価格"
              />
              <Divider sx={{ my: 3 }} />
              <SimpleStyleEditor
                style={salePriceStyle}
                onStyleChange={handleSalePriceStyleChange}
                propertyMapping={salePriceStyleMapping}
                title="セール価格"
              />
            </>
          ) : (
            <>
              <AdvancedStyleEditor
                style={priceStyle}
                onStyleChange={handlePriceStyleChange}
                title="通常価格スタイル (CSS)"
              />
              <Divider sx={{ my: 3 }} />
              <AdvancedStyleEditor
                style={salePriceStyle}
                onStyleChange={handleSalePriceStyleChange}
                title="セール価格スタイル (CSS)"
              />
            </>
          )}
        </Box>
      ),
    },
    {
      label: '画像',
      icon: <AspectRatioIcon fontSize="small" />,
      content:
        editMode === 'simple' ? (
          <SimpleStyleEditor
            style={imageStyle}
            onStyleChange={handleImageStyleChange}
            propertyMapping={imageStyleMapping}
            title="商品画像"
          />
        ) : (
          <AdvancedStyleEditor
            style={imageStyle}
            onStyleChange={handleImageStyleChange}
            title="商品画像スタイル (CSS)"
          />
        ),
    },
  ];

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          編集モード
        </Typography>
        <Tabs
          value={editMode}
          onChange={(_, newValue) => setEditMode(newValue)}
          aria-label="スタイル編集モード"
          sx={{ mb: 2 }}
        >
          <Tab
            value="simple"
            label="簡易モード"
            icon={<PaletteIcon fontSize="small" />}
            iconPosition="start"
          />
          <Tab
            value="advanced"
            label="上級者モード"
            icon={<CodeIcon fontSize="small" />}
            iconPosition="start"
          />
        </Tabs>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          aria-label="アイテムスタイルタブ"
          variant="scrollable"
          scrollButtons="auto"
        >
          {styleTabMapping.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              icon={tab.icon}
              iconPosition="start"
              id={`item-tab-${index}`}
              aria-controls={`item-tabpanel-${index}`}
              sx={{ fontSize: '0.8rem' }}
            />
          ))}
        </Tabs>
      </Box>

      <Box px={1}>
        {styleTabMapping.map((tab, index) => (
          <div
            key={index}
            role="tabpanel"
            hidden={activeTab !== index}
            id={`item-tabpanel-${index}`}
            aria-labelledby={`item-tab-${index}`}
          >
            {activeTab === index && tab.content}
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default ItemStylePanel;
