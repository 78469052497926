import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import RestoreIcon from '@mui/icons-material/Restore';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { postShelfItemsSearch } from '../../services/shelf-builder/post-shelf-items-search';
import { postShelfTaglinesKnn } from '../../services/shelf-previewer/post-shelf-taglines-knn';
import { ShelfStatus } from '../../types/stg-search-shelf-query.type';
import ItemSlider from './ItemSlider';
import { ShelfState, SimilarShelvesState } from './ShelfBuilder';
import SimilarShelves from './SimilarShelves';
import { InputContainer, ShelfContainer } from './styles';

interface ShelfEditorProps {
  shelf: ShelfState;
  onShelfUpdate: (updatedShelf: ShelfState) => Promise<void>;
  initialSimilarShelves: SimilarShelvesState;
}

const ShelfEditorComponent = memo(
  ({
    shelf: initialShelf,
    onShelfUpdate,
    initialSimilarShelves,
  }: ShelfEditorProps): JSX.Element => {
    const [shelf, setShelf] = useState<ShelfState>(initialShelf);
    const [expanded, setExpanded] = useState(false);
    const [similarShelves, setSimilarShelves] = useState<SimilarShelvesState>(
      initialSimilarShelves,
    );
    const [isUpdating, setIsUpdating] = useState(false);
    const [isItemCountLoading, setIsItemCountLoading] = useState(false);
    const [isSimilarShelvesLoading, setIsSimilarShelvesLoading] =
      useState(false);
    const [isSimilarShelvesStale, setIsSimilarShelvesStale] = useState(false);
    const [isItemsStale, setIsItemsStale] = useState(false);
    const [similarShelvesError, setSimilarShelvesError] = useState(false);
    const fetchShelfItemsTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const fetchSimilarShelvesTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const updateShelfStateTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const debouncedUpdateShelfState = useCallback(
      (updates: Partial<ShelfState>, ignoreItems: boolean = false) => {
        if (updateShelfStateTimeoutRef.current) {
          clearTimeout(updateShelfStateTimeoutRef.current);
        }

        setIsUpdating(true);
        updateShelfStateTimeoutRef.current = setTimeout(() => {
          setShelf((prev) => {
            const newShelf = {
              ...prev,
              ...updates,
            };
            if (ignoreItems) {
              newShelf.items = null;
            }
            onShelfUpdate(newShelf);
            return newShelf;
          });
          setIsUpdating(false);
        }, 1000);
      },
      [onShelfUpdate],
    );

    const updateShelfState = useCallback(
      (
        updates: Partial<ShelfState>,
        ignoreItems: boolean = false,
        saveImmediately: boolean = false,
      ) => {
        setShelf((prev) => {
          const newShelf = {
            ...prev,
            ...updates,
          };
          if (ignoreItems) {
            newShelf.items = null;
          }
          if (saveImmediately) {
            setIsUpdating(true);
            onShelfUpdate(newShelf).finally(() => {
              setIsUpdating(false);
            });
          } else {
            debouncedUpdateShelfState(updates, ignoreItems);
          }
          return newShelf;
        });
      },
      [onShelfUpdate, debouncedUpdateShelfState],
    );

    const fetchShelfItems = useCallback(
      async (where_condition: string, ignoreItems: boolean = false) => {
        try {
          setIsUpdating(true);
          setIsItemCountLoading(true);
          const searchRes = await postShelfItemsSearch({
            where_condition,
            order_by: 'pv_count_7d desc',
            limit: 100,
          });
          const itemCount = searchRes.item_count || 0;

          const updates: Partial<ShelfState> = {
            item_count: itemCount,
            updated_at: new Date(),
          };

          if (expanded) {
            updates.items = searchRes.items;
          }

          updateShelfState(updates, ignoreItems);
          setIsItemsStale(false);
        } catch (error) {
          updateShelfState({
            item_count: -1,
            items: [],
            updated_at: new Date(),
          });
          console.error('Error fetching shelf items:', error);
        } finally {
          setIsUpdating(false);
          setIsItemCountLoading(false);
        }
      },
      [shelf, expanded, updateShelfState],
    );

    const debouncedFetchShelfItems = useCallback(
      (where_condition: string, ignoreItems: boolean = false) => {
        if (fetchShelfItemsTimeoutRef.current) {
          clearTimeout(fetchShelfItemsTimeoutRef.current);
        }

        fetchShelfItemsTimeoutRef.current = setTimeout(() => {
          fetchShelfItems(where_condition, ignoreItems);
        }, 1000);
      },
      [fetchShelfItems],
    );

    const fetchSimilarShelves = useCallback(async (tagline: string) => {
      if (!tagline) return;
      try {
        setIsSimilarShelvesLoading(true);
        setSimilarShelvesError(false);
        const knnResults = await postShelfTaglinesKnn([tagline]);
        setSimilarShelves(knnResults[tagline] || []);
      } catch (error) {
        setSimilarShelves([]);
        setSimilarShelvesError(true);
        console.error('Error fetching similar shelves:', error);
      } finally {
        setIsSimilarShelvesLoading(false);
        setIsSimilarShelvesStale(false);
      }
    }, []);

    const debouncedFetchSimilarShelves = useCallback(
      (tagline: string) => {
        if (fetchSimilarShelvesTimeoutRef.current) {
          clearTimeout(fetchSimilarShelvesTimeoutRef.current);
        }

        fetchSimilarShelvesTimeoutRef.current = setTimeout(() => {
          fetchSimilarShelves(tagline);
        }, 1000);
      },
      [fetchSimilarShelves],
    );

    const handleFieldChange = useCallback(
      async (field: keyof ShelfState, value: string) => {
        try {
          const updates = {
            [field]: value,
          };

          // where_conditionが変更された場合の処理を追加
          if (field === 'where_condition') {
            const categoryInfo = extractCategoryInfo(value);
            if (categoryInfo) {
              if (categoryInfo.category !== shelf.category) {
                updates.category = categoryInfo.category;
              }
              if (categoryInfo.sub_category !== shelf.sub_category) {
                updates.sub_category = categoryInfo.sub_category;
              }
            }
            setIsItemsStale(true);
          }

          updateShelfState(updates);

          if (field === 'tagline_2') {
            setIsSimilarShelvesStale(true);
            debouncedFetchSimilarShelves(value);
          } else if (field === 'where_condition') {
            debouncedFetchShelfItems(value);
          }
        } catch (error) {
          console.error(error);
          setIsUpdating(false);
        }
      },
      [
        shelf,
        debouncedFetchSimilarShelves,
        updateShelfState,
        debouncedFetchShelfItems,
      ],
    );

    const extractItemTitleSearchStrings = (
      where_condition: string,
    ): string[] => {
      if (!where_condition) return [];

      const matches =
        where_condition.match(/item_title (?:like|LIKE) '%([^']+)%'/g) || [];
      return matches.map(
        (match) => match.match(/item_title (?:like|LIKE) '%([^']+)%'/)[1],
      );
    };

    const extractCategoryInfo = (where_condition: string) => {
      if (!where_condition) return null;

      const categoryMatch = where_condition.match(
        /(?<!sub_)category\s*=\s*'([^']+)'/i,
      );
      const subCategoryMatch = where_condition.match(
        /sub_category\s*=\s*'([^']+)'/i,
      );

      if (categoryMatch || subCategoryMatch) {
        return {
          category: categoryMatch ? categoryMatch[1] : '',
          sub_category: subCategoryMatch ? subCategoryMatch[1] : '',
        };
      }
      return null;
    };

    const handleApplyImprovedTagline = useCallback(
      async (tagline: string) => {
        if (!tagline) return;

        setIsUpdating(true);
        try {
          const updatedShelf = {
            ...shelf,
            tagline_2: tagline,
          };

          updateShelfState(updatedShelf);
          await fetchSimilarShelves(tagline);
        } catch (error) {
          console.error(error);
        } finally {
          setIsUpdating(false);
        }
      },
      [shelf, fetchSimilarShelves, updateShelfState],
    );

    const handleToggleApprove = useCallback(async () => {
      try {
        const newStatus =
          shelf.status === ShelfStatus.APPROVED
            ? ShelfStatus.DRAFT
            : ShelfStatus.APPROVED;

        const updates = {
          status: newStatus,
        };
        updateShelfState(updates, false, true);
        setExpanded(false);
      } catch (error) {
        console.error(error);
      }
    }, [shelf, updateShelfState]);

    const handleSuspend = useCallback(async () => {
      try {
        const newStatus =
          shelf.status === ShelfStatus.SUSPENDED
            ? ShelfStatus.DRAFT
            : ShelfStatus.SUSPENDED;

        const updates = {
          status: newStatus,
        };
        updateShelfState(updates, false, true);
        setExpanded(false);
      } catch (error) {
        console.error(error);
      }
    }, [shelf, updateShelfState]);

    const handleDelete = useCallback(async () => {
      try {
        const updates = {
          status: ShelfStatus.DELETED,
        };
        updateShelfState(updates, false, true);
        setExpanded(false);
      } catch (error) {
        console.error(error);
      }
    }, [shelf, updateShelfState]);

    const handleRestore = useCallback(async () => {
      try {
        const updates = {
          status: ShelfStatus.DRAFT,
        };
        updateShelfState(updates, false, true);
      } catch (error) {
        console.error(error);
      }
    }, [shelf, updateShelfState]);

    const formatMatchResult = (result: string) => {
      if (result === 'MATCH') {
        return '類似あり';
      } else if (result === 'NOT MATCH') {
        return '類似なし';
      } else {
        return '-';
      }
    };

    // Cleanup timeout on unmount
    useEffect(() => {
      return () => {
        if (fetchShelfItemsTimeoutRef.current) {
          clearTimeout(fetchShelfItemsTimeoutRef.current);
        }
        if (fetchSimilarShelvesTimeoutRef.current) {
          clearTimeout(fetchSimilarShelvesTimeoutRef.current);
        }
        if (updateShelfStateTimeoutRef.current) {
          clearTimeout(updateShelfStateTimeoutRef.current);
        }
      };
    }, []);

    // アコーディオンが開いた時にitemsを更新する
    useEffect(() => {
      if (
        expanded &&
        !isItemCountLoading &&
        shelf.where_condition &&
        !shelf.items
      ) {
        console.log('fetchShelfItems 2');
        // 展開時は即時取得
        fetchShelfItems(shelf.where_condition);
      }
    }, [expanded, fetchShelfItems]);

    // 一覧表示時にitem_countがnullの場合はitemsを更新する
    useEffect(() => {
      if (
        shelf.item_count == null &&
        !isItemCountLoading &&
        shelf.where_condition
      ) {
        debouncedFetchShelfItems(shelf.where_condition, true);
      }
    }, [debouncedFetchShelfItems]);

    return (
      <ShelfContainer>
        <Accordion
          expanded={expanded}
          onChange={(_, isExpanded) => setExpanded(isExpanded)}
        >
          <AccordionSummary
            sx={{
              '& .MuiAccordionSummary-content': {
                margin: '0 !important',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                '& > *': {
                  flex: '0 0 auto',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  mr: 2,
                  width: '300px',
                }}
              >
                {shelf.tagline_2 || 'No tagline'}
              </Typography>
              <Box
                sx={{
                  width: '100px',
                  mr: 2,
                }}
              >
                {isUpdating ? (
                  <CircularProgress size={24} sx={{ ml: 1 }} />
                ) : (
                  <>
                    {shelf.status === ShelfStatus.DRAFT && (
                      <Chip
                        label="下書き"
                        size="small"
                        color="default"
                        icon={<EditNoteIcon />}
                        sx={{ height: '20px' }}
                      />
                    )}
                    {shelf.status === ShelfStatus.APPROVED && (
                      <Chip
                        label="承認済み"
                        size="small"
                        color="success"
                        icon={<CheckCircleIcon />}
                        sx={{ height: '20px' }}
                      />
                    )}
                    {shelf.status === ShelfStatus.DEPLOYED && (
                      <Chip
                        label="デプロイ済み"
                        size="small"
                        color="info"
                        icon={<RocketLaunchIcon />}
                        sx={{ height: '20px', ml: 0.5 }}
                      />
                    )}
                    {shelf.status === ShelfStatus.SUSPENDED && (
                      <Chip
                        label="停止中"
                        size="small"
                        color="warning"
                        icon={<PauseCircleIcon />}
                        sx={{ height: '20px', ml: 0.5 }}
                      />
                    )}
                    {shelf.status === ShelfStatus.DELETED && (
                      <Chip
                        label="削除済み"
                        size="small"
                        color="error"
                        icon={<DeleteIcon />}
                        sx={{ height: '20px', ml: 0.5 }}
                      />
                    )}
                  </>
                )}
              </Box>
              <Typography
                variant="body2"
                sx={{
                  width: '100px',
                  mr: 2,
                  textAlign: 'left',
                }}
              >
                {shelf.category || '-'}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '100px',
                  mr: 2,
                  textAlign: 'left',
                }}
              >
                {shelf.sub_category || '-'}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '100px',
                  mr: 2,
                  textAlign: 'center',
                  color:
                    shelf.tagline_2 && shelf.tagline_2.length >= 19
                      ? 'red'
                      : 'inherit',
                }}
              >
                {shelf.tagline_2 ? shelf.tagline_2.length : '-'}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '80px',
                  mr: 2,
                  textAlign: 'center',
                  color:
                    shelf.similarity_check_result === 'NOT MATCH'
                      ? 'green'
                      : shelf.similarity_check_result === 'MATCH'
                        ? 'red'
                        : 'inherit',
                }}
              >
                {formatMatchResult(shelf.similarity_check_result)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '50px',
                  mr: 2,
                  textAlign: 'center',
                  color: Number(shelf.quality_score) >= 8 ? 'green' : 'red',
                }}
              >
                {typeof shelf.quality_score === 'number'
                  ? shelf.quality_score
                  : '-'}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '80px',
                  mr: 2,
                  textAlign: 'center',
                }}
              >
                {isItemCountLoading ? (
                  <CircularProgress size={18} />
                ) : shelf.item_count === null ? (
                  '-'
                ) : shelf.item_count === -1 ? (
                  <Typography color="error">エラー</Typography>
                ) : (
                  shelf.item_count
                )}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '50px',
                  textAlign: 'center',
                  color: Number(shelf.relevance_score) >= 8 ? 'green' : 'red',
                }}
              >
                {typeof shelf.relevance_score === 'number'
                  ? shelf.relevance_score
                  : '-'}
              </Typography>
              <Box
                sx={{
                  width: '220px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                }}
              >
                <Button
                  variant="outlined"
                  color={
                    shelf.status === ShelfStatus.APPROVED ? 'error' : 'success'
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleApprove();
                  }}
                  size="small"
                  disabled={
                    shelf.status === ShelfStatus.DELETED ||
                    shelf.status === ShelfStatus.DEPLOYED ||
                    shelf.status === ShelfStatus.SUSPENDED ||
                    isUpdating
                  }
                >
                  {shelf.status === ShelfStatus.APPROVED
                    ? '承認取消'
                    : '承認する'}
                </Button>
                {shelf.status === ShelfStatus.DELETED ? (
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRestore();
                    }}
                    size="small"
                    startIcon={<RestoreIcon />}
                    disabled={isUpdating}
                  >
                    復活
                  </Button>
                ) : shelf.status === ShelfStatus.SUSPENDED ? (
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSuspend();
                    }}
                    size="small"
                    startIcon={<PlayCircleIcon />}
                    disabled={isUpdating}
                  >
                    再開
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSuspend();
                      }}
                      size="small"
                      startIcon={<PauseCircleIcon />}
                      disabled={isUpdating}
                    >
                      停止
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete();
                      }}
                      size="small"
                      startIcon={<DeleteIcon />}
                      disabled={isUpdating}
                    >
                      削除
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <InputContainer>
              <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                <TextField
                  label="タグライン"
                  value={shelf.tagline_2}
                  onChange={(e) =>
                    handleFieldChange('tagline_2', e.target.value)
                  }
                  size="small"
                  placeholder="タグラインを入力"
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="カテゴリ"
                  value={shelf.category || '-'}
                  size="small"
                  sx={{
                    width: '200px',
                    '& .MuiOutlinedInput-root': {
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                    },
                  }}
                  slotProps={{
                    input: {
                      readOnly: true,
                      sx: {
                        bgcolor: 'action.hover',
                        color: 'text.primary',
                        cursor: 'default',
                        '&:focus': {
                          bgcolor: 'action.hover',
                        },
                      },
                    },
                  }}
                />
                <TextField
                  label="サブカテゴリ"
                  value={shelf.sub_category || '-'}
                  size="small"
                  sx={{
                    width: '200px',
                    '& .MuiOutlinedInput-root': {
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                    },
                  }}
                  slotProps={{
                    input: {
                      readOnly: true,
                      sx: {
                        bgcolor: 'action.hover',
                        color: 'text.primary',
                        cursor: 'default',
                        '&:focus': {
                          bgcolor: 'action.hover',
                        },
                      },
                    },
                  }}
                />
              </Box>

              <Box sx={{ mb: 1 }}>
                <TextField
                  label="Where条件"
                  value={shelf.where_condition}
                  onChange={(e) =>
                    handleFieldChange('where_condition', e.target.value)
                  }
                  size="small"
                  multiline
                  rows={2}
                  placeholder="Where条件を入力"
                  sx={{ width: '100%' }}
                />
                {(typeof shelf.relevance_score === 'number' ||
                  shelf.relevance_reason ||
                  (shelf.improved_where_condition &&
                    shelf.improved_where_condition !== '変更なし')) && (
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        Number(shelf.relevance_score) >= 8 ? 'green' : 'red',
                      mt: 0.5,
                      ml: 2,
                    }}
                  >
                    {<div>関連度スコア: {shelf.relevance_score}</div>}
                    {shelf.relevance_reason && (
                      <div>関連度理由: {shelf.relevance_reason}</div>
                    )}
                    {shelf.improved_where_condition &&
                      shelf.improved_where_condition !== '変更なし' && (
                        <div>改善案: {shelf.improved_where_condition}</div>
                      )}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    類似タグライン
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      p: 1,
                      minHeight: '200px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: isSimilarShelvesStale ? 0.5 : 1,
                    }}
                  >
                    {isSimilarShelvesLoading ? (
                      <CircularProgress />
                    ) : similarShelvesError ? (
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography color="error" sx={{ mb: 2 }}>
                          エラーが発生しました
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setSimilarShelvesError(false);
                            fetchSimilarShelves(shelf.tagline_2);
                          }}
                          startIcon={<RestoreIcon />}
                          size="small"
                        >
                          再取得
                        </Button>
                      </Box>
                    ) : (
                      <SimilarShelves similarShelves={similarShelves} />
                    )}
                  </Box>
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    タグライン品質
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      p: 1.5,
                    }}
                  >
                    {typeof shelf.quality_score === 'number' && (
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 1,
                          fontWeight: 'bold',
                        }}
                      >
                        品質スコア:
                        {
                          <Box
                            sx={{
                              color:
                                Number(shelf.quality_score) >= 8
                                  ? 'green'
                                  : 'red',
                              display: 'inline-block',
                              ml: 1,
                            }}
                          >
                            {shelf.quality_score}
                          </Box>
                        }
                      </Typography>
                    )}
                    {shelf.quality_reason && (
                      <Typography
                        variant="body2"
                        sx={{ mb: 1, whiteSpace: 'pre-wrap' }}
                      >
                        理由: {shelf.quality_reason}
                      </Typography>
                    )}
                    {shelf.improved_taglines &&
                      shelf.improved_taglines.length > 0 && (
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="body2" sx={{ mb: 0.5 }}>
                            改善案:
                          </Typography>
                          {shelf.improved_taglines.map((tagline, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: 'action.hover',
                                p: 1,
                                borderRadius: 1,
                                mb: 1,
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ flex: 1, wordBreak: 'break-all' }}
                              >
                                {tagline}
                              </Typography>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() =>
                                  handleApplyImprovedTagline(tagline)
                                }
                                startIcon={<CheckIcon />}
                                sx={{ minWidth: 'auto' }}
                              >
                                適用
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>
                </Box>
              </Box>
            </InputContainer>

            {isItemCountLoading ? (
              <CircularProgress />
            ) : expanded && shelf.items ? (
              shelf.items.length > 0 ? (
                <Box sx={{ opacity: isItemsStale ? 0.5 : 1 }}>
                  <ItemSlider
                    items={shelf.items}
                    itemTitleSearchStrings={extractItemTitleSearchStrings(
                      shelf.where_condition,
                    )}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 4,
                  }}
                >
                  <Typography color="error">
                    アイテムが取得できませんでした
                  </Typography>
                </Box>
              )
            ) : null}
          </AccordionDetails>
        </Accordion>
      </ShelfContainer>
    );
  },
  (prevProps: ShelfEditorProps, nextProps: ShelfEditorProps): boolean => {
    return prevProps.initialSimilarShelves === nextProps.initialSimilarShelves;
  },
);

ShelfEditorComponent.displayName = 'ShelfEditor';

export default ShelfEditorComponent;
