import MonacoEditor from '@monaco-editor/react';
import { Box } from '@mui/material';
import React from 'react';

interface JsonEditorProps {
  json: string;
  onChange: (json: string) => void;
  readOnly?: boolean;
}

/**
 * JSONエディターコンポーネント
 */
const JsonEditor: React.FC<JsonEditorProps> = ({
  json,
  onChange,
  readOnly = false,
}) => {
  // エディタが変更されたときの処理
  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined && !readOnly) {
      onChange(value);
    }
  };

  return (
    <Box sx={{ height: 'calc(100% - 40px)', border: '1px solid #ddd' }}>
      <MonacoEditor
        height="100%"
        defaultLanguage="json"
        defaultValue={json}
        value={json}
        onChange={handleEditorChange}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          formatOnPaste: true,
          automaticLayout: true,
          readOnly: readOnly,
        }}
      />
    </Box>
  );
};

export default JsonEditor;
