import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { Calculator, Calendar } from 'lucide-react';
import React from 'react';
import DateRangePicker from './DateRangePicker';

interface MetricsHeaderProps {
  startDate: string;
  endDate: string;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  compareStartDate: string;
  compareEndDate: string;
  onCompareStartDateChange: (date: string) => void;
  onCompareEndDateChange: (date: string) => void;
  showComparison: boolean;
  onShowComparisonChange: (show: boolean) => void;
  shelfUnitPrice: number;
  otherUnitPrice: number;
  onShelfUnitPriceChange: (price: number) => void;
  onOtherUnitPriceChange: (price: number) => void;
  isActualUnitPrice?: boolean;
}

const MetricsHeader: React.FC<MetricsHeaderProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  compareStartDate,
  compareEndDate,
  onCompareStartDateChange,
  onCompareEndDateChange,
  showComparison,
  onShowComparisonChange,
  shelfUnitPrice,
  otherUnitPrice,
  onShelfUnitPriceChange,
  onOtherUnitPriceChange,
  isActualUnitPrice,
}) => {
  const handleShelfUnitPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = parseInt(e.target.value.replace(/,/g, '')) || 0;
    onShelfUnitPriceChange(value);
  };

  const handleOtherUnitPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = parseInt(e.target.value.replace(/,/g, '')) || 0;
    onOtherUnitPriceChange(value);
  };

  const formatDate = (date: string) => {
    return format(new Date(date), 'yyyy/MM/dd', { locale: ja });
  };

  const formatNumber = (num: number) => {
    return num.toLocaleString();
  };

  const renderUnitPriceInputs = () => {
    if (!isActualUnitPrice) {
      return (
        <Box sx={{ flex: 1 }}>
          <Tooltip title="デフォルトのCV単価です" arrow>
            <Box sx={{ position: 'relative' }}>
              <OutlinedInput
                fullWidth
                placeholder="CV単価"
                value={formatNumber(shelfUnitPrice)}
                onChange={handleShelfUnitPriceChange}
                startAdornment={
                  <InputAdornment position="start">
                    <Calculator size={20} />
                  </InputAdornment>
                }
              />
            </Box>
          </Tooltip>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ flex: 1 }}>
          <Tooltip
            title={`${formatDate(startDate)}から${formatDate(endDate)}の期間のシェルフ経由CVの平均単価（実績値）です`}
            arrow
          >
            <Box sx={{ position: 'relative' }}>
              <OutlinedInput
                fullWidth
                placeholder="シェルフ経由CV単価"
                value={formatNumber(shelfUnitPrice)}
                onChange={handleShelfUnitPriceChange}
                startAdornment={
                  <InputAdornment position="start">
                    <Calculator size={20} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: '#4CAF50',
                          fontWeight: 'bold',
                        }}
                      >
                        実績値
                      </Typography>
                    </Box>
                  </InputAdornment>
                }
                sx={{
                  backgroundColor: 'rgba(76, 175, 80, 0.05)',
                }}
              />
            </Box>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{ color: '#666666', mt: 0.5, display: 'block' }}
          >
            シェルフ経由CV単価
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Tooltip
            title={`${formatDate(startDate)}から${formatDate(endDate)}の期間のその他CVの平均単価（実績値）です`}
            arrow
          >
            <Box sx={{ position: 'relative' }}>
              <OutlinedInput
                fullWidth
                placeholder="シェルフを経由していないCV単価"
                value={formatNumber(otherUnitPrice)}
                onChange={handleOtherUnitPriceChange}
                startAdornment={
                  <InputAdornment position="start">
                    <Calculator size={20} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: '#4CAF50',
                          fontWeight: 'bold',
                        }}
                      >
                        実績値
                      </Typography>
                    </Box>
                  </InputAdornment>
                }
                sx={{
                  backgroundColor: 'rgba(76, 175, 80, 0.05)',
                }}
              />
            </Box>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{ color: '#666666', mt: 0.5, display: 'block' }}
          >
            シェルフを経由していないCV単価
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Grid container spacing={3} alignItems="center" sx={{ mb: 3 }}>
      <Grid item xs={12} md={4}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <img
            src="https://storage.googleapis.com/insightx-images/dashboard/public/assets/insightx_logo_black.png"
            alt="InsightX Logo"
            style={{ height: '80px', width: 'auto' }}
          />
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#1a1a1a',
              fontSize: '50px',
              lineHeight: 1,
              fontFamily: 'Helvetica Neue, Arial, sans-serif',
            }}
          >
            InsightX
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={12} md={8} spacing={3}>
        <Grid item xs={12} md={6}>
          <Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}
            >
              <Calendar size={24} color="#666666" />
              <Typography
                sx={{
                  color: '#666666',
                  fontSize: '1.25rem',
                  fontWeight: 600,
                }}
              >
                集計期間
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showComparison}
                    onChange={(e) => onShowComparisonChange(e.target.checked)}
                    size="small"
                  />
                }
                label="比較期間を表示"
                sx={{ ml: 2 }}
              />
            </Box>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={onStartDateChange}
              onEndDateChange={onEndDateChange}
            />
            {showComparison && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    color: '#666666',
                    fontSize: '1rem',
                    fontWeight: 600,
                    mb: 0.5,
                  }}
                >
                  比較期間
                </Typography>
                <DateRangePicker
                  startDate={compareStartDate}
                  endDate={compareEndDate}
                  onStartDateChange={onCompareStartDateChange}
                  onEndDateChange={onCompareEndDateChange}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}
            >
              <Typography
                sx={{
                  color: '#666666',
                  fontSize: '1.25rem',
                  fontWeight: 600,
                }}
              >
                ¥ CVセッション単価
              </Typography>
              {isActualUnitPrice && (
                <Chip
                  label={`${formatDate(startDate)}〜${formatDate(endDate)}の実績値`}
                  size="small"
                  sx={{
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                  }}
                />
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {renderUnitPriceInputs()}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MetricsHeader;
