import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  FilterList as FilterListIcon,
  AutoFixHigh as MagicWandIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ShelfTypeEnum } from '../../../../enums/shelf-type.enum';
import { plpService } from '../../../../services/plp-service';
import { shelfSearchService } from '../../../../services/shelf-search-service';
import { useClient } from '../../../../store/client.store';
import {
  IItemOption,
  IPlp,
  IPlpBlockShelfConfig,
  IShelfBlock,
} from '../../../../types/plp-builder.type';
import { IShelfWithRelevance } from '../../../../types/shelf-search.type';
import ShelfConfigBuilder from '../../components/ShelfConfigBuilder';
import FilterPanel from './FilterPanel';
import SelectedShelves from './SelectedShelves';
import ShelfSearchResults from './ShelfSearchResults';
import {
  FilterSettings,
  ShelfSearchModalProps,
  TaglineOverride,
} from './types';

/**
 * タブパネルコンポーネント
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`shelf-search-tabpanel-${index}`}
      aria-labelledby={`shelf-search-tab-${index}`}
      {...other}
      style={{
        height: '100%',
        display: value === index ? 'flex' : 'none',
        flexDirection: 'column',
      }}
    >
      {value === index && children}
    </div>
  );
};

/**
 * シェルフ検索モーダルコンポーネント
 */
const ShelfSearchModal: React.FC<ShelfSearchModalProps> = ({
  open,
  onClose,
  onAdd,
  isEditMode = false,
  existingBlock,
  onUpdate,
}) => {
  const muiTheme = useTheme();
  const { client: clientState } = useClient();
  const [searchTheme, setSearchTheme] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<IShelfWithRelevance[]>([]);
  const [selectedShelves, setSelectedShelves] = useState<IShelfWithRelevance[]>(
    [],
  );
  const [error, setError] = useState<string | null>(null);
  const [maxItems, setMaxItems] = useState<number>(6);
  const [uiType, setUiType] = useState<'slider' | 'grid'>('grid');
  const [gridColumns, setGridColumns] = useState<number>(3);
  const [columnGap, setColumnGap] = useState<number>(8);
  const [sliderRows, setSliderRows] = useState<number>(1);
  const [isLoadingExistingData, setIsLoadingExistingData] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  // フィルター対象商品のAID一覧
  const [filteredAids, setFilteredAids] = useState<string[]>([]);
  // タグラインオーバーライド設定
  const [shelfOverrides, setShelfOverrides] = useState<TaglineOverride[]>([]);

  // フィルター設定
  const [filterSettings, setFilterSettings] = useState<FilterSettings>({
    brandNames: [],
    categories: [],
    subCategories: [],
    itemGenders: [],
    filterItems: true,
  });

  // フィルターパネル表示フラグ
  const [showFilterPanel, setShowFilterPanel] = useState(true);

  // フィルターのアクティブ状態
  const hasActiveFilters =
    filterSettings.brandNames.length > 0 ||
    filterSettings.categories.length > 0 ||
    filterSettings.subCategories.length > 0 ||
    filterSettings.itemGenders.length > 0 ||
    !filterSettings.filterItems;

  // スタイル設定
  const [itemOption, setItemOption] = useState<IItemOption>({
    widthVw: 30,
    maxWidth: 300,
    imageRatio: 1,
    titleLineClamp: 2,
    brandLineClamp: 1,
    itemStyle: {},
    itemsStyle: {},
    brandStyle: {},
    titleStyle: {},
    priceStyle: {},
    salePriceStyle: {},
    imageStyle: {},
  });

  // 重要: 状態変更を追跡するためのレンダリングカウンター
  const [updateCount, setUpdateCount] = useState<number>(0);

  // 自動プレビュー更新を無効化するフラグ
  const [disableAutoPreview, setDisableAutoPreview] = useState<boolean>(false);

  // プレビュー更新を強制的に停止するための参照
  const shouldStopPreviewRef = useRef<boolean>(false);

  // プレビューが現在実行中かどうかのフラグ
  const [isPreviewUpdating, setIsPreviewUpdating] = useState<boolean>(false);

  // 短時間での更新回数をカウント
  const updateFrequencyCounterRef = useRef<number>(0);
  const lastUpdateTimeRef = useRef<number>(Date.now());

  // タブ切り替えの初回フラグ
  const initialTabSwitchRef = useRef<boolean>(true);

  // ShelfConfigBuilder用のconfig生成
  const createShelfConfig = useCallback((): IPlpBlockShelfConfig => {
    return {
      shelfOrders: [
        {
          shelfType: ShelfTypeEnum.search,
          shelfNum: selectedShelves.length,
          shelfIds: selectedShelves.map((s) => s.shelf_id as string),
          maxItems,
        },
      ],
      uiType,
      gridColumns: uiType === 'grid' ? gridColumns : undefined,
      columnGap: uiType === 'grid' ? columnGap : undefined,
      sliderRows: uiType === 'slider' ? sliderRows : undefined,
      shelfOverrides: shelfOverrides.length > 0 ? shelfOverrides : undefined,
      brandNames:
        filterSettings.brandNames.length > 0
          ? filterSettings.brandNames
          : undefined,
      categories:
        filterSettings.categories.length > 0
          ? filterSettings.categories
          : undefined,
      subCategories:
        filterSettings.subCategories.length > 0
          ? filterSettings.subCategories
          : undefined,
      filteredAids: filteredAids.length > 0 ? filteredAids : undefined,
      itemOption: Object.keys(itemOption).length > 0 ? itemOption : undefined,
      shelves: selectedShelves.map((shelf) => ({
        shelf_id: shelf.shelf_id,
        tagline1: '',
        tagline2: shelf.tagline2 || '',
        items: shelf.items || [],
      })),
    };
  }, [
    selectedShelves,
    maxItems,
    uiType,
    gridColumns,
    columnGap,
    sliderRows,
    shelfOverrides,
    filterSettings,
    filteredAids,
    itemOption,
  ]);

  // プレビューの更新（単純化・完全な手動実行版）
  const updatePreview = useCallback(async () => {
    // 既に更新中またはプレビュー無効化中は何もしない
    if (isPreviewUpdating || shouldStopPreviewRef.current) {
      return;
    }

    // 保護条件: クライアントIDまたは選択されたシェルフがない場合は更新しない
    if (!clientState.selectedClient?.clientId || selectedShelves.length === 0) {
      console.log('プレビュー更新スキップ: クライアントIDかシェルフがない');
      return;
    }

    // 保護条件: モーダルが開いていない、または設定タブが表示されていない場合は更新しない
    if (!open || activeTab !== 1) {
      console.log('プレビュー更新スキップ: モーダル非表示か設定タブ以外');
      return;
    }

    // 更新頻度制限
    const now = Date.now();
    if (now - lastUpdateTimeRef.current < 2000) {
      // 2秒以内の更新
      updateFrequencyCounterRef.current++;
      if (updateFrequencyCounterRef.current > 5) {
        // 2秒間に5回以上の更新は異常と判断して停止
        console.log('プレビュー更新を一時停止: 短時間での過剰な更新');
        shouldStopPreviewRef.current = true;
        setIsPreviewUpdating(false);
        setTimeout(() => {
          shouldStopPreviewRef.current = false;
          updateFrequencyCounterRef.current = 0;
        }, 5000); // 5秒後に再開
        return;
      }
    } else {
      // 2秒以上経過していたらカウンターリセット
      updateFrequencyCounterRef.current = 0;
    }
    lastUpdateTimeRef.current = now;

    try {
      // 更新中フラグをセット
      setIsPreviewUpdating(true);
      console.log('プレビュー更新開始:', new Date().toISOString());

      // 現在の選択されたシェルフから一時的なシェルフブロックを作成
      const tempShelfBlock: IShelfBlock = {
        blockType: 'shelf',
        shelfConfig: {
          shelfOrders: [
            {
              shelfType: ShelfTypeEnum.search,
              shelfNum: selectedShelves.length,
              shelfIds: selectedShelves.map((s) => s.shelf_id as string),
              maxItems: maxItems,
            },
          ],
          uiType: uiType,
          gridColumns: uiType === 'grid' ? gridColumns : undefined,
          columnGap: uiType === 'grid' ? columnGap : undefined,
          sliderRows: uiType === 'slider' ? sliderRows : undefined,
          // タグラインオーバーライド設定を追加
          shelfOverrides:
            shelfOverrides.length > 0 ? shelfOverrides : undefined,
          // フィルター設定を追加
          brandNames:
            filterSettings.brandNames.length > 0
              ? filterSettings.brandNames
              : undefined,
          categories:
            filterSettings.categories.length > 0
              ? filterSettings.categories
              : undefined,
          subCategories:
            filterSettings.subCategories.length > 0
              ? filterSettings.subCategories
              : undefined,
          filteredAids: filteredAids.length > 0 ? filteredAids : undefined,
          // アイテムスタイル設定を追加
          itemOption:
            Object.keys(itemOption).length > 0 ? itemOption : undefined,
        },
      };

      // 途中でコンポーネントがアンマウントされたか確認
      if (shouldStopPreviewRef.current) {
        console.log('プレビュー更新キャンセル: コンポーネント状態変更');
        return;
      }

      // このシェルフブロックだけを含むPLPデータを作成
      const tempPlp: IPlp = {
        plpId: `preview_${Date.now()}`,
        plpBlocks: [tempShelfBlock],
      };

      // プレビュー用にPLPビルドAPIを呼び出し
      await plpService.buildPlp(tempPlp, clientState.selectedClient.clientId);

      console.log('プレビュー更新成功');
    } catch (error) {
      console.error('プレビュー生成エラー:', error);
    } finally {
      // 更新完了
      if (!shouldStopPreviewRef.current) {
        setIsPreviewUpdating(false);
      }
    }
  }, [
    selectedShelves,
    maxItems,
    uiType,
    gridColumns,
    columnGap,
    sliderRows,
    shelfOverrides,
    filterSettings,
    filteredAids,
    itemOption,
    clientState.selectedClient,
    open,
    activeTab,
    isPreviewUpdating,
  ]);

  // コンポーネントのマウント/アンマウント時の処理
  useEffect(() => {
    console.log('シェルフ検索モーダルマウント');
    shouldStopPreviewRef.current = false;
    updateFrequencyCounterRef.current = 0;
    lastUpdateTimeRef.current = Date.now();
    initialTabSwitchRef.current = true;

    // クリーンアップ関数
    return () => {
      console.log('シェルフ検索モーダルアンマウント');
      shouldStopPreviewRef.current = true;
      setIsPreviewUpdating(false);
    };
  }, []);

  // モーダルの開閉状態の変化を監視
  useEffect(() => {
    if (!open) {
      console.log('モーダルが閉じられました - プレビュー更新を停止');
      shouldStopPreviewRef.current = true;
      setIsPreviewUpdating(false);
      initialTabSwitchRef.current = true;
    } else {
      console.log('モーダルが開かれました');
      shouldStopPreviewRef.current = false;
      updateFrequencyCounterRef.current = 0;
    }
  }, [open]);

  // タブ切り替え時の処理 - ShelfConfigBuilder初期化用
  const [tabSwitchTrigger, setTabSwitchTrigger] = useState<number>(0);

  useEffect(() => {
    // タブ切り替え時、設定タブへの変更のみを処理
    if (activeTab === 1 && selectedShelves.length > 0 && open) {
      if (initialTabSwitchRef.current) {
        // 初回タブ切り替え時のみフラグを設定し、updateCountは触らない
        console.log('初回設定タブ切り替え - カウンター設定のみ');
        initialTabSwitchRef.current = false;
        setTabSwitchTrigger((prev) => prev + 1);
      }
    } else if (activeTab !== 1) {
      // 設定タブから離れた場合はフラグをリセット
      initialTabSwitchRef.current = true;
    }
  }, [activeTab, selectedShelves.length, open]);

  // タブ切り替え後の初期プレビュー更新（分離して単独で制御）
  useEffect(() => {
    if (
      tabSwitchTrigger > 0 &&
      activeTab === 1 &&
      selectedShelves.length > 0 &&
      open &&
      !disableAutoPreview
    ) {
      console.log('タブ切り替え後の単独プレビュー更新');
      const timer = setTimeout(() => {
        if (!shouldStopPreviewRef.current && !isPreviewUpdating) {
          updatePreview();
        }
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [
    tabSwitchTrigger,
    activeTab,
    selectedShelves.length,
    open,
    disableAutoPreview,
    isPreviewUpdating,
    updatePreview,
  ]);

  // updateCountの変更を監視し、必要に応じてプレビューを更新（設定変更用）
  useEffect(() => {
    if (
      updateCount > 0 &&
      open &&
      activeTab === 1 &&
      selectedShelves.length > 0 &&
      !disableAutoPreview &&
      !initialTabSwitchRef.current // 初回タブ切り替え時は処理しない
    ) {
      console.log(`設定変更によるプレビュー更新: updateCount=${updateCount}`);
      const timer = setTimeout(() => {
        if (!shouldStopPreviewRef.current && !isPreviewUpdating) {
          updatePreview();
        }
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [
    updateCount,
    open,
    activeTab,
    selectedShelves.length,
    disableAutoPreview,
    isPreviewUpdating,
    updatePreview,
  ]);

  // ShelfConfigBuilderからのconfig変更を処理
  const handleShelfConfigChange = useCallback(
    (newConfig: IPlpBlockShelfConfig) => {
      // ShelfConfigBuilderからの変更が無限ループの原因になっている場合は停止
      if (updateFrequencyCounterRef.current > 3) {
        console.log('ShelfConfigBuilder変更を一時停止: 頻度制限');
        return;
      }

      // 一時的に自動プレビュー更新を無効化
      setDisableAutoPreview(true);

      // UI表示タイプを更新
      if (newConfig.uiType) {
        setUiType(newConfig.uiType as 'slider' | 'grid');
      }

      // 最大表示数を更新
      if (newConfig.shelfOrders && newConfig.shelfOrders[0]?.maxItems) {
        setMaxItems(newConfig.shelfOrders[0].maxItems);
      }

      // グリッド列数を更新
      if (newConfig.gridColumns) {
        setGridColumns(newConfig.gridColumns);
      }

      // 列間隔を更新
      if (newConfig.columnGap) {
        setColumnGap(newConfig.columnGap);
      }

      // スライダー行数を更新
      if (newConfig.sliderRows) {
        setSliderRows(newConfig.sliderRows);
      }

      // フィルター設定を更新
      setFilterSettings((prev) => ({
        ...prev,
        brandNames: newConfig.brandNames || [],
        categories: newConfig.categories || [],
        subCategories: newConfig.subCategories || [],
      }));

      // 商品フィルターを更新
      if (newConfig.filteredAids !== undefined) {
        setFilteredAids(newConfig.filteredAids);
      }

      // タグラインオーバーライドを更新
      if (newConfig.shelfOverrides !== undefined) {
        setShelfOverrides(newConfig.shelfOverrides);
      }

      // アイテムスタイル設定を更新
      if (newConfig.itemOption !== undefined) {
        setItemOption(newConfig.itemOption);
      }

      // 設定変更後、一定時間後に自動プレビュー更新を再有効化して一度だけ更新
      setTimeout(() => {
        if (
          activeTab === 1 &&
          open &&
          !shouldStopPreviewRef.current &&
          !initialTabSwitchRef.current
        ) {
          console.log('設定変更後のプレビュー更新');
          setDisableAutoPreview(false);
          setUpdateCount((prev) => prev + 1);
        } else {
          setDisableAutoPreview(false);
        }
      }, 800);
    },
    [activeTab, open],
  );

  // フィルターの切り替え
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // タブ変更前にフラグを設定
    if (newValue === 1 && activeTab !== 1) {
      initialTabSwitchRef.current = true;
    }
    setActiveTab(newValue);
  };

  // モーダルクローズ時の処理
  const handleClose = () => {
    // まずプレビュー更新を停止
    console.log('モーダルクローズ - プレビュー更新を停止');
    shouldStopPreviewRef.current = true;
    setIsPreviewUpdating(false);

    // 状態をリセット
    setUpdateCount(0);
    setDisableAutoPreview(false);
    setSearchTheme('');
    setSearchResults([]);
    setSelectedShelves([]);
    setError(null);
    setMaxItems(6);
    setUiType('grid');
    setGridColumns(3);
    setColumnGap(8);
    setSliderRows(1);
    resetFilters();
    setFilteredAids([]);
    setActiveTab(0);
    setShelfOverrides([]);
    // アイテムスタイル設定をリセット
    setItemOption({
      widthVw: 30,
      maxWidth: 300,
      imageRatio: 1,
      titleLineClamp: 2,
      brandLineClamp: 1,
      itemStyle: {},
      itemsStyle: {},
      brandStyle: {},
      titleStyle: {},
      priceStyle: {},
      salePriceStyle: {},
      imageStyle: {},
    });
    onClose();
  };

  // フィルター設定の更新
  const updateFilterSettings = (newSettings: Partial<FilterSettings>) => {
    setFilterSettings((prev) => ({ ...prev, ...newSettings }));
  };

  // フィルターのリセット
  const resetFilters = () => {
    setFilterSettings({
      brandNames: [],
      categories: [],
      subCategories: [],
      itemGenders: [],
      filterItems: true,
    });
    setFilteredAids([]);
  };

  // 関連度に基づく色を取得
  const getRelevanceColor = (score: number) => {
    // スコアに基づいて色の濃さを調整（0.6〜1.0の範囲）
    if (score >= 0.9) return muiTheme.palette.success.main;
    if (score >= 0.8) return muiTheme.palette.success.light;
    if (score >= 0.7) return muiTheme.palette.info.main;
    if (score >= 0.6) return muiTheme.palette.info.light;
    if (score >= 0.5) return muiTheme.palette.warning.main;
    return muiTheme.palette.warning.light;
  };

  // モーダルを開いた時に初期シェルフをロード
  useEffect(() => {
    if (
      open &&
      !isEditMode &&
      !isInitialLoading && // 既にロード中でない
      searchResults.length === 0 &&
      !hasActiveFilters &&
      !searchTheme.trim()
    ) {
      loadInitialShelves();
    }
  }, [
    open,
    isEditMode,
    isInitialLoading,
    searchResults.length,
    hasActiveFilters,
    searchTheme,
  ]);

  // 初期シェルフのロード処理
  const loadInitialShelves = async () => {
    setIsInitialLoading(true);
    try {
      // 初期表示シェルフをロード（デフォルトでは人気順になると想定）
      const response = await shelfSearchService.getShelfDetails({
        page: 1,
        limit: 30,
        filter_items: true,
      });
      setSearchResults(response.shelves);
    } catch (err) {
      console.error('初期シェルフロードエラー:', err);
      // 初期ロード失敗は表示しない（ユーザーに混乱を与えないため）
    } finally {
      setIsInitialLoading(false);
    }
  };

  // 既存のシェルフデータをロード（編集モード時）
  useEffect(() => {
    if (isEditMode && existingBlock && open) {
      loadExistingShelfData();
    }
  }, [isEditMode, existingBlock, open]);

  // 既存のシェルフデータをロードする
  const loadExistingShelfData = async () => {
    if (!existingBlock) return;

    try {
      setIsLoadingExistingData(true);
      setError(null);

      // 既存の設定を取得
      const shelfConfig = existingBlock.shelfConfig;
      const shelfOrder = shelfConfig.shelfOrders[0]; // 最初のシェルフオーダーを取得

      // 設定を引き継ぐ
      setMaxItems(shelfOrder.maxItems || 6);
      setUiType((shelfConfig.uiType as 'slider' | 'grid') || 'grid');
      setGridColumns(shelfConfig.gridColumns || 3);
      setColumnGap(shelfConfig.columnGap || 8);
      setSliderRows(shelfConfig.sliderRows || 1);

      // アイテムスタイル設定を引き継ぐ
      if (shelfConfig.itemOption) {
        setItemOption(shelfConfig.itemOption);
      }

      // タグラインオーバーライド設定を引き継ぐ
      if (shelfConfig.shelfOverrides?.length) {
        setShelfOverrides(shelfConfig.shelfOverrides);
      }

      // フィルター設定を引き継ぐ
      if (shelfConfig.brandNames?.length) {
        setFilterSettings((prev) => ({
          ...prev,
          brandNames: shelfConfig.brandNames,
        }));
      }
      if (shelfConfig.categories?.length) {
        setFilterSettings((prev) => ({
          ...prev,
          categories: shelfConfig.categories,
        }));
      }
      if (shelfConfig.subCategories?.length) {
        setFilterSettings((prev) => ({
          ...prev,
          subCategories: shelfConfig.subCategories,
        }));
      }
      if (shelfConfig.filteredAids?.length) {
        setFilteredAids(shelfConfig.filteredAids);
      }

      // シェルフIDを取得
      const shelfIds = shelfOrder.shelfIds || [];

      if (shelfIds.length > 0) {
        // APIから詳細情報を取得
        const response =
          await shelfSearchService.getShelfDetailsByIds(shelfIds);
        setSelectedShelves(response.shelves);
      }
    } catch (err) {
      console.error('既存シェルフデータのロードエラー:', err);
      setError('既存シェルフデータの取得に失敗しました');
    } finally {
      setIsLoadingExistingData(false);
    }
  };

  // テーマ入力ハンドラー
  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTheme(event.target.value);
  };

  // シェルフ検索実行
  const handleSearch = useCallback(async () => {
    if (!searchTheme.trim() && !hasActiveFilters) {
      setError('検索テーマを入力するか、フィルターを設定してください');
      return;
    }

    setError(null);
    setIsSearching(true);
    setSearchResults([]);

    try {
      if (searchTheme.trim()) {
        // LLM検索（テーマあり）
        const response = await shelfSearchService.searchShelves(searchTheme);

        if (hasActiveFilters) {
          // フィルターも設定されている場合は、LLM検索結果をフィルタリング
          const filteredShelfIds = response.shelves.map(
            (shelf) => shelf.shelf_id as string,
          );
          const detailResponse = await shelfSearchService.getShelfDetails({
            shelf_ids: filteredShelfIds,
            brand_names:
              filterSettings.brandNames.length > 0
                ? filterSettings.brandNames
                : undefined,
            categories:
              filterSettings.categories.length > 0
                ? filterSettings.categories
                : undefined,
            sub_categories:
              filterSettings.subCategories.length > 0
                ? filterSettings.subCategories
                : undefined,
            item_genders:
              filterSettings.itemGenders.length > 0
                ? filterSettings.itemGenders
                : undefined,
            filter_items: filterSettings.filterItems,
          });

          // LLM検索結果の順序を維持するためのマッピング
          const resultMap = new Map<string, IShelfWithRelevance>();
          detailResponse.shelves.forEach((shelf) => {
            if (shelf.shelf_id) {
              resultMap.set(shelf.shelf_id, shelf);
            }
          });

          // LLM検索結果の順序を維持しつつフィルタリング
          const filteredResults = response.shelves
            .filter((shelf) => shelf.shelf_id && resultMap.has(shelf.shelf_id))
            .map((shelf) => {
              const filteredShelf = resultMap.get(shelf.shelf_id as string);
              // 関連度情報を保持
              if (filteredShelf) {
                filteredShelf.relevance_score = shelf.relevance_score;
                filteredShelf.reason = shelf.reason;
                return filteredShelf;
              }
              return shelf;
            });

          setSearchResults(filteredResults);
        } else {
          // フィルターなしの場合はLLM検索結果をそのまま表示
          setSearchResults(response.shelves);
        }
      } else {
        // メタデータによるフィルタリング検索（テーマなし）
        const response = await shelfSearchService.getShelfDetails({
          brand_names:
            filterSettings.brandNames.length > 0
              ? filterSettings.brandNames
              : undefined,
          categories:
            filterSettings.categories.length > 0
              ? filterSettings.categories
              : undefined,
          sub_categories:
            filterSettings.subCategories.length > 0
              ? filterSettings.subCategories
              : undefined,
          item_genders:
            filterSettings.itemGenders.length > 0
              ? filterSettings.itemGenders
              : undefined,
          filter_items: filterSettings.filterItems,
        });
        setSearchResults(response.shelves);
      }
    } catch (err) {
      console.error('シェルフ検索エラー:', err);
      setError('検索中にエラーが発生しました。再試行してください。');
    } finally {
      setIsSearching(false);
    }
  }, [searchTheme, filterSettings, hasActiveFilters]);

  // フィルター変更時に検索を実行（検索テーマは除外）
  useEffect(() => {
    // モーダルが開いていない場合や検索中の場合は実行しない
    if (!open || isSearching) return;

    // アクティブなフィルターがある場合のみ検索を実行
    if (hasActiveFilters) {
      // 依存配列の変更によって検索が実行される前に遅延を入れる
      const searchTimer = setTimeout(() => handleSearch(), 300);
      return () => clearTimeout(searchTimer);
    }
  }, [filterSettings, open, hasActiveFilters, isSearching, handleSearch]);

  // シェルフ選択の切り替え
  const handleToggleShelf = (shelf: IShelfWithRelevance) => {
    if (!shelf.shelf_id) return; // シェルフIDがない場合は選択できない

    // プレビュー更新を一時的に無効化
    setDisableAutoPreview(true);

    setSelectedShelves((prev) => {
      // 既に選択されているか確認
      const existingIndex = prev.findIndex(
        (s) => s.shelf_id === shelf.shelf_id,
      );

      const newShelves =
        existingIndex >= 0
          ? // 選択解除 (配列から削除)
            prev.filter((s) => s.shelf_id !== shelf.shelf_id)
          : // 選択 (配列に追加)
            [...prev, shelf];

      // 選択内容が変更され、設定タブが表示されている場合はプレビュー更新をスケジュール
      if (activeTab === 1 && open) {
        setTimeout(() => {
          if (!shouldStopPreviewRef.current) {
            setDisableAutoPreview(false);
            setUpdateCount((prev) => prev + 1);
          }
        }, 300);
      } else {
        setDisableAutoPreview(false);
      }

      return newShelves;
    });
  };

  // 選択したシェルフの順序を変更する
  const handleMoveShelf = (index: number, direction: 'up' | 'down') => {
    if (
      (direction === 'up' && index === 0) ||
      (direction === 'down' && index === selectedShelves.length - 1)
    ) {
      return; // 最初のアイテムを上に、または最後のアイテムを下に移動できない
    }

    // プレビュー更新を一時的に無効化
    setDisableAutoPreview(true);

    setSelectedShelves((prev) => {
      const newOrder = [...prev];
      const targetIndex = direction === 'up' ? index - 1 : index + 1;

      // 選択したシェルフと移動先のシェルフを入れ替える
      [newOrder[index], newOrder[targetIndex]] = [
        newOrder[targetIndex],
        newOrder[index],
      ];

      // シェルフの順序が変更され、設定タブが表示されている場合はプレビュー更新をスケジュール
      if (activeTab === 1 && open) {
        setTimeout(() => {
          if (!shouldStopPreviewRef.current) {
            setDisableAutoPreview(false);
            setUpdateCount((prev) => prev + 1);
          }
        }, 300);
      } else {
        setDisableAutoPreview(false);
      }

      return newOrder;
    });
  };

  // 選択したシェルフをブロックとして追加または更新
  const handleAddSelectedShelves = () => {
    if (selectedShelves.length === 0) {
      setError('シェルフを選択してください');
      return;
    }

    try {
      // シェルフブロックの作成
      const shelfBlock: IShelfBlock = {
        blockType: 'shelf',
        shelfConfig: {
          shelfOrders: [
            {
              shelfType: ShelfTypeEnum.search,
              shelfNum: selectedShelves.length,
              shelfIds: selectedShelves.map((s) => s.shelf_id as string), // 選択済みならshelf_idが存在する
              maxItems: maxItems, // 追加：最大表示商品数
            },
          ],
          uiType: uiType, // 修正：UI表示タイプ（slider/grid）
          gridColumns: uiType === 'grid' ? gridColumns : undefined, // グリッド列数
          columnGap: uiType === 'grid' ? columnGap : undefined, // 列間隔
          sliderRows: uiType === 'slider' ? sliderRows : undefined, // スライダー行数
          // タグラインオーバーライド設定を追加
          shelfOverrides:
            shelfOverrides.length > 0 ? shelfOverrides : undefined,
          // フィルター設定を追加
          brandNames:
            filterSettings.brandNames.length > 0
              ? filterSettings.brandNames
              : undefined,
          categories:
            filterSettings.categories.length > 0
              ? filterSettings.categories
              : undefined,
          subCategories:
            filterSettings.subCategories.length > 0
              ? filterSettings.subCategories
              : undefined,
          filteredAids: filteredAids.length > 0 ? filteredAids : undefined,
          // アイテムスタイル設定を追加
          itemOption:
            Object.keys(itemOption).length > 0 ? itemOption : undefined,
        },
      };

      // 編集モードの場合は更新、そうでなければ追加
      if (isEditMode && onUpdate) {
        onUpdate(shelfBlock);
      } else {
        onAdd(shelfBlock);
      }
      handleClose();
    } catch (err) {
      console.error('シェルフブロック追加エラー:', err);
      setError('ブロックの追加に失敗しました');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="shelf-search-modal-title"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '95%',
          maxWidth: 1400,
          height: '92vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {/* ヘッダー部分 */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: muiTheme.palette.primary.main,
            color: muiTheme.palette.primary.contrastText,
            p: 2,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Typography variant="h5" component="h2" fontWeight="bold">
            テーマに基づいてシェルフを検索
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{ color: 'white' }}
            aria-label="閉じる"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* タブ */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="shelf search tabs"
            variant="fullWidth"
          >
            <Tab
              icon={<SearchIcon />}
              iconPosition="start"
              label="検索"
              id="shelf-search-tab-0"
              aria-controls="shelf-search-tabpanel-0"
            />
            <Tab
              icon={<SettingsIcon />}
              iconPosition="start"
              label="設定"
              id="shelf-search-tab-1"
              aria-controls="shelf-search-tabpanel-1"
              disabled={selectedShelves.length === 0}
            />
          </Tabs>
        </Box>

        {/* タブコンテンツ - 検索タブ */}
        <TabPanel value={activeTab} index={0}>
          {/* 検索フォーム */}
          <Box sx={{ p: 2, bgcolor: muiTheme.palette.background.default }}>
            <Box sx={{ display: 'flex' }}>
              <TextField
                fullWidth
                label="検索テーマ"
                variant="outlined"
                value={searchTheme}
                onChange={handleThemeChange}
                placeholder="例: 夏のお出かけファッション、オフィスカジュアル、トレンドアイテム..."
                error={!!error}
                helperText={error}
                disabled={isSearching}
                size="small"
                sx={{
                  bgcolor: 'white',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '1.5px',
                      borderColor: 'rgba(25, 118, 210, 0.3)',
                      transition: 'all 0.3s',
                      background:
                        'linear-gradient(145deg, rgba(255,255,255,0) 0%, rgba(176,234,255,0.1) 100%)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(25, 118, 210, 0.5)',
                      background:
                        'linear-gradient(145deg, rgba(255,255,255,0) 0%, rgba(176,234,255,0.2) 100%)',
                    },
                    '&.Mui-focused fieldset': {
                      borderWidth: '2px',
                      borderColor: 'rgba(25, 118, 210, 0.8)',
                      boxShadow: '0 0 6px rgba(25, 118, 210, 0.2)',
                      background:
                        'linear-gradient(145deg, rgba(255,255,255,0) 0%, rgba(176,234,255,0.3) 100%)',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagicWandIcon
                        color="primary"
                        sx={{ opacity: 0.7, fontSize: '1.2rem' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                startIcon={
                  isSearching ? <CircularProgress size={20} /> : <SearchIcon />
                }
                onClick={handleSearch}
                disabled={
                  isSearching || (!searchTheme.trim() && !hasActiveFilters)
                }
                sx={{
                  ml: 1,
                  minWidth: 120,
                  background:
                    'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
                  '&:hover': {
                    background:
                      'linear-gradient(45deg, #1565c0 30%, #1976d2 90%)',
                  },
                }}
              >
                {isSearching ? '検索中...' : 'AIで検索'}
              </Button>
              <Badge
                badgeContent={hasActiveFilters ? '!' : 0}
                color="error"
                sx={{ ml: 1 }}
              >
                <Button
                  variant={showFilterPanel ? 'contained' : 'outlined'}
                  color="secondary"
                  onClick={() => setShowFilterPanel(!showFilterPanel)}
                  startIcon={<FilterListIcon />}
                  size="small"
                >
                  フィルター
                </Button>
              </Badge>
            </Box>

            {/* フィルターパネル */}
            <Collapse in={showFilterPanel}>
              <Box
                sx={{ mt: 1, bgcolor: 'white', borderRadius: 1, boxShadow: 1 }}
              >
                <FilterPanel
                  brandNames={filterSettings.brandNames}
                  categories={filterSettings.categories}
                  subCategories={filterSettings.subCategories}
                  itemGenders={filterSettings.itemGenders}
                  filterItems={filterSettings.filterItems}
                  onChangeBrandNames={(brandNames) => {
                    // 一時的にプレビュー更新を停止
                    setDisableAutoPreview(true);
                    updateFilterSettings({ brandNames });
                    if (activeTab === 1 && open) {
                      setTimeout(() => {
                        if (!shouldStopPreviewRef.current) {
                          setDisableAutoPreview(false);
                          setUpdateCount((prev) => prev + 1);
                        }
                      }, 300);
                    } else {
                      setDisableAutoPreview(false);
                    }
                  }}
                  onChangeCategories={(categories) => {
                    // 一時的にプレビュー更新を停止
                    setDisableAutoPreview(true);
                    updateFilterSettings({ categories });
                    if (activeTab === 1 && open) {
                      setTimeout(() => {
                        if (!shouldStopPreviewRef.current) {
                          setDisableAutoPreview(false);
                          setUpdateCount((prev) => prev + 1);
                        }
                      }, 300);
                    } else {
                      setDisableAutoPreview(false);
                    }
                  }}
                  onChangeSubCategories={(subCategories) => {
                    // 一時的にプレビュー更新を停止
                    setDisableAutoPreview(true);
                    updateFilterSettings({ subCategories });
                    if (activeTab === 1 && open) {
                      setTimeout(() => {
                        if (!shouldStopPreviewRef.current) {
                          setDisableAutoPreview(false);
                          setUpdateCount((prev) => prev + 1);
                        }
                      }, 300);
                    } else {
                      setDisableAutoPreview(false);
                    }
                  }}
                  onChangeItemGenders={(itemGenders) => {
                    // 一時的にプレビュー更新を停止
                    setDisableAutoPreview(true);
                    updateFilterSettings({ itemGenders });
                    if (activeTab === 1 && open) {
                      setTimeout(() => {
                        if (!shouldStopPreviewRef.current) {
                          setDisableAutoPreview(false);
                          setUpdateCount((prev) => prev + 1);
                        }
                      }, 300);
                    } else {
                      setDisableAutoPreview(false);
                    }
                  }}
                  onChangeFilterItems={(filterItems) => {
                    // 一時的にプレビュー更新を停止
                    setDisableAutoPreview(true);
                    updateFilterSettings({ filterItems });
                    if (activeTab === 1 && open) {
                      setTimeout(() => {
                        if (!shouldStopPreviewRef.current) {
                          setDisableAutoPreview(false);
                          setUpdateCount((prev) => prev + 1);
                        }
                      }, 300);
                    } else {
                      setDisableAutoPreview(false);
                    }
                  }}
                  onResetFilters={() => {
                    // 一時的にプレビュー更新を停止
                    setDisableAutoPreview(true);
                    resetFilters();
                    if (activeTab === 1 && open) {
                      setTimeout(() => {
                        if (!shouldStopPreviewRef.current) {
                          setDisableAutoPreview(false);
                          setUpdateCount((prev) => prev + 1);
                        }
                      }, 300);
                    } else {
                      setDisableAutoPreview(false);
                    }
                  }}
                  showBrandFilter={true}
                  showCategoryFilter={true}
                  showSubCategoryFilter={true}
                  showGenderFilter={false}
                  open={open}
                />
              </Box>
            </Collapse>
          </Box>

          {/* コンテンツエリア */}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              overflow: 'hidden',
              borderTop: `1px solid ${muiTheme.palette.divider}`,
            }}
          >
            {/* 左側: 検索結果 */}
            <ShelfSearchResults
              searchResults={searchResults}
              selectedShelves={selectedShelves}
              isSearching={isSearching || isInitialLoading}
              isLoadingExistingData={isLoadingExistingData}
              searchTheme={searchTheme}
              onToggleShelf={handleToggleShelf}
              getRelevanceColor={getRelevanceColor}
              hasActiveFilters={hasActiveFilters}
            />

            {/* 右側: 選択済みシェルフ */}
            <SelectedShelves
              selectedShelves={selectedShelves}
              onToggleShelf={handleToggleShelf}
              onMoveShelf={handleMoveShelf}
              onClearAll={() => setSelectedShelves([])}
            />
          </Box>
        </TabPanel>

        {/* タブコンテンツ - 設定タブ - ShelfConfigPreviewLayoutを使用 */}
        <TabPanel value={activeTab} index={1}>
          <Box sx={{ height: '100%' }}>
            {activeTab === 1 && selectedShelves.length > 0 && (
              <ShelfConfigBuilder
                key={`config-builder-${selectedShelves.length}-${tabSwitchTrigger}`}
                config={createShelfConfig()}
                onConfigChange={handleShelfConfigChange}
                clientId={clientState.selectedClient?.clientId}
                defaultActiveTab={0}
                tabConfigs={[
                  { id: 0, show: true }, // 表示設定
                  { id: 1, show: true }, // 商品スタイル
                  { id: 2, show: true }, // ブランドフィルター
                  { id: 3, show: true }, // カテゴリフィルター
                  { id: 4, show: true }, // タグラインオーバーライド
                  { id: 5, show: false }, // シェルフオーダー無効化
                ]}
              />
            )}
          </Box>
        </TabPanel>

        {/* フッター部分 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 2,
            borderTop: `1px solid ${muiTheme.palette.divider}`,
            bgcolor: muiTheme.palette.background.default,
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mr: 'auto' }}
          >
            {selectedShelves.length}件のシェルフを選択中
            {filteredAids.length > 0 &&
              ` / ${filteredAids.length}件の商品をフィルタリング中`}
            {shelfOverrides.length > 0 &&
              ` / ${shelfOverrides.length}件のタグラインをカスタマイズ中`}
          </Typography>
          <Button onClick={handleClose} sx={{ mr: 1 }} color="inherit">
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={handleAddSelectedShelves}
            disabled={selectedShelves.length === 0}
            startIcon={isEditMode ? <EditIcon /> : <AddIcon />}
          >
            {isEditMode ? 'シェルフを更新' : '選択したシェルフを追加'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShelfSearchModal;
