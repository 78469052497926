import {
  ShoppingBasket as BasketIcon,
  Clear as ClearIcon,
  DragIndicator as DragIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { SelectedItemsProps } from './types';

/**
 * 選択済み商品表示コンポーネント
 */
const SelectedItems: React.FC<SelectedItemsProps> = ({
  selectedItems,
  onToggleItem,
  onMoveItem,
  onClearAll,
  onSearchRelatedItems,
}) => {
  const muiTheme = useTheme();

  // ドラッグ終了時の処理
  const handleDragEnd = (result: DropResult) => {
    // ドロップ先がない場合は何もしない
    if (!result.destination) return;

    // 同じ位置にドロップした場合は何もしない
    if (result.destination.index === result.source.index) return;

    // 移動処理を親コンポーネントに委譲
    // 移動方向は不要になるため、インデックスのみで処理
    onMoveItem(result.source.index, result.destination.index);
  };

  return (
    <Box
      sx={{
        width: '35%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          p: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: muiTheme.palette.background.default,
          borderBottom: `1px solid ${muiTheme.palette.divider}`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BasketIcon
            fontSize="small"
            sx={{ mr: 1, color: muiTheme.palette.success.main }}
          />
          <Typography variant="subtitle1" fontWeight="bold">
            選択済み商品 ({selectedItems.length}件)
          </Typography>
        </Box>
        {selectedItems.length > 0 && (
          <Button
            variant="outlined"
            size="small"
            onClick={onClearAll}
            color="error"
            sx={{ fontSize: '0.75rem', py: 0.2 }}
            startIcon={<ClearIcon fontSize="small" />}
          >
            すべて解除
          </Button>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          p: 1.5,
          bgcolor: muiTheme.palette.grey[50],
        }}
      >
        {selectedItems.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="selected-items">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedItems.map((item, index) => (
                    <Draggable
                      key={item.aid}
                      draggableId={`item-${item.aid}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Card
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{
                            mb: 1.5,
                            border: `1px solid #e0e0e0`,
                            boxShadow: snapshot.isDragging ? 3 : 1,
                            borderRadius: 1.5,
                            position: 'relative',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            '&:hover': {
                              boxShadow: 3,
                              borderColor: muiTheme.palette.primary.light,
                            },
                            ...(snapshot.isDragging && {
                              borderColor: muiTheme.palette.primary.main,
                              backgroundColor: 'rgba(25, 118, 210, 0.04)',
                            }),
                          }}
                          onClick={() => onToggleItem(item)}
                        >
                          <Box sx={{ display: 'flex', p: 1.5 }}>
                            <Box
                              sx={{ display: 'flex', alignItems: 'flex-start' }}
                            >
                              <Checkbox
                                checked={true}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  onToggleItem(item);
                                }}
                                size="small"
                                sx={{ ml: -1, mr: 0.5, mt: -0.5 }}
                              />
                              <CardMedia
                                component="img"
                                sx={{
                                  width: 60,
                                  height: 60,
                                  objectFit: 'contain',
                                  borderRadius: 1,
                                  border: '1px solid #eee',
                                }}
                                image={item.image_url || '/placeholder.png'}
                                alt={item.item_title}
                              />
                            </Box>

                            <Box sx={{ ml: 1.5, flex: 1 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  sx={{ display: 'block', fontWeight: 'bold' }}
                                >
                                  {item.brand_name}
                                </Typography>

                                {/* 関連商品検索ボタン */}
                                {onSearchRelatedItems && (
                                  <Tooltip title="この商品の関連商品を検索">
                                    <IconButton
                                      size="small"
                                      color="primary"
                                      sx={{
                                        p: 0.25,
                                        '&:hover': {
                                          backgroundColor:
                                            muiTheme.palette.primary.light +
                                            '30',
                                        },
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onSearchRelatedItems(item);
                                      }}
                                    >
                                      <LinkIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 500,
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  lineHeight: 1.3,
                                  height: '2.6em',
                                }}
                              >
                                {item.item_title}
                              </Typography>
                              <Box sx={{ display: 'flex', gap: 0.5, mt: 0.5 }}>
                                {item.category && (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      padding: '0px 6px',
                                      borderRadius: 1,
                                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                      fontSize: '0.65rem',
                                    }}
                                  >
                                    {item.category}
                                  </Typography>
                                )}
                                {item.sub_category && (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      padding: '0px 6px',
                                      borderRadius: 1,
                                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                      fontSize: '0.65rem',
                                    }}
                                  >
                                    {item.sub_category}
                                  </Typography>
                                )}
                              </Box>
                            </Box>

                            {/* ドラッグハンドル */}
                            <Box
                              {...provided.dragHandleProps}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'text.secondary',
                                cursor: 'grab',
                                '&:hover': { color: 'primary.main' },
                              }}
                            >
                              <DragIcon />
                            </Box>
                          </Box>
                        </Card>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              color: muiTheme.palette.text.secondary,
            }}
          >
            <BasketIcon sx={{ fontSize: 50, opacity: 0.3, mb: 2 }} />
            <Typography variant="body1" align="center">
              まだ商品が選択されていません
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ mt: 1 }}
            >
              検索結果から商品を選択してください
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SelectedItems;
