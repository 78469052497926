import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  SellOutlined as ShelfIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { ShelfTypeEnum } from '../../../../enums/shelf-type.enum';
import { IShelfOrder } from '../../../../types/plp-builder.type';

// シェルフタイプの日本語表示名マッピング
const shelfTypeNames: Record<string, string> = {
  [ShelfTypeEnum.foryou]: 'あなたへのおすすめ',
  [ShelfTypeEnum.oneItem]: '1商品',
  [ShelfTypeEnum.history]: '閲覧履歴',
  [ShelfTypeEnum.related]: '関連',
  [ShelfTypeEnum.brand]: 'ブランド',
  [ShelfTypeEnum.category]: 'カテゴリー',
  [ShelfTypeEnum.subCategory]: 'サブカテゴリー',
  [ShelfTypeEnum.sale]: 'セール',
  [ShelfTypeEnum.restock]: '再入荷',
  [ShelfTypeEnum.magazine]: '雑誌掲載',
  [ShelfTypeEnum.goodReviewScore]: '高評価',
  [ShelfTypeEnum.favoriteTrend]: 'お気に入り急上昇',
  [ShelfTypeEnum.popularTrend]: '人気急上昇',
  [ShelfTypeEnum.newPopular]: '新着人気',
  [ShelfTypeEnum.viewedBrand]: '閲覧ブランド',
  [ShelfTypeEnum.viewedCategory]: '閲覧カテゴリー',
  [ShelfTypeEnum.topRanking]: '上位',
  [ShelfTypeEnum.search]: 'こだわり',
  [ShelfTypeEnum.newArrival]: '新着',
  [ShelfTypeEnum.searchKeyword]: '検索キーワード',
  [ShelfTypeEnum.coordForyou]: 'コーディネートおすすめ',
  [ShelfTypeEnum.coordSearch]: 'コーデこだわり',
  [ShelfTypeEnum.articleForyou]: '記事おすすめ',
  [ShelfTypeEnum.articleSearch]: '記事こだわり',

  // 以下のタイプはUIに表示しないが、互換性のために定義
  [ShelfTypeEnum.perspective]: '視点ベース',
  [ShelfTypeEnum.perspective2]: '視点ベース2',
  [ShelfTypeEnum.perspective3]: '視点ベース3',
  [ShelfTypeEnum.perspective4]: '視点ベース4',
  [ShelfTypeEnum.perspective5]: '視点ベース5',
  [ShelfTypeEnum.manual]: '手動選択',
};

// UIに表示しないシェルフタイプ
const hiddenShelfTypes = [
  ShelfTypeEnum.perspective,
  ShelfTypeEnum.perspective2,
  ShelfTypeEnum.perspective3,
  ShelfTypeEnum.perspective4,
  ShelfTypeEnum.perspective5,
  ShelfTypeEnum.manual,
];

// シェルフタイプをカテゴリ別に分類
const shelfTypeCategories = [
  {
    name: 'パーソナライズ',
    types: [
      ShelfTypeEnum.foryou,
      ShelfTypeEnum.history,
      ShelfTypeEnum.viewedBrand,
      ShelfTypeEnum.viewedCategory,
    ],
  },
  {
    name: '商品特性',
    types: [
      ShelfTypeEnum.related,
      ShelfTypeEnum.brand,
      ShelfTypeEnum.category,
      ShelfTypeEnum.subCategory,
      ShelfTypeEnum.oneItem,
    ],
  },
  {
    name: 'トレンド',
    types: [
      ShelfTypeEnum.popularTrend,
      ShelfTypeEnum.favoriteTrend,
      ShelfTypeEnum.topRanking,
      ShelfTypeEnum.newPopular,
      ShelfTypeEnum.newArrival,
    ],
  },
  {
    name: '特集',
    types: [
      ShelfTypeEnum.sale,
      ShelfTypeEnum.restock,
      ShelfTypeEnum.magazine,
      ShelfTypeEnum.goodReviewScore,
      ShelfTypeEnum.search,
      ShelfTypeEnum.searchKeyword,
    ],
  },
  {
    name: 'その他コンテンツ',
    types: [
      ShelfTypeEnum.coordForyou,
      ShelfTypeEnum.coordSearch,
      ShelfTypeEnum.articleForyou,
      ShelfTypeEnum.articleSearch,
    ],
  },
];

interface ShelfOrderPanelProps {
  shelfOrders: IShelfOrder[];
  onUpdateShelfOrders: (orders: IShelfOrder[]) => void;
  /**
   * 全体に適用されている最大商品数（未指定の場合はデフォルト値を使用）
   */
  globalMaxItems?: number;
}

/**
 * シェルフ設定コンポーネント
 * シェルフの表示順序と設定を管理します
 */
const ShelfOrderPanel: React.FC<ShelfOrderPanelProps> = ({
  shelfOrders,
  onUpdateShelfOrders,
  globalMaxItems,
}) => {
  const theme = useTheme();

  // コンポーネント読み込み時にデバッグ出力
  useEffect(() => {
    console.log('ShelfOrderPanel マウント - shelfOrders:', shelfOrders);

    // シェルフタイプの値を確認
    shelfOrders.forEach((order, index) => {
      console.log(`シェルフ ${index + 1} のタイプ:`, order.shelfType);
      console.log(`シェルフタイプ名:`, getShelfTypeName(order.shelfType));
    });

    console.log('全シェルフタイプ:', Object.values(ShelfTypeEnum));
  }, [shelfOrders]);

  // 既に使用されているシェルフタイプを除外した選択可能なタイプを取得
  const availableShelfTypes = useMemo(() => {
    const usedTypes = shelfOrders.map((order) => order.shelfType);
    return Object.values(ShelfTypeEnum)
      .filter((type) => !usedTypes.includes(type))
      .filter((type) => !hiddenShelfTypes.includes(type));
  }, [shelfOrders]);

  // シェルフオーダーを追加
  const handleAddShelfOrder = useCallback(() => {
    // 利用可能なシェルフタイプがない場合は何もしない
    if (availableShelfTypes.length === 0) {
      console.log('利用可能なシェルフタイプがありません');
      return;
    }

    // 最初の利用可能なシェルフタイプを使用
    const firstAvailableType = availableShelfTypes[0];
    console.log('追加するシェルフタイプ:', firstAvailableType);

    // 新しいシェルフオーダーを作成
    const newOrder: IShelfOrder = {
      shelfType: firstAvailableType,
      shelfNum: 1, // デフォルト値1
      maxItems: globalMaxItems || 6, // 全体設定された最大商品数、または6をデフォルトとして使用
    };

    // レンダリングをトリガーするために新しい配列を作成
    onUpdateShelfOrders([...shelfOrders, newOrder]);
  }, [availableShelfTypes, shelfOrders, onUpdateShelfOrders, globalMaxItems]);

  // シェルフオーダーの削除
  const handleDeleteShelfOrder = useCallback(
    (index: number) => {
      const newOrders = [...shelfOrders];
      newOrders.splice(index, 1);
      onUpdateShelfOrders(newOrders);
    },
    [shelfOrders, onUpdateShelfOrders],
  );

  // シェルフオーダーの更新
  const handleUpdateShelfOrder = useCallback(
    (index: number, field: keyof IShelfOrder, value: any) => {
      // 配列のコピーを作成
      const newOrders = [...shelfOrders];

      // 指定されたインデックスのオーダーを更新
      newOrders[index] = {
        ...newOrders[index],
        [field]: value,
      };

      console.log(`シェルフ ${index + 1} の ${field} を更新:`, value);
      console.log('更新後のシェルフオーダー:', newOrders);

      // 更新された配列を親コンポーネントに通知
      onUpdateShelfOrders(newOrders);
    },
    [shelfOrders, onUpdateShelfOrders],
  );

  // ドラッグ&ドロップによる並び替え
  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const items = Array.from(shelfOrders);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      onUpdateShelfOrders(items);
    },
    [shelfOrders, onUpdateShelfOrders],
  );

  // シェルフタイプの名前（日本語）を取得
  const getShelfTypeName = useCallback((type: string | undefined) => {
    if (!type) return '選択してください';
    return shelfTypeNames[type] || type;
  }, []);

  // 選択可能なシェルフタイプをカテゴリ別に整理（特定のシェルフ向け）
  const getShelfTypeOptions = (currentType: string | undefined) => {
    // 有効なcurrentTypeかどうかチェック
    const validCurrentType = currentType && currentType !== '';

    // 他のシェルフで使用中のタイプ
    const otherUsedTypes = shelfOrders
      .map((order) => order.shelfType)
      .filter((type) => type && type !== currentType); // undefinedや空文字を除外

    // すべてのシェルフタイプ（hiddenを除く）
    const allTypes = Object.values(ShelfTypeEnum).filter(
      (type) => !hiddenShelfTypes.includes(type),
    );

    // デバッグ用
    console.log('現在の選択:', currentType);
    console.log('使用中タイプ:', otherUsedTypes);
    console.log('全有効タイプ:', allTypes);

    // メニュー項目のリスト
    const menuItems: JSX.Element[] = [];

    // カテゴリ別にグループ化
    shelfTypeCategories.forEach((category) => {
      // カテゴリ内の有効なタイプ
      const categoryTypes = category.types.filter(
        (type) => !hiddenShelfTypes.includes(type),
      );

      // このカテゴリで選択可能なタイプ（現在選択中のタイプを含む）
      const availableTypes = categoryTypes.filter(
        (type) => type === currentType || !otherUsedTypes.includes(type),
      );

      if (availableTypes.length === 0) return;

      // カテゴリ見出し
      menuItems.push(
        <MenuItem key={`cat-${category.name}`} disabled divider>
          <Typography variant="caption" fontWeight="bold">
            {category.name}
          </Typography>
        </MenuItem>,
      );

      // カテゴリのタイプ
      availableTypes.forEach((type) => {
        menuItems.push(
          <MenuItem key={`type-${type}`} value={type} sx={{ pl: 4 }}>
            {getShelfTypeName(type)}
          </MenuItem>,
        );
      });
    });

    // 選択肢がない場合は全タイプを表示（エラー防止）
    if (menuItems.length === 0) {
      // 最低限、最初のカテゴリと現在選択中のタイプは表示
      const firstCategory = shelfTypeCategories[0];

      menuItems.push(
        <MenuItem key="empty-cat" disabled divider>
          <Typography variant="caption" fontWeight="bold">
            {firstCategory.name}
          </Typography>
        </MenuItem>,
      );

      if (validCurrentType) {
        menuItems.push(
          <MenuItem
            key={`current-${currentType}`}
            value={currentType}
            sx={{ pl: 4 }}
          >
            {getShelfTypeName(currentType)}
          </MenuItem>,
        );
      } else {
        // 何も選択されていない場合は最初のタイプを表示
        const firstType = firstCategory.types[0];
        menuItems.push(
          <MenuItem
            key={`default-${firstType}`}
            value={firstType}
            sx={{ pl: 4 }}
          >
            {getShelfTypeName(firstType)}
          </MenuItem>,
        );
      }
    }

    return menuItems;
  };

  // シェルフ数の選択肢を生成（1-10）
  const renderShelfNumOptions = () => {
    return Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
      <MenuItem key={num} value={num}>
        {num}
      </MenuItem>
    ));
  };

  // 商品数の選択肢を生成（1-30）
  const renderMaxItemsOptions = () => {
    return Array.from({ length: 30 }, (_, i) => i + 1).map((num) => (
      <MenuItem key={num} value={num}>
        {num}
      </MenuItem>
    ));
  };

  // シェルフカードをレンダリング
  const renderShelfCard = (
    order: IShelfOrder,
    index: number,
    provided: any,
    snapshot: any,
  ) => {
    return (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        sx={{
          mb: 1,
          p: 1,
          border: `1px solid ${
            snapshot.isDragging
              ? theme.palette.primary.main
              : theme.palette.divider
          }`,
          boxShadow: snapshot.isDragging ? 3 : 1,
          borderRadius: 1,
          position: 'relative',
          bgcolor: snapshot.isDragging
            ? 'rgba(25, 118, 210, 0.04)'
            : 'background.paper',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {/* ドラッグハンドル */}
          <Box
            {...provided.dragHandleProps}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
              cursor: 'grab',
            }}
          >
            <DragIcon fontSize="small" />
          </Box>

          {/* 番号 */}
          <Chip
            label={`${index + 1}`}
            color="primary"
            variant="outlined"
            size="small"
          />

          {/* シェルフタイプ */}
          <Box sx={{ flex: 3, minWidth: 150 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id={`shelf-type-label-${index}`}>タイプ</InputLabel>
              <Select
                labelId={`shelf-type-label-${index}`}
                value={order.shelfType || ''}
                onChange={(e) => {
                  console.log('選択された値:', e.target.value);
                  handleUpdateShelfOrder(index, 'shelfType', e.target.value);
                }}
                label="タイプ"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {getShelfTypeOptions(order.shelfType)}
              </Select>
            </FormControl>
          </Box>

          {/* シェルフ数 */}
          <FormControl size="small" sx={{ width: 100 }}>
            <InputLabel>シェルフ数</InputLabel>
            <Select
              value={order.shelfNum || 1}
              onChange={(e) =>
                handleUpdateShelfOrder(
                  index,
                  'shelfNum',
                  Number(e.target.value),
                )
              }
              label="シェルフ数"
            >
              {renderShelfNumOptions()}
            </Select>
          </FormControl>

          {/* 商品数 */}
          <FormControl size="small" sx={{ width: 100 }}>
            <InputLabel>商品数</InputLabel>
            <Select
              value={order.maxItems || 6}
              onChange={(e) =>
                handleUpdateShelfOrder(
                  index,
                  'maxItems',
                  Number(e.target.value),
                )
              }
              label="商品数"
            >
              {renderMaxItemsOptions()}
            </Select>
          </FormControl>

          {/* 削除ボタン */}
          <Tooltip title="削除">
            <IconButton
              size="small"
              color="error"
              onClick={() => handleDeleteShelfOrder(index)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Card>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <Paper
          elevation={0}
          sx={{
            p: 3,
            height: '100%',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ShelfIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
              <Typography variant="subtitle1" fontWeight="medium">
                シェルフ設定
              </Typography>
            </Box>

            <Typography variant="body2" color="text.secondary">
              {shelfOrders.length}個のシェルフが設定されています
            </Typography>
          </Box>

          {shelfOrders.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                py: 8,
              }}
            >
              <ShelfIcon sx={{ fontSize: 60, color: 'text.disabled', mb: 2 }} />
              <Typography variant="body1" color="text.secondary">
                シェルフが設定されていません
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                追加ボタンからシェルフを追加してください
              </Typography>
            </Box>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="shelf-orders">
                {(provided) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{ flex: 1 }}
                  >
                    {shelfOrders.map((order, index) => (
                      <Draggable
                        key={`${order.shelfType || 'empty'}-${index}`}
                        draggableId={`order-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) =>
                          renderShelfCard(order, index, provided, snapshot)
                        }
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {/* 追加ボタン */}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddShelfOrder}
              disabled={availableShelfTypes.length === 0}
              fullWidth
              sx={{ maxWidth: '300px' }}
            >
              シェルフを追加
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ShelfOrderPanel;
