import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { Eye, MousePointerClick, ShoppingCart } from 'lucide-react';
import React from 'react';
import { DailySSMetricsV2 } from '../../../services/shelf-service';

interface MetricsSummaryCardsProps {
  data: DailySSMetricsV2[];
  compareData: DailySSMetricsV2[];
  showComparison: boolean;
  shelfUnitPrice: number;
  otherUnitPrice: number;
}

const MetricsSummaryCards: React.FC<MetricsSummaryCardsProps> = ({
  data,
  compareData,
  showComparison,
  shelfUnitPrice,
  otherUnitPrice,
}) => {
  const calculateTotal = (
    metric: keyof DailySSMetricsV2,
    targetData: DailySSMetricsV2[],
  ): number => {
    return targetData.reduce((sum, day) => sum + (Number(day[metric]) || 0), 0);
  };

  const calculateRevenue = (targetData: DailySSMetricsV2[]): number => {
    return calculateTotal('shelf_cv_ss', targetData) * shelfUnitPrice;
  };

  const calculateTotalRevenue = (targetData: DailySSMetricsV2[]): number => {
    const shelfRevenue =
      calculateTotal('shelf_cv_ss', targetData) * shelfUnitPrice;
    const otherRevenue =
      (calculateTotal('cv_ss', targetData) -
        calculateTotal('shelf_cv_ss', targetData)) *
      otherUnitPrice;
    return shelfRevenue + otherRevenue;
  };

  const calculateRates = (targetData: DailySSMetricsV2[]) => {
    const totalSS = calculateTotal('ss', targetData);
    const shelfShown = calculateTotal('shown_ss', targetData);
    const shelfClick = calculateTotal('click_ss', targetData);
    const totalRevenue = calculateTotalRevenue(targetData);
    const shelfRevenue = calculateRevenue(targetData);

    return {
      shownRate: totalSS > 0 ? (shelfShown / totalSS) * 100 : 0,
      clickRate: shelfShown > 0 ? (shelfClick / shelfShown) * 100 : 0,
      revenueRate: totalRevenue > 0 ? (shelfRevenue / totalRevenue) * 100 : 0,
    };
  };

  const calculateComparisonRate = (
    current: number,
    previous: number,
  ): number => {
    if (previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  const rates = calculateRates(data);

  const renderComparisonValue = (current: number, previous: number) => {
    if (!showComparison) return null;
    const rate = calculateComparisonRate(current, previous);
    const color = rate >= 0 ? '#4CAF50' : '#f44336';
    return (
      <Typography
        variant="caption"
        sx={{
          color,
          display: 'block',
          fontWeight: 'bold',
          mt: 0.5,
        }}
      >
        {rate >= 0 ? '▲' : '▼'} {Math.abs(rate).toFixed(1)}%
      </Typography>
    );
  };

  const metrics = [
    {
      title: '見た',
      icon: Eye,
      color: '#2196F3',
      value: calculateTotal('shown_ss', data),
      compareValue: showComparison
        ? calculateTotal('shown_ss', compareData)
        : null,
      label: 'シェルフIMPSS数',
      subLabel: `サイト全体SSの${rates.shownRate.toFixed(1)}%`,
    },
    {
      title: '使った',
      icon: MousePointerClick,
      color: '#4CAF50',
      value: calculateTotal('click_ss', data),
      compareValue: showComparison
        ? calculateTotal('click_ss', compareData)
        : null,
      label: 'シェルフクリックSS数',
      subLabel: `クリック率: ${rates.clickRate.toFixed(1)}%`,
    },
    {
      title: '買った',
      icon: ShoppingCart,
      color: '#FF9800',
      values: [
        {
          label: 'シェルフ経由CV SS数',
          value: calculateTotal('shelf_cv_ss', data),
          compareValue: showComparison
            ? calculateTotal('shelf_cv_ss', compareData)
            : null,
        },
        {
          label: 'シェルフ経由売上高',
          value: calculateRevenue(data),
          compareValue: showComparison ? calculateRevenue(compareData) : null,
          isRevenue: true,
          subLabel: `サイト全体売上の${rates.revenueRate.toFixed(1)}%`,
        },
      ],
    },
  ];

  return (
    <Box sx={{ mb: 4 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(3, 1fr)',
          },
          gap: 3,
          mb: 2,
        }}
      >
        {metrics.map((metric) => (
          <Box
            key={metric.title}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                right: '-1.5rem',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 0,
                height: 0,
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
                borderLeft: '10px solid rgba(0, 0, 0, 0.1)',
                display: {
                  xs: 'none',
                  sm: metric.title === '買った' ? 'none' : 'block',
                },
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 48,
                height: 48,
                borderRadius: '50%',
                bgcolor: `${metric.color}15`,
                color: metric.color,
              }}
            >
              <metric.icon size={28} />
            </Box>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {metric.title}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: 'text.secondary',
                  display: 'block',
                  mt: -0.5,
                }}
              >
                {metric.title === '見た' && '露出度'}
                {metric.title === '使った' && '魅力度'}
                {metric.title === '買った' && 'CV決定力'}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(3, 1fr)',
          },
          gap: 3,
        }}
      >
        {metrics.map((metric) => (
          <Card
            key={metric.title}
            sx={{
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
              },
              height: '100%',
              display: 'flex',
            }}
          >
            <CardContent
              sx={{
                p: 3,
                textAlign: 'center',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {'values' in metric ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Tooltip
                    title="シェルフ内の商品をクリックした後に購入したセッション数"
                    arrow
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ color: 'text.secondary', mb: 1 }}
                      >
                        {metric.values[0].label}
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{ fontWeight: 'bold', mb: 1 }}
                      >
                        {metric.values[0].value.toLocaleString()}
                      </Typography>
                      {renderComparisonValue(
                        metric.values[0].value,
                        metric.values[0].compareValue || 0,
                      )}
                    </Box>
                  </Tooltip>
                  <Box
                    sx={{
                      pt: 3,
                      borderTop: '1px solid',
                      borderColor: 'divider',
                    }}
                  >
                    <Tooltip
                      title={`シェルフ経由CV SS数にCVセッション単価(¥${shelfUnitPrice.toLocaleString()})を掛けたもの`}
                      arrow
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{ color: 'text.secondary', mb: 1 }}
                        >
                          {metric.values[1].label}
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{ fontWeight: 'bold', mb: 1 }}
                        >
                          {metric.values[1].isRevenue
                            ? `¥${metric.values[1].value.toLocaleString()}`
                            : metric.values[1].value.toLocaleString()}
                        </Typography>
                        {renderComparisonValue(
                          metric.values[1].value,
                          metric.values[1].compareValue || 0,
                        )}
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary' }}
                        >
                          {metric.values[1].subLabel.split('の')[0]}の
                          <Typography
                            component="span"
                            sx={{
                              color: '#1a1a1a',
                              fontWeight: 'bold',
                              fontSize: '1.1rem',
                            }}
                          >
                            {rates.revenueRate.toFixed(1)}%
                          </Typography>
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <Tooltip
                  title={
                    metric.label === 'シェルフIMPSS数'
                      ? 'シェルフを見たセッションの数'
                      : 'シェルフ内の商品をクリックしたセッションの数'
                  }
                  arrow
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ color: 'text.secondary', mb: 1 }}
                    >
                      {metric.label}
                    </Typography>
                    <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 1 }}>
                      {metric.value.toLocaleString()}
                    </Typography>
                    {renderComparisonValue(
                      metric.value,
                      metric.compareValue || 0,
                    )}
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      {metric.subLabel.includes('クリック率')
                        ? 'クリック率: '
                        : `${metric.subLabel.split('の')[0]}の`}
                      <Typography
                        component="span"
                        sx={{
                          color: '#1a1a1a',
                          fontWeight: 'bold',
                          fontSize: '1.1rem',
                        }}
                      >
                        {metric.subLabel.includes('クリック率')
                          ? rates.clickRate.toFixed(1)
                          : rates.shownRate.toFixed(1)}
                        %
                      </Typography>
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default MetricsSummaryCards;
