import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { SimpleStyleEditorProps, StylePropertyMapping } from '../../types';

/**
 * 簡易スタイル編集コンポーネント
 * 直感的なUIを使って、スタイルプロパティを簡単に編集できます
 */
const SimpleStyleEditor: React.FC<SimpleStyleEditorProps> = ({
  style,
  onStyleChange,
  propertyMapping,
  title,
}) => {
  const theme = useTheme();
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  // 適用されているプロパティリスト
  const appliedProperties = Object.keys(style);

  // 適用可能なプロパティリスト（まだ適用されていないもの）
  const availableProperties = Object.keys(propertyMapping).filter(
    (key) => !appliedProperties.includes(key),
  );

  // スタイルプロパティ変更ハンドラー
  const handleStyleChange = (property: string, value: string) => {
    const newStyle = { ...style, [property]: value };
    onStyleChange(newStyle);
  };

  // スタイルプロパティ削除ハンドラー
  const handleRemoveProperty = (property: string) => {
    const newStyle = { ...style };
    delete newStyle[property];
    onStyleChange(newStyle);
  };

  // 新しいプロパティを追加
  const handleAddProperty = () => {
    if (selectedProperty && !style[selectedProperty]) {
      const config = propertyMapping[selectedProperty];
      let defaultValue = '';

      // プロパティタイプに応じたデフォルト値を設定
      switch (config.type) {
        case 'color':
          defaultValue = '#ffffff';
          break;
        case 'size':
          defaultValue = `${config.min || 0}${config.unit || 'px'}`;
          break;
        case 'select':
          defaultValue = config.options?.[0]?.value || '';
          break;
        default:
          defaultValue = '';
      }

      handleStyleChange(selectedProperty, defaultValue);
      setSelectedProperty(null);
    }
  };

  // 各プロパティタイプに応じたコントロールをレンダリング
  const renderPropertyControl = (
    property: string,
    config: StylePropertyMapping[string],
  ) => {
    const value = style[property] || '';

    switch (config.type) {
      case 'color':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box
              sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                bgcolor: value,
                border: '1px solid #ddd',
                mr: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedProperty(property);
                setColorPickerOpen(true);
              }}
            />
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={(e) => handleStyleChange(property, e.target.value)}
              placeholder={config.label}
              sx={{ flexGrow: 1 }}
            />
            {selectedProperty === property && colorPickerOpen && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1000,
                  mt: 2,
                  ml: 2,
                  boxShadow: 3,
                  p: 1,
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <HexColorPicker
                  color={value}
                  onChange={(color) => handleStyleChange(property, color)}
                />
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => setColorPickerOpen(false)}
                  >
                    完了
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        );

      case 'size':
        const numValue = parseInt(value) || config.min || 0;
        return (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Slider
                size="small"
                value={numValue}
                min={config.min || 0}
                max={config.max || 100}
                step={config.step || 1}
                onChange={(_, newValue) =>
                  handleStyleChange(property, `${newValue}${config.unit || ''}`)
                }
                sx={{ flexGrow: 1, mr: 2 }}
              />
              <TextField
                size="small"
                value={numValue}
                type="number"
                onChange={(e) => {
                  const val = parseInt(e.target.value) || 0;
                  handleStyleChange(property, `${val}${config.unit || ''}`);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {config.unit || ''}
                    </InputAdornment>
                  ),
                }}
                sx={{ width: 100 }}
              />
            </Box>
          </Box>
        );

      case 'select':
        return (
          <FormControl fullWidth size="small">
            <Select
              value={value}
              onChange={(e) => handleStyleChange(property, e.target.value)}
              displayEmpty
            >
              {config.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case 'text':
      default:
        return (
          <TextField
            fullWidth
            size="small"
            value={value}
            onChange={(e) => handleStyleChange(property, e.target.value)}
            placeholder={config.label}
          />
        );
    }
  };

  return (
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>

      {/* スタイルプロパティリスト */}
      <Box sx={{ mb: 3 }}>
        {appliedProperties.length > 0 ? (
          appliedProperties.map((property) => {
            const config = propertyMapping[property];
            if (!config) return null;

            return (
              <Box
                key={property}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                  p: 1,
                  borderRadius: 1,
                  border: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Box sx={{ width: '30%', mr: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                      {config.label}
                    </Typography>
                    {config.description && (
                      <Tooltip title={config.description} placement="top" arrow>
                        <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                          <HelpIcon fontSize="small" color="action" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Box>

                <Box sx={{ flexGrow: 1, position: 'relative' }}>
                  {renderPropertyControl(property, config)}
                </Box>

                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleRemoveProperty(property)}
                  sx={{ ml: 1 }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            );
          })
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ my: 2 }}
          >
            プロパティがまだ設定されていません。
          </Typography>
        )}
      </Box>

      {/* プロパティ追加セクション */}
      {availableProperties.length > 0 && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <FormControl size="small" sx={{ flexGrow: 1, mr: 1 }}>
            <InputLabel id="add-property-label">プロパティを追加</InputLabel>
            <Select
              labelId="add-property-label"
              value={selectedProperty || ''}
              onChange={(e) => setSelectedProperty(e.target.value)}
              label="プロパティを追加"
            >
              {availableProperties.map((property) => (
                <MenuItem key={property} value={property}>
                  {propertyMapping[property].label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddProperty}
            disabled={!selectedProperty}
            size="small"
          >
            追加
          </Button>
        </Box>
      )}

      {appliedProperties.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="caption" color="text.secondary">
            適用中のプロパティ:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
            {appliedProperties.map((property) => (
              <Chip
                key={property}
                label={propertyMapping[property]?.label || property}
                size="small"
                variant="outlined"
                onDelete={() => handleRemoveProperty(property)}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SimpleStyleEditor;
