import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { IShelfSearchItem } from '../../types/post-shelf-item-search.type';

const ItemSliderContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  max-width: 1300px;
`;

const ItemSliderWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 16px;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }
`;

const ItemContainer = styled.a`
  text-decoration: none;
  color: inherit;
  width: 100px;
  margin-right: 8px;
  text-align: center;
`;

const ItemImage = styled.img`
  width: 100px;
  object-fit: cover;
  margin-bottom: 4px;
  border-radius: 4px;
`;

const ItemTitle = styled(Typography)`
  width: 100px;
  font-size: 10px;
  margin-bottom: 4px;
`;

const HighlightedText = styled.span`
  background-color: yellow;
  font-weight: bold;
`;

const highlightText = (text: string, searchStrings: string[]) => {
  if (!searchStrings.length) return text;

  const escapedSearchStrings = searchStrings.map((s) =>
    s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
  );
  const regex = new RegExp(`(${escapedSearchStrings.join('|')})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, i) => {
    if (searchStrings.some((s) => part.toLowerCase() === s.toLowerCase())) {
      return <HighlightedText key={i}>{part}</HighlightedText>;
    }
    return part;
  });
};

interface ItemSliderProps {
  items: IShelfSearchItem[];
  itemTitleSearchStrings: string[];
}

const ItemSlider: React.FC<ItemSliderProps> = ({
  items,
  itemTitleSearchStrings,
}) => {
  return (
    <ItemSliderContainer>
      <ItemSliderWrapper>
        {items.map((item: IShelfSearchItem) => (
          <ItemContainer key={item.aid} href={item.item_url} target="_blank">
            <ItemImage
              src={item.image_url}
              alt={item.item_title}
              loading="lazy"
            />
            <ItemTitle variant="body2">
              {highlightText(item.item_title, itemTitleSearchStrings)}
            </ItemTitle>
            <Typography
              variant="caption"
              sx={{ display: 'block', fontSize: '10px', color: '#666' }}
            >
              {item.category} {`>`} {item.sub_category}
            </Typography>
            <Typography
              variant="caption"
              sx={{ display: 'block', fontSize: '10px' }}
            >
              ランク: {item.pv_count_7d_rank}
            </Typography>
          </ItemContainer>
        ))}
      </ItemSliderWrapper>
    </ItemSliderContainer>
  );
};

export default ItemSlider;
