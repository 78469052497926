import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { deleteStgSearchShelfQueries } from '../../services/shelf-builder/delete-stg-search-shelf-queries';
import { deployToProduction } from '../../services/shelf-builder/deploy-to-production';
import { getStgSearchShelfQueries } from '../../services/shelf-builder/get-stg-search-shelf-queries';
import { postStgSearchShelfQueries } from '../../services/shelf-builder/post-stg-search-shelf-queries';
import { reviewShelfQueries } from '../../services/shelf-builder/review-shelf-queries';
import { updateShelfVisibility } from '../../services/shelf-builder/update-shelf-visibility';
import { postShelfTaglinesKnn } from '../../services/shelf-previewer/post-shelf-taglines-knn';
import { useClient } from '../../store/client.store';
import { IShelfSearchItem } from '../../types/post-shelf-item-search.type';
import {
  IStgSearchShelfQuery,
  ShelfStatus,
} from '../../types/stg-search-shelf-query.type';
import BulkRegistration from './BulkRegistration';
import ShelfEditor from './ShelfEditor';
import { LoadingContainer } from './styles';

export interface ShelfState extends IStgSearchShelfQuery {
  updating?: boolean;
  items?: IShelfSearchItem[];
  visibleItems?: IShelfSearchItem[];
  item_count?: number;
}

export type SimilarShelvesState = {
  text: string;
  cossim_score: number;
  where_condition: string;
  status: string;
}[];

type StatusType =
  | 'deploy'
  | 'review'
  | 'bulk_generate'
  | 'delete'
  | 'update_visibility';

interface Status {
  message: string;
  severity: 'success' | 'error' | 'info';
}

interface StatusItem {
  isProcessing: boolean;
  status: Status | null;
}

type StatusState = Record<StatusType, StatusItem>;

const TableHeader = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px',
      backgroundColor: '#f5f5f5',
      borderRadius: '4px',
      marginBottom: '8px',
      fontWeight: 'bold',
      top: 0,
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      minWidth: 'fit-content',
    }}
  >
    <Typography sx={{ width: '300px', mr: 2 }}>タグライン</Typography>
    <Typography sx={{ width: '100px', mr: 2 }}>ステータス</Typography>
    <Typography sx={{ width: '100px', mr: 2 }}>カテゴリ</Typography>
    <Typography sx={{ width: '100px', mr: 2 }}>サブカテゴリ</Typography>
    <Typography sx={{ width: '100px', mr: 2, textAlign: 'center' }}>
      文字数
    </Typography>
    <Typography sx={{ width: '80px', mr: 2, textAlign: 'center' }}>
      類似度判定
    </Typography>
    <Typography sx={{ width: '50px', mr: 2, textAlign: 'center' }}>
      品質
    </Typography>
    <Typography sx={{ width: '80px', mr: 2, textAlign: 'center' }}>
      アイテム数
    </Typography>
    <Typography sx={{ width: '50px', textAlign: 'center' }}>関連度</Typography>
    <Typography sx={{ width: '220px', textAlign: 'center' }}>
      アクション
    </Typography>
  </Box>
);

const ShelfBuilder: React.FC = () => {
  const { client: clientState } = useClient();
  const [shelves, setShelves] = useState<ShelfState[]>([]);
  const [visibleCount, setVisibleCount] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatuses, setSelectedStatuses] = useState<ShelfStatus[]>([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [subCategoryFilter, setSubCategoryFilter] = useState('');
  const [taglineFilter, setTaglineFilter] = useState('');
  const [statusState, setStatusState] = useState<StatusState>({
    deploy: { isProcessing: false, status: null },
    review: { isProcessing: false, status: null },
    bulk_generate: { isProcessing: false, status: null },
    delete: { isProcessing: false, status: null },
    update_visibility: { isProcessing: false, status: null },
  });
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [shelfSimilarities, setShelfSimilarities] = useState<
    Record<string, SimilarShelvesState>
  >({});

  const statusOptions = [
    { value: ShelfStatus.DRAFT, label: '下書き' },
    { value: ShelfStatus.APPROVED, label: '承認済み' },
    { value: ShelfStatus.DEPLOYED, label: 'デプロイ済み' },
    { value: ShelfStatus.SUSPENDED, label: '停止中' },
    { value: ShelfStatus.DELETED, label: '削除済み' },
  ];

  const handleStatusChange = (event: SelectChangeEvent<ShelfStatus[]>) => {
    const value = event.target.value;
    setSelectedStatuses(
      typeof value === 'string'
        ? [value as ShelfStatus]
        : (value as ShelfStatus[]),
    );
  };

  const updateStatus = useCallback(
    (type: StatusType, isProcessing: boolean, status: Status | null) => {
      setStatusState((prev) => ({
        ...prev,
        [type]: { isProcessing, status },
      }));
    },
    [],
  );

  const clearAllStatuses = useCallback(() => {
    setStatusState((prev) => {
      const newState = { ...prev };
      (Object.keys(newState) as StatusType[]).forEach((key) => {
        newState[key] = {
          ...newState[key],
          status: null,
        };
      });
      return newState;
    });
  }, []);

  const fetchSimilarShelves = useCallback(
    async (shelves: IStgSearchShelfQuery[]) => {
      try {
        const taglines = shelves.map((s) => s.tagline_2).filter(Boolean);
        if (taglines.length === 0) return;

        const knnResults = await postShelfTaglinesKnn(taglines);
        setShelfSimilarities(knnResults);
      } catch (error) {
        console.error('Error fetching similar shelves:', error);
      }
    },
    [],
  );

  const fetchShelves = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getStgSearchShelfQueries();
      setShelves(data);
      await fetchSimilarShelves(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchSimilarShelves]);

  useEffect(() => {
    fetchShelves();
  }, [fetchShelves, clientState.selectedClient]);

  const handleAddShelf = useCallback(async () => {
    const newShelf: IStgSearchShelfQuery = {
      shelf_id: uuidv4(),
      tagline_1: '',
      tagline_2: '',
      where_condition: '',
      category: '',
      sub_category: '',
      status: ShelfStatus.DRAFT,
    };
    try {
      await postStgSearchShelfQueries([newShelf]);
      setShelves((prevShelves) => [newShelf, ...prevShelves]);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleBulkRegistration = useCallback(
    async (newShelves: IStgSearchShelfQuery[]) => {
      try {
        await postStgSearchShelfQueries(newShelves);
        setShelves((prevShelves) => [...newShelves, ...prevShelves]);
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  const handleVisibleCountIncrease = useCallback(() => {
    setVisibleCount((prev) => {
      return prev + 20;
    });
  }, []);

  const handleDeploy = async () => {
    const deployableShelves = shelves.filter(
      (s) => s.status === ShelfStatus.APPROVED,
    );

    try {
      updateStatus('deploy', true, {
        message: `デプロイを開始しました。承認済みシェルフ ${deployableShelves.length}件 をデプロイします...`,
        severity: 'info',
      });

      const response = await deployToProduction();

      updateStatus('deploy', false, {
        message: `デプロイジョブを開始しました。承認済みシェルフ ${deployableShelves.length}件 をデプロイします。ジョブID: ${response.jobId}`,
        severity: 'success',
      });
    } catch (error) {
      console.error('デプロイエラー:', error);
      updateStatus('deploy', false, {
        message: 'デプロイに失敗しました',
        severity: 'error',
      });
    }
  };

  const handleReview = async () => {
    try {
      updateStatus('review', true, {
        message: 'レビュージョブを開始しました...',
        severity: 'info',
      });

      const response = await reviewShelfQueries();

      updateStatus('review', false, {
        message: `レビュージョブを実行しました。ジョブID: ${response.jobId}`,
        severity: 'success',
      });
    } catch (error) {
      console.error('レビューエラー:', error);
      updateStatus('review', false, {
        message: 'レビューに失敗しました',
        severity: 'error',
      });
    }
  };

  const handleBulkGenerate = async () => {
    try {
      updateStatus('bulk_generate', true, {
        message: 'シェルフの一括生成を開始しました...',
        severity: 'info',
      });

      const response = await fetch('/api/shelf/bulk-generate-shelves', {
        method: 'POST',
      });
      const data = await response.json();

      updateStatus('bulk_generate', false, {
        message: `シェルフの一括生成ジョブを実行しました。ジョブID: ${data.jobId}`,
        severity: 'success',
      });
    } catch (error) {
      console.error('一括生成エラー:', error);
      updateStatus('bulk_generate', false, {
        message: 'シェルフの一括生成に失敗しました',
        severity: 'error',
      });
    }
  };

  const handleOpenBulkModal = () => {
    setIsBulkModalOpen(true);
  };

  const handleCloseBulkModal = () => {
    setIsBulkModalOpen(false);
  };

  const handleDeleteShelves = useCallback(async () => {
    const shelfIdsToDelete = shelves
      .filter((shelf) => shelf.status === ShelfStatus.DELETED)
      .map((shelf) => shelf.shelf_id);

    if (shelfIdsToDelete.length === 0) {
      return;
    }

    if (
      !window.confirm(
        `削除済みのシェルフ ${shelfIdsToDelete.length}件 を完全に削除しますか？`,
      )
    ) {
      return;
    }

    try {
      updateStatus('delete', true, {
        message: `削除済みのシェルフ ${shelfIdsToDelete.length}件 を完全に削除しています...`,
        severity: 'info',
      });

      await deleteStgSearchShelfQueries(shelfIdsToDelete);
      setShelves((prevShelves) =>
        prevShelves.filter((shelf) => shelf.status !== ShelfStatus.DELETED),
      );

      updateStatus('delete', false, {
        message: `削除済みのシェルフ ${shelfIdsToDelete.length}件 を完全に削除しました`,
        severity: 'success',
      });
    } catch (error) {
      console.error(error);
      updateStatus('delete', false, {
        message: '完全削除に失敗しました',
        severity: 'error',
      });
    }
  }, [shelves, updateStatus]);

  const handleShelfUpdate = useCallback(async (updatedShelf: ShelfState) => {
    try {
      await postStgSearchShelfQueries([updatedShelf]);
      setShelves((prevShelves) => {
        const newShelves = [...prevShelves];
        const index = newShelves.findIndex(
          (s) => s.shelf_id === updatedShelf.shelf_id,
        );
        if (index !== -1) {
          newShelves[index] = {
            ...newShelves[index],
            ...updatedShelf,
          };
        }
        return newShelves;
      });
    } catch (error) {
      console.error('Error updating shelf:', error);
    }
  }, []);

  const handleUpdateShelfVisibility = async () => {
    try {
      updateStatus('update_visibility', true, {
        message: 'シェルフの表示期間を更新中...',
        severity: 'info',
      });

      const response = await updateShelfVisibility();

      updateStatus('update_visibility', false, {
        message: `シェルフの表示期間を更新しました。ジョブID: ${response.jobId}`,
        severity: 'success',
      });
    } catch (error) {
      console.error('表示期間更新エラー:', error);
      updateStatus('update_visibility', false, {
        message: 'シェルフの表示期間の更新に失敗しました',
        severity: 'error',
      });
    }
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  return (
    <Box
      sx={{
        padding: '24px',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      <Modal
        open={isBulkModalOpen}
        onClose={handleCloseBulkModal}
        aria-labelledby="bulk-registration-modal"
        aria-describedby="bulk-registration-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          <BulkRegistration
            onNewShelvesAdded={(shelves) => {
              handleBulkRegistration(shelves);
              handleCloseBulkModal();
            }}
          />
        </Box>
      </Modal>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          paddingBottom: '12px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4">こだわりシェルフ一覧</Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            (
            {Math.min(
              visibleCount,
              shelves.filter(
                (s) =>
                  (selectedStatuses.length === 0 ||
                    selectedStatuses.includes(s.status)) &&
                  (categoryFilter === '' ||
                    s.category
                      .toLowerCase()
                      .includes(categoryFilter.toLowerCase())) &&
                  (subCategoryFilter === '' ||
                    s.sub_category
                      .toLowerCase()
                      .includes(subCategoryFilter.toLowerCase())) &&
                  (taglineFilter === '' ||
                    s.tagline_1
                      .toLowerCase()
                      .includes(taglineFilter.toLowerCase()) ||
                    s.tagline_2
                      .toLowerCase()
                      .includes(taglineFilter.toLowerCase())),
              ).length,
            )}
            件表示 / 全
            {
              shelves.filter(
                (s) =>
                  (selectedStatuses.length === 0 ||
                    selectedStatuses.includes(s.status)) &&
                  (categoryFilter === '' ||
                    s.category
                      .toLowerCase()
                      .includes(categoryFilter.toLowerCase())) &&
                  (subCategoryFilter === '' ||
                    s.sub_category
                      .toLowerCase()
                      .includes(subCategoryFilter.toLowerCase())) &&
                  (taglineFilter === '' ||
                    s.tagline_1
                      .toLowerCase()
                      .includes(taglineFilter.toLowerCase()) ||
                    s.tagline_2
                      .toLowerCase()
                      .includes(taglineFilter.toLowerCase())),
              ).length
            }
            件)
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddShelf}
            startIcon={<AddIcon />}
          >
            新規登録
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenBulkModal}
            startIcon={<PlaylistAddIcon />}
          >
            一括登録
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBulkGenerate}
            disabled={statusState.bulk_generate?.isProcessing}
            startIcon={
              statusState.bulk_generate?.isProcessing ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <AutoAwesomeIcon />
              )
            }
          >
            一括生成
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteShelves}
            startIcon={<DeleteForeverIcon />}
          >
            削除済みシェルフを完全に削除
          </Button>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Tooltip
            title="下書き状態の全てのシェルフをレビューします"
            arrow
            placement="top"
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={handleReview}
              disabled={statusState.review?.isProcessing}
              startIcon={
                statusState.review?.isProcessing ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <AutoFixHighIcon />
                )
              }
            >
              1. レビュー実行
            </Button>
          </Tooltip>
          <Tooltip title="シェルフの表示期間を更新します" arrow placement="top">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleUpdateShelfVisibility}
              disabled={statusState.update_visibility?.isProcessing}
              startIcon={
                statusState.update_visibility?.isProcessing ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <VisibilityIcon />
                )
              }
            >
              2. シェルフ表示期間をフィルタ
            </Button>
          </Tooltip>
          <Tooltip
            title="承認済みのシェルフを本番環境に反映します"
            arrow
            placement="top"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeploy}
              disabled={statusState.deploy?.isProcessing}
              startIcon={
                statusState.deploy?.isProcessing ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <RocketLaunchIcon />
                )
              }
            >
              3. 本番反映
            </Button>
          </Tooltip>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="status-filter-label">ステータス</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              multiple
              value={selectedStatuses}
              onChange={handleStatusChange}
              input={<OutlinedInput label="ステータス" />}
              renderValue={(selected) =>
                selected
                  .map(
                    (status) =>
                      statusOptions.find((opt) => opt.value === status)?.label,
                  )
                  .join(', ')
              }
              size="small"
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={selectedStatuses.includes(option.value)} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel htmlFor="category-filter">
              カテゴリ（完全一致）
            </InputLabel>
            <OutlinedInput
              id="category-filter"
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
              label="カテゴリ（完全一致）"
              size="small"
            />
          </FormControl>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel htmlFor="sub-category-filter">
              サブカテゴリ（完全一致）
            </InputLabel>
            <OutlinedInput
              id="sub-category-filter"
              value={subCategoryFilter}
              onChange={(e) => setSubCategoryFilter(e.target.value)}
              label="サブカテゴリ（完全一致）"
              size="small"
            />
          </FormControl>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel htmlFor="tagline-filter">
              タグライン（部分一致）
            </InputLabel>
            <OutlinedInput
              id="tagline-filter"
              value={taglineFilter}
              onChange={(e) => setTaglineFilter(e.target.value)}
              label="タグライン（部分一致）"
              size="small"
            />
          </FormControl>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'relative',
          overflowX: 'auto',
          '& > *': {
            minWidth: 'fit-content',
          },
        }}
      >
        <TableHeader />
        {shelves
          .filter(
            (s) =>
              (selectedStatuses.length === 0 ||
                selectedStatuses.includes(s.status)) &&
              (categoryFilter === '' || categoryFilter === s.category) &&
              (subCategoryFilter === '' ||
                subCategoryFilter === s.sub_category) &&
              (taglineFilter === '' || s.tagline_2.includes(taglineFilter)),
          )
          .slice(0, visibleCount)
          .map((shelf) => (
            <ShelfEditor
              key={shelf.shelf_id}
              shelf={shelf}
              onShelfUpdate={handleShelfUpdate}
              initialSimilarShelves={shelfSimilarities[shelf.tagline_2] || []}
            />
          ))}
        {visibleCount <
          shelves.filter(
            (s) =>
              selectedStatuses.length === 0 ||
              selectedStatuses.includes(s.status),
          ).length && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
            <Button variant="outlined" onClick={handleVisibleCountIncrease}>
              さらに表示
            </Button>
          </Box>
        )}
      </Box>

      <Snackbar
        open={Object.values(statusState).some(
          (state) => state?.status !== null,
        )}
        autoHideDuration={6000}
        onClose={clearAllStatuses}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {(() => {
          const activeStatus = Object.values(statusState).find(
            (state) => state?.status !== null,
          );
          if (!activeStatus?.status) return null;
          return (
            <Alert
              onClose={clearAllStatuses}
              severity={activeStatus.status.severity}
              sx={{ width: '100%' }}
            >
              {activeStatus.status.message}
            </Alert>
          );
        })()}
      </Snackbar>
    </Box>
  );
};

export default ShelfBuilder;
