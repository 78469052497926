import { PageTypeEnum } from '../insightx-utils/enums/page-type.enum';
import { ShelfTypeEnum } from '../insightx-utils/enums/shelf-type.enum';
import {
  IItemMetadataResponse,
  IItemSearchRequest,
  IItemSearchResponse,
  Item,
} from '../types/item-search.type';
import { IPostRecommendsReq } from '../types/post-recommends.type';
import api from './api';
import { postRecommends } from './shelf-previewer/post-recommends';

class ItemSearchService {
  /**
   * 商品検索
   * @param params 検索パラメータ
   */
  async searchItems(params: IItemSearchRequest): Promise<IItemSearchResponse> {
    const response = await api.post<IItemSearchResponse>(
      '/item-search/search',
      params,
    );
    return response.data;
  }

  /**
   * AIDリストを使用して商品詳細を取得
   * @param aids AIDリスト
   */
  async searchItemsByAids(aids: string[]): Promise<IItemSearchResponse> {
    const response = await api.post<IItemSearchResponse>(
      '/item-search/search-by-aids',
      { aids },
    );
    return response.data;
  }

  /**
   * フィールドのメタデータを取得
   * @param field メタデータ取得対象のフィールド名
   */
  async getMetadata(field: string): Promise<IItemMetadataResponse> {
    const response = await api.post<IItemMetadataResponse>(
      '/item-search/metadata',
      { field },
    );
    return response.data;
  }

  /**
   * 関連商品を検索
   * @param seedAid 関連商品検索の起点となる商品AID
   * @param session セッションID（ランダムなUUIDでも可）
   * @returns 関連商品の検索結果
   */
  async searchRelatedItems(
    seedAid: string,
    session: string = `session_${Date.now()}`,
  ): Promise<IItemSearchResponse> {
    try {
      // 1. レコメンドAPIを呼び出して関連商品のAIDを取得
      const recommendsRequest: IPostRecommendsReq = {
        pageType: PageTypeEnum.Pdp,
        session: session,
        seedAids: [seedAid],
        shelfConfig: {
          shelfOrders: [
            {
              shelfType: ShelfTypeEnum.related,
              shelfNum: 1,
            },
          ],
        },
      };

      const recommendsResponse = await postRecommends(recommendsRequest);

      // 2. レコメンドから関連商品のAIDリストを抽出
      let relatedAids: string[] = [];

      if (recommendsResponse.shelves && recommendsResponse.shelves.length > 0) {
        const relatedShelf = recommendsResponse.shelves.find(
          (shelf) => shelf.shelfType === ShelfTypeEnum.related,
        );

        if (
          relatedShelf &&
          relatedShelf.items &&
          relatedShelf.items.length > 0
        ) {
          relatedAids = relatedShelf.items.map((item) => item.aid);
        }
      }

      // 3. 検索対象商品自身のAIDを先頭に追加（重複を避けるため一度除外してから追加）
      relatedAids = relatedAids.filter((aid) => aid !== seedAid);
      relatedAids.unshift(seedAid);

      // 4. AIDリストが空でなければ、商品詳細を取得
      if (relatedAids.length > 0) {
        const response = await this.searchItemsByAids(relatedAids);

        // 5. レコメンドの順番に並び替え
        const sortedItems = this.sortItemsByAidOrder(
          response.items,
          relatedAids,
        );

        // 6. ソートした結果をレスポンスに設定して返す
        return {
          ...response,
          items: sortedItems,
        };
      }

      // 関連商品が見つからない場合でも、検索対象の商品は表示する
      const response = await this.searchItemsByAids([seedAid]);
      return {
        ...response,
        items: response.items,
      };
    } catch (error) {
      console.error('関連商品検索エラー:', error);
      throw error;
    }
  }

  /**
   * 商品一覧をAID順に並び替え
   * @param items 商品リスト
   * @param aidOrder AIDの順序リスト
   * @returns 並び替えられた商品リスト
   */
  private sortItemsByAidOrder(items: Item[], aidOrder: string[]): Item[] {
    // AIDの順序をMapで管理
    const aidOrderMap = new Map<string, number>();
    aidOrder.forEach((aid, index) => {
      aidOrderMap.set(aid, index);
    });

    // 商品を順序マップに基づいてソート
    return [...items].sort((a, b) => {
      const orderA = aidOrderMap.get(a.aid) ?? Number.MAX_SAFE_INTEGER;
      const orderB = aidOrderMap.get(b.aid) ?? Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    });
  }
}

export const itemSearchService = new ItemSearchService();
